import React, { useContext } from "react";
import { StoreContext } from "../../store/store";
import { SVGImages } from "./SVGImages";
import { getListComponent } from "./Lists";
import Auth from "../../services/auth";
import { ViewRefs } from "../../store/refs";
import { setPanelStateOut } from "./functions";
import { State, Action } from "../../store/storetypes";

interface Props {
  auth: Auth;
  viewrefs: React.MutableRefObject<ViewRefs>;
  clickstate: { active: boolean; box: DOMRect | null; header: string };
  dashboardCleanupComponents: (key: string) => void;
}

export const Click: React.FC<Props> = ({
  auth,
  viewrefs,
  clickstate,
  dashboardCleanupComponents,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { panelExpanded } = store;
  if (clickstate.active) {
    const box = clickstate.box;
    const SvgImage = SVGImages(clickstate.header, true);
    const ListComponent = getListComponent(clickstate.header);
    if (box && SvgImage) {
      return (
        <>
          <div
            className="transition-left"
            style={{
              position: "absolute",
              left: panelExpanded ? 225 : 75,
              top: box.top,
              zIndex: 9999,
              minHeight: 158,
              minWidth: 245,
              backgroundColor: "#363636",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 800,
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6,
            }}
            onMouseLeave={() =>
              setPanelStateOut(clickstate.header, dispatch, viewrefs)
            }
          >
            <div
              style={{
                display: "flex",
                marginTop: 20,
                marginLeft: 20,
                justifyContent: "left",
              }}
            >
              <div>
                <SvgImage fill="#ffffff" />
              </div>
              <div style={{ marginLeft: 20 }}>{clickstate.header}</div>
            </div>
            <ListComponent
              auth={auth}
              viewrefs={viewrefs}
              dashboardCleanupComponents={dashboardCleanupComponents}
            />
          </div>
        </>
      );
    } else return null;
  } else return null;
};
