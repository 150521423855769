import * as React from "react";
import { SVGProps } from "react";

const LayersIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.026}
    height={27.025}
    viewBox="0 0 27.026 27.025"
    {...props}
  >
    <defs>{/* <style>{".a_LayersIconLarge{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(1 1)">
      <path
        className="a_LayersIconLarge"
        d="M15.513,2a1,1,0,0,1,.447.106L28.472,8.362a1,1,0,0,1,0,1.789L15.96,16.407a1,1,0,0,1-.894,0L2.553,10.151a1,1,0,0,1,0-1.789L15.065,2.106A1,1,0,0,1,15.513,2ZM25.789,9.256,15.513,4.118,5.236,9.256l10.276,5.138Z"
        transform="translate(-3 -3)"
      />
      <path
        className="a_LayersIconLarge"
        d="M15.513,32.756a1,1,0,0,1-.447-.106L2.553,26.394a1,1,0,1,1,.894-1.789l12.065,6.033,12.065-6.033a1,1,0,0,1,.894,1.789L15.96,32.651A1,1,0,0,1,15.513,32.756Z"
        transform="translate(-3 -6.731)"
      />
      <path
        className="a_LayersIconLarge"
        d="M15.513,25.256a1,1,0,0,1-.447-.106L2.553,18.894a1,1,0,1,1,.894-1.789l12.065,6.033,12.065-6.033a1,1,0,1,1,.894,1.789L15.96,25.151A1,1,0,0,1,15.513,25.256Z"
        transform="translate(-3 -5.488)"
      />
    </g>
  </svg>
);

export default LayersIconLarge;
