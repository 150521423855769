import { toJpeg } from "html-to-image";
import jsPDF from "jspdf";
import { Map } from "ol";
import { Size } from "ol/size";

const NO_OP = () => {};

// PDF settings
const OUTPUT_FORMAT = "a4";
const OUTPUT_DIMENSIONS = [297, 210]; // A4 based
const OUTPUT_RESOLUTION = 300;

// Map viewport settings to fit PDF
const OUTPUT_WIDTH = Math.round(
  (OUTPUT_DIMENSIONS[0] * OUTPUT_RESOLUTION) / 25.4
);
const OUTPUT_HEIGHT = Math.round(
  (OUTPUT_DIMENSIONS[1] * OUTPUT_RESOLUTION) / 25.4
);
const OUTPUT_SIZE = [OUTPUT_WIDTH, OUTPUT_HEIGHT];

/**
 * Manipulates current map view so it can be exported as a pdf.
 * Returns a cancel function. When that function is called the map will be reset to its initial state.
 */
export function mapViewToPdf(map: Map, callback: () => void): () => void {
  const initialSize = map.getSize();
  if (!initialSize) {
    callback();
    return NO_OP;
  }
  const intialResolution = map.getView().getResolution();
  if (!intialResolution) {
    callback();
    return NO_OP;
  }

  var cancelled = false;
  const cancelFn = () => {
    cancelled = true;
    setMapViewport(map, initialSize, intialResolution);
    callback();
  };

  // Set up listener for when changes to map size change is complete.
  map.once("rendercomplete", function () {
    if (cancelled) {
      return;
    }
    toJpeg(map.getViewport(), {
      width: OUTPUT_WIDTH,
      height: OUTPUT_HEIGHT,
      filter: (element: HTMLElement) =>
        !element.className.includes("ol-control"),
    })
      .then((dataUrl) => {
        if (cancelled) {
          return;
        }
        var pdf = new jsPDF("landscape", undefined, OUTPUT_FORMAT);
        pdf.addImage(
          dataUrl,
          "JPEG",
          0,
          0,
          OUTPUT_DIMENSIONS[0],
          OUTPUT_DIMENSIONS[1]
        );
        pdf.save("AlchemyAnalytics.pdf");
        // Reset original map size
        setMapViewport(map, initialSize, intialResolution);
        document.body.style.cursor = "auto";
        callback();
      })
      .catch((err) => {
        console.error("Could not print current view", err);
      });
  });

  // Change map to size for pdf
  const scaling = Math.min(
    OUTPUT_WIDTH / initialSize[0],
    OUTPUT_HEIGHT / initialSize[1]
  );
  const outputResolution = intialResolution / scaling;
  setMapViewport(map, OUTPUT_SIZE, outputResolution);
  return cancelFn;
}

function setMapViewport(map: Map, size: Size, resolution: number) {
  map.setSize(size);
  map.getView().setResolution(resolution);
}
