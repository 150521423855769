import React from "react";
import { Export } from "../api/AlchemyApiTypes";
import { Loading } from "../components/Loading";
import LoginLogo from "../images/svg/logos/LoginLogo";

interface Props {
  cancelExport: (exportCancelFn: (() => void) | null) => void;
  exporting: Export;
}

export const ExportPage: React.FC<Props> = ({ cancelExport, exporting }) => {
  return (
    <div
      style={{
        position: "absolute",
        height: "100vh",
        width: "100vw",
        zIndex: 1000,
        top: 0,
        left: 0,
        backgroundColor: "white",
        display: "flex",
      }}
    >
      <div style={{ margin: "auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 40,
          }}
        >
          {/* <img
            alt="AlchemyAnalytics"
            style={{ margin: "auto", width: "240px" }}
            src={logo}
          /> */}
          <LoginLogo fill="#000000" />
        </div>
        <div style={{ display: "flex" }}>
          <Loading></Loading>
        </div>
        <h2 className="mt-2 text-center">Exporting</h2>
        <div>
          <i className="">This can take take as long as a few minutes...</i>
        </div>
        <div className="mt-2">
          <button
            className="btn btn-warning btn-block"
            onClick={() => cancelExport(exporting.exportCancelFn)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
