import React, { useContext } from "react";
import { MapBaseLayer } from "../../map/types";
import { Loader } from "../Loader";
import Auth from "../../services/auth";
import InfoIcon from "../../images/svg/other/InfoIcon";
import _ from "lodash";
import { StoreContext } from "../../store/store";
import { ViewRefs } from "../../store/refs";
import { State, Action, actionTypes } from "../../store/storetypes";

interface Props {
  auth: Auth;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const MapLayers: React.FC<Props> = () => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { mapSettings, showlegend } = store;

  const toggleLayers = (maplayer: MapBaseLayer) => {
    for (const layer of maplayer.layers) {
      layer.setVisible(!layer.getVisible());
      let newshowlegend = { ...showlegend };
      if (maplayer.legend) {
        newshowlegend[maplayer.legend] = !_.get(showlegend, maplayer.legend);
        dispatch({
          type: actionTypes.SET_SHOW_LEGEND,
          payload: newshowlegend,
        });
      }
    }
    dispatch({
      type: actionTypes.SET_FORCE_UPDATE,
      payload: Math.random(),
    });
  };

  if (mapSettings && mapSettings.layers.length > 0) {
    return (
      <div style={{ width: 245, backgroundColor: "transparent", padding: 20 }}>
        <div
          style={{
            borderTop: "2px solid #757575",
            marginTop: 10,
            paddingTop: 10,
          }}
        ></div>
        {mapSettings.layers.map((mapLayer, i) => (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              padding: 5,
            }}
            key={i}
          >
            <input
              style={{ accentColor: "#ffffff", cursor: "pointer" }}
              type="checkbox"
              onChange={() => toggleLayers(mapLayer)}
              checked={mapLayer.layers[0]?.getVisible()}
            />
            <div
              style={{
                fontSize: 14,
                fontWeight: 600,
                pointerEvents: "none",
                marginLeft: 20,
                width: 140,
              }}
            >
              {mapLayer.name}
            </div>
            <div
              style={{ marginLeft: 5 }}
              title={mapLayer.info ? mapLayer.info : mapLayer.name}
            >
              <InfoIcon fill="#ffffff" />
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <Loader name={"Map Layers"} />;
  }
};
