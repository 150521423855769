import * as React from "react";
import { SVGProps } from "react";

const SettingsIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.131}
    height={18.131}
    viewBox="0 0 18.131 18.131"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a"
        d="M15.836,13A2.836,2.836,0,1,1,13,15.836,2.839,2.839,0,0,1,15.836,13Zm0,4.672A1.836,1.836,0,1,0,14,15.836,1.838,1.838,0,0,0,15.836,17.672Z"
        transform="translate(-7.271 -7.271)"
      />
      <path
        className="a"
        d="M10.128,19.131A2.06,2.06,0,0,1,8.07,17.074V17.01a.78.78,0,0,0-.514-.713l-.029-.012a.785.785,0,0,0-.864.155l-.044.044a2.058,2.058,0,1,1-2.911-2.911l.044-.044a.781.781,0,0,0,.155-.864l0,0a.784.784,0,0,0-.718-.476h-.13a2.057,2.057,0,1,1,0-4.115h.064a.78.78,0,0,0,.713-.514l.012-.029a.781.781,0,0,0-.155-.864l-.044-.044A2.058,2.058,0,1,1,6.557,3.709l.045.045a.785.785,0,0,0,.864.155.5.5,0,0,1,.142-.039.784.784,0,0,0,.4-.681v-.13a2.057,2.057,0,0,1,4.115,0v.07a.784.784,0,0,0,.476.716l0,0a.785.785,0,0,0,.864-.155l.044-.044a2.058,2.058,0,1,1,2.911,2.911l-.044.044a.781.781,0,0,0-.155.864.5.5,0,0,1,.039.142.784.784,0,0,0,.681.4h.13a2.057,2.057,0,1,1,0,4.115H17a.784.784,0,0,0-.716.476l0,0a.781.781,0,0,0,.155.864l.045.045a2.058,2.058,0,1,1-2.911,2.911l-.045-.045a.785.785,0,0,0-.864-.155l0,0a.784.784,0,0,0-.476.718v.13A2.06,2.06,0,0,1,10.128,19.131ZM7.917,15.364A1.775,1.775,0,0,1,9.07,16.992s0,.008,0,.012v.07a1.057,1.057,0,0,0,2.115,0v-.132a1.794,1.794,0,0,1,3.046-1.276l0,0,.047.047a1.058,1.058,0,1,0,1.5-1.5l-.047-.047,0,0A1.8,1.8,0,0,1,17,11.123h.072a1.057,1.057,0,1,0,0-2.115h-.132a1.785,1.785,0,0,1-1.635-1.082.5.5,0,0,1-.039-.159,1.775,1.775,0,0,1,.4-1.868l0,0,.047-.047a1.058,1.058,0,1,0-1.5-1.5l-.047.047,0,0a1.8,1.8,0,0,1-3.046-1.274V3.057a1.057,1.057,0,0,0-2.115,0V3.19A1.785,1.785,0,0,1,7.926,4.825a.5.5,0,0,1-.159.039,1.785,1.785,0,0,1-1.867-.4l0,0L5.85,4.415a1.058,1.058,0,1,0-1.5,1.5l.047.047,0,0a1.776,1.776,0,0,1,.363,1.954A1.775,1.775,0,0,1,3.139,9.07H3.057a1.057,1.057,0,1,0,0,2.115H3.19a1.8,1.8,0,0,1,1.276,3.046l0,0-.047.047a1.058,1.058,0,1,0,1.5,1.5l.047-.047,0,0a1.785,1.785,0,0,1,1.954-.363Z"
        transform="translate(-1.5 -1.5)"
      />
    </g>
  </svg>
);

export default SettingsIconSmall;
