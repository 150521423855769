import * as d3 from "d3";

export function draw(container, config) {
  const {
    data,
    width: cwidth,
    height: cheight,
    layout,
    bars,
    svgBackground,
    axes,
    getColour,
    onMouseOver,
    labels,
  } = config;

  const svg = d3.select(container);
  svg.selectAll("g").remove();
  /** d3 margin convention */
  const width = cwidth - layout.margin.left - layout.margin.right;
  const height = cheight - layout.margin.top - layout.margin.bottom;
  /** SVG Styles */
  svg.style("background-color", svgBackground);
  /** create some groups  */

  const Y_axesGroup = svg
    .append("g")
    .attr(
      "transform",
      "translate(" + layout.margin.left + "," + layout.margin.top + ")"
    );
  const barGroup = svg
    .append("g")
    .attr(
      "transform",
      "translate(" + layout.margin.left + "," + layout.margin.top + ")"
    );
  const axesGroup = svg
    .append("g")
    .attr(
      "transform",
      "translate(" + layout.margin.left + "," + layout.margin.top + ")"
    );
  const labelGroup = svg
    .append("g")
    .attr(
      "transform",
      "translate(" + layout.margin.left + "," + layout.margin.top + ")"
    );
  /** scales */
  let x = d3
    .scaleBand()
    .range([0, width])
    .paddingInner(bars.padding.inner)
    .paddingOuter(bars.padding.outer)
    .domain(
      data.map(function (d) {
        return d.ARN;
      })
    );

  let y = d3
    .scaleLinear()
    .range([height, 0])
    .domain([
      0,
      d3.max(data, function (d) {
        return d.NumberOfFeatures;
      }),
    ]);

  /** axes */
  const xAxis = d3.axisBottom(x).tickSize(6).tickPadding(10);
  const yAxis = d3.axisLeft(y).tickSizeInner(6).tickSizeOuter(0);
  /** yAxis2 is for gridlines */
  const yAxis2 = d3
    .axisLeft(y)
    .tickSizeInner(-width)
    .tickSizeOuter(0)
    .tickFormat("");

  /** add to SVG */
  addXaxis();
  addYaxis();
  addBars();
  addLabels();

  function addXaxis() {
    const xAxisGroup = axesGroup
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);
    /** Style the xAxis */
    xAxisGroup.selectAll("line").attr("stroke", axes.ticks.colour);
    xAxisGroup.selectAll(".domain").attr("stroke", axes.ticks.colour);
    xAxisGroup
      .selectAll("text")
      .attr("fill", axes.text.colour)
      .attr("transform", "rotate(90) translate(10,-20)")
      .style("text-anchor", "start")
      .style("font-size", axes.fontSize.x);
  }
  function addYaxis() {
    const yAxisGroup = Y_axesGroup.append("g").call(yAxis);
    /** Style the y axis */
    yAxisGroup.selectAll("line").style("stroke", axes.ticks.colour);
    yAxisGroup.selectAll(".domain").attr("stroke", axes.ticks.colour);
    yAxisGroup
      .selectAll("text")
      .attr("fill", axes.text.colour)
      .style("font-size", axes.fontSize.y);
    /** gridlines */
    const gridlineGroup = Y_axesGroup.append("g").call(yAxis2);
    /** style the gridlines */
    gridlineGroup.selectAll("line").style("stroke", axes.gridlines.colour);
    gridlineGroup.selectAll(".domain").attr("stroke", axes.gridlines.colour);
  }
  function addLabels() {
    // text label for the y axis
    labelGroup
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - layout.margin.left)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-size", axes.fontSize.labely)
      .text(labels.y);

    // text label for the x axis
    labelGroup
      .append("text")
      .attr(
        "transform",
        "translate(" +
          width / 2 +
          " ," +
          (height + layout.margin.top + 90) +
          ")"
      )
      .style("text-anchor", "middle")
      .style("font-size", axes.fontSize.labelx)
      .text(labels.x);
  }
  function addBars() {
    /** append the rectangles for the bar chart */
    barGroup
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", function (d) {
        return x(d.ARN);
      })
      .attr("width", function (d) {
        return x.bandwidth();
      })
      .attr("y", function (d) {
        return y(d.NumberOfFeatures);
      })
      .attr("height", function (d) {
        return height - y(d.NumberOfFeatures);
      })
      .style("fill", (d) => {
        return getColour(d.NumberOfFeatures);
      })
      .on("mouseover", function (e, d) {
        onMouseOver(d, "over");
      })
      .on("mouseout", function (e, d) {
        onMouseOver(d, "out");
      });
  }
}
