import React, { useContext } from "react";
import Layer from "ol/layer/Layer";
import Source from "ol/source/Source";
import Auth from "../services/auth";
import { icons } from "../map/icons";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { ViewRefs } from "../store/refs";

interface Props {
  auth: Auth;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const Markers: React.FC<Props> = ({ viewrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { mapInfo } = store;
  const markerHeaders = mapInfo?.mapConfig?.view_config.markers;

  const markerLayerTypes = Object.keys(
    viewrefs.current.markerVectorLayers
  ).filter((item) => {
    return viewrefs.current.markerVectorLayers[item];
  });

  const getHeader = (name: string) => {
    if (markerHeaders && name in markerHeaders) {
      return markerHeaders[name];
    } else return name;
  };

  const toggleLayers = (layer: Layer<Source, any> | null) => {
    if (layer) {
      layer.setVisible(!layer.getVisible());
    }
    dispatch({
      type: actionTypes.SET_FORCE_UPDATE,
      payload: Math.random(),
    });
  };

  if (markerLayerTypes && markerLayerTypes.length > 0) {
    return (
      <div
        style={{
          backgroundColor: "transparent",
          padding: 20,
        }}
      >
        <div
          style={{
            borderTop: "2px solid #757575",
            marginTop: 10,
            paddingTop: 10,
          }}
        ></div>
        {markerLayerTypes.map((item: string, i: number) => {
          return (
            <div
              key={i}
              style={{
                verticalAlign: "top",
                display: "flex",
                justifyContent: "flexStart",
                alignItems: "flexStart",
              }}
            >
              <input
                style={{ accentColor: "#ffffff", cursor: "pointer" }}
                type="checkbox"
                onChange={() =>
                  toggleLayers(viewrefs.current.markerVectorLayers[item])
                }
                checked={
                  viewrefs.current.markerVectorLayers[item]?.getVisible() ||
                  false
                }
              />
              <img
                src={icons[item]}
                alt="alt"
                style={{
                  width: 15,
                  height: 15,
                  marginRight: 10,
                  marginLeft: 10,
                  marginTop: 3,
                }}
              />
              <div>{getHeader(item)}</div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};
