import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import close from "../../../images/closeInverse.png";
import {
  fetchParcel,
  fetchBuildings,
  fetchFuel,
} from "../../../api/AlchemyAnalytics";
import {
  DivPosition,
  BuildingAnalysisScore,
  BuildingAnalysisAreaDefaults,
} from "../../../api/AlchemyApiTypes";
import { getDefaultPositionTopRight } from "../functions";
import { getExpandedScores } from "../../dashboards/building/functions";
import { Rnd } from "react-rnd";
import { ButtonComponent } from "../../ButtonComponent";
import loadingGIF from "../../../images/ZZ5H.gif";
import { StoreContext } from "../../../store/store";
import { State, Action, actionTypes } from "../../../store/storetypes";
import {
  drawPolygonOnMap,
  getBox,
  drawCombinedPolygons,
  filterFuelbyCheckedZones,
  zoomToFeatures,
  addInnerPolygons,
  addHexFueltoMap,
  getBoxFromZone,
  isBuildingInParcel,
  setHighlightedBuilding,
} from "./functions";
import {
  DisableFuelObjects,
  DisableFuelObjectDefaults,
  FilteredCoordinates,
  FilteredCoordinatesDefaults,
  FuelObject,
  FuelObjects,
  FuelObjectDefaults,
  H3Indexes,
  H3IndexesDefaults,
  Props,
  ZoneObject,
  ZoneObjects,
  ZoneObjectDefaults,
} from "./types";
import { ExportReport } from "./ExportReport";

interface Fuel {
  latitude: string;
  longitude: string;
}

export const BuildingAnalysis: React.FC<Props> = ({
  mapviewRoot,
  auth,
  viewrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { dashboardSettings, dashboardSettingModeState, selectedBuilding } =
    store;
  const DashboardDimensions = { height: 370, width: 340 };

  const map = viewrefs.current.map;

  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });
  const [apn, setApn] = useState<string>("13245000"); //("131824311015");131823401010
  // const [fid, setFid] = useState<string>("23380");
  //incline example parcels
  // 12710000
  // 13245000
  // 13220209
  // 13204000
  const [fuelInfo, setFuelInfo] = useState<FuelObjects>(
    JSON.parse(JSON.stringify(FuelObjectDefaults))
  );
  const [zoneInfo, setZoneInfo] = useState<ZoneObjects>(
    JSON.parse(JSON.stringify(ZoneObjectDefaults))
  );
  const zoneRef = useRef<ZoneObjects>(
    JSON.parse(JSON.stringify(ZoneObjectDefaults))
  );
  const disablefuel = useRef<DisableFuelObjects>(
    JSON.parse(JSON.stringify(DisableFuelObjectDefaults))
  );
  const disablezone = useRef<boolean>(true);
  const [triggerRefresh, setTriggerRefresh] = useState<number>(0);
  const h3indexes = useRef<H3Indexes>(
    JSON.parse(JSON.stringify(H3IndexesDefaults))
  );
  const filteredCoordinates = useRef<FilteredCoordinates>(
    JSON.parse(JSON.stringify(FilteredCoordinatesDefaults))
  );

  if (triggerRefresh) {
    //use somehow
  }

  const [selectedKEY, setSelectedKEY] = useState<{
    APN: string;
    FID: string;
  } | null>(null);
  const [disableDrag, setDisableDrag] = useState<boolean>(false);

  const [inProgress, setInProgress] = useState<number>(0);

  const setFuelScore = useCallback(
    (score: BuildingAnalysisScore | null) => {
      dispatch({
        type: actionTypes.SET_BUILDINGANALYSIS_SCORE,
        payload: score,
      });
    },
    [dispatch]
  );

  const setSelectedBuilding = useCallback(
    (building: string) => {
      dispatch({
        type: actionTypes.SET_SELECTED_BUILDING,
        payload: building,
      });
    },
    [dispatch]
  );

  const onClose = () => {
    // setSelected("BuildingAnalysis");
    const newSettings = { ...dashboardSettings };
    newSettings["BuildingAnalysis"].selected = false;
    dispatch({
      type: actionTypes.SET_DASHBOARD_SETTINGS,
      payload: newSettings,
    });
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.SET_CLEAR_DASHBOARD,
        payload: "BuildingAnalysis",
      });
    };
  }, [dispatch]);

  useEffect(() => {
    setHighlightedBuilding(selectedBuilding, viewrefs);
    const index =
      viewrefs.current.buildingAnalysisCardOrder.indexOf(selectedBuilding);
    if (index > -1) {
      viewrefs.current.buildingAnalysisCardOrder.splice(index, 1);
      viewrefs.current.buildingAnalysisCardOrder.unshift(selectedBuilding);
    }
    dispatch({
      type: actionTypes.SET_BUILDINGANALYSIS_REFRESH_SCORECARD,
      payload: Math.random(),
    });
  }, [selectedBuilding, viewrefs, dispatch]);

  const onMouseenter = () => {
    setDisableDrag(true);
  };
  const onMouseleave = () => {
    setDisableDrag(false);
  };

  const toggleItems = (item: string) => {
    const fuel: FuelObject = fuelInfo[item];
    const checked = !fuel.checked;
    const allfuels = { ...fuelInfo, [item]: { ...fuel, checked } };
    setFuelInfo(allfuels);

    if (map) {
      if (checked) {
        map.addLayer(viewrefs.current.buildingAnalysisLayer[item]);
      } else {
        map.removeLayer(viewrefs.current.buildingAnalysisLayer[item]);
      }
    }
  };

  const toggleZones = (item: string) => {
    const zone: ZoneObject = zoneInfo[item];
    const checked = !zone.checked;
    const allzones = { ...zoneInfo, [item]: { ...zone, checked } };
    setZoneInfo(allzones);
    zoneRef.current = allzones;
    filterFuelbyCheckedZones(viewrefs, zoneRef, h3indexes);
  };

  const enableItems = (item: string) => {
    disablefuel.current = { ...disablefuel.current, [item]: false };
    setTriggerRefresh(Math.random());
  };
  const enableZones = () => {
    disablezone.current = false;
    setTriggerRefresh(Math.random());
  };

  useEffect(() => {
    let mounted = true;
    if (selectedKEY && selectedKEY.APN && selectedKEY.FID) {
      const asyncfunction = async () => {
        if (mounted) {
          setInProgress(1);
        }
        const polydata = await fetchParcel(
          auth,
          selectedKEY.APN,
          selectedKEY.FID
        );
        if (polydata) {
          const polyobj = JSON.parse(polydata[0].coordinates);
          const box = getBox(polyobj);
          const parcel_area = drawPolygonOnMap(
            polyobj,
            map,
            viewrefs,
            "parcel",
            0
          );
          if (parcel_area < 100000) {
            try {
              const buildings = await fetchBuildings(auth, box);
              const buildingsInParcel = buildings.filter((building: any) => {
                const buildingcentroid = JSON.parse(building.centroid);
                return isBuildingInParcel(viewrefs, buildingcentroid);
              });
              if (buildingsInParcel.length > 0) {
                buildingsInParcel.forEach((item: any, i: number) => {
                  const buildingobj = JSON.parse(item.coordinates);
                  drawPolygonOnMap(buildingobj, map, viewrefs, "building", i);
                  viewrefs.current.buildingAnalysisCardOrder.push(i.toString());
                });
                drawCombinedPolygons(buildingsInParcel, map, viewrefs, "zone2");
                drawCombinedPolygons(buildingsInParcel, map, viewrefs, "zone1");
                drawCombinedPolygons(buildingsInParcel, map, viewrefs, "zone0");

                addInnerPolygons(viewrefs, "zone0", "building");
                addInnerPolygons(viewrefs, "zone1", "zone0");
                addInnerPolygons(viewrefs, "zone2", "zone1");
                zoomToFeatures(buildingsInParcel, map, viewrefs);
                const z2box = getBoxFromZone(viewrefs, "zone2");
                const fuelh: Fuel[] = await fetchFuel(auth, z2box, "heavy");

                enableItems("heavy");
                addHexFueltoMap(
                  viewrefs,
                  fuelh,
                  "heavy",
                  filteredCoordinates,
                  h3indexes
                );
                const fuelm: Fuel[] = await fetchFuel(auth, z2box, "medium");
                enableItems("medium");
                addHexFueltoMap(
                  viewrefs,
                  fuelm,
                  "medium",
                  filteredCoordinates,
                  h3indexes
                );
                const fuell: Fuel[] = await fetchFuel(auth, z2box, "light");
                enableItems("light");
                addHexFueltoMap(
                  viewrefs,
                  fuell,
                  "light",
                  filteredCoordinates,
                  h3indexes
                );
                const reducedFuelScore = Object.entries(
                  h3indexes.current
                ).reduce((res: any, fuel: any) => {
                  return {
                    ...res,
                    [fuel[0]]: Object.entries(fuel[1]).reduce(
                      (fuelobj: any, zone: any) => {
                        return {
                          ...fuelobj,
                          [zone[0]]: Object.entries(zone[1]).reduce(
                            (zoneobj: any, id: any) => {
                              return {
                                ...zoneobj,
                                [id[0]]: Array.from(new Set(id[1])).length,
                              };
                            },
                            {}
                          ),
                        };
                      },
                      {}
                    ),
                  };
                }, {});
                // getExpandedScores

                const expandedscores = getExpandedScores(
                  reducedFuelScore,
                  viewrefs
                );
                viewrefs.current.expandedbuildingAnalysisScore =
                  expandedscores["score"];
                viewrefs.current.expandedbuildingAnalysisArea =
                  expandedscores["area"];
                setHighlightedBuilding("0", viewrefs);
                setFuelScore(reducedFuelScore);

                enableZones();
              } else {
                //zoom to parcel
                if (mounted) {
                  zoomToFeatures(buildingsInParcel, map, viewrefs);
                }
              }
            } catch (err) {
              const buildingsInParcelx: number[][][][] = [];
              if (mounted) {
                zoomToFeatures(buildingsInParcelx, map, viewrefs);
              }
            }
          } else {
            alert("The parcel is too large to process.");
            const buildingsInParcelx: number[][][][] = [];
            if (mounted) {
              zoomToFeatures(buildingsInParcelx, map, viewrefs);
            }
          }
        }
        if (mounted) {
          setInProgress(0);
        }
      };
      if (mounted) {
        asyncfunction();
      }
    }
    return () => {
      mounted = false;
    };
  }, [selectedKEY, auth, map, viewrefs, setFuelScore]);

  const InsertBorder =
    dashboardSettingModeState && dashboardSettingModeState === "Building"
      ? "Solid 2px red"
      : "Solid 2px #ffffff";

  const onKEYSelect = () => {
    onCancel();
    setSelectedKEY({ APN: apn, FID: "0" });
  };
  const onCancel = () => {
    setSelectedKEY(null);
    setFuelScore(null);
    viewrefs.current.expandedbuildingAnalysisScore = {};
    viewrefs.current.expandedbuildingAnalysisArea = {};
    viewrefs.current.buildingAnalysisCardOrder = [];
    viewrefs.current.buildingAnalysisFloatingCards = [];
    viewrefs.current.buildingAnalysisArea = JSON.parse(
      JSON.stringify(BuildingAnalysisAreaDefaults)
    );
    filteredCoordinates.current = JSON.parse(
      JSON.stringify(FilteredCoordinatesDefaults)
    );
    h3indexes.current = JSON.parse(JSON.stringify(H3IndexesDefaults));
    zoneRef.current = JSON.parse(JSON.stringify(ZoneObjectDefaults));
    disablefuel.current = JSON.parse(JSON.stringify(DisableFuelObjectDefaults));
    disablezone.current = true;
    setFuelInfo(JSON.parse(JSON.stringify(FuelObjectDefaults)));
    setZoneInfo(JSON.parse(JSON.stringify(ZoneObjectDefaults)));
    // setTriggerRefresh(Math.random());
    Object.keys(viewrefs.current.buildingAnalysisLayer).forEach((item) => {
      if (viewrefs.current.buildingAnalysisLayer[item] && map) {
        map.removeLayer(viewrefs.current.buildingAnalysisLayer[item]);
        delete viewrefs.current.buildingAnalysisLayer[item];
      }
    });
    setSelectedBuilding("0");
    setInProgress(0);
  };
  const onAPNChange = (val: string) => {
    setApn(val);
  };

  const isChecked = (building: string) => {
    return building === selectedBuilding;
  };

  const onBuildingChange = (building: string) => {
    setSelectedBuilding(building);
  };

  return (
    <Rnd
      position={getDefaultPositionTopRight(
        DashboardDimensions.height,
        DashboardDimensions.width,
        position,
        mapviewRoot
      )}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      disableDragging={disableDrag}
      style={{ zIndex: 9999 }}
    >
      <div
        style={{
          position: "absolute",
          display: "block",
          backgroundColor: "#000000",
          height: DashboardDimensions.height + "px",
          width: DashboardDimensions.width + "px",
          zIndex: 999,
          border: "1px solid black",
          padding: "1rem",
          borderRadius: 4,
          color: "#ffffff",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "absolute",
            top: 10,
            left: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 18,
            }}
          >
            {"Parcel Assessment"}
          </div>
          {inProgress === 1 && (
            <img
              alt="ip"
              src={loadingGIF}
              style={{ height: 20, marginTop: -15 }}
            />
          )}
          {inProgress === 0 && <div style={{ height: 20 }} />}
          {inProgress === 2 && (
            <div style={{ height: 20, color: "red", marginTop: -10 }}>
              No data found
            </div>
          )}
        </div>

        <div
          style={{
            width: 20,
            position: "absolute",
            top: 5,
            left: 10,
            cursor: "pointer",
          }}
        >
          <ExportReport auth={auth}></ExportReport>
        </div>

        <img
          alt="close"
          src={close}
          style={{
            width: 20,
            position: "absolute",
            top: 0,
            right: 0,
            cursor: "pointer",
          }}
          onClick={() => onClose()}
        ></img>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "60px",
          }}
        >
          <ButtonComponent
            name="Select"
            style={{
              width: "100px",
              height: "30px",
              border: InsertBorder,
              marginLeft: "0px",
              marginRight: "15px",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
            }}
            onClick={() => onKEYSelect()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
          />
          <ButtonComponent
            name="Clear"
            style={{
              width: "100px",
              height: "30px",
              marginLeft: "15px",
              marginRight: "0px",
              border: "2px solid #FFFFFF",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
            }}
            onClick={() => onCancel()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <div style={{ fontSize: 16, marginBottom: 5 }}>APN</div>
            <input
              className="signin-input"
              value={apn}
              type="text"
              onChange={(e) => onAPNChange(e.target.value)}
              style={{
                height: 30,
                width: 230,
                fontSize: 16,
              }}
              onMouseEnter={() => onMouseenter()}
              onMouseLeave={() => onMouseleave()}
            ></input>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            marginTop: 20,
            marginLeft: 60,
          }}
        >
          <div style={{ flex: 3 }}>
            {Object.keys(fuelInfo).map((item: string, i: number) => {
              const fuel: FuelObject = fuelInfo[item];
              const disabledfuel: boolean = disablefuel.current[item];
              return (
                <div
                  key={i}
                  style={{
                    fontSize: 14,
                    // fontWeight: 600,
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ accentColor: "#ffffff" }}
                    onChange={() => toggleItems(item)}
                    checked={fuel.checked}
                    disabled={disabledfuel}
                  />
                  <div style={{ marginLeft: 10 }}>{fuel.name}</div>
                </div>
              );
            })}
          </div>
          <div style={{ flex: 5 }}>
            {Object.keys(zoneInfo).map((item: string, i: number) => {
              const zone: ZoneObject = zoneInfo[item];
              return (
                <div
                  key={"zone" + i}
                  style={{
                    fontSize: 14,
                    // fontWeight: 600,
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    style={{ accentColor: "#ffffff" }}
                    onChange={() => toggleZones(item)}
                    checked={zone.checked}
                    disabled={disablezone.current}
                  />
                  <div style={{ marginLeft: 10 }}>{zone.name}</div>
                </div>
              );
            })}
          </div>
        </div>
        {viewrefs.current.expandedbuildingAnalysisScore["heavy"] &&
          viewrefs.current.expandedbuildingAnalysisScore["heavy"][
            "building"
          ] && (
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <span>Building scorecard selection</span>
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "center",
                }}
              >
                {Object.keys(
                  viewrefs.current.expandedbuildingAnalysisScore["heavy"][
                    "building"
                  ]
                ).map((building: string, i: number) => {
                  return (
                    <div
                      key={i}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ fontSize: 12 }}>{+building + 1}</span>
                      <input
                        style={{ margin: 5, accentColor: "black" }}
                        type="radio"
                        name="buildings"
                        checked={isChecked(building)}
                        onChange={(e) => onBuildingChange(building)}
                      ></input>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    </Rnd>
  );
};
