import React, { useEffect, useState, useRef, useContext } from "react";
import VectorLayer from "ol/layer/Vector";
import Geometry from "ol/geom/Geometry";
import { Heatmap as HeatmapLayer } from "ol/layer";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import geojson2h3 from "geojson2h3";
import { geoToH3 } from "h3-js";

import close from "../../../images/closeInverse.png";
import { Rnd } from "react-rnd";
import { fetchDataStorage } from "../../../api/AlchemyAnalytics";
import {
  MapLayer,
  defaultHeatmapStyle,
  DivPosition,
} from "../../../api/AlchemyApiTypes";
import { Controls } from "./Controls";
import { getLinearColorScale } from "../../../helpers/colours";
import { resolutionFromZoom, gifOrInput } from "./functions";
import { getDefaultPosition } from "../functions";
import {
  Props,
  H3VectorSource,
  HeatmapData,
  PointCount,
  GHObj,
} from "./heatmaptypes";
import { StoreContext } from "../../../store/store";
import { State, Action, actionTypes } from "../../../store/storetypes";

export const Heatmaps: React.FC<Props> = ({
  mapviewRoot,
  storage,
  auth,
  settings,
  viewrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const {
    dashboardSettings,
    heatmapZoomRatio,
    loadedVectorlayers,
    vectorlayers,
    showlegend,
  } = store;

  const map = viewrefs.current.map;

  const stringlegend = JSON.stringify(showlegend);

  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });

  const [selectedHeatmap, setSelectedHeatmap] = useState<MapLayer | null>(null);
  const [radius, setRadius] = useState<number>(5);
  const [blur, setBlur] = useState<number>(15);
  const [disableDrag, setDisableDrag] = useState<boolean>(false);
  const [heatmapdata, setHeatmapdata] = useState<any>();
  const [h3view, setH3view] = useState<boolean>(false);
  const maxValues = useRef<{ [key: string]: number }>({});
  const [h3disabled, setH3disabled] = useState<boolean>(true);

  const resolutions = useRef<string[]>([
    "h3index_14",
    "h3index_13",
    "h3index_12",
    "h3index_11",
    "h3index_10",
    "h3index_09",
    "h3index_08",
  ]);

  const initializeObjects = (itemlist: string[], val: any) => {
    return itemlist.reduce((obj: any, item) => {
      return {
        ...obj,
        [item]: val,
      };
    }, {});
  };

  const pointCount = useRef<PointCount>({});
  const ghobj = useRef<GHObj>(initializeObjects(resolutions.current, {}));
  const h3vectorsource = useRef<H3VectorSource>(
    initializeObjects(resolutions.current, null)
  );
  const h3heatmapcolourscales = useRef<any>({});

  useEffect(() => {
    viewrefs.current.heatmapH3Layer = initializeObjects(
      resolutions.current,
      null
    );
  }, [viewrefs]);

  useEffect(() => {
    let mounted = true;
    const setFeatureStyle = (feature: any) => {
      const fill = feature.get("System_fill");
      return new Style({
        fill: new Fill({
          color: fill,
        }),
        stroke: new Stroke({
          color: "transparent",
          width: 0.1,
        }),
      });
    };

    if (selectedHeatmap && map) {
      if (viewrefs.current.heatmapVector) {
        map.removeLayer(viewrefs.current.heatmapVector);
      }
      if (viewrefs.current.heatmapH3Layer) {
        Object.values(viewrefs.current.heatmapH3Layer).forEach((layer) => {
          if (layer) {
            map.removeLayer(layer);
          }
        });
      }
      viewrefs.current.heatmapH3Layer = initializeObjects(
        resolutions.current,
        null
      );
      h3vectorsource.current = initializeObjects(resolutions.current, null);
      ghobj.current = initializeObjects(resolutions.current, {});
      h3heatmapcolourscales.current = {};
      pointCount.current = {};
      maxValues.current = {};

      const asyncdata = async () => {
        const hexstyle = (res: string, h3index: string) => {
          const lev: string = res.split("_")[1];
          const pointcount = pointCount.current[lev][h3index].count;
          return {
            System_fill: h3heatmapcolourscales.current[lev](pointcount),
            count: pointcount,
            Class: "point count",
          };
        };

        const data = (await fetchDataStorage(
          selectedHeatmap.config.key,
          storage,
          auth,
          selectedHeatmap.config.cacheKey,
          "vectorurl"
        )) as HeatmapData;
        const heatmapstyle = selectedHeatmap.config.heatmapstyle
          ? selectedHeatmap.config.heatmapstyle
          : defaultHeatmapStyle;

        //get hex data
        const hexdata = data.features.map((item) => {
          return item.geometry.coordinates;
        });
        resolutions.current.forEach((res: string) => {
          const hexstylex = (h3index: string) => {
            return hexstyle(res, h3index);
          };
          const lev: string = res.split("_")[1];
          hexdata.forEach((item: any) => {
            if (lev in pointCount.current) {
              //do nothing
            } else {
              pointCount.current[lev] = {};
            }
            const h3Index = geoToH3(item[1], item[0], +lev);
            if (h3Index in pointCount.current[lev]) {
              pointCount.current[lev][h3Index].count++;
            } else {
              pointCount.current[lev][h3Index] = {
                count: 1,
              };
            }
          });
          const maxvaluearray = Object.values(pointCount.current[lev]).map(
            (val) => {
              return val.count;
            }
          );
          const maxvalue = Math.max(...maxvaluearray);
          maxValues.current[res] = maxvalue;
          h3heatmapcolourscales.current[lev] = getLinearColorScale(
            [0, maxvalue],
            ["white", "red"]
          );
          ghobj.current[res] = geojson2h3.h3SetToFeatureCollection(
            Object.keys(pointCount.current[lev]),
            hexstylex
          );
          h3vectorsource.current[res] = new VectorSource({
            features: new GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(ghobj.current[res]),
          });
        });
        resolutions.current.forEach((res: string) => {
          if (viewrefs.current.heatmapH3Layer) {
            viewrefs.current.heatmapH3Layer[res] = new VectorLayer();
            (
              viewrefs.current.heatmapH3Layer[res] as VectorLayer<
                VectorSource<Geometry>
              >
            ).setSource(h3vectorsource.current[res] as VectorSource<Geometry>);
            (
              viewrefs.current.heatmapH3Layer[res] as VectorLayer<
                VectorSource<Geometry>
              >
            ).setStyle(setFeatureStyle);
          }
        });
        if (mounted) {
          setHeatmapdata(data);
          setRadius(+heatmapstyle.radius);
          setBlur(+heatmapstyle.blur);
        }
      };
      asyncdata();
    }
    return () => {
      mounted = false;
    };
  }, [viewrefs, selectedHeatmap, auth, storage, setHeatmapdata, map]);

  useEffect(() => {
    let mounted = true;
    if (map && heatmapdata) {
      if (viewrefs.current.heatmapVector) {
        map.removeLayer(viewrefs.current.heatmapVector);
        viewrefs.current.heatmapVector = undefined;
      }
      if (!h3view) {
        viewrefs.current.heatmapVector = new HeatmapLayer({
          source: new VectorSource({
            features: new GeoJSON({
              featureProjection: "EPSG:3857",
            }).readFeatures(heatmapdata),
          }),
          blur: 0,
          radius: 0,
          weight: function (feature) {
            return 10;
          },
        });
        map.addLayer(viewrefs.current.heatmapVector);
        if (mounted) {
          setH3disabled(false);
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [viewrefs, heatmapdata, map, h3view]);

  useEffect(() => {
    if (viewrefs.current.heatmapH3Layer && map) {
      Object.values(viewrefs.current.heatmapH3Layer).forEach((layer) => {
        if (layer) {
          map.removeLayer(layer);
        }
      });
    }
    const jsonlegend = JSON.parse(stringlegend);
    if (h3view) {
      const res = resolutionFromZoom(heatmapZoomRatio);
      dispatch({
        type: actionTypes.SET_H3HEATMAP_LEGEND_INFO,
        payload: { active: true, max: maxValues.current[res] },
      });
      dispatch({
        type: actionTypes.SET_SHOW_LEGEND,
        payload: { ...jsonlegend, H3heatmap: true },
      });

      if (viewrefs.current.heatmapH3Layer && map) {
        const heatmapH3Layerx = viewrefs.current.heatmapH3Layer[res];
        if (heatmapH3Layerx) {
          map.addLayer(heatmapH3Layerx);
        }
      }
    } else {
      dispatch({
        type: actionTypes.SET_H3HEATMAP_LEGEND_INFO,
        payload: { active: false, max: 0 },
      });

      dispatch({
        type: actionTypes.SET_SHOW_LEGEND,
        payload: { ...jsonlegend, H3heatmap: false },
      });
    }

    if (viewrefs.current.heatmapVector) {
      const newRadius = radius * heatmapZoomRatio;
      const newBlur = blur * heatmapZoomRatio;
      viewrefs.current.heatmapVector.setBlur(newBlur);
      viewrefs.current.heatmapVector.setRadius(newRadius);
    }
  }, [
    viewrefs,
    blur,
    radius,
    heatmapdata,
    heatmapZoomRatio,
    h3view,
    map,
    dispatch,
    stringlegend,
  ]);
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.SET_CLEAR_DASHBOARD,
        payload: "Heatmaps",
      });
    };
  }, [dispatch]);

  const onClose = () => {
    // dashboardCleanupComponents("Heatmaps");
    // setSelected("Heatmaps");

    const newSettings = { ...dashboardSettings };
    newSettings["Heatmaps"].selected = false;
    dispatch({
      type: actionTypes.SET_DASHBOARD_SETTINGS,
      payload: newSettings,
    });
  };

  const onSelect = (item: MapLayer) => {
    setSelectedHeatmap(item);
  };

  const DashboardDimensions = {
    height:
      170 +
      (settings.specificSettings && settings.specificSettings?.heatmaps
        ? settings.specificSettings?.heatmaps?.itemList.length * 26
        : 1),
    width: 300,
  };

  const isChecked = (item: MapLayer) => {
    return item === selectedHeatmap;
  };

  if (settings) {
    return (
      <Rnd
        position={getDefaultPosition(
          DashboardDimensions.height,
          DashboardDimensions.width,
          position,
          mapviewRoot
        )}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        disableDragging={disableDrag}
        style={{ zIndex: 9999 }}
      >
        <div
          style={{
            position: "absolute",
            display: "block",
            backgroundColor: "#000000",
            height: DashboardDimensions.height + "px",
            width: DashboardDimensions.width + "px",
            zIndex: 999,
            border: "1px solid black",
            padding: "1rem",
            borderRadius: 4,
            color: "#ffffff",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              position: "absolute",
              top: 10,
              left: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 18,
              }}
            >
              {settings.displayName}
            </div>
          </div>
          <img
            alt="close"
            src={close}
            style={{
              width: 20,
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
            onClick={() => onClose()}
          ></img>
          <div
            style={{
              display: "flex",
              marginTop: "30px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "10px" }}>H3 View</span>
            <input
              style={{
                accentColor: "#ffffff",
                cursor: h3disabled ? "default" : "pointer",
              }}
              type="checkbox"
              disabled={h3disabled}
              checked={h3view}
              onChange={() => {
                setH3view(!h3view);
              }}
            />
          </div>
          {vectorlayers && vectorlayers.length > 0 && (
            <div style={{ display: "flex", marginTop: "10px" }}>
              <table>
                <tbody>
                  {vectorlayers
                    .filter((item: MapLayer) => {
                      if (
                        settings.specificSettings &&
                        settings.specificSettings?.heatmaps
                      ) {
                        return settings.specificSettings?.heatmaps.itemList.includes(
                          item.config.key
                        );
                      } else return false;
                    })
                    .map((item: MapLayer, i: number) => {
                      return (
                        <tr key={i}>
                          <td>
                            {gifOrInput(
                              item,
                              i,
                              loadedVectorlayers,
                              onSelect,
                              isChecked
                            )}
                          </td>
                          <td>{item.config.displayName}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
          <Controls
            radius={radius}
            setRadius={setRadius}
            blur={blur}
            setBlur={setBlur}
            setDisableDrag={setDisableDrag}
          />
        </div>
      </Rnd>
    );
  } else {
    return null;
  }
};
