import React, { useContext } from "react";
import { Map } from "ol";
import {
  ElevationProfilePayload,
  ElevationProfileData,
} from "../../../api/AlchemyApiTypes";
import { writeElevationProfiles } from "../../../api/AlchemyAnalytics";
import { feettometers } from "../../../map/math";
import {
  drawElevationPoints,
  drawActualProfileLine,
  clearLines,
  checkname,
} from "../../../map/functions";
import Auth from "../../../services/auth";
import deleteicon from "../../../images/deleteInverse.png";
import privateicon from "../../../images/privateInverse.png";
import publicicon from "../../../images/public2Inverse.png";
import exporticon from "../../../images/downloadExcel25Black.png";
import { exportToCSV } from "./functions";
import { StoreContext } from "../../../store/store";
import { State, Action, actionTypes } from "../../../store/storetypes";
import { ViewRefs } from "../../../store/refs";

interface Props {
  savedElevationProfiles: ElevationProfilePayload;
  destinationElevationProfiles: ElevationProfilePayload;
  map: Map | null;
  setSelectedProfileName: (name: string | null) => void;
  setShowSelectionOptions: (value: React.SetStateAction<boolean>) => void;
  scope: string;
  auth: Auth;
  viewId: string;
  setSavedElevationProfiles: React.Dispatch<
    React.SetStateAction<ElevationProfilePayload>
  >;
  setDestinationElevationProfiles: React.Dispatch<
    React.SetStateAction<ElevationProfilePayload>
  >;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const DisplaySavedProfiles: React.FC<Props> = ({
  savedElevationProfiles,
  destinationElevationProfiles,
  map,
  setSelectedProfileName,
  setShowSelectionOptions,
  scope,
  auth,
  viewId,
  setSavedElevationProfiles,
  setDestinationElevationProfiles,
  viewrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  if (store) {
    //use store somehow
  }
  const headerstyle = { fontWeight: 650, paddingRight: "20px" };
  const namestyle = { width: "260px" };

  const setElevationProfileData = (val: ElevationProfileData[]) => {
    dispatch({
      type: actionTypes.SET_ELEVATION_PROFILE_DATA,
      payload: val,
    });
  };
  const setElevationProfileDataLoaded = (val: boolean) => {
    dispatch({
      type: actionTypes.SET_ELEVATION_PROFILE_DATA_LOADED,
      payload: val,
    });
  };

  const onDelete = (name: string) => {
    const asyncDeleteElevationProfile = async () => {
      const payload: ElevationProfilePayload = JSON.parse(
        JSON.stringify(savedElevationProfiles)
      );
      delete payload[name];
      try {
        const ret = await writeElevationProfiles(auth, viewId, payload, scope);
        if (ret.body === "saved") {
          setSavedElevationProfiles(payload);
          setSelectedProfileName(null);
        } else {
          throw JSON.stringify(ret);
        }
      } catch (err) {
        console.log(JSON.stringify(err));
      }
    };
    asyncDeleteElevationProfile();
  };
  const onExport = (i: string) => {
    console.log("export");

    const data: ElevationProfileData[] = savedElevationProfiles[i].lineData.map(
      (item) => {
        return {
          id: item.id,
          distance: +item.distance,
          elevation: +item.elevation,
          coordinates: item.coordinates.map((coord) => +coord),
        };
      }
    );
    const filename = i + "_download";
    const wscols = [
      { wch: 5 },
      { wch: 20 },
      {
        wch: 20,
      },
      { wch: 20 },
      { wch: 20 },
    ];

    const datatoexport = data.map((item) => {
      const latitude = item.coordinates[0];
      const longitude = item.coordinates[1];
      return {
        id: item.id,
        distance: item.distance,
        elevation: item.elevation,
        latitude,
        longitude,
      };
    });

    exportToCSV(datatoexport, filename, wscols);
  };
  const onMove = (name: string) => {
    const asyncSaveElevationProfile = async () => {
      const payload: ElevationProfilePayload = JSON.parse(
        JSON.stringify(savedElevationProfiles)
      );
      const destpayload: ElevationProfilePayload = JSON.parse(
        JSON.stringify(destinationElevationProfiles)
      );

      const newname: string = checkname(
        name,
        destpayload,
        scope === "private" ? "public" : "private"
      );

      if (newname) {
        try {
          destpayload[newname] = payload[name];
          const ret = await writeElevationProfiles(
            auth,
            viewId,
            destpayload,
            scope === "private" ? "public" : "private"
          );
          if (ret.body === "saved") {
            setDestinationElevationProfiles(destpayload);
          } else {
            throw JSON.stringify(ret);
          }
        } catch (err) {
          console.log(JSON.stringify(err));
        }
      }
    };
    asyncSaveElevationProfile();
  };

  const onRowClick = (i: string) => {
    const data: ElevationProfileData[] = savedElevationProfiles[i].lineData.map(
      (item) => {
        return {
          id: item.id,
          distance: +item.distance,
          elevation: +item.elevation,
          coordinates: item.coordinates.map((coord) => +coord),
        };
      }
    );

    clearLines(
      setElevationProfileData,
      setElevationProfileDataLoaded,
      viewrefs
    );
    let distance: number = 0;
    let linecoordinate: number[][] = [];
    data.forEach((item) => {
      const coordinate: number[] = item.coordinates;
      linecoordinate.push([
        feettometers(item.distance),
        coordinate[1],
        coordinate[0],
      ]);
    });
    drawActualProfileLine(linecoordinate, viewrefs, map);
    data.forEach((item) => {
      const coordinate = item.coordinates;
      distance = item.distance;

      drawElevationPoints(
        coordinate[0],
        coordinate[1],
        item.elevation,
        item.distance,
        item.id,
        viewrefs
      );
    });
    dispatch({
      type: actionTypes.SET_ELEVATION_PROFILE_DATA,
      payload: [...data],
    });
    viewrefs.current.refElevationProfileData = [...data];

    viewrefs.current.distanceBetweenPoints = distance;
    dispatch({
      type: actionTypes.SET_ELEVATION_PROFILE_DATA_LOADED,
      payload: true,
    });
    setSelectedProfileName(i);
    setShowSelectionOptions(false);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "0px",
          maxHeight: "365px",
          overflowY: "auto",
        }}
      >
        <table>
          <thead>
            <tr>
              <th style={{ ...headerstyle, ...namestyle }}>Name</th>
              <th style={headerstyle}>Min Elevation</th>
              <th style={headerstyle}>Max Elevation</th>
              <th style={headerstyle}>Distance</th>
              <th style={headerstyle}>Points</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(savedElevationProfiles).map((item, i) => {
              return (
                <tr key={i} style={{ cursor: "pointer" }}>
                  <td onClick={() => onRowClick(item[0])}>{item[0]}</td>
                  <td onClick={() => onRowClick(item[0])}>
                    {Math.min(
                      ...item[1].lineData.map((item) => +item.elevation)
                    ).toFixed(1) + "ft"}
                  </td>
                  <td onClick={() => onRowClick(item[0])}>
                    {" "}
                    {Math.max(
                      ...item[1].lineData.map((item) => +item.elevation)
                    ).toFixed(1) + "ft"}
                  </td>
                  <td onClick={() => onRowClick(item[0])}>
                    {Number(
                      item[1].lineData[item[1].lineData.length - 1].distance
                    ).toFixed(1) + "ft"}
                  </td>
                  <td onClick={() => onRowClick(item[0])}>
                    {item[1].lineData.length}
                  </td>
                  <td>
                    <img
                      alt="delete"
                      title={
                        scope === "private"
                          ? "Delete from private list"
                          : "Delete from public list"
                      }
                      src={deleteicon}
                      style={{ width: 16, marginRight: 20, cursor: "pointer" }}
                      onClick={() => onDelete(item[0])}
                    ></img>
                  </td>
                  <td>
                    <img
                      alt="public"
                      title={
                        scope === "private"
                          ? "Add to public list"
                          : "Add to private list"
                      }
                      src={scope === "private" ? publicicon : privateicon}
                      style={{ width: 25, marginRight: 20, cursor: "pointer" }}
                      onClick={() => onMove(item[0])}
                    ></img>
                  </td>
                  <td>
                    <img
                      alt="export"
                      title={"export to Excel"}
                      src={exporticon}
                      style={{ width: 25, cursor: "pointer" }}
                      onClick={() => onExport(item[0])}
                    ></img>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
