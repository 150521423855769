import Feature from "ol/Feature";
import Map from "ol/Map";
import Geometry from "ol/geom/Geometry";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Heatmap as HeatmapLayer } from "ol/layer";
import {
  BuildingAnalysisArea,
  BuildingAnalysisAreaDefaults,
  BuildingAnalysisScore,
  ExplodedFeatures,
  ElevationProfileData,
  FieldPhotoObject,
  MarkerLayers,
  markerVectorLayersDefaults,
  VideoObject,
} from "../api/AlchemyApiTypes";

import { H3Layer } from "../components/dashboards/heatmaps/heatmaptypes";

interface Storage {
  [key: string]: object;
}

export interface AllRefs {
  storage: Storage;
}

export const AllRefsDefault = {
  storage: { x: {} },
};
export interface ViewRefs {
  buildingAnalysisArea: BuildingAnalysisArea;
  buildingAnalysisCardOrder: string[];
  buildingAnalysisFloatingCards: string[];
  buildingAnalysisLayer: {
    [key: string]: VectorLayer<VectorSource<Geometry>>;
  };
  canopyCoordinate: number[];
  currentLocation: Feature<Geometry> | null;
  currentLocationLayer: VectorLayer<VectorSource<Geometry>> | null;
  dashboardSettingsMode: string | null;
  distanceBetweenPoints: number;
  drawPolySelect: boolean;
  drawProfileLine: boolean;
  elevationSettingsValue: number[] | undefined;
  explodedFeatures: ExplodedFeatures | null;
  expandedbuildingAnalysisScore: BuildingAnalysisScore;
  expandedbuildingAnalysisArea: BuildingAnalysisArea;
  fieldphotoitemsRef: Array<FieldPhotoObject> | undefined;
  heatmapH3Layer: H3Layer | null;
  heatmapVector: HeatmapLayer | undefined;
  loadedlayers: {
    [key: string]: boolean;
  } | null;
  map: Map | null;
  mapValid: boolean;
  markerVectorLayers: MarkerLayers;
  polyFirstPoint: boolean;
  polySelectLine: Feature<Geometry> | null;
  polySelectCoordinates: number[][];
  polySelectLayer: VectorLayer<VectorSource<Geometry>> | null;
  profileLine: Feature<Geometry> | null;
  profileLineCoordinates: number[][];
  profileLineLayer: VectorLayer<VectorSource<Geometry>> | null;
  refElevationProfileData: ElevationProfileData[];
  runpaneleffects: boolean;
  slopeLine: Feature<Geometry> | null;
  slopePoints: number[];
  videoitemsRef: Array<VideoObject> | undefined;
  viewId: string;
  zoomfactor: number;
}
export const ViewRefsDefault = {
  buildingAnalysisArea: JSON.parse(
    JSON.stringify(BuildingAnalysisAreaDefaults)
  ),
  buildingAnalysisCardOrder: [],
  buildingAnalysisFloatingCards: [],
  buildingAnalysisLayer: {},
  canopyCoordinate: [0, 0],
  currentLocation: null,
  currentLocationLayer: null,
  dashboardSettingsMode: null,
  distanceBetweenPoints: 0,
  drawPolySelect: false,
  drawProfileLine: false,
  elevationSettingsValue: undefined,
  explodedFeatures: null,
  expandedbuildingAnalysisScore: {},
  expandedbuildingAnalysisArea: {},
  fieldphotoitemsRef: undefined,
  heatmapH3Layer: null,
  heatmapVector: undefined,
  loadedlayers: null,
  map: null,
  mapValid: false,
  markerVectorLayers: JSON.parse(JSON.stringify(markerVectorLayersDefaults)),
  polyFirstPoint: false,
  polySelectLine: null,
  polySelectCoordinates: [],
  polySelectLayer: null,
  profileLine: null,
  profileLineCoordinates: [
    [0, 0],
    [0, 0],
  ],
  profileLineLayer: null,
  refElevationProfileData: [],
  runpaneleffects: true,
  slopeLine: null,
  slopePoints: [0, 0],
  videoitemsRef: undefined,
  viewId: "",
  zoomfactor: 13.25,
};
