import React from "react";
import ZoomInIcon from "../../images/svg/other/ZoomInIcon";
import ZoomOutIcon from "../../images/svg/other/ZoomOutIcon";
import { easeOut } from "./easing.js";
import { Map } from "ol";

interface Props {
  map: Map | null;
}

export const ZoomControls: React.FC<Props> = ({ map }) => {
  const zoomByDelta = (delta: number) => {
    if (map) {
      const view = map.getView();
      if (!view) {
        // the map does not have a view, so we can't act
        // upon it
        return;
      }
      const currentZoom = view.getZoom();
      if (currentZoom !== undefined) {
        const newZoom = view.getConstrainedZoom(currentZoom + delta);

        if (view.getAnimating()) {
          view.cancelAnimations();
        }
        view.animate({
          zoom: newZoom,
          duration: 250,
          easing: easeOut,
        });
      }
    } else return;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#000000",
          width: 30,
          height: 30,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
        }}
        onClick={() => zoomByDelta(1)}
      >
        <ZoomInIcon fill="#ffffff" cursor="pointer" />
      </div>
      <div
        style={{
          backgroundColor: "#000000",
          width: 30,
          height: 30,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => zoomByDelta(-1)}
      >
        <ZoomOutIcon fill="#ffffff" cursor="pointer" />
      </div>
    </div>
  );
};
