import VectorSource from "ol/source/Vector";
import { Map } from "ol";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import VectorLayer from "ol/layer/Vector";
import Geometry from "ol/geom/Geometry";
import {
  getHighestFeatureCountObj,
  getHighestFeatureCountArray,
} from "../barChart/dataFormatFunctions";
import { getSequentialColorScale } from "../../helpers/colours";
import {
  BuildingAnalysisAreaDefaults,
  VectorLayerConfig,
  MapLayer,
  MapLegend,
  DashboardSettings,
  DivPosition,
} from "../../api/AlchemyApiTypes";
import { colorWithAlpha } from "../../map/functions";
import { getStorage } from "../../helpers/localStorage";
import { getFileName } from "../../utils/download";
import { ViewRefs } from "../../store/refs";
import { Action, actionTypes } from "../../store/storetypes";

interface Counter {
  [key: string]: number;
}

interface ChartData {
  ARN: string;
  NumberOfFeatures: number;
}

export const getIntersectionData = (
  polysource: VectorSource<Geometry>,
  featureSource: VectorSource<Geometry>
) => {
  // const polyId = polysource.get("Identifier");
  const polyId = "APN"; //polysource.get("APN");

  const polyfeatures = polysource.getFeatures();

  let counter: Counter = {};
  polyfeatures.forEach((poly) => {
    const key = poly.getProperties()[polyId];
    counter[key] = 0;
    const pf = poly.getGeometry();
    if (pf) {
      featureSource.forEachFeatureInExtent(pf.getExtent(), function (feature) {
        const fg = feature.getGeometry();
        if (fg) {
          if (pf.intersectsExtent(fg.getExtent())) {
            counter[key]++;
          }
        }
      });
    }
  });

  return counter;
};
export const setParcelColours = (
  polysource: VectorSource<Geometry>,
  counter: Counter
) => {
  const highestValue = getHighestFeatureCountObj(counter);
  const colorScale = getSequentialColorScale([0, highestValue], "Oranges");

  const polyId = polysource.get("Identifier");
  polysource.forEachFeature((feature) => {
    const featureId = feature.getProperties()[polyId];
    if (featureId in counter) {
      const fill: string = colorWithAlpha(
        colorScale(counter[featureId]).toString(),
        0.8
      );
      feature.setStyle(
        new Style({
          fill: new Fill({
            color: fill,
          }),
          stroke: new Stroke({
            color: "grey",
            width: 1,
          }),
        })
      );
    } else {
      feature.setStyle(
        new Style({
          fill: new Fill({
            color: "transparent",
          }),
          stroke: new Stroke({
            color: "grey",
            width: 1,
          }),
        })
      );
    }
    // }
  });
};
export const highlightParcel = (
  polysource: VectorSource<Geometry>,
  d: ChartData,
  action: String,
  counter: ChartData[]
) => {
  const highestValue = getHighestFeatureCountArray(counter);
  const colorScale = getSequentialColorScale([0, highestValue], "Oranges");
  let fill: string = "red";
  let stroke: string = "red";
  if (action === "out") {
    fill = colorScale(d.NumberOfFeatures).toString();
    stroke = "grey";
  }

  // const polyId = polysource.get("Identifier");
  const polyId = "APN"; //polysource.get("APN");
  polysource.forEachFeature((feature) => {
    const featureId = feature.getProperties()[polyId].toString();
    if (featureId === d.ARN) {
      feature.setStyle(
        new Style({
          fill: new Fill({
            color: fill,
          }),
          stroke: new Stroke({
            color: stroke,
            width: 1,
          }),
        })
      );
    }
  });
};
export const setParcelDefaults = (layer: MapLayer) => {
  const config: VectorLayerConfig = layer.config;
  const storedColour = getStorage(config.key);
  const vectorSource = layer.layers[0].getSource();
  vectorSource.forEachFeature((feature) => {
    feature.setStyle(
      new Style({
        fill: new Fill({
          color: storedColour ? storedColour.fill : config.style?.fill,
        }),
        stroke: new Stroke({
          color: storedColour
            ? storedColour.linecolour
            : config.style?.linecolour,
          width: storedColour
            ? storedColour.linewidth
            : config.style?.linewidth,
        }),
      })
    );
  });
};
export const dashboardCleanup = (
  key: string,
  VectorLayers: MapLayer[] | null,
  setProfileLineReady: (val: boolean) => void,
  setPolySelectReady: (val: boolean) => void,
  map: Map | null,
  setSlopePoints: (val: number[]) => void,
  setDashboardSettingsMode: (mode: string | null) => void,
  h3layer: { [key: string]: VectorLayer<VectorSource<Geometry>> | null },
  showlegend: MapLegend,
  setShowLegend: (val: MapLegend) => void,
  dashboardSettings: DashboardSettings,
  viewrefs: React.MutableRefObject<ViewRefs>,
  dispatch: React.Dispatch<Action>
) => {
  switch (key) {
    case "ParcelAnalysis":
      let ParcelLayer: MapLayer | null = null;
      VectorLayers?.forEach((item) => {
        if (getFileName(item.config.key) === "Douglas_Parcels.geojson") {
          ParcelLayer = item;
        }
      });
      if (ParcelLayer) {
        setParcelDefaults(ParcelLayer);
      }
      break;
    case "ElevationProfile":
      if (viewrefs.current.profileLineLayer && map) {
        map.removeLayer(viewrefs.current.profileLineLayer);
        if (viewrefs.current.polySelectLayer) {
          map.removeLayer(viewrefs.current.polySelectLayer);
        }
        viewrefs.current.polyFirstPoint = false;
        viewrefs.current.polySelectCoordinates = [];
        viewrefs.current.polySelectLine = null;
        viewrefs.current.profileLine = null;
        viewrefs.current.slopeLine = null;
        setSlopePoints([0, 0]);
        viewrefs.current.profileLineLayer = null;
        setProfileLineReady(false);
        setPolySelectReady(false);
        viewrefs.current.drawProfileLine = false;
        viewrefs.current.profileLineCoordinates = [
          [0, 0],
          [0, 0],
        ];
        viewrefs.current.polySelectCoordinates = [
          [0, 0],
          [0, 0],
        ];
        viewrefs.current.polySelectLayer = null;
      }
      setDashboardSettingsMode(null);
      break;
    case "Heatmaps":
      if (map) {
        if (viewrefs.current.heatmapVector) {
          map.removeLayer(viewrefs.current.heatmapVector);
        }
        if (viewrefs.current.heatmapH3Layer) {
          Object.values(viewrefs.current.heatmapH3Layer).forEach((layer) => {
            if (layer) {
              map.removeLayer(layer);
            }
          });
        }
        viewrefs.current.heatmapVector = undefined;
        const newShowLegend = {
          ...showlegend,
          H3heatmap: false,
        };
        setShowLegend(newShowLegend);
      }
      break;
    case "Other":
      console.log("dashboardCleanup Other");
      break;
    case "CanopyAnalysis":
      if (map) {
        Object.keys(h3layer).forEach((res: string) => {
          map.removeLayer(h3layer[res] as VectorLayer<VectorSource<Geometry>>);
        });
      }
      setDashboardSettingsMode(null);
      if (dashboardSettings[key] && dashboardSettings[key].legend) {
        const newShowLegend = {
          ...showlegend,
          [dashboardSettings[key].legend]: false,
        };
        setShowLegend(newShowLegend);
      }

      break;
    case "BuildingAnalysis":
      dispatch({
        type: actionTypes.SET_BUILDINGANALYSIS_SCORE,
        payload: null,
      });
      viewrefs.current.expandedbuildingAnalysisScore = {};
      viewrefs.current.expandedbuildingAnalysisArea = {};
      viewrefs.current.buildingAnalysisCardOrder = [];
      viewrefs.current.buildingAnalysisFloatingCards = [];
      viewrefs.current.buildingAnalysisArea = JSON.parse(
        JSON.stringify(BuildingAnalysisAreaDefaults)
      );
      dispatch({
        type: actionTypes.SET_SELECTED_BUILDING,
        payload: "0",
      });

      Object.keys(viewrefs.current.buildingAnalysisLayer).forEach((item) => {
        if (viewrefs.current.buildingAnalysisLayer[item] && map) {
          map.removeLayer(viewrefs.current.buildingAnalysisLayer[item]);
          delete viewrefs.current.buildingAnalysisLayer[item];
        }
      });

      setDashboardSettingsMode(null);
      const newShowLegend = {
        ...showlegend,
        FuelScore: false,
      };
      setShowLegend(newShowLegend);
      const newSettings = { ...dashboardSettings };
      console.log("newSettings", newSettings);
      if (newSettings["BuildingAnalysis"]) {
        newSettings["BuildingAnalysis"].selected = false;
        dispatch({
          type: actionTypes.SET_DASHBOARD_SETTINGS,
          payload: newSettings,
        });
      }

      break;
    default:
    //Do Nothing
  }
};

export const getDefaultPosition = (
  height: number,
  width: number,
  position: DivPosition,
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined
) => {
  if (position.x === 0 && position.y === 0) {
    if (mapviewRoot && mapviewRoot.current) {
      const box = mapviewRoot.current.getBoundingClientRect();
      return { x: box.width / 2 - width / 2, y: box.height / 2 - height / 2 };
    } else {
      return { x: 0, y: 0 };
    }
  } else return position;
};

export const getDefaultPositionRight = (
  height: number,
  width: number,
  position: DivPosition,
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined
) => {
  if (position.x === 0 && position.y === 0) {
    if (mapviewRoot && mapviewRoot.current) {
      const box = mapviewRoot.current.getBoundingClientRect();
      return { x: box.width - width - 10, y: box.height / 2 - height / 2 };
    } else {
      return { x: 0, y: 0 };
    }
  } else return position;
};

export const getDefaultPositionTopRight = (
  height: number,
  width: number,
  position: DivPosition,
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined
) => {
  if (position.x === 0 && position.y === 0) {
    if (mapviewRoot && mapviewRoot.current) {
      const box = mapviewRoot.current.getBoundingClientRect();
      return { x: box.width - width - 20, y: 160 };
    } else {
      return { x: 0, y: 0 };
    }
  } else return position;
};

export const getDefaultPositionBottomRight = (
  height: number,
  width: number,
  position: DivPosition,
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined
) => {
  if (position.x === 0 && position.y === 0) {
    if (mapviewRoot && mapviewRoot.current) {
      const box = mapviewRoot.current.getBoundingClientRect();
      return { x: box.width - width - 60, y: box.height - height - 40 };
    } else {
      return { x: 0, y: 0 };
    }
  } else return position;
};
