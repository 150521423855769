import * as React from "react";
import { SVGProps } from "react";

const DashboardIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={17.939}
    height={13.04}
    viewBox="0 0 17.939 13.04"
    {...props}
  >
    <defs>
      <style>
        {/* {
          ".a_DashboardIconSmall{fill:none;stroke:#fff;}.b_DashboardIconSmall{clip-path:url(#a);}.c_DashboardIconSmall{fill:#fff;}"
        } */}
      </style>
      <clipPath id="a">
        <rect className="a_DashboardIconSmall" width={17.939} height={13.04} />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <g className="b_DashboardIconSmall">
        <path
          className="c_DashboardIconSmall"
          d="M1.3.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H1.3a.5.5,0,0,1,.5.5A.5.5,0,0,1,1.3.5Z"
          transform="translate(1.109 8.97)"
        />
      </g>
    </g>
    <path
      className="c_DashboardIconSmall"
      d="M.92,1.42a.5.5,0,0,1-.354-.146l-.92-.92a.5.5,0,0,1,0-.707.5.5,0,0,1,.707,0l.92.92A.5.5,0,0,1,.92,1.42Z"
      transform="translate(3.411 3.411)"
    />
    <g transform="translate(0 0)">
      <g className="b_DashboardIconSmall">
        <path
          className="c_DashboardIconSmall"
          d="M0,1.8a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V1.3A.5.5,0,0,1,0,1.8Z"
          transform="translate(8.97 1.109)"
        />
      </g>
    </g>
    <path
      className="c_DashboardIconSmall"
      d="M0,1.42a.5.5,0,0,1-.354-.146.5.5,0,0,1,0-.707l.92-.92a.5.5,0,0,1,.707,0,.5.5,0,0,1,0,.707l-.92.92A.5.5,0,0,1,0,1.42Z"
      transform="translate(13.608 3.411)"
    />
    <g transform="translate(0 0)">
      <g className="b_DashboardIconSmall">
        <path
          className="c_DashboardIconSmall"
          d="M1.3.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H1.3a.5.5,0,0,1,.5.5A.5.5,0,0,1,1.3.5Z"
          transform="translate(15.53 8.97)"
        />
      </g>
    </g>
    <path
      className="c_DashboardIconSmall"
      d="M0,2.7a.5.5,0,0,1-.354-.146.5.5,0,0,1,0-.707l2.2-2.2a.5.5,0,0,1,.707,0,.5.5,0,0,1,0,.707l-2.2,2.2A.5.5,0,0,1,0,2.7Z"
      transform="translate(8.97 6.767)"
    />
    <g transform="translate(0 0)">
      <g className="b_DashboardIconSmall">
        <path
          className="c_DashboardIconSmall"
          d="M17.189,13.482h-15a.5.5,0,0,1-.458-.3A8.69,8.69,0,1,1,18.382,9.691a8.625,8.625,0,0,1-.736,3.493A.5.5,0,0,1,17.189,13.482Zm-14.662-1H16.855a7.618,7.618,0,0,0,.527-2.791A7.691,7.691,0,0,0,2,9.691,7.618,7.618,0,0,0,2.527,12.482Z"
          transform="translate(-0.721 -0.721)"
        />
      </g>
    </g>
  </svg>
);

export default DashboardIconSmall;
