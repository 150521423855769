import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import "ol/ol.css";
import { Map } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import {
  ViewConfigResponse,
  FeatureSelection,
  ViewInfo,
  ExplodedFeatures,
  MapLegend,
  ElevationProfileData,
} from "../api/AlchemyApiTypes";
import { ExportPage } from "./ExportPage";
import { OlMapSettings } from "../map/types";
import Auth from "../services/auth";
import ViewsService from "../services/views";
import { FieldPhotoSelected } from "../components/fieldphotos/FieldPhotoSelected";
import { Hover } from "../components/leftpanel/Hover";
import { Click } from "../components/leftpanel/Click";
import { BottomLeft, BottomMiddle, TopRight } from "../components/Layouts";
import { DashboardController } from "../components/dashboards/DashboardController";
import { viewChange } from "../map/createmap";
import { VideoPlayerController } from "../components/video/VideoPlayerController";
import { FieldPhotoDisplayController } from "../components/fieldphotos/FieldPhotoDisplayController";
import { PropertyDisplayController } from "../components/PropertyDisplayController";
import { LeftPanel } from "../components/leftpanel/LeftPanel";
import { cancelExport, showCurrentLocation } from "../map/functions";
import { dashboardCleanup } from "../components/dashboards/functions";
import { CoordinateElevDisplay } from "../components/CoordinateElevDisplay";
import { Fuel, FuelHealth } from "../components/legends/Fuel";
import { Elevation } from "../components/legends/Elevation";
import { Canopy } from "../components/legends/Canopy";
import { FuelScore } from "../components/dashboards/building/fuelscorecard/FuelScore";
import { H3heatmap } from "../components/legends/H3heatmap";
import { FilterSelector } from "../components/vectorlayers/FilterSelector";
import { SupportEmailForm } from "../components/email/SupportEmailForm";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { ViewRefs, ViewRefsDefault } from "../store/refs";
import { ZoomControls } from "../components/zoomcontrols/ZoomControls";

interface Props extends RouteComponentProps<{ viewId: string }> {
  auth: Auth;
  viewsService: ViewsService;
  storage: storage;
  registerDefault: () => void;
}
const baseBorder = ".5em";

interface storage {
  [key: string]: object;
}

const MapView: React.FC<Props> = (props) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const {
    activatedashboardSettingsMode,
    activateElevationProfileData,
    clearDashboard,
    clickPanel,
    dashboardSettings,
    exporting,
    hoverPanel,
    showlegend,
    supportEmail,
    vectorlayers,
  } = store;

  const { viewsService, auth, storage, registerDefault } = props;
  const { viewId } = props.match.params;
  const [mapId] = useState(`map-${Math.floor(Math.random() * 1000)}`);

  const setActivateElevationProfileData = useCallback(
    (val: number) => {
      dispatch({
        type: actionTypes.SET_ACTIVATE_ELEVATION_PROFILE_DATA,
        payload: val,
      });
    },
    [dispatch]
  );
  const setCoordinates = useCallback(
    (val: number[]) => {
      dispatch({
        type: actionTypes.SET_COORDINATES,
        payload: val,
      });
    },
    [dispatch]
  );
  const setDashboardSettingModeState = useCallback(
    (val: string | null) => {
      dispatch({
        type: actionTypes.SET_DASHBOARD_SETTINGS_MODE_STATE,
        payload: val,
      });
    },
    [dispatch]
  );
  const setElevation = useCallback(
    (val: number) => {
      dispatch({
        type: actionTypes.SET_ELEVATION,
        payload: val,
      });
    },
    [dispatch]
  );
  const setElevationProfileData = useCallback(
    (val: ElevationProfileData[]) => {
      dispatch({
        type: actionTypes.SET_ELEVATION_PROFILE_DATA,
        payload: val,
      });
    },
    [dispatch]
  );
  const setFeatureProperties = useCallback(
    (val: FeatureSelection) => {
      dispatch({
        type: actionTypes.SET_FEATURE_PROPERTIES,
        payload: val,
      });
    },
    [dispatch]
  );
  const setHoveredFieldPhoto = useCallback(
    (val: string[] | null) => {
      dispatch({
        type: actionTypes.SET_HOVERED_FIELD_PHOTO,
        payload: val,
      });
    },
    [dispatch]
  );
  const setLoadingElevation = useCallback(
    (val: boolean) => {
      dispatch({
        type: actionTypes.SET_LOADING_ELEVATION,
        payload: val,
      });
    },
    [dispatch]
  );
  const setMap = useCallback(
    (map: Map | null) => {
      if (map) {
        if (viewrefs.current.map) {
          viewrefs.current.map.setTarget(undefined);
        }
        map.setTarget(mapId);
        viewrefs.current.map = map;
      }
    },
    [mapId]
  );
  const setMapConfig = useCallback(
    (mapconfig: ViewConfigResponse | null) => {
      dispatch({
        type: actionTypes.SET_MAP_CONFIG,
        payload: mapconfig,
      });
    },
    [dispatch]
  );
  const setMapInfo = useCallback(
    (mapinfo: ViewInfo | null) => {
      dispatch({
        type: actionTypes.SET_MAP_INFO,
        payload: mapinfo,
      });
    },
    [dispatch]
  );
  const setMapSettings = useCallback(
    (mapSettings: OlMapSettings | null) => {
      dispatch({
        type: actionTypes.SET_MAP_SETTINGS,
        payload: mapSettings,
      });
    },
    [dispatch]
  );
  const setProfileLineReady = useCallback(
    (val: boolean) => {
      dispatch({
        type: actionTypes.SET_PROFILE_LINE_READY,
        payload: val,
      });
    },
    [dispatch]
  );
  const setPolySelectReady = useCallback(
    (val: boolean) => {
      dispatch({
        type: actionTypes.SET_POLY_SELECT_READY,
        payload: val,
      });
    },
    [dispatch]
  );
  const setShowLegend = useCallback(
    (val: MapLegend) => {
      dispatch({
        type: actionTypes.SET_SHOW_LEGEND,
        payload: val,
      });
    },
    [dispatch]
  );

  const viewrefs = useRef<ViewRefs>(ViewRefsDefault);
  viewrefs.current.viewId = viewId;

  const setZoomFactor = useCallback(
    (zoom: number) => {
      viewrefs.current.zoomfactor = zoom;
      dispatch({
        type: actionTypes.SET_HEATMAP_ZOOM_RATIO,
        payload: Math.pow(1.5, viewrefs.current.zoomfactor - 13.25),
      });
    },
    [dispatch]
  );
  const setDashboardSettingsMode = useCallback(
    (mode: string | null) => {
      viewrefs.current.dashboardSettingsMode = mode;
      dispatch({
        type: actionTypes.SET_ACTIVATE_DASHBOARD_SETTINGS_MODE,
        payload: Math.random(),
      });
    },
    [dispatch]
  );
  const setCanopyCoordinate = useCallback(
    (val: number[]) => {
      viewrefs.current.canopyCoordinate = val;
      dispatch({
        type: actionTypes.SET_CANOPY_COORDINATES_CHANGED,
        payload: Math.random(),
      });
    },
    [dispatch]
  );
  const setSelectedBuilding = useCallback(
    (val: string) => {
      dispatch({
        type: actionTypes.SET_SELECTED_BUILDING,
        payload: val,
      });
    },
    [dispatch]
  );
  const setSlopePoints = useCallback(
    (val: number[]) => {
      viewrefs.current.slopePoints = val;
      dispatch({
        type: actionTypes.SET_SLOPE_CHANGED,
        payload: Math.random(),
      });
    },
    [dispatch]
  );
  const mapviewRoot = useRef<HTMLDivElement>(null);
  const fireVideoFromMarker = useCallback(
    (id: string) => {
      const newItems = viewrefs.current.videoitemsRef?.map((item) => {
        if (item.video.id === id) {
          return { video: item.video, checked: !item.checked };
        } else {
          return item;
        }
      });
      viewrefs.current.videoitemsRef = newItems;
      dispatch({
        type: actionTypes.SET_VIDEO_ITEMS,
        payload: newItems,
      });
    },
    [dispatch]
  );
  const openFieldPhotoFromMarker = useCallback(
    (id: string) => {
      const newItems = viewrefs.current.fieldphotoitemsRef?.map((item) => {
        if (item.fieldphoto.id === id) {
          return { fieldphoto: item.fieldphoto, checked: !item.checked };
        } else {
          return item;
        }
      });
      viewrefs.current.fieldphotoitemsRef = newItems;
      dispatch({
        type: actionTypes.SET_FIELD_PHOTO_ITEMS,
        payload: newItems,
      });
    },
    [dispatch]
  );
  const dashboardCleanupComponents = useCallback(
    (key: string) => {
      dashboardCleanup(
        key,
        vectorlayers,
        setProfileLineReady,
        setPolySelectReady,
        viewrefs.current.map,
        setSlopePoints,
        setDashboardSettingsMode,
        storage["canopy"]
          ? (storage["canopy"] as {
              [key: string]: VectorLayer<VectorSource<Geometry>> | null;
            })
          : {},
        showlegend,
        setShowLegend,
        dashboardSettings,
        viewrefs,
        dispatch
      );
    },
    [
      vectorlayers,
      storage,
      dispatch,
      dashboardSettings,
      setDashboardSettingsMode,
      setPolySelectReady,
      setProfileLineReady,
      setShowLegend,
      setSlopePoints,
      showlegend,
    ]
  );
  const getExplodedFeatures = () => {
    return viewrefs.current.explodedFeatures;
  };
  const setExplodedFeatures = (p: ExplodedFeatures | null) => {
    viewrefs.current.explodedFeatures = p;
  };
  useEffect(() => {
    if (clearDashboard) {
      dashboardCleanupComponents(clearDashboard);
      dispatch({
        type: actionTypes.SET_CLEAR_DASHBOARD,
        payload: "",
      });
    }
  }, [clearDashboard, dispatch, dashboardCleanupComponents]);

  useEffect(() => {
    registerDefault();
  }, [registerDefault]);
  useEffect(() => {
    if (viewrefs.current.map) {
      if ("geolocation" in navigator) {
        try {
          navigator.geolocation.watchPosition(
            function (position) {
              showCurrentLocation(
                position.coords.latitude,
                position.coords.longitude,
                viewrefs.current.map,
                viewrefs
              );
            },
            function (error) {
              console.log("ERROR", error);
            },
            { enableHighAccuracy: true }
          );
        } catch (err) {
          console.log("geolocation error");
        }
      }
    }
  }, [viewrefs]);
  useEffect(() => {
    viewrefs.current.mapValid = false;
    viewChange(
      mapId,
      viewId,
      viewsService,
      fireVideoFromMarker,
      openFieldPhotoFromMarker,
      setFeatureProperties,
      setMapSettings,
      setMap,
      setMapConfig,
      setMapInfo,
      auth,
      setHoveredFieldPhoto,
      setExplodedFeatures,
      getExplodedFeatures,
      setCoordinates,
      setElevation,
      setLoadingElevation,
      setShowLegend,
      setProfileLineReady,
      setPolySelectReady,
      setDashboardSettingsMode,
      setActivateElevationProfileData,
      setZoomFactor,
      setCanopyCoordinate,
      setSelectedBuilding,
      viewrefs
    );
    return () => {
      // console.log("unmounted");
    };
  }, [
    mapId,
    viewId,
    viewsService,
    auth,
    setActivateElevationProfileData,
    setCoordinates,
    setDashboardSettingsMode,
    setElevation,
    setLoadingElevation,
    setZoomFactor,
    setCanopyCoordinate,
    setSelectedBuilding,
    setMapInfo,
    setMapConfig,
    setMapSettings,
    setMap,
    setProfileLineReady,
    setPolySelectReady,
    setShowLegend,
    fireVideoFromMarker,
    openFieldPhotoFromMarker,
    setHoveredFieldPhoto,
    setFeatureProperties,
  ]);
  useEffect(() => {
    setElevationProfileData(viewrefs.current.refElevationProfileData);
  }, [setElevationProfileData, activateElevationProfileData]);
  useEffect(() => {
    setDashboardSettingModeState(viewrefs.current.dashboardSettingsMode);
  }, [setDashboardSettingModeState, activatedashboardSettingsMode]);

  return (
    <>
      <div
        ref={mapviewRoot}
        style={{
          height: "100vh",
          width: "100vw",
          position: "absolute",
          backgroundColor: `#444f3c`,
        }}
      >
        <div id={mapId} style={{ height: "100%", width: "100%" }}></div>
      </div>
      <Hover hoverstate={hoverPanel} />
      <Click
        auth={auth}
        viewrefs={viewrefs}
        clickstate={clickPanel}
        dashboardCleanupComponents={dashboardCleanupComponents}
      />
      {supportEmail.show && (
        <SupportEmailForm auth={auth} mapviewRoot={mapviewRoot} />
      )}
      <LeftPanel auth={auth} storage={storage} viewrefs={viewrefs} />
      <VideoPlayerController
        auth={props.auth}
        mapviewRoot={mapviewRoot}
        viewrefs={viewrefs}
      />
      <FieldPhotoDisplayController
        auth={props.auth}
        mapviewRoot={mapviewRoot}
        storage={storage}
        viewrefs={viewrefs}
      />
      <FieldPhotoSelected mapviewRoot={mapviewRoot} />
      <DashboardController
        mapviewRoot={mapviewRoot}
        auth={auth}
        setDashboardSettingsMode={setDashboardSettingsMode}
        viewId={viewId}
        storage={storage}
        setSlopePoints={setSlopePoints}
        viewrefs={viewrefs}
      />
      <FuelScore
        mapviewRoot={mapviewRoot}
        viewrefs={viewrefs}
        setDashboardSettingsMode={setDashboardSettingsMode}
      />
      <FilterSelector storage={storage} mapviewRoot={mapviewRoot} />
      <BottomMiddle baseBorder={baseBorder}>
        <CoordinateElevDisplay />
      </BottomMiddle>

      {viewrefs.current.map && (
        <div
          style={{
            position: "absolute",
            bottom: 37,
            right: 15,
            backgroundColor: "none",
          }}
        >
          <ZoomControls map={viewrefs.current.map} />
        </div>
      )}

      <TopRight baseBorder={"21px"}>
        <Fuel />
        <FuelHealth />
        <Canopy />
        <Elevation />
        <H3heatmap />
      </TopRight>
      <BottomLeft baseBorder={baseBorder}>
        <PropertyDisplayController
          getExplodedFeatures={getExplodedFeatures}
          auth={auth}
          setSlopePoints={setSlopePoints}
          viewrefs={viewrefs}
        />
      </BottomLeft>
      {exporting.exporting ? (
        <ExportPage cancelExport={cancelExport} exporting={exporting} />
      ) : undefined}
    </>
  );
};

export default withRouter(MapView);
