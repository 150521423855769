import React, { useEffect, useState, useRef, useContext } from "react";
import {
  BarchartConfig,
  ChartData,
  DivPosition,
  ParcelAnalysisSettings,
} from "../../api/AlchemyApiTypes";
import close from "../../images/closeInverse.png";
import {
  getDefaultPosition,
  getIntersectionData,
  setParcelColours,
  highlightParcel,
} from "./functions";
import { BarChart } from "../barChart/BarChartReact";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import { Rnd } from "react-rnd";
import { getHighestFeatureCountArray } from "../barChart/dataFormatFunctions";
import { getSequentialColorScale } from "../../helpers/colours";
import { getFileName } from "../../utils/download";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";

interface Counter {
  [key: string]: number;
}

interface Props {
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
}

export const ParcelAnalysis: React.FC<Props> = ({ mapviewRoot }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { dashboardSettings, vectorlayers } = store;
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });
  const [featureSelections, setfeatureSelections] =
    useState<ParcelAnalysisSettings>({});
  const polysourceRef = useRef<VectorSource<Geometry> | null>(null);

  useEffect(() => {
    let ParcelLayer: VectorLayer<VectorSource<Geometry>>[] = [];
    let SelectionList: ParcelAnalysisSettings = {};
    let DefaultSelectionFound: boolean = false;
    let DefaultSelectionKey: string = "";
    vectorlayers?.forEach((item) => {
      const key = getFileName(item.config.key);
      if (key === "Douglas_Parcels.geojson") {
        ParcelLayer = item.layers;
      }
      //add to selection list
      if (item.config.parcelAnalysis) {
        //take the first item as the default in case no default selection is found
        if (DefaultSelectionKey === "") {
          DefaultSelectionKey = key;
        }
        SelectionList[key] = {
          displayName: item.config.displayName
            ? item.config.displayName
            : key.split("_").join(" "),
          selected:
            !DefaultSelectionFound && item.config.parcelAnalysisDefault
              ? true
              : false,
        };
      }
      if (!DefaultSelectionFound && item.config.parcelAnalysisDefault) {
        DefaultSelectionFound = true;
      }
    });
    //ensure there is a default selection
    if (!DefaultSelectionFound) {
      SelectionList[DefaultSelectionKey].selected = true;
    }
    setfeatureSelections(SelectionList);
    polysourceRef.current = ParcelLayer[0].getSource();
  }, [vectorlayers]);

  useEffect(() => {
    //get selected feature
    let selectedFeature: string = "";
    for (let k in featureSelections) {
      if (featureSelections[k].selected) {
        selectedFeature = k;
      }
    }
    if (selectedFeature) {
      let ComparissonLayer: VectorLayer<VectorSource<Geometry>>[] = [];
      vectorlayers?.forEach((item) => {
        if (getFileName(item.config.key) === selectedFeature) {
          ComparissonLayer = item.layers;
        }
      });

      const featuresource = ComparissonLayer[0].getSource();
      if (polysourceRef.current) {
        const intersectionCount: Counter = getIntersectionData(
          polysourceRef.current,
          featuresource
        );

        const top10Intersections: any = Object.entries(intersectionCount)
          .sort((a, b) => {
            return b[1] - a[1];
          })
          .filter((item, i) => {
            return i < 10;
          });
        const intersectionCountTop10: Counter =
          Object.fromEntries(top10Intersections);
        const top10IntersectionsChartData = top10Intersections.map(
          (item: any) => {
            return { ARN: item[0], NumberOfFeatures: item[1] };
          }
        );

        setParcelColours(polysourceRef.current, intersectionCountTop10);
        setChartData(top10IntersectionsChartData);
      }
    }
  }, [featureSelections, vectorlayers]);

  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.SET_CLEAR_DASHBOARD,
        payload: "ParcelAnalysis",
      });
    };
  }, [dispatch]);

  const highestValue = getHighestFeatureCountArray(chartData);
  const colorScale = getSequentialColorScale([0, highestValue], "Oranges");

  const onClose = () => {
    //Set Parcel style back to default
    // dashboardCleanupComponents("ParcelAnalysis");
    // setSelected("ParcelAnalysis");
    const newSettings = { ...dashboardSettings };
    newSettings["ParcelAnalysis"].selected = false;
    dispatch({
      type: actionTypes.SET_DASHBOARD_SETTINGS,
      payload: newSettings,
    });
  };

  const onMouseOver = (d: ChartData, action: string) => {
    if (polysourceRef.current) {
      highlightParcel(polysourceRef.current, d, action, chartData);
    }
  };

  const config: BarchartConfig = {
    data: chartData,
    width: 200,
    height: 230,
    bars: {
      padding: { inner: 0.05, outer: 0 },
    },
    svgBackground: "#000000",
    axes: {
      text: { colour: "#ffffff" },
      ticks: { colour: "#ffffff" },
      gridlines: { colour: "grey" },
      fontSize: { x: 10, y: 10, labelx: 12, labely: 12 },
    },
    layout: {
      margin: { top: 10, right: 10, bottom: 100, left: 50 },
    },
    labels: {
      x: dashboardSettings["ParcelAnalysis"].chart.x_label,
      y: dashboardSettings["ParcelAnalysis"].chart.y_label,
    },
    getColour: (d) => colorScale(d),
    onMouseOver: (d, action) => onMouseOver(d, action),
  };

  const toggleSelectFeatures = (key: string) => {
    let newfeatureSelections = { ...featureSelections };
    for (let k in newfeatureSelections) {
      newfeatureSelections[k].selected = k === key;
    }
    setfeatureSelections(newfeatureSelections);
  };

  const DashboardDimensions = { height: 310, width: 470 };

  return (
    <Rnd
      position={getDefaultPosition(
        DashboardDimensions.height,
        DashboardDimensions.width,
        position,
        mapviewRoot
      )}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      style={{ zIndex: 9999 }}
    >
      <div
        style={{
          position: "absolute",
          display: "block",
          backgroundColor: "#000000",
          height: DashboardDimensions.height + "px",
          width: DashboardDimensions.width + "px",
          zIndex: 999,
          // border: "1px solid black",
          padding: "1rem",
          borderRadius: 4,
          color: "#ffffff",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "absolute",
            top: 10,
            left: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 18,
            }}
          >
            {"DC Parcel Analysis (Top 10)"}
          </div>
        </div>
        <img
          alt="close"
          src={close}
          style={{
            width: 20,
            position: "absolute",
            top: 0,
            right: 0,
            cursor: "pointer",
          }}
          onClick={() => onClose()}
        ></img>

        <div style={{ display: "flex", marginTop: "40px" }}>
          <BarChart config={config} />
          <div style={{ marginLeft: "30px" }}>
            {Object.keys(featureSelections).map((item: string, i: number) => {
              return (
                <div className="form-check" key={i} style={{ width: "11em" }}>
                  <input
                    style={{ accentColor: "#000000" }}
                    className="form-check-input"
                    id={`check_${i}`}
                    type="radio"
                    name="features"
                    onChange={() => toggleSelectFeatures(item)}
                    checked={featureSelections[item].selected}
                  />
                  <label className="form-check-label" htmlFor={`check_${i}`}>
                    {featureSelections[item].displayName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Rnd>
  );
};
