import * as React from "react";
import { SVGProps } from "react";

const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.4}
    height={11.5}
    viewBox="0 0 9.4 11.5"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(-5.5 -2.5)">
      <path
        className="a"
        d="M7.05,2.5h4.2a.5.5,0,0,1,.354.146l3.15,3.15a.5.5,0,0,1,.146.354v6.3A1.552,1.552,0,0,1,13.35,14H7.05A1.552,1.552,0,0,1,5.5,12.45V4.05A1.552,1.552,0,0,1,7.05,2.5Zm3.993,1H7.05a.551.551,0,0,0-.55.55v8.4a.551.551,0,0,0,.55.55h6.3a.551.551,0,0,0,.55-.55V6.357Z"
      />
      <path
        className="a"
        d="M24.15,6.65H21a.5.5,0,0,1-.5-.5V3a.5.5,0,0,1,1,0V5.65h2.65a.5.5,0,0,1,0,1Z"
        transform="translate(-9.75)"
      />
      <path
        className="a"
        d="M16.2,20H12a.5.5,0,0,1,0-1h4.2a.5.5,0,1,1,0,1Z"
        transform="translate(-3.9 -10.725)"
      />
      <path
        className="a"
        d="M16.2,26H12a.5.5,0,0,1,0-1h4.2a.5.5,0,1,1,0,1Z"
        transform="translate(-3.9 -14.625)"
      />
      <path
        className="a"
        d="M13.05,14H12a.5.5,0,0,1,0-1h1.05a.5.5,0,0,1,0,1Z"
        transform="translate(-3.9 -6.825)"
      />
    </g>
  </svg>
);

export default DocumentIcon;
