export const getStorage = (key: string | null) => {
  let objlocalStorage: any = {};

  if (window.sessionStorage.getItem("Alchemy-layerColours") === null) {
    window.sessionStorage.setItem("Alchemy-layerColours", JSON.stringify({}));
  } else {
    const stringStorage: string | null = window.sessionStorage.getItem(
      "Alchemy-layerColours"
    );
    if (stringStorage) {
      objlocalStorage = JSON.parse(stringStorage);
    }
  }
  if (key) {
    return objlocalStorage[key];
  } else {
    return objlocalStorage;
  }
};

export const setStorage = (key: string | null, value: any) => {
  let objlocalStorage: any = {};
  if (key) {
    let stringStorage: string | null = window.sessionStorage.getItem(
      "Alchemy-layerColours"
    );
    if (stringStorage) {
      objlocalStorage = JSON.parse(stringStorage);
      objlocalStorage[key] = value;
    }
    window.sessionStorage.setItem(
      "Alchemy-layerColours",
      JSON.stringify(objlocalStorage)
    );
  } else {
    window.sessionStorage.setItem(
      "Alchemy-layerColours",
      JSON.stringify(value)
    );
  }
};
