import React, { CSSProperties, useRef, useContext } from "react";
import { SVGImages } from "./SVGImages";
import { Transition } from "react-transition-group";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import { State, Action, actionTypes } from "../../store/storetypes";

const duration = 1000;

interface ActiveFeatures {
  [key: string]: boolean;
}
interface Panelrefs {
  [key: string]: DOMRect;
}

interface Props {
  header: string;
  clickPanel: { active: boolean; box: DOMRect | null; header: string };
  setPanelState: (header: string, active: boolean, type: string) => void;
  panelExpanded: boolean;
  activeFeatures: ActiveFeatures;
  panelBox: React.MutableRefObject<Panelrefs>;
}

export const PanelItem: React.FC<Props> = ({
  header,
  clickPanel,
  setPanelState,
  panelExpanded,
  activeFeatures,
  panelBox,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const history = useHistory();
  const redirect = (ViewId: string) => {
    history.push(`/views/${ViewId}`);
  };

  const setinTransition = (payload: boolean) => {
    dispatch({
      type: actionTypes.SET_LEFT_PANEL_TRANSITION,
      payload: payload,
    });
  };
  const setPanelExpanded = (expanded: boolean) => {
    dispatch({
      type: actionTypes.SET_PANEL_EXPANDED,
      payload: expanded,
    });
  };

  const { leftPanelTransition } = store;
  const DivRef = useRef<HTMLDivElement>(null);

  const SvgComponent = SVGImages(header);

  const defaultStyle: CSSProperties = {
    width: panelExpanded ? 80 : 0,
    whiteSpace: "nowrap",
    overflow: "visible",
    color: activeFeatures[header] ? "#ffffff" : "#757575",
    cursor: "pointer",
  };

  interface TransitionStyles {
    [state: string]: CSSProperties;
  }

  const transitionStyles: TransitionStyles = {
    entering: { overflow: "hidden" },
    entered: { overflow: panelExpanded ? "visible" : "hidden" },
    exiting: { overflow: "hidden" },
    exited: { overflow: panelExpanded ? "visible" : "hidden" },
  };

  const onClick = () => {
    switch (header) {
      case "Expand":
        setPanelExpanded(true);
        setinTransition(false);
        setPanelState("Expand", false, "hover");
        break;
      case "Collapse":
        setPanelExpanded(false);
        setinTransition(true);
        setPanelState("Collapse", false, "hover");
        break;
      case "Return Home":
        redirect("");
        setPanelState("Return Home", false, "hover");
        break;
      // case "Settings":
      //   setPanelState("Settings", false, "hover");
      //   auth.logout();
      //   break;
      default:
        activeFeatures[header]
          ? setPanelState(header, true, "click")
          : console.log("waiting");
    }
  };

  if (SvgComponent) {
    const ret = (
      <div
        style={{
          width: "100%",
          height: 36,
          backgroundColor:
            clickPanel.active && clickPanel.header === header
              ? "#363636"
              : "transparent",
        }}
      >
        <div
          ref={DivRef}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 5,
          }}
        >
          <SvgComponent
            onClick={() => onClick()}
            onMouseEnter={() => setPanelState(header, true, "hover")}
            onMouseLeave={() => setPanelState(header, false, "hover")}
            fill={activeFeatures[header] ? "#ffffff" : "#757575"}
            cursor="pointer"
          />
          <Transition in={leftPanelTransition} timeout={duration}>
            {(state) => {
              return (
                <div
                  onClick={() => onClick()}
                  onMouseEnter={() => setPanelState(header, true, "hover")}
                  onMouseLeave={() => setPanelState(header, false, "hover")}
                  className="transition-width"
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                >
                  <div className="transition-margin" style={{ marginLeft: 15 }}>
                    {header}
                  </div>
                </div>
              );
            }}
          </Transition>
        </div>
      </div>
    );
    if (DivRef && DivRef.current) {
      panelBox.current[header] = DivRef.current.getBoundingClientRect();
    }
    return ret;
  } else return null;
};
