import * as React from "react";
import { SVGProps } from "react";

const MarkersIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.523}
    height={29.527}
    viewBox="0 0 24.523 29.527"
    {...props}
  >
    <defs>{/* <style>{".a_MarkersIconLarge{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(1 1)">
      <path
        className="a_MarkersIconLarge"
        d="M15.761.5h0A12.261,12.261,0,0,1,28.023,12.761a14.052,14.052,0,0,1-1.882,6.715,26.228,26.228,0,0,1-4.023,5.39,37.684,37.684,0,0,1-5.8,4.993,1,1,0,0,1-1.109,0,37.684,37.684,0,0,1-5.8-4.993,26.227,26.227,0,0,1-4.023-5.39A14.052,14.052,0,0,1,3.5,12.761,12.262,12.262,0,0,1,15.761.5Zm0,27.3a37.383,37.383,0,0,0,4.932-4.34A24.172,24.172,0,0,0,24.4,18.5a12.077,12.077,0,0,0,1.627-5.739A10.273,10.273,0,0,0,15.761,2.5h0A10.273,10.273,0,0,0,5.5,12.761,12.077,12.077,0,0,0,7.127,18.5a24.172,24.172,0,0,0,3.7,4.961A37.384,37.384,0,0,0,15.761,27.8Z"
        transform="translate(-4.5 -1.5)"
      />
      <path
        className="a_MarkersIconLarge"
        d="M17.254,9.5A4.754,4.754,0,1,1,12.5,14.254,4.759,4.759,0,0,1,17.254,9.5Zm0,7.507A2.754,2.754,0,1,0,14.5,14.254,2.757,2.757,0,0,0,17.254,17.007Z"
        transform="translate(-5.992 -2.993)"
      />
    </g>
  </svg>
);

export default MarkersIconLarge;
