import React, { useContext } from "react";
import { SettingsProps } from "./Types";
import { ButtonComponent } from "../../components/ButtonComponent";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";

export const SettingsForm: React.FC<SettingsProps> = ({
  auth,
  settings,
  setSettings,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { supportEmail } = store;

  const onSupportClick = () => {
    dispatch({
      type: actionTypes.SET_SUPPORT_EMAIL,
      payload: { ...supportEmail, show: true },
    });
    setSettings(false);
  };
  const onCancelClick = () => {
    setSettings(false);
  };

  if (settings) {
    return (
      <div
        style={{
          position: "absolute",
          top: 80,
          right: 150,
          width: 148,
          minHeight: 158,
          backgroundColor: "#363636",
          borderRadius: "5px",
          color: "#ffffff",
          paddingBottom: 15,
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: 600,
            borderBottom: "2px solid #000000",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <div style={{ paddingLeft: 15 }}>Settings</div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "centre",
            flexDirection: "column",
            padding: 5,
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonComponent
              name="Log out"
              style={{
                width: "100px",
                height: "40px",
                border: "2px solid #ffffff",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "10px",
                backgroundColor: "#000000",
                color: "#ffffff",
                fontSize: "16px",
                borderRadius: "2px",
              }}
              onClick={() => auth.logout()}
              hoverStyle={{
                backgroundColor: "#757575",
                border: "2px solid #757575",
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonComponent
              name="Support"
              style={{
                width: "100px",
                height: "40px",
                border: "2px solid #ffffff",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "10px",
                backgroundColor: "#000000",
                color: "#ffffff",
                fontSize: "16px",
                borderRadius: "2px",
              }}
              onClick={() => onSupportClick()}
              hoverStyle={{
                backgroundColor: "#757575",
                border: "2px solid #757575",
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonComponent
              name="Cancel"
              style={{
                width: "100px",
                height: "40px",
                border: "2px solid #ffffff",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "10px",
                backgroundColor: "#000000",
                color: "#ffffff",
                fontSize: "16px",
                borderRadius: "2px",
              }}
              onClick={() => onCancelClick()}
              hoverStyle={{
                backgroundColor: "#757575",
                border: "2px solid #757575",
              }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
