import * as React from "react";
import { SVGProps } from "react";

const ExpandIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.679}
    height={24.522}
    viewBox="0 0 24.679 24.522"
    {...props}
  >
    <defs>
      <style>
        {/* {".a_ExpandIconLarge{fill:#fff;stroke:#fff;stroke-width:0.5px}"} */}
      </style>
    </defs>
    <g transform="translate(1 1)">
      <path
        className="a_ExpandIconLarge"
        d="M22.679,23.522H2.5A3.506,3.506,0,0,1-1,20.02V2.5A3.506,3.506,0,0,1,2.5-1H22.679a1,1,0,0,1,1,1,1,1,0,0,1-1,1H2.5A1.5,1.5,0,0,0,1,2.5V20.02a1.5,1.5,0,0,0,1.5,1.5H22.679a1,1,0,0,1,0,2Z"
        transform="translate(0 0)"
      />
      <path
        className="a_ExpandIconLarge"
        d="M0,13.512a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414L4.842,6.256-.707.707a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0L6.963,5.549a1,1,0,0,1,0,1.414L.707,13.22A1,1,0,0,1,0,13.512Z"
        transform="translate(16.266 5.005)"
      />
      <path
        className="a_ExpandIconLarge"
        d="M15.015,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H15.015a1,1,0,0,1,1,1A1,1,0,0,1,15.015,1Z"
        transform="translate(7.508 11.261)"
      />
    </g>
  </svg>
);

export default ExpandIconLarge;
