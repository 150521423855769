import React, { useState, useContext } from "react";
import { Rnd } from "react-rnd";
import { ButtonComponentMemo } from "../../ButtonComponentMemo";
import {
  removePotentialElevationPoint,
  checkname,
  removeSlopeLine,
} from "../../../map/functions";
import loadingGIF from "../../../images/ZZ5H.gif";
import {
  ElevationProfilePayload,
  DivPosition,
} from "../../../api/AlchemyApiTypes";
import { StoreContext } from "../../../store/store";
import { State } from "../../../store/storetypes";
import { ViewRefs } from "../../../store/refs";

interface Props {
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  setDashboardSettingsMode: (mode: string | null) => void;
  dashboardSettingModeState: string | null;
  saveElevationProfile: (
    setSaveInProgress: React.Dispatch<React.SetStateAction<boolean>>,
    name: string
  ) => void;
  selectedProfileName: React.MutableRefObject<string | null>;
  setSelectedProfileName: (name: string | null) => void;
  privateElevationProfiles: ElevationProfilePayload;
  setSlopePoints: (val: number[]) => void;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

const DashboardDimensions = { height: 200, width: 110 };

export const ElevationProfileControls: React.FC<Props> = ({
  mapviewRoot,
  setDashboardSettingsMode,
  dashboardSettingModeState,
  saveElevationProfile,
  selectedProfileName,
  setSelectedProfileName,
  privateElevationProfiles,
  setSlopePoints,
  viewrefs,
}) => {
  const [store] = useContext(StoreContext) as [State];
  const { elevationProfileData } = store;
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });
  const onInsert = () => {
    setDashboardSettingsMode("Insert");
  };
  const onCancel = () => {
    removePotentialElevationPoint(viewrefs);
    setSlopePoints([0, 0]);
    removeSlopeLine(viewrefs);
    setDashboardSettingsMode(null);
  };
  const onDelete = () => {
    setDashboardSettingsMode("Delete");
  };
  const onSlope = () => {
    setSlopePoints([0, elevationProfileData.length - 1]);
    setDashboardSettingsMode("Slope");
  };
  const onSave = () => {
    const name: string = checkname(
      selectedProfileName.current,
      privateElevationProfiles,
      "private"
    );
    if (name) {
      setSelectedProfileName(name);
      setSaveInProgress(true);
      setTimeout(() => {
        saveElevationProfile(setSaveInProgress, name);
      }, 10);
    }
  };

  const getDefaultPosition = (height: number, width: number) => {
    if (position.x === 0 && position.y === 0) {
      if (mapviewRoot && mapviewRoot.current) {
        const box = mapviewRoot.current.getBoundingClientRect();
        return { x: box.width - width - 100, y: 10 };
      }
    } else return position;
  };

  const InsertBorder =
    dashboardSettingModeState && dashboardSettingModeState === "Insert"
      ? "Solid 2px red"
      : "Solid 2px #ffffff";
  const DeleteBorder =
    dashboardSettingModeState && dashboardSettingModeState === "Delete"
      ? "Solid 2px red"
      : "Solid 2px #ffffff";
  const SlopeBorder =
    dashboardSettingModeState && dashboardSettingModeState === "Slope"
      ? "Solid 2px blue"
      : "Solid 2px #ffffff";

  return (
    <Rnd
      position={getDefaultPosition(
        DashboardDimensions.height,
        DashboardDimensions.width
      )}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      style={{ zIndex: 9999 }}
    >
      <div
        style={{
          position: "absolute",
          display: "block",
          left: 0,
          top: 0,
          width: DashboardDimensions.width,
          height: DashboardDimensions.height,
          backgroundColor: "#000000",
          zIndex: 9999,
          // border: "1px solid black",
          borderRadius: 4,
          color: "#ffffff",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100%",
          }}
        >
          <ButtonComponentMemo
            name="Insert Point"
            style={{
              width: "90px",
              height: "30px",
              border: InsertBorder,
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
            }}
            onClick={() => onInsert()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
          />
          <ButtonComponentMemo
            name="Delete Point"
            style={{
              width: "90px",
              height: "30px",
              border: DeleteBorder,
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
            }}
            onClick={() => onDelete()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
          />
          <ButtonComponentMemo
            name="Get Slope"
            style={{
              width: "90px",
              height: "30px",
              border: SlopeBorder,
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
            }}
            onClick={() => onSlope()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
          />
          <ButtonComponentMemo
            name="Cancel"
            style={{
              width: "90px",
              height: "30px",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
              border: "2px solid #ffffff",
            }}
            onClick={() => onCancel()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
          />

          {saveInProgress && (
            <div
              style={{
                display: "flex",
                width: "90px",
                height: "30px",
                marginLeft: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img alt="Loading" src={loadingGIF} style={{ width: "20px" }} />
            </div>
          )}
          {!saveInProgress && (
            <ButtonComponentMemo
              name="Save Line"
              style={{
                width: "90px",
                height: "30px",
                backgroundColor: "transparent",
                color: "#ffffff",
                fontSize: "14px",
                borderRadius: "2px",
                border: "2px solid #ffffff",
              }}
              onClick={() => onSave()}
              hoverStyle={{
                backgroundColor: "#757575",
                border: "2px solid #757575",
              }}
            />
          )}
        </div>
      </div>
    </Rnd>
  );
};
