import React, { useEffect, useState, useContext } from "react";
import { getFileDownloadUrl } from "../../api/AlchemyAnalytics";
import Auth from "../../services/auth";
import { VideoControls, VideoObject } from "../../api/AlchemyApiTypes";
import { getFileName } from "../../utils/download";
import { VideoPlayer } from "./VideoPlayer";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { ViewRefs } from "../../store/refs";

interface Props {
  auth: Auth;
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const VideoPlayerController: React.FC<Props> = ({
  auth,
  mapviewRoot,
  viewrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { mapInfo, videoitems } = store;
  const [urls, seturls] = useState<VideoControls | null>(null);

  useEffect(() => {
    let mounted = true;
    async function getUrl() {
      const newurls: VideoControls = {};
      if (videoitems) {
        for (var i = 0; i < videoitems.length; i++) {
          if (videoitems[i].checked) {
            const filepath = videoitems[i].video.fileLocation;
            const key = getFileName(
              filepath.split("?")[0].split("%20").join(" ")
            );

            const url =
              videoitems[i].video.type === "S3"
                ? await getFileDownloadUrl(auth, filepath, "videourl")
                : filepath;
            const name =
              videoitems[i].video.type === "S3"
                ? mapInfo?.mapStyle?.VideoStyle[key].displayName ||
                  key.split(".")[0]
                : videoitems[i].video.displayName || key.split(".")[0];
            newurls[i] = {
              url: url,
              play: true,
              type: videoitems[i].video.type,
              name: name,
              key: videoitems[i].video.key,
            };
          }
        }
      }
      if (mounted) {
        seturls(newurls);
      }
    }
    getUrl();
    return () => {
      mounted = false;
    };
  }, [videoitems, auth, mapInfo]);

  const setPlay = (id: string) => {
    const newUrls: VideoControls = { ...urls };
    if (videoitems) {
      const newvideoitems: VideoObject[] | undefined = [...videoitems];
      newvideoitems[+id].checked = false;
      viewrefs.current.videoitemsRef = newvideoitems;
      dispatch({
        type: actionTypes.SET_VIDEO_ITEMS,
        payload: newvideoitems,
      });
      // setVideoItems(newvideoitems);
      if (newUrls) {
        newUrls[id].play = false;
      }
      seturls(newUrls);
    }
  };

  const showPlayer = (id: string) => {
    if (urls) {
      const index = Object.keys(urls).indexOf(id);
      const video = urls[id];

      if (video.play) {
        return (
          <VideoPlayer
            key={id}
            video={urls[id]}
            id={id}
            setPlay={setPlay}
            index={index}
            mapviewRoot={mapviewRoot}
            count={Object.keys(urls).length}
            auth={auth}
          />
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  if (urls && Object.keys(urls).length > 0) {
    return (
      <>
        {Object.keys(urls).map((id: string) => {
          return showPlayer(id);
        })}
      </>
    );
  } else {
    return null;
  }
};
