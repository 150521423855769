import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";

import MapView from "./pages/MapView";
import Signin from "./pages/signin";
import { ResetPassword } from "./pages/ResetPassword";
import BrowseViews from "./pages/browseviews/BrowseViews";
import Auth from "./services/auth";
import ViewsService from "./services/views";
import { AllRefs, AllRefsDefault } from "./store/refs";

import "./App.css";

const authServ = new Auth();
const viewsService: ViewsService = new ViewsService(authServ);

const App: React.FC = (props: RouteProps) => {
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);
  const [redirected, setRedirected] = useState<boolean>(false);
  const allrefs = useRef<AllRefs>(AllRefsDefault);

  const checkAuth = async () => {
    const isAuthed = await authServ.checkAuth();
    setIsLogged(isAuthed);
  };

  const registerDefault = () => {
    setRedirected(true);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return isLogged === undefined ? null : (
    <Switch>
      <RedirectIfTrueRoute
        condition={isLogged}
        redirectLocation="/"
        path="/login"
      >
        <Signin auth={authServ} validateAuth={() => checkAuth()}></Signin>
      </RedirectIfTrueRoute>
      <Route path="/reset-password">
        <ResetPassword auth={authServ}></ResetPassword>
      </Route>
      <RedirectIfTrueRoute
        condition={!isLogged}
        redirectLocation="/login"
        path="/views/:viewId"
      >
        <MapView
          auth={authServ}
          viewsService={viewsService}
          storage={allrefs.current.storage}
          registerDefault={registerDefault}
        ></MapView>
      </RedirectIfTrueRoute>
      <RedirectIfTrueRoute
        condition={!isLogged}
        redirectLocation="/login"
        path="/views"
      >
        <BrowseViews
          auth={authServ}
          viewsService={viewsService}
          redirected={redirected}
        ></BrowseViews>
      </RedirectIfTrueRoute>
      <RedirectIfTrueRoute
        condition={!isLogged}
        redirectLocation="/login"
        path="/"
      >
        <Redirect to="/views"></Redirect>
      </RedirectIfTrueRoute>
    </Switch>
  );
};

function RedirectIfTrueRoute({
  condition,
  redirectLocation,
  children,
  ...rest
}: any) {
  return (
    <Route
      {...rest}
      render={() => (condition ? <Redirect to={redirectLocation} /> : children)}
    />
  );
}

const RoutedApp = () => (
  <Router>
    <App></App>
  </Router>
);

export default RoutedApp;
