import React, { useEffect, useRef } from "react";
import { AreaChartConfig } from "../../api/AlchemyApiTypes";
import { draw } from "./draw";

interface Props {
  config: AreaChartConfig;
}

export const AreaChart: React.FC<Props> = ({ config }) => {
  const ref = useRef<SVGSVGElement>(null);
  const scalefactors = useRef({
    scale: 1,
    position: [0, 0],
    dimensions: [0, 0],
    xbrush: {
      e: 0,
      w: 0,
    },
    ybrush: {
      s: 0,
      n: 0,
    },
    brushactive: false,
  });

  const drawGraph = () => {
    if (
      scalefactors.current.dimensions[0] === 0 &&
      scalefactors.current.dimensions[1] === 0
    ) {
      scalefactors.current.dimensions = [config.width, config.height];
      scalefactors.current.xbrush = {
        e:
          config.width - config.layout.margin.left - config.layout.margin.right,
        w: 0,
      };
      scalefactors.current.ybrush = {
        s:
          config.height -
          config.layout.margin.top -
          config.layout.margin.bottom,
        n: 0,
      };
    } else {
      /** Compare old and new dimensions to adjust the scalefactors */
      const oldWidth = scalefactors.current.dimensions[0];
      const oldHeight = scalefactors.current.dimensions[1];
      const oldX = scalefactors.current.position[0];
      const oldY = scalefactors.current.position[1];
      const oldW = scalefactors.current.xbrush.w;
      const oldE = scalefactors.current.xbrush.e;
      const oldN = scalefactors.current.ybrush.n;
      const oldS = scalefactors.current.ybrush.s;
      /** Save the current dimensions to be used to compare against when the dimensions change and the
       *  scalefactors need to be adjusted.
       */
      scalefactors.current.dimensions = [config.width, config.height];
      /**  Calculate new scalefactors */
      scalefactors.current.position = [
        (config.width / oldWidth) * oldX,
        (config.height / oldHeight) * oldY,
      ];
      scalefactors.current.xbrush = {
        e:
          ((config.width -
            config.layout.margin.left -
            config.layout.margin.right) /
            (oldWidth -
              config.layout.margin.left -
              config.layout.margin.right)) *
          oldE,
        w:
          ((config.width -
            config.layout.margin.left -
            config.layout.margin.right) /
            (oldWidth -
              config.layout.margin.left -
              config.layout.margin.right)) *
          oldW,
      };

      scalefactors.current.ybrush = {
        s:
          ((config.height -
            config.layout.margin.top -
            config.layout.margin.bottom) /
            (oldHeight -
              config.layout.margin.top -
              config.layout.margin.bottom)) *
          oldS,
        n:
          ((config.height -
            config.layout.margin.top -
            config.layout.margin.bottom) /
            (oldHeight -
              config.layout.margin.top -
              config.layout.margin.bottom)) *
          oldN,
      };
    }
    draw(ref.current, scalefactors.current, config);
  };
  useEffect(drawGraph, [config]);

  return <svg ref={ref} width={config.width} height={config.height}></svg>;
};
