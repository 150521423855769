import React, { useContext } from "react";
import Layer from "ol/layer/Layer";
import Source from "ol/source/Source";
import { StoreContext } from "../store/store";
import { State } from "../store/storetypes";

interface FeatureProperties {
  properties: Object;
  layer: Layer<Source, any>;
}

interface Props {
  featureProperties: FeatureProperties;
}

export const PropertyDisplay: React.FC<Props> = ({ featureProperties }) => {
  const [store] = useContext(StoreContext) as [State];
  const { mapInfo, panelExpanded } = store;
  const layerName = featureProperties.layer.getSource().get("Name");
  const properties =
    mapInfo?.mapStyle?.VectorStyle && mapInfo?.mapStyle?.VectorStyle[layerName]
      ? mapInfo?.mapStyle?.VectorStyle[layerName].properties
      : null;

  return (
    <div
      style={{
        position: "relative",
        display: "block",
        backgroundColor: "#000000",
        margin: 5,
        border: "1px solid #363636",
        borderRadius: 4,
        padding: 5,
        fontSize: 10,
        color: "#ffffff",
        marginLeft: panelExpanded ? 225 : 75,
      }}
    >
      <table>
        <tbody>
          {Object.entries(featureProperties.properties)
            .filter((item) => {
              if (properties?.hideEmpty && !item[1]) {
                return false;
              } else if (
                properties?.hideList &&
                properties.hideList.indexOf(item[0]) !== -1
              ) {
                return false;
              } else {
                if (item[0].includes("System_")) {
                  return false;
                }
                return true;
              }
            })
            .map((item, i) => {
              return (
                <tr key={i}>
                  <td style={{ fontSize: 12 }}>
                    {properties &&
                    properties.changeName &&
                    properties.changeName[item[0]]
                      ? properties.changeName[item[0]]
                      : item[0]}
                  </td>
                  <td>&nbsp;:&nbsp;</td>
                  <td>{item[1]}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
