import Auth from "../../../services/auth";
import { ViewRefs } from "../../../store/refs";

export interface Props {
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  auth: Auth;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export interface ParcelReport {
  parcel_id: number;
  building_id: number;
  zone: string;
  fuel: string;
  hexagons: number;
  density: number;
  area_ft: number;
  parcel_area: number;
}

export interface Fuel {
  latitude: string;
  longitude: string;
}

export interface FuelObject {
  name: string;
  value: string;
  checked: boolean;
}
export interface FuelObjects {
  [key: string]: FuelObject;
}
export interface ZoneObject {
  name: string;
  value: string;
  checked: boolean;
}
export interface ZoneObjects {
  [key: string]: ZoneObject;
}

export interface DisableFuelObjects {
  [key: string]: boolean;
}

export interface H3Indexes {
  [key: string]: { [key: string]: { [key: string]: string[] } };
}

export const H3IndexesDefaults: H3Indexes = {
  heavy: {
    building: {},
    zone0: {},
    zone1: {},
    zone2: {},
  },
  medium: {
    building: {},
    zone0: {},
    zone1: {},
    zone2: {},
  },
  light: {
    building: {},
    zone0: {},
    zone1: {},
    zone2: {},
  },
};

export interface FilteredCoordinates {
  [key: string]: { [key: string]: { [key: string]: number[][] } };
}

export const FilteredCoordinatesDefaults: FilteredCoordinates = {
  heavy: {
    building: {},
    zone0: {},
    zone1: {},
    zone2: {},
  },
  medium: {
    building: {},
    zone0: {},
    zone1: {},
    zone2: {},
  },
  light: {
    building: {},
    zone0: {},
    zone1: {},
    zone2: {},
  },
};

export const FuelObjectDefaults: FuelObjects = {
  heavy: { name: "Heavy", value: "heavy", checked: false },
  medium: { name: "Medium", value: "medium", checked: false },
  light: { name: "Light", value: "light", checked: false },
};
export const ZoneObjectDefaults: ZoneObjects = {
  building: { name: "Buildings", value: "building", checked: true },
  zone0: { name: "Zone 0 (0 - 5ft)", value: "zone0", checked: true },
  zone1: { name: "Zone 1 (5 - 30ft)", value: "zone1", checked: true },
  zone2: { name: "Zone 2 (30 - 100ft)", value: "zone2", checked: true },
};
export const DisableFuelObjectDefaults: DisableFuelObjects = {
  heavy: true,
  medium: true,
  light: true,
};
