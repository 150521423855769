import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  CollectionMap,
  SubheaderMap,
  ViewConfigResponse,
} from "../../api/AlchemyApiTypes";
import topPanel from "../../images/background/AlchemyBG-Dashboard.jpg";
import DashboardLogo from "../../images/svg/logos/DashboardLogo";
import ViewsService from "../../services/views";
import Auth from "../../services/auth";

import FilterIcon from "../../images/svg/other/FilterIcon";
import SettingsIcon from "../../images/svg/other/SettingsIcon";

// import { SupportEmail } from "../../components/email/SupportEmail";
import { SupportEmailForm } from "../../components/email/SupportEmailForm";
import { Loading } from "../../components/Loading";
import { ViewCard } from "./ViewCard";
import { FilterForm } from "./FilterForm";
import { SettingsForm } from "./SettingsForm";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";

const DEFAULT_COLLECTION_NAME = "Other";
const DEFAULT_SUBHEADER = "Unknown";

const BrowseViews: React.FC<{
  viewsService: ViewsService;
  auth: Auth;
  redirected: boolean;
}> = ({ viewsService, auth, redirected }) => {
  // const [displayViews, setDisplayViews] = useState<CollectionMap | null>(null);

  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const browseviewRoot = useRef<HTMLDivElement>(null);

  const { displayViews, filter, filtered, supportEmail } = store;
  const [unfilteredViews, setUnfilteredViews] = useState<CollectionMap | null>(
    null
  );
  const [clientName, setClientName] = useState<string>("");
  const [isActive, setIsActive] = useState<{
    filter: boolean;
    settings: boolean;
  }>({ filter: false, settings: false });
  const [settings, setSettings] = useState<boolean>(false);

  const setFilter = useCallback(
    (payload: { active: boolean; selected: string[] }) => {
      dispatch({ type: actionTypes.SET_FILTER, payload: payload });
    },
    [dispatch]
  );

  const setFiltered = useCallback(
    (payload: string[]) => {
      dispatch({ type: actionTypes.SET_FILTERED, payload: payload });
    },
    [dispatch]
  );

  const setDisplayViews = useCallback(
    (payload: CollectionMap | null) => {
      dispatch({ type: actionTypes.SET_DISPLAYVIEWS, payload: payload });
    },
    [dispatch]
  );
  const setSQLActive = useCallback(
    (payload: boolean) => {
      dispatch({ type: actionTypes.SET_SQL_ACTIVE, payload: payload });
    },
    [dispatch]
  );

  useEffect(() => {
    const getViews = async () => {
      const newViews: ViewConfigResponse[] = await viewsService.getAllViews();
      const newDisplayViews: CollectionMap = {};
      newViews.forEach((item, i) => {
        if (i === 0) {
          setClientName(item.client_name);
          setSQLActive(item.SQL_Active ? true : false);
        }
        const collection: string = item.view_config.collectionName
          ? item.view_config.collectionName
          : DEFAULT_COLLECTION_NAME;
        const subheader = item.view_config.subheader ?? DEFAULT_SUBHEADER;
        if (collection in newDisplayViews) {
          if (subheader in newDisplayViews[collection]) {
            newDisplayViews[collection][subheader].push(item);
          } else {
            newDisplayViews[collection][subheader] = [item];
          }
        } else {
          newDisplayViews[collection] = {};
          newDisplayViews[collection][subheader] = [item];
        }
      });

      setUnfilteredViews(newDisplayViews);
      setDisplayViews(newDisplayViews);
    };
    getViews();
  }, [viewsService, setDisplayViews, setUnfilteredViews, setSQLActive]);

  useEffect(() => {
    if (unfilteredViews) {
      const newViews = { ...unfilteredViews };
      filtered.forEach((item) => {
        delete newViews[item];
      });
      setDisplayViews(newViews);
    }
  }, [filtered, setDisplayViews, unfilteredViews]);

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100vw",
        display: "flex",
        backgroundColor: "#FAFAFA",
      }}
    >
      <div
        ref={browseviewRoot}
        style={{
          minHeight: "100%",
          width: "100%",
          backgroundColor: "#000000",
        }}
      >
        {supportEmail.show && (
          <SupportEmailForm auth={auth} mapviewRoot={browseviewRoot} />
        )}
        <div
          style={{
            position: "relative",
            height: 100,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundImage: `url(${topPanel})`,
            backgroundSize: "cover",
            backgroundRepeat: "none",
            backgroundPosition: "center center",
          }}
        >
          <div style={{ marginLeft: 140 }}>
            <DashboardLogo fill="#fff" />
          </div>
          <span
            style={{
              color: "#ffffff",
              fontSize: 32,
              fontWeight: "normal",
              marginRight: 140,
            }}
          >
            {`${clientName} Data`}
          </span>
        </div>
        <div
          style={{
            position: "relative",
            height: 90,
            width: "100%",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            backgroundColor: "#000000",
            color: "#ffffff",
          }}
        >
          <div style={{ display: "flex", marginRight: 150 }}>
            <div
              onMouseEnter={() => {
                setIsActive({ filter: true, settings: false });
              }}
              onMouseLeave={() => {
                setIsActive({ filter: false, settings: false });
              }}
              onClick={() => setFilter({ ...filter, active: true })}
              style={{
                backgroundColor: isActive.filter ? "#363636" : "#000000",
                borderRadius: "5px",
                paddingTop: 10,
                paddingBottom: 12,
                paddingLeft: 22,
                paddingRight: 22,
                cursor: "pointer",
              }}
            >
              <FilterIcon fill="#fff" />
              <span style={{ fontSize: 18, marginLeft: 10 }}>Filter</span>
            </div>
            <div
              onMouseEnter={() => {
                setIsActive({ filter: false, settings: true });
              }}
              onMouseLeave={() => {
                setIsActive({ filter: false, settings: false });
              }}
              onClick={() => setSettings(true)}
              style={{
                backgroundColor: isActive.settings ? "#363636" : "#000000",
                borderRadius: "5px",
                paddingTop: 10,
                paddingBottom: 12,
                paddingLeft: 22,
                paddingRight: 22,
                cursor: "pointer",
              }}
            >
              <SettingsIcon fill="#fff" />
              <span style={{ fontSize: 18, marginLeft: 10 }}>Settings</span>
            </div>
          </div>
          <FilterForm
            displayViews={unfilteredViews}
            filter={filter}
            setFilter={setFilter}
            setFiltered={setFiltered}
          />
          <SettingsForm
            auth={auth}
            settings={settings}
            setSettings={setSettings}
          />
        </div>
        {displayViews == null ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loading></Loading>
          </div>
        ) : Object.keys(displayViews).length === 0 ? (
          <div className="text-center">
            No maps available right now, please check back later.
          </div>
        ) : (
          Object.entries(displayViews)
            .sort()
            // .sort(defaultLastSort)
            .map(([collectionName, sviews]: [string, SubheaderMap], i) => (
              <div
                style={{
                  backgroundColor: "#000000",
                  marginLeft: 140,
                  marginRight: 140,
                  color: "#ffffff",
                  borderTop: i === 0 ? "none" : "1px solid #757575",
                  marginTop: 30,
                }}
                key={i}
              >
                <div style={{ fontSize: 32, fontWeight: 800, marginTop: 30 }}>
                  {collectionName}
                </div>
                {Object.entries(sviews).map(
                  ([subheader, views]: [string, ViewConfigResponse[]], i) => (
                    <div key={i}>
                      {subheader !== "Unknown" && (
                        <span style={{ fontSize: 16, fontWeight: "normal" }}>
                          {subheader}
                        </span>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "100%",
                          backgroundColor: "#000000",
                          marginTop: 20,
                        }}
                      >
                        {views.map((view, j) => (
                          <ViewCard
                            key={j}
                            view={view}
                            redirected={redirected}
                            auth={auth}
                          />
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default BrowseViews;
