import { iconstyles, iconstylesHighlight } from "../../map/icons";
import { MarkerLayers, FieldPhotoObject } from "../../api/AlchemyApiTypes";

export const highlightItem = (
  item: FieldPhotoObject,
  action: string,
  markerVectorLayers: MarkerLayers
) => {
  markerVectorLayers["Field Photos"]
    ?.getSource()
    .getFeatures()
    .forEach((feature) => {
      if (feature.getId() === item.fieldphoto.id) {
        if (item.fieldphoto.marker) {
          feature.setStyle(
            action === "enter"
              ? iconstylesHighlight[item.fieldphoto.marker]
              : iconstyles[item.fieldphoto.marker]
          );
          feature.set("System_RenderOrder", action === "enter" ? 2 : 0);
        }
      }
    });
};
