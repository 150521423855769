import * as React from "react";
import { SVGProps } from "react";

const SettingsIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29.528}
    height={29.527}
    viewBox="0 0 29.528 29.527"
    {...props}
  >
    <defs>{/* <style>{".a_SettingsIconLarge{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(1 1)">
      <path
        className="a_SettingsIconLarge"
        d="M17.254,12.5A4.754,4.754,0,1,1,12.5,17.254,4.759,4.759,0,0,1,17.254,12.5Zm0,7.507A2.754,2.754,0,1,0,14.5,17.254,2.757,2.757,0,0,0,17.254,20.007Z"
        transform="translate(-3.49 -3.49)"
      />
      <path
        className="a_SettingsIconLarge"
        d="M15.364,30.027a3.506,3.506,0,0,1-3.5-3.5v-.1a1.059,1.059,0,0,0-.7-.965l-.059-.024a1.064,1.064,0,0,0-1.171.21l-.071.071A3.5,3.5,0,1,1,4.91,20.762l.07-.07a1.059,1.059,0,0,0,.21-1.171l0-.01a1.064,1.064,0,0,0-.974-.645H4a3.5,3.5,0,1,1,0-7h.1a1.059,1.059,0,0,0,.965-.7q.011-.03.024-.059a1.059,1.059,0,0,0-.21-1.171l-.071-.071A3.5,3.5,0,1,1,9.765,4.91l.07.07a1.064,1.064,0,0,0,1.171.21,1,1,0,0,1,.242-.072,1.064,1.064,0,0,0,.513-.907V4a3.5,3.5,0,0,1,7.005,0v.113a1.063,1.063,0,0,0,.645.97l.01,0a1.064,1.064,0,0,0,1.171-.21l.071-.071a3.5,3.5,0,1,1,4.955,4.956l-.07.07a1.059,1.059,0,0,0-.21,1.171,1,1,0,0,1,.072.242,1.064,1.064,0,0,0,.907.513h.209a3.5,3.5,0,1,1,0,7h-.113a1.063,1.063,0,0,0-.97.645l0,.01a1.059,1.059,0,0,0,.21,1.171l.071.071a3.5,3.5,0,1,1-4.956,4.955l-.07-.07a1.064,1.064,0,0,0-1.171-.21l-.01,0a1.064,1.064,0,0,0-.645.974v.209A3.506,3.506,0,0,1,15.364,30.027ZM11.885,23.6a3.047,3.047,0,0,1,1.976,2.793q0,.012,0,.023v.113a1.5,1.5,0,0,0,3.005,0v-.213a3.079,3.079,0,0,1,5.228-2.191l.008.008.075.075A1.5,1.5,0,1,0,24.3,22.077L24.229,22l-.008-.008a3.081,3.081,0,0,1,2.187-5.228h.117a1.5,1.5,0,1,0,0-3h-.213A3.064,3.064,0,0,1,23.5,11.9a1,1,0,0,1-.075-.289,3.048,3.048,0,0,1,.692-3.182l.008-.008L24.2,8.35a1.5,1.5,0,1,0-2.126-2.127L22,6.3l-.008.008a3.081,3.081,0,0,1-5.228-2.187V4a1.5,1.5,0,0,0-3.005,0v.213A3.064,3.064,0,0,1,11.9,7.024a1,1,0,0,1-.289.075,3.065,3.065,0,0,1-3.182-.692L8.426,6.4,8.35,6.324A1.5,1.5,0,1,0,6.223,8.45l.075.075.008.008a3.05,3.05,0,0,1,.626,3.351,3.048,3.048,0,0,1-2.793,1.976H4a1.5,1.5,0,1,0,0,3h.213a3.081,3.081,0,0,1,2.191,5.228L6.4,22.1l-.075.075A1.5,1.5,0,1,0,8.45,24.3l.075-.075.008-.008a3.064,3.064,0,0,1,3.351-.625Z"
        transform="translate(-1.5 -1.5)"
      />
    </g>
  </svg>
);

export default SettingsIconLarge;
