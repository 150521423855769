import * as React from "react";
import { SVGProps } from "react";

const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.874}
    height={22.874}
    viewBox="0 0 22.874 22.874"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a"
        d="M16.483,13A3.483,3.483,0,1,1,13,16.483,3.487,3.487,0,0,1,16.483,13Zm0,5.966A2.483,2.483,0,1,0,14,16.483,2.486,2.486,0,0,0,16.483,18.966Z"
        transform="translate(-5.546 -5.546)"
      />
      <path
        className="a"
        d="M12.517,23.874a2.491,2.491,0,0,1-2.489-2.489V21.3a1.134,1.134,0,0,0-.746-1.038l-.029-.012A1.14,1.14,0,0,0,8,20.479l-.057.057a2.49,2.49,0,1,1-3.521-3.521l.057-.057A1.135,1.135,0,0,0,4.7,15.7l0,0a1.14,1.14,0,0,0-1.044-.691H3.489a2.489,2.489,0,0,1,0-4.977h.083A1.134,1.134,0,0,0,4.61,9.282l.012-.029A1.135,1.135,0,0,0,4.4,8l-.057-.057A2.49,2.49,0,1,1,7.859,4.418l.057.057A1.14,1.14,0,0,0,9.173,4.7.5.5,0,0,1,9.34,4.66a1.14,1.14,0,0,0,.609-1V3.489a2.489,2.489,0,1,1,4.977,0v.089a1.139,1.139,0,0,0,.691,1.042l0,0A1.14,1.14,0,0,0,16.879,4.4l.057-.057a2.49,2.49,0,1,1,3.521,3.521l-.057.057a1.135,1.135,0,0,0-.226,1.256.5.5,0,0,1,.041.167,1.14,1.14,0,0,0,1,.609h.167a2.489,2.489,0,0,1,0,4.977H21.3a1.139,1.139,0,0,0-1.042.691l0,0a1.135,1.135,0,0,0,.226,1.256l.057.057a2.49,2.49,0,1,1-3.521,3.521l-.057-.057a1.14,1.14,0,0,0-1.256-.226l0,0a1.14,1.14,0,0,0-.691,1.044v.167A2.491,2.491,0,0,1,12.517,23.874ZM9.642,19.331a2.129,2.129,0,0,1,1.386,1.953s0,.008,0,.012v.089a1.489,1.489,0,0,0,2.977,0v-.169a2.152,2.152,0,0,1,3.654-1.531l0,0,.06.06a1.49,1.49,0,1,0,2.107-2.107l-.06-.06,0,0a2.154,2.154,0,0,1,1.529-3.654h.091a1.489,1.489,0,0,0,0-2.977h-.169a2.14,2.14,0,0,1-1.961-1.3.5.5,0,0,1-.04-.177,2.13,2.13,0,0,1,.47-2.259l0,0,.06-.06a1.49,1.49,0,1,0-2.107-2.107l-.06.06,0,0A2.154,2.154,0,0,1,13.926,3.58V3.489a1.489,1.489,0,1,0-2.977,0v.169a2.14,2.14,0,0,1-1.3,1.961.5.5,0,0,1-.177.04,2.141,2.141,0,0,1-2.259-.47l0,0-.06-.06A1.49,1.49,0,1,0,5.045,7.231l.06.06,0,0a2.13,2.13,0,0,1,.434,2.347A2.129,2.129,0,0,1,3.59,11.028h-.1a1.489,1.489,0,0,0,0,2.977h.169a2.154,2.154,0,0,1,1.531,3.654l0,0-.06.06a1.49,1.49,0,1,0,2.107,2.107l.06-.06,0,0a2.14,2.14,0,0,1,2.347-.434Z"
        transform="translate(-1.5 -1.5)"
      />
    </g>
  </svg>
);

export default SettingsIcon;
