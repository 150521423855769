export const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "#000000",
    color: "#ffffff",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#757575" : "#363636",
      color: "#FFF",
      cursor: isDisabled ? "not-allowed" : "default",
      borderColor: "green",
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#363636",
      color: "#ffffff",
    };
  },
  multiValueLabel: (styles) => {
    return {
      ...styles,
      backgroundColor: "#363636",
      color: "#ffffff",
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    backgroundColor: "#363636",
    color: "#ffffff",
    ":hover": {
      backgroundColor: "#757575",
      color: "white",
    },
  }),
  menuList: (styles) => {
    return {
      ...styles,
      backgroundColor: "#757575",
    };
  },
};
