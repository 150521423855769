import * as React from "react";
import { SVGProps } from "react";

const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.886}
    height={18.897}
    viewBox="0 0 20.886 18.897"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <path
      className="a"
      d="M3,4H22.886a.5.5,0,0,1,.382.823l-7.836,9.266V22.4a.5.5,0,0,1-.724.447l-3.977-1.989a.5.5,0,0,1-.276-.447v-6.32L2.618,4.823A.5.5,0,0,1,3,4ZM21.808,5H4.078l7.258,8.583a.5.5,0,0,1,.118.323V20.1l2.977,1.489V13.906a.5.5,0,0,1,.118-.323Z"
      transform="translate(-2.5 -4)"
    />
  </svg>
);

export default FilterIcon;
