import React, { useState, useEffect, useMemo } from "react";

interface StyleButton {
  width?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
  fontSize?: string;
  fontWeight?: string;
  height?: string | undefined;
  backgroundColor?: string;
  border?: string;
  color?: string;
  borderRadius?: string;
  paddingLeft?: string;
  paddingRight?: string;
}

const defaultStyle: StyleButton = {
  width: undefined,
  marginLeft: "10px",
  marginRight: "0px",
  marginTop: "0px",
  marginBottom: "0px",
  fontSize: "12px",
  fontWeight: "normal",
  height: undefined,
  backgroundColor: "#F2F3F5",
  border: "1px solid grey",
  color: "black",
  borderRadius: "4px",
  paddingLeft: "0px",
  paddingRight: "0px",
};

interface Props {
  name: string;
  title?: string;
  style: StyleButton;
  onClick: () => void;
  hoverStyle?: StyleButton;
  onMouseOver?: () => void;
  image?: string;
  component?: JSX.Element;
}

export const ButtonComponentMemo: React.FC<Props> = ({
  name,
  title,
  style,
  onClick,
  hoverStyle,
  onMouseOver,
  image,
  component,
}) => {
  const combinedStyle = useMemo(() => {
    const stylewithdefaults = { ...defaultStyle, ...style };
    return {
      display: "flex",
      width: stylewithdefaults.width,
      height: stylewithdefaults.height,
      borderRadius: stylewithdefaults.borderRadius,
      border: stylewithdefaults.border,
      marginLeft: stylewithdefaults.marginLeft,
      marginRight: stylewithdefaults.marginRight,
      marginTop: stylewithdefaults.marginTop,
      marginBottom: stylewithdefaults.marginBottom,
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: stylewithdefaults.backgroundColor,
      fontSize: stylewithdefaults.fontSize,
      fontWeight: stylewithdefaults.fontWeight,
      color: stylewithdefaults.color,
      paddingLeft: stylewithdefaults.paddingLeft,
      paddingRight: stylewithdefaults.paddingRight,
    };
  }, [style]);

  const [buttonStyle, setButtonStyle] = useState<any>(combinedStyle);

  useEffect(() => {
    setButtonStyle(combinedStyle);
  }, [combinedStyle]);

  const onMouseOverx = () => {
    setButtonStyle({ ...combinedStyle, ...hoverStyle });
    if (onMouseOver) {
      onMouseOver();
    }
  };
  const onMouseLeave = () => {
    setButtonStyle(combinedStyle);
  };

  return (
    <div
      title={title}
      style={buttonStyle}
      onClick={() => onClick()}
      onMouseOver={() => onMouseOverx()}
      onMouseLeave={() => onMouseLeave()}
    >
      {name}
      {image && <img alt="" src={image} style={{ marginLeft: 6, width: 12 }} />}
      {component && <div style={{ marginLeft: 6 }}>{component}</div>}
    </div>
  );
};
