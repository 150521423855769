import React from "react";
import Auth from "../../../services/auth";
import exporticon from "../../../images/downloadExcel25Black.png";
import { exportToCSV } from "./functions";
import { fetchParcelReport } from "../../../api/AlchemyAnalytics";
import { ParcelReport } from "./types";
const onExport = (auth: Auth) => {
  const asyncfunction = async (auth: Auth) => {
    const parcelreportdata: ParcelReport[] = await fetchParcelReport(auth);
    const filename = "parcel_report";
    const wscols = [
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 12 },
      { wch: 15 },
    ];

    exportToCSV(parcelreportdata, filename, wscols);
  };
  asyncfunction(auth);
};
interface Props {
  auth: Auth;
}

export const ExportReport: React.FC<Props> = ({ auth }) => {
  return (
    <img
      alt="export"
      title={"export to Excel"}
      src={exporticon}
      style={{ width: 25, cursor: "pointer" }}
      onClick={() => onExport(auth)}
    ></img>
  );
};
