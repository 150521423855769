import Style from "ol/style/Style";
import Icon from "ol/style/Icon";

import towerImage from "../images/electricalTower.png";
import towerImageHighlight from "../images/electricalTowerHighlight.png";
import pylonImage from "../images/electricalPylon.png";
import pylonImageHighlight from "../images/electricalPylonHighlight.png";
import videoimage from "../images/videoS.png";
import videoimagehighlight from "../images/videoSCyan.png";
import fieldphotoimage from "../images/cameraS.png";
import fieldphotoimagehighlight from "../images/cameraSCyan.png";
import summary from "../images/summaryT.png";
import summaryHighlight from "../images/summaryHighlight.png";
import video3D from "../images/3DVideo.png";
import video3Dhighlight from "../images/3DVideoHighlight.png";
import PSOM from "../images/PSOMT.png";
import PSOMhighlight from "../images/PSOMHighlight.png";
import lidar from "../images/lidarT.png";
import lidarhighlight from "../images/lidarhighlight.png";
import RedDot from "../images/red_dot.png";

import RedDot50 from "../images/red_dot50.png";

import RedDot20 from "../images/red_dot20.png";
import PolyBox from "../images/polybox.png";

interface IconStyles {
  [key: string]: Style;
}

interface Icons {
  [key: string]: any;
}

export const icons: Icons = {
  ElectricalTower: towerImage,
  ElectricalPylon: pylonImage,
  Summary: summary,
  Ground: videoimage,
  PSOM: PSOM,
  "Field Photos": fieldphotoimage,
  LiDAR: lidar,
  Video3D: video3D,
};

export const iconstyles: IconStyles = {
  ElectricalTower: new Style({
    image: new Icon({
      opacity: 1,
      src: towerImage,
      scale: 0.1,
    }),
  }),
  ElectricalPylon: new Style({
    image: new Icon({
      opacity: 1,
      src: pylonImage,
      scale: 0.1,
    }),
  }),
  Summary: new Style({
    image: new Icon({
      opacity: 1,
      src: summary,
      scale: 0.1,
    }),
  }),
  Ground: new Style({
    image: new Icon({
      opacity: 1,
      src: videoimage,
      scale: 0.1,
    }),
  }),
  PSOM: new Style({
    image: new Icon({
      opacity: 1,
      src: PSOM,
      scale: 0.07,
    }),
  }),
  "Field Photos": new Style({
    image: new Icon({
      opacity: 1,
      src: fieldphotoimage,
      scale: 0.1,
    }),
  }),
  LiDAR: new Style({
    image: new Icon({
      opacity: 1,
      src: lidar,
      scale: 0.1,
    }),
  }),
  Video3D: new Style({
    image: new Icon({
      opacity: 1,
      src: video3D,
      scale: 0.1,
    }),
  }),
  RedDot: new Style({
    image: new Icon({
      opacity: 1,
      src: RedDot,
      scale: 0.04,
    }),
  }),

  RedDot50: new Style({
    image: new Icon({
      opacity: 1,
      src: RedDot50,
      scale: 1,
    }),
  }),
  RedDot20: new Style({
    image: new Icon({
      opacity: 1,
      src: RedDot20,
      scale: 1,
    }),
  }),
  PolyBox: new Style({
    image: new Icon({
      opacity: 1,
      src: PolyBox,
      scale: 0.5,
    }),
  }),
};

export const iconstylesHighlight: IconStyles = {
  ElectricalTower: new Style({
    image: new Icon({
      opacity: 1,
      src: towerImageHighlight,
      scale: 0.1,
    }),
  }),
  ElectricalPylon: new Style({
    image: new Icon({
      opacity: 1,
      src: pylonImageHighlight,
      scale: 0.1,
    }),
  }),
  Summary: new Style({
    image: new Icon({
      opacity: 1,
      src: summaryHighlight,
      scale: 0.1,
    }),
  }),
  Ground: new Style({
    image: new Icon({
      opacity: 1,
      src: videoimagehighlight,
      scale: 0.1,
    }),
  }),
  PSOM: new Style({
    image: new Icon({
      opacity: 1,
      src: PSOMhighlight,
      scale: 0.07,
    }),
  }),
  "Field Photos": new Style({
    image: new Icon({
      opacity: 1,
      src: fieldphotoimagehighlight,
      scale: 0.1,
    }),
  }),
  LiDAR: new Style({
    image: new Icon({
      opacity: 1,
      src: lidarhighlight,
      scale: 0.1,
    }),
  }),

  Video3D: new Style({
    image: new Icon({
      opacity: 1,
      src: video3Dhighlight,
      scale: 0.1,
    }),
  }),
};
