import * as React from "react";
import { SVGProps } from "react";

const ImagesIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.016}
    height={15.016}
    viewBox="0 0 15.016 15.016"
    {...props}
  >
    <defs>{/* <style>{".a_ImagesIconSmall{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a_ImagesIconSmall"
        d="M6.057,4h10.9a2.06,2.06,0,0,1,2.057,2.057v10.9a2.06,2.06,0,0,1-2.057,2.057H6.057A2.06,2.06,0,0,1,4,16.959V6.057A2.06,2.06,0,0,1,6.057,4Zm10.9,14.016a1.059,1.059,0,0,0,1.057-1.057V6.057A1.059,1.059,0,0,0,16.959,5H6.057A1.059,1.059,0,0,0,5,6.057v10.9a1.059,1.059,0,0,0,1.057,1.057Z"
        transform="translate(-4.5 -4.5)"
      />
      <path
        className="a_ImagesIconSmall"
        d="M11.668,13.336a1.668,1.668,0,1,1,1.668-1.668A1.67,1.67,0,0,1,11.668,13.336Zm0-2.336a.668.668,0,1,0,.668.668A.669.669,0,0,0,11.668,11Z"
        transform="translate(-7.385 -7.385)"
      />
      <path
        className="a_ImagesIconSmall"
        d="M7.5,24.065a.5.5,0,0,1-.354-.854l8.566-8.565a.5.5,0,0,1,.707,0l3.893,3.893a.5.5,0,0,1-.707.707l-3.54-3.54L7.854,23.919A.5.5,0,0,1,7.5,24.065Z"
        transform="translate(-5.943 -9.549)"
      />
    </g>
  </svg>
);

export default ImagesIconSmall;
