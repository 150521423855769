import Auth from "../services/auth";
import { Map } from "ol";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import * as olInteraction from "ol/interaction";
import Collection from "ol/Collection";
import {
  getFileDownloadUrl,
  fetchElevation,
  fetchDataStorage,
} from "../api/AlchemyAnalytics";
import { mapViewToPdf } from "../utils/mapExport";
import * as olColor from "ol/color";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import { fromLonLat, transform as oltransform } from "ol/proj";
import {
  ViewConfigResponse,
  ConsolidatedURLs,
  MapStyle,
  FileStyle,
  VectorStyle,
  VideoStyle,
  DashboardItems,
  FieldPhotoStyle,
  HostedClips,
  MarkerLayers,
  Marker,
  MapLayer,
  VectorStyleStyle,
  Export,
  ElevationProfileData,
  ElevationProfilePayload,
  GetElevationSQL,
  Storage,
} from "../api/AlchemyApiTypes";
import { iconstyles } from "../map/icons";
import { getFileName } from "../utils/download";
import _ from "lodash";
import {
  findDistanceBetweenPoints,
  meterstofeet,
  feettometers,
  findPointAlongLine,
} from "../map/math";
import { ViewRefs } from "../store/refs";

interface Features {
  [key: string]: number[];
}

const profileLineStyle = new Style({
  stroke: new Stroke({
    color: "red",
    width: 2,
  }),
});

const slopeLineStyle = new Style({
  stroke: new Stroke({
    color: "blue",
    width: 1,
  }),
});

const slopePointStyle = new Style({
  image: new Circle({
    radius: 3,
    fill: new Fill({ color: "blue" }),
    stroke: new Stroke({
      color: "blue",
      width: 1,
    }),
  }),
});

const tempprofileLineStyle = new Style({
  stroke: new Stroke({
    color: "red",
    width: 1,
  }),
});

const polygonSelectLineStyle = new Style({
  stroke: new Stroke({
    color: "blue",
    width: 1,
  }),
});

const elevationPointStyle = new Style({
  image: new Circle({
    radius: 2,
    fill: new Fill({ color: "transparent" }),
    stroke: new Stroke({
      color: "black",
      width: 1,
    }),
  }),
});
const potentialelevationPointStyle = new Style({
  image: new Circle({
    radius: 4,
    fill: new Fill({ color: "transparent" }),
    stroke: new Stroke({
      color: "red",
      width: 1,
    }),
  }),
});
const elevationPointStyleselected = new Style({
  image: new Circle({
    radius: 2,
    fill: new Fill({ color: "black" }),
    stroke: new Stroke({
      color: "black",
      width: 1,
    }),
  }),
});

export const colorWithAlpha = (color: string, alpha: number) => {
  const [r, g, b] = Array.from(olColor.asArray(color));
  return olColor.asString([r, g, b, alpha]);
};

export const downloadFile = async (auth: Auth, filepath: string) => {
  const url = await getFileDownloadUrl(auth, filepath, "fileurl");
  window.open(url, "_blank");
};

export const exportViewAsPdf = (
  map: Map | null,
  setExporting: (payload: Export) => void
) => {
  if (map) {
    const exportCancelFn = mapViewToPdf(map, () => {
      setExporting({ exporting: false, exportCancelFn: null });
    });
    setExporting({ exporting: true, exportCancelFn: exportCancelFn });
  }
};
export const cancelExport = (exportCancelFn: (() => void) | null) => {
  if (exportCancelFn) {
    exportCancelFn();
  }
};

const combineURLs = (
  specific: string[] | undefined,
  shared: string[] | undefined
) => {
  let viewURLs: string[] = [];
  let sharedURLs: string[] = [];
  let combined: string[] | undefined;
  let final: string[] = [];
  if (specific && specific.length > 0) {
    viewURLs = specific;
  }
  if (shared && shared.length > 0) {
    sharedURLs = shared;
  }
  combined = [...viewURLs, ...sharedURLs];
  const objControl: { [key: string]: string[] } = {};
  combined.forEach((filepath) => {
    const name: string = getFileName(filepath);
    if (name in objControl) {
      objControl[name].push(filepath);
    } else {
      objControl[name] = [];
      objControl[name].push(filepath);
    }
  });
  Object.entries(objControl).forEach((item) => {
    final.push(item[1][0]);
  });
  return final;
};

export const consolidateURLS = (
  mapConfig: ViewConfigResponse | null
): ConsolidatedURLs => {
  const vector_urls: string[] = combineURLs(
    mapConfig?.vector_urls,
    mapConfig?.shared?.vector_urls
  );
  const fieldphoto_urls: string[] = combineURLs(
    mapConfig?.fieldphoto_urls,
    mapConfig?.shared?.fieldphoto_urls
  );
  const video_urls: string[] = combineURLs(
    mapConfig?.video_urls,
    mapConfig?.shared?.video_urls
  );

  return {
    vector_urls: vector_urls,
    video_urls: video_urls,
    fieldphoto_urls: fieldphoto_urls,
  };
};

const convertEXIF = (
  GPSLatitude: string,
  GPSLatitudeRef: string,
  GPSLongitude: string,
  GPSLongitudeRef: string
) => {
  const setSign = (ref: string) => {
    return ref.toUpperCase() === "W" || ref.toUpperCase() === "S" ? -1 : 1;
  };
  const arrayify = (logorlat: string) => {
    return JSON.parse(logorlat.split("(").join("[").split(")").join("]"));
  };
  const getDegrees = (array: any[]) => {
    let dms: number[] = [];
    for (let i = 0; i < array.length; i++) {
      dms.push(array[i][0] / array[i][1]);
    }
    return dms[0] + dms[1] / 60 + dms[2] / 3600;
  };

  return [
    getDegrees(arrayify(GPSLongitude)) * setSign(GPSLongitudeRef),
    getDegrees(arrayify(GPSLatitude)) * setSign(GPSLatitudeRef),
  ];
};

export const configureMapStyles = (
  mapConfig: ViewConfigResponse | null
): MapStyle => {
  const file_style: FileStyle = {};
  const vector_style: VectorStyle = {};
  const video_style: VideoStyle = {};
  const Dashboards: DashboardItems = {};
  const fieldphoto_style: FieldPhotoStyle = {};
  const hostedclips: HostedClips = {};

  if (
    mapConfig &&
    mapConfig.bucket_item_styles &&
    mapConfig.bucket_item_styles.exif &&
    Object.keys(mapConfig.bucket_item_styles.exif).length > 0
  ) {
    Object.entries(mapConfig.bucket_item_styles.exif).forEach((item: any) => {
      if (
        item[1].GPSLatitude &&
        item[1].GPSLatitudeRef &&
        item[1].GPSLongitude &&
        item[1].GPSLongitudeRef
      ) {
        //add to style
        fieldphoto_style[item[0]] = {
          displayName: item[0].split("_").join(" ").split(".")[0],
          datetime: item[1].DateTime,
          coordinates: convertEXIF(
            item[1].GPSLatitude,
            item[1].GPSLatitudeRef,
            item[1].GPSLongitude,
            item[1].GPSLongitudeRef
          ),
          marker: "Field Photos",
        };
      }
    });
  }

  if (mapConfig && mapConfig.bucket_item_styles) {
    if (mapConfig.bucket_item_styles.documents) {
      Object.entries(mapConfig.bucket_item_styles.documents).forEach(
        (item: any) => {
          file_style[item[0]] = {
            displayName: item[1].displayName
              ? item[1].displayName
              : getFileName(item[1].key).split("_").join(" ").split(".")[0],
            coordinates: item[1].coordinates,
            marker: item[1].marker,
            key: item[1].key,
          };
        }
      );
    }
    if (mapConfig.bucket_item_styles.vectorLayers) {
      Object.entries(mapConfig.bucket_item_styles.vectorLayers).forEach(
        (item: any) => {
          vector_style[item[0]] = {
            key: item[1].key,
            displayName: item[1].displayName,
            filepath: "",
            displayOnLoad: item[1].displayOnLoad,
            propertiesOnLoad: item[1].propertiesOnLoad,
            parcelAnalysis: item[1].parcelAnalysis,
            parcelAnalysisDefault: item[1].parcelAnalysisDefault,
            style: item[1].style,
            // featureIdentifier: item[1].featureIdentifier,
            cacheKey: item[1].cacheKey,
            properties: item[1].properties,
            geometry: item[1].geometry,
            heatmapOnly: item[1].heatmapOnly,
            heatmapstyle: item[1].heatmapstyle,
          };
        }
      );
    }
    if (mapConfig.bucket_item_styles.videoClips) {
      Object.entries(mapConfig.bucket_item_styles.videoClips).forEach(
        (item: any) => {
          video_style[item[0]] = {
            displayName: item[1].displayName,
            coordinates: item[1].coordinates,
            marker: item[1].marker,
            key: item[1].key,
          };
        }
      );
    }
    if (mapConfig.bucket_item_styles.fieldPhotos) {
      Object.entries(mapConfig.bucket_item_styles.fieldPhotos).forEach(
        (item: any) => {
          if (item[0] in fieldphoto_style) {
            if (item[1].displayName) {
              fieldphoto_style[item[0]].displayName = item[1].displayName;
            }
          } else {
            fieldphoto_style[item[0]] = {
              displayName: item[1].displayName,
              coordinates: item[1].coordinates,
              marker: item[1].marker,
            };
          }
        }
      );
    }
    if (mapConfig.bucket_item_styles.hostedClips) {
      Object.entries(mapConfig.bucket_item_styles.hostedClips).forEach(
        (item: any) => {
          hostedclips[item[0]] = {
            displayName: item[1].displayName,
            coordinates: item[1].coordinates,
            marker: item[1].marker,
            URL: item[1].URL,
            key: item[1].key,
            potree: item[1].potree,
          };
        }
      );
    }

    if (mapConfig.bucket_item_styles.dashboards) {
      Object.entries(mapConfig.bucket_item_styles.dashboards).forEach(
        (item: any) => {
          Dashboards[item[0]] = {
            displayName: item[1].displayName,
            dependancy: item[1].dependancy,
            itemList: item[1].itemList,
            specificSettings: item[1].specificSettings,
            chart: item[1].chart,
            legend: item[1].legend,
            active: item[1].active,
          };
        }
      );
    }
  }

  const map_style = {
    FileStyle: file_style,
    VectorStyle: vector_style,
    VideoStyle: video_style,
    Dashboards: Dashboards,
    FieldPhotoStyle: fieldphoto_style,
    HostedClips: hostedclips,
  };

  return map_style;
};

export const setMarkers = (
  marker: string,
  markerItems: Marker[],
  markerVectorLayers: MarkerLayers,
  map: Map | null,
  singleLayer?: boolean
) => {
  const markers = markerItems
    .filter((item) => {
      return (
        item.mapCoordinates &&
        item.mapCoordinates?.length > 0 &&
        item.marker &&
        (item.marker === marker || singleLayer)
      );
    })
    .map((item, i) => {
      const newFeature = new Feature({
        geometry: new Point(
          fromLonLat(
            item.mapCoordinates ? item.mapCoordinates : [1, 1],
            "EPSG:3857"
          )
        ),
      });
      newFeature.setId(item.id);
      if (item.marker) {
        newFeature.setStyle(iconstyles[item.marker]);
      }
      newFeature.setProperties({
        Class: item.marker,
        Name: item.displayName,
        System_RenderOrder: 0,
      });
      return newFeature;
    });

  if (markers.length > 0) {
    const vectorSource = new VectorSource({
      features: markers,
    });
    markerVectorLayers[marker] = new VectorLayer({
      source: vectorSource,
      zIndex: 10,
      renderOrder: function (a, b) {
        return a.get("System_RenderOrder") - b.get("System_RenderOrder");
      },
    });
    if (map) {
      const newLayer: VectorLayer<VectorSource<Geometry>> | null =
        markerVectorLayers[marker];
      if (newLayer) {
        map.addLayer(newLayer);
      }
    }
  }
};

export const setLayerStyle = (layer: MapLayer, style: VectorStyleStyle) => {
  const vectorSource = layer.layers[0].getSource();
  vectorSource.forEachFeature((feature) => {
    feature.setStyle(
      new Style({
        fill: new Fill({
          color: style.fill,
        }),
        stroke: new Stroke({
          color: style.linecolour,
          width: style.linewidth,
        }),
        image: new Circle({
          radius: style.radius,
          fill: new Fill({ color: style.fill }),
          stroke: new Stroke({
            color: style.linecolour,
            width: style.linewidth,
          }),
        }),
      })
    );
  });
};

const createPolyline = (viewrefs: React.MutableRefObject<ViewRefs>) => {
  const c = viewrefs.current.polySelectCoordinates.map((item) =>
    fromLonLat(item, "EPSG:3857")
  );
  if (viewrefs.current.polySelectLayer) {
    if (viewrefs.current.polySelectLine) {
      viewrefs.current.polySelectLayer
        .getSource()
        .removeFeature(viewrefs.current.polySelectLine);
    }
    viewrefs.current.polySelectLine = new Feature({
      geometry: new LineString(c),
    });
    viewrefs.current.polySelectLine.setStyle(polygonSelectLineStyle);
  }
};

const createPolyPoint = (coordinate: any, firstPoint: boolean) => {
  const newpoint = new Feature({
    geometry: new Point(fromLonLat(coordinate, "EPSG:3857")),
  });
  newpoint.setProperties({
    Class: "Poly Select Point",
    System_FirstPoint: firstPoint ? "true" : "false",
  });
  newpoint.setStyle(iconstyles["PolyBox"]);
  return newpoint;
};
const addPolyFeatures = (
  viewrefs: React.MutableRefObject<ViewRefs>,
  features: any[]
) => {
  if (viewrefs.current.polySelectLayer) {
    viewrefs.current.polySelectLayer.getSource().addFeatures(features);
  }
};

export const polySelectPoints = async (
  viewrefs: React.MutableRefObject<ViewRefs>,
  map: Map | null,
  polySelectable: string[] | undefined,
  storage: Storage,
  auth: Auth
) => {
  const c = viewrefs.current.polySelectCoordinates.map((item) =>
    fromLonLat(item, "EPSG:3857")
  );
  const polygonselect = new Polygon([c]);

  if (map && polySelectable) {
    //Remove polygon line and markers
    if (viewrefs.current.polySelectLayer) {
      map.removeLayer(viewrefs.current.polySelectLayer);
      viewrefs.current.polySelectLine = null;
      viewrefs.current.polySelectLayer = null;
      viewrefs.current.polySelectCoordinates = [];
    }

    let mapLayers: VectorLayer<VectorSource<Geometry>>[] = [];
    map.getLayers().forEach(function (layer) {
      if (layer instanceof VectorLayer) {
        mapLayers.push(layer);
      }
    });

    let selectedPoints: { coord: number[]; coordinate: number[] }[] = [];

    for (let i = 0; i < mapLayers.length; i++) {
      const layer = mapLayers[i];
      const source = layer.getSource();
      const props = source.getProperties();
      if (polySelectable.includes(props["Key"]) && layer.getVisible()) {
        const filepath = props["Key"];
        const cacheKey = props["cacheKey"];

        const data: any = await fetchDataStorage(
          filepath,
          storage,
          auth,
          cacheKey,
          "vectorurl"
        );
        if (data && data["features"]) {
          const features = data["features"];
          features.forEach((feat: any) => {
            const geom = feat["geometry"];
            const coord = geom["coordinates"];
            const coordinate = fromLonLat(coord, "EPSG:3857");
            if (polygonselect.intersectsCoordinate(coordinate)) {
              selectedPoints.push({ coord, coordinate });
            }
          });
        }
      }
    }
    if (selectedPoints.length === 0) return [];

    const verticalDistance = Math.abs(
      selectedPoints[0].coordinate[1] -
        selectedPoints[selectedPoints.length - 1].coordinate[1]
    );
    const horizontalDistance = Math.abs(
      selectedPoints[0].coordinate[0] -
        selectedPoints[selectedPoints.length - 1].coordinate[0]
    );
    const initialdirection =
      selectedPoints[0].coordinate[1] >
      selectedPoints[selectedPoints.length - 1].coordinate[1]
        ? "down"
        : "up";
    const upordown =
      selectedPoints[0].coordinate[0] <
      selectedPoints[selectedPoints.length - 1].coordinate[0]
        ? initialdirection === "down"
          ? "down"
          : "up"
        : initialdirection === "down"
        ? "up"
        : "down";

    const sortedLefttoRight = selectedPoints.sort((a, b) => {
      if (horizontalDistance > verticalDistance) {
        return a.coord[0] > b.coord[0] ? 1 : -1;
      } else {
        return upordown === "down"
          ? a.coord[1] < b.coord[1]
            ? 1
            : -1
          : a.coord[1] > b.coord[1]
          ? 1
          : -1;
      }
    });

    let distance: number = 0;
    let prevDistance: number = 0;
    let newdistance: number = 0;
    const selectedPointsWithDistance = sortedLefttoRight.map((item, i) => {
      if (i > 0) {
        distance = findDistanceBetweenPoints([
          selectedPoints[i - 1].coordinate,
          selectedPoints[i].coordinate,
        ]);
      }
      newdistance = distance + prevDistance;
      prevDistance = newdistance;
      return [newdistance, ...item.coord];
    });
    return selectedPointsWithDistance;
  } else return [];
};

export const savePolySelectCoordinates = (
  map: Map | null,
  viewrefs: React.MutableRefObject<ViewRefs>,
  setPolySelectReady: (val: boolean) => void,
  coordinate: any
) => {
  if (map) {
    viewrefs.current.polySelectCoordinates.push(coordinate);
    if (viewrefs.current.polySelectLayer) {
      const newpoint = createPolyPoint(coordinate, false);
      //Check if point is start point
      if (viewrefs.current.polyFirstPoint) {
        viewrefs.current.polyFirstPoint = false;
        viewrefs.current.polySelectCoordinates.push(
          viewrefs.current.polySelectCoordinates[0]
        );
        createPolyline(viewrefs);
        addPolyFeatures(viewrefs, [viewrefs.current.polySelectLine]);
        setPolySelectReady(true);
      } else {
        createPolyline(viewrefs);
        addPolyFeatures(viewrefs, [newpoint, viewrefs.current.polySelectLine]);
      }
    } else {
      const newpoint = createPolyPoint(coordinate, true);
      const vectorSource = new VectorSource({
        features: [newpoint],
      });
      const polyVectorLayer = new VectorLayer({
        source: vectorSource,
        zIndex: 10,
      });
      viewrefs.current.polySelectLayer = polyVectorLayer;
      if (viewrefs.current.polySelectLayer) {
        map.addLayer(viewrefs.current.polySelectLayer);
      }
    }
  }
};

export const drawInitialProfileLine = (
  coordinates: number[][],
  pointercoordinate: number[],
  map: Map | null,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  if (map) {
    const c = [
      fromLonLat(coordinates[0], "EPSG:3857"),
      fromLonLat(pointercoordinate),
    ];

    if (viewrefs.current.profileLine && viewrefs.current.profileLineLayer) {
      viewrefs.current.profileLineLayer
        .getSource()
        .removeFeature(viewrefs.current.profileLine);
      viewrefs.current.profileLine = new Feature({
        geometry: new LineString(c),
      });
      viewrefs.current.profileLine.setStyle(tempprofileLineStyle);
      viewrefs.current.profileLine.setProperties({
        Name: "Elevation Profile Line",
      });
      viewrefs.current.profileLineLayer
        .getSource()
        .addFeature(viewrefs.current.profileLine);
    } else {
      //create feature
      viewrefs.current.profileLine = new Feature({
        geometry: new LineString(c),
      });
      viewrefs.current.profileLine.setStyle(tempprofileLineStyle);
      viewrefs.current.profileLine.setProperties({
        Name: "Elevation Profile Line",
      });
      const vectorSource = new VectorSource({
        features: [viewrefs.current.profileLine],
      });

      const markerVectorLayer = new VectorLayer({
        source: vectorSource,
        zIndex: 10,
      });

      viewrefs.current.profileLineLayer = markerVectorLayer;
      if (viewrefs.current.profileLineLayer) {
        map.addLayer(viewrefs.current.profileLineLayer);
      }
    }
  }
};

export const drawActualProfileLine = (
  coordinates: number[][],
  viewrefs: React.MutableRefObject<ViewRefs>,
  map?: Map | null
) => {
  const c = coordinates.map((item) =>
    fromLonLat([item[1], item[2]], "EPSG:3857")
  );
  if (viewrefs.current.profileLine && viewrefs.current.profileLineLayer) {
    viewrefs.current.profileLineLayer
      .getSource()
      .removeFeature(viewrefs.current.profileLine);
    viewrefs.current.profileLine = new Feature({
      geometry: new LineString(c),
    });
    viewrefs.current.profileLine.setStyle(profileLineStyle);
    viewrefs.current.profileLine.setProperties({
      Name: "Elevation Profile Line",
    });
    viewrefs.current.profileLineLayer
      .getSource()
      .addFeature(viewrefs.current.profileLine);
  } else {
    //create feature
    viewrefs.current.profileLine = new Feature({
      geometry: new LineString(c),
    });
    viewrefs.current.profileLine.setStyle(profileLineStyle);
    viewrefs.current.profileLine.setProperties({
      Name: "Elevation Profile Line",
    });
    const vectorSource = new VectorSource({
      features: [viewrefs.current.profileLine],
    });

    const markerVectorLayer = new VectorLayer({
      source: vectorSource,
      zIndex: 10,
    });

    viewrefs.current.profileLineLayer = markerVectorLayer;
    if (viewrefs.current.profileLineLayer && map) {
      map.addLayer(viewrefs.current.profileLineLayer);
    }
  }
};
export const showCurrentLocation = (
  latitude: number,
  longitude: number,
  map: Map | null,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  if (map) {
    if (
      viewrefs.current.currentLocation &&
      viewrefs.current.currentLocationLayer
    ) {
      viewrefs.current.currentLocationLayer
        .getSource()
        .removeFeature(viewrefs.current.currentLocation);
      viewrefs.current.currentLocation = new Feature({
        geometry: new Point(fromLonLat([longitude, latitude], "EPSG:3857")),
      });
      viewrefs.current.currentLocation.setStyle(iconstyles["RedDot20"]);
      viewrefs.current.currentLocation.setProperties({
        Class: "CurrentPosition",
        Name: "Current Position",
      });
      viewrefs.current.currentLocationLayer
        .getSource()
        .addFeature(viewrefs.current.currentLocation);
    } else {
      //create feature
      viewrefs.current.currentLocation = new Feature({
        geometry: new Point(fromLonLat([longitude, latitude], "EPSG:3857")),
      });
      viewrefs.current.currentLocation.setStyle(iconstyles["RedDot20"]);
      viewrefs.current.currentLocation.setProperties({
        Class: "CurrentPosition",
        Name: "Current Position",
      });
      const vectorSource = new VectorSource({
        features: [viewrefs.current.currentLocation],
      });

      const markerVectorLayer = new VectorLayer({
        source: vectorSource,
        zIndex: 10,
      });

      viewrefs.current.currentLocationLayer = markerVectorLayer;
      if (viewrefs.current.currentLocationLayer) {
        map.addLayer(viewrefs.current.currentLocationLayer);
      }
    }
  }
};

export const drawElevationPoints = (
  latitude: number,
  longitude: number,
  elevation: number,
  distance: number,
  order: number,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  if (viewrefs.current.profileLineLayer) {
    //create feature
    const newpoint = new Feature({
      geometry: new Point(fromLonLat([longitude, latitude], "EPSG:3857")),
    });
    newpoint.setStyle(elevationPointStyle);
    newpoint.setProperties({
      Class: "Elevation Point",
      Elevation: elevation.toFixed(1),
      Distance: distance.toFixed(1),
      Order: order,
    });
    viewrefs.current.profileLineLayer.getSource().addFeature(newpoint);
  }
};

export const drawPotentialElevationPoint = (
  segment: number[] | undefined,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  let features: Features = {};
  if (viewrefs.current.profileLineLayer && segment) {
    viewrefs.current.profileLineLayer.getSource().forEachFeature((feat) => {
      const propertyclass = feat.get("Class");
      if (propertyclass === "Elevation Point") {
        if (feat.getGeometry()) {
          if (feat.getGeometry()?.getType() === "Point") {
            const p: Point = feat.getGeometry() as Point;
            const c: number[] = p.getCoordinates();
            const id: number = feat.get("Order");
            features[id.toString()] = c;
          }
        }
      }
    });
    const coordinate1 = features[segment[0]];
    if (segment.length === 1) {
      const newpoint = new Feature({
        geometry: new Point(coordinate1),
      });
      newpoint.setStyle(potentialelevationPointStyle);
      newpoint.setProperties({
        Class: "Potential Delete",
      });
      viewrefs.current.profileLineLayer.getSource().addFeature(newpoint);
    } else {
      const coordinate2 = features[segment[1]];
      const distance = findDistanceBetweenPoints([coordinate1, coordinate2]);

      const newpoint = new Feature({
        geometry: new Point(
          findPointAlongLine(
            [coordinate1, coordinate2],
            (distance * (coordinate2[0] < coordinate1[0] ? -1 : 1)) / 2
          )
        ),
      });
      newpoint.setStyle(potentialelevationPointStyle);
      newpoint.setProperties({
        Class: "Potential Point",
      });
      viewrefs.current.profileLineLayer.getSource().addFeature(newpoint);
    }
  }
};

export const drawNewElevationPoint = (
  setActivateElevationProfileData: (val: number) => void,
  auth: Auth,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  const drawNewElevationPointAsync = async () => {
    let features: Features = {};
    if (
      viewrefs.current.profileLineLayer &&
      viewrefs.current.elevationSettingsValue
    ) {
      viewrefs.current.profileLineLayer.getSource().forEachFeature((feat) => {
        const propertyclass = feat.get("Class");
        if (propertyclass === "Elevation Point") {
          if (feat.getGeometry()) {
            if (feat.getGeometry()?.getType() === "Point") {
              const p: Point = feat.getGeometry() as Point;
              const c: number[] = p.getCoordinates();
              const id: number = feat.get("Order");
              features[id.toString()] = c;
              if (viewrefs.current.elevationSettingsValue) {
                if (id > viewrefs.current.elevationSettingsValue[0]) {
                  feat.set("Order", id + 1);
                }
              }
            }
          }
        }
      });
      const oldData = viewrefs.current.refElevationProfileData;
      const coordinate1 = features[viewrefs.current.elevationSettingsValue[0]];
      const coordinate2 = features[viewrefs.current.elevationSettingsValue[1]];
      const distance = findDistanceBetweenPoints([coordinate1, coordinate2]);

      const newCoordinates: number[] = findPointAlongLine(
        [coordinate1, coordinate2],
        (distance * (coordinate2[0] < coordinate1[0] ? -1 : 1)) / 2
      );
      const newLatLng: number[] = oltransform(
        newCoordinates,
        "EPSG:3857",
        "EPSG:4326"
      ).reverse();
      const ret = await fetchElevation(
        auth,
        newLatLng[0].toString(),
        newLatLng[1].toString()
      );

      const elevation: number = getClosestElevation(ret, [
        newLatLng[0],
        newLatLng[1],
      ]);

      const newpoint = new Feature({
        geometry: new Point(newCoordinates),
      });
      newpoint.setStyle(elevationPointStyle);
      newpoint.setProperties({
        Class: "Elevation Point",
        Elevation: meterstofeet(elevation).toFixed(1),
        Distance: (
          oldData[viewrefs.current.elevationSettingsValue[0]].distance +
          meterstofeet(distance) / 2
        ).toFixed(1),
        Order: viewrefs.current.elevationSettingsValue[1],
      });
      viewrefs.current.profileLineLayer.getSource().addFeature(newpoint);
      const newData: ElevationProfileData[] = getUpdatedElevationProfileData(
        oldData,
        newLatLng,
        meterstofeet(distance),
        meterstofeet(elevation),
        viewrefs.current.elevationSettingsValue
      );
      viewrefs.current.refElevationProfileData = newData;
      const newDataCopy: ElevationProfileData[] = JSON.parse(
        JSON.stringify(newData)
      );
      //recreate the line
      drawActualProfileLine(
        newDataCopy
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((item) => [
            feettometers(item.distance),
            ...item.coordinates.reverse(),
          ]),
        viewrefs
      );
      setActivateElevationProfileData(Math.random());
    }
  };
  drawNewElevationPointAsync();
};

export const deleteElevationPoint = (
  setActivateElevationProfileData: (val: number) => void,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  let features: Features = {};
  if (
    viewrefs.current.profileLineLayer &&
    viewrefs.current.elevationSettingsValue
  ) {
    const oldData = viewrefs.current.refElevationProfileData;

    const previtem = oldData[viewrefs.current.elevationSettingsValue[0] - 1];
    const nextitem = oldData[viewrefs.current.elevationSettingsValue[0] + 1];

    const oldDistance = nextitem
      ? nextitem.distance - (previtem ? previtem.distance : 0)
      : previtem.distance;

    const prevcoordinate = previtem
      ? fromLonLat([...previtem.coordinates].reverse(), "EPSG:3857")
      : fromLonLat([...nextitem.coordinates].reverse(), "EPSG:3857");
    const nextcoordinate = nextitem
      ? fromLonLat([...nextitem.coordinates].reverse(), "EPSG:3857")
      : fromLonLat([...previtem.coordinates].reverse(), "EPSG:3857");

    const newDistance: number = findDistanceBetweenPoints([
      prevcoordinate,
      nextcoordinate,
    ]);

    const distanceincrement: number = meterstofeet(newDistance) - oldDistance;

    viewrefs.current.profileLineLayer.getSource().forEachFeature((feat) => {
      const propertyclass = feat.get("Class");
      if (propertyclass === "Elevation Point") {
        if (feat.getGeometry()) {
          if (feat.getGeometry()?.getType() === "Point") {
            const p: Point = feat.getGeometry() as Point;
            const c: number[] = p.getCoordinates();
            const id: number = feat.get("Order");
            features[id.toString()] = c;
            if (viewrefs.current.elevationSettingsValue) {
              if (id === viewrefs.current.elevationSettingsValue[0]) {
                if (viewrefs.current.profileLineLayer) {
                  viewrefs.current.profileLineLayer
                    .getSource()
                    .removeFeature(feat);
                }
              }
              if (id > viewrefs.current.elevationSettingsValue[0]) {
                const d = oldData[id].distance;
                feat.set("Order", id - 1);
                feat.set("Distance", (d + distanceincrement).toFixed(1));
              }
            }
          }
        }
      }
    });

    const newData: ElevationProfileData[] = getDeletedElevationProfileData(
      oldData,
      viewrefs.current.elevationSettingsValue,
      distanceincrement
    );
    viewrefs.current.refElevationProfileData = newData;
    const newDataCopy: ElevationProfileData[] = JSON.parse(
      JSON.stringify(newData)
    );
    //recreate the line
    drawActualProfileLine(
      newDataCopy
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((item) => [
          feettometers(item.distance),
          ...item.coordinates.reverse(),
        ]),
      viewrefs
    );
    setActivateElevationProfileData(Math.random());
  }
};

export const removePotentialElevationPoint = (
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  if (viewrefs.current.profileLineLayer) {
    viewrefs.current.profileLineLayer.getSource().forEachFeature((feat) => {
      if (feat.getGeometry()) {
        if (feat.getGeometry()?.getType() === "Point") {
          const propertyClass: string = feat.get("Class");
          if (
            propertyClass === "Potential Point" ||
            propertyClass === "Potential Delete"
          ) {
            if (viewrefs.current.profileLineLayer) {
              viewrefs.current.profileLineLayer.getSource().removeFeature(feat);
            }
          }
        }
      }
    });
  }
};

export const setStyleElevationPoints = (
  selected: number,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  if (viewrefs.current.profileLineLayer) {
    viewrefs.current.profileLineLayer.getSource().forEachFeature((f) => {
      const propertyclass = f.get("Class");
      if (propertyclass === "Elevation Point") {
        if (f.getGeometry()) {
          if (f.getGeometry()?.getType() === "Point") {
            const Order = _.get(f.getProperties(), "Order");
            if (+Order === selected) {
              f.setStyle(elevationPointStyleselected);
            } else {
              f.setStyle(elevationPointStyle);
            }
          }
        }
      }
    });
  }
};

export const getClosestElevation = (
  ret: GetElevationSQL[],
  coord: number[]
): number => {
  const reversedcoord = coord.slice().reverse();
  if (ret && ret.length > 0) {
    const res = ret.map((item: GetElevationSQL) => {
      return {
        distance: findDistanceBetweenPoints([
          fromLonLat(reversedcoord, "EPSG:3857"),
          fromLonLat([item.longitude, item.latitude], "EPSG:3857"),
        ]),
        elevation: item.elevation,
      };
    });
    res.sort((a, b) => (a.distance > b.distance ? 1 : -1));
    return res[0].elevation;
  }
  return 0;
};

export const setElevationPointsTranslation = (
  selected: number,
  map: Map | null,
  auth: Auth,
  elevationProfileData: ElevationProfileData[],
  fsetElevationProfileData: (newData: ElevationProfileData[]) => void,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  if (viewrefs.current.profileLineLayer && map) {
    let selectedFeatures: Feature<Geometry>[] = [];
    let profileLineFeature: Feature<Geometry> | null = null;
    let startcoordinates: number[][] = [];
    viewrefs.current.profileLineLayer.getSource().forEachFeature((f) => {
      if (f.getGeometry()) {
        if (f.getGeometry()?.getType() === "Point") {
          const Order = _.get(f.getProperties(), "Order");
          if (selected === +Order) {
            selectedFeatures.push(f);
          }
        }
        if (f.getGeometry()?.getType() === "LineString") {
          const PropertyClass = _.get(f.getProperties(), "Class");
          if (PropertyClass !== "Slope Line") {
            profileLineFeature = f;
          }
        }
      }
    });

    const translateElevation = new olInteraction.Translate({
      features: new Collection(selectedFeatures),
    });
    map.addInteraction(translateElevation);
    translateElevation.on("translatestart", function (evt) {
      viewrefs.current.dashboardSettingsMode = "moving";
      if (profileLineFeature) {
        let x: LineString | undefined =
          profileLineFeature.getGeometry() as LineString;
        startcoordinates = x.getCoordinates();
      }
    });
    translateElevation.on("translating", function (evt) {
      if (profileLineFeature) {
        let x: LineString | undefined =
          profileLineFeature.getGeometry() as LineString;
        let newCoordinates = startcoordinates.map((item, i) => {
          if (selected === i) {
            return evt.coordinate;
          }
          return item;
        });
        x.setCoordinates(newCoordinates);
      }
    });
    translateElevation.on("translateend", function (evt) {
      map.removeInteraction(translateElevation);
      if (profileLineFeature) {
        selectedFeatures.forEach((feat) => {
          const p: Point = feat.getGeometry() as Point;
          const c: number[] = p.getCoordinates();
          const fetchElevationAsync = async () => {
            const coordinate = oltransform(c, "EPSG:3857", "EPSG:4326");
            const ret = await fetchElevation(
              auth,
              coordinate[1].toString(),
              coordinate[0].toString()
            );
            const elevation: number = meterstofeet(
              getClosestElevation(ret, [coordinate[1], coordinate[0]])
            );
            //Set Chart Data
            const copyofOldData = JSON.parse(
              JSON.stringify(elevationProfileData)
            );

            const previtem = copyofOldData[selected - 1];
            const currentitem = copyofOldData[selected];
            const nextitem = copyofOldData[selected + 1];

            const oldDistance =
              currentitem.distance -
              (previtem ? previtem.distance : 0) +
              (nextitem
                ? nextitem.distance - currentitem.distance
                : currentitem.distance);

            const prevcoordinate = previtem
              ? fromLonLat([...previtem.coordinates].reverse(), "EPSG:3857")
              : c;
            const nextcoordinate = nextitem
              ? fromLonLat([...nextitem.coordinates].reverse(), "EPSG:3857")
              : c;

            const newDistancePC: number = findDistanceBetweenPoints([
              prevcoordinate,
              c,
            ]);
            const newDistanceCN: number = findDistanceBetweenPoints([
              c,
              nextcoordinate,
            ]);

            const newDistance: number = newDistancePC + newDistanceCN;
            const distanceincrement: number =
              meterstofeet(newDistance) - oldDistance;

            const newData = copyofOldData
              .map((item: ElevationProfileData, i: number) => {
                if (selected === i) {
                  const d = previtem
                    ? previtem.distance + meterstofeet(newDistancePC)
                    : 0;
                  viewrefs.current.distanceBetweenPoints = d;
                  return {
                    ...item,
                    distance: d,
                    elevation: elevation,
                    coordinates: coordinate.reverse(),
                  };
                }
                if (selected + 1 === i) {
                  const d = previtem
                    ? previtem.distance + meterstofeet(newDistance)
                    : meterstofeet(newDistance);
                  viewrefs.current.distanceBetweenPoints = d;
                  return {
                    ...item,
                    distance: d,
                  };
                }
                if (i > selected + 1) {
                  const d = item.distance + distanceincrement;
                  viewrefs.current.distanceBetweenPoints = d;
                  return {
                    ...item,
                    distance: d,
                  };
                }
                return item;
              })
              .sort((a: ElevationProfileData, b: ElevationProfileData) => {
                return a.id > b.id ? 1 : -1;
              });

            //Set the properties
            if (viewrefs.current.profileLineLayer && map) {
              viewrefs.current.profileLineLayer
                .getSource()
                .forEachFeature((feat) => {
                  if (feat.getGeometry()) {
                    if (feat.getGeometry()?.getType() === "Point") {
                      const propertyClass = feat.get("Class");
                      if (propertyClass === "Elevation Point") {
                        const id: number = feat.get("Order");
                        feat.set("Distance", newData[id].distance.toFixed(1));
                        if (selected === id) {
                          feat.set("Elevation", elevation.toFixed(1));
                        }
                      }
                    }
                  }
                });
            }
            fsetElevationProfileData(newData);

            const copyofnewData: ElevationProfileData[] = JSON.parse(
              JSON.stringify(newData)
            );

            //recreate the line
            drawActualProfileLine(
              copyofnewData.map((item: ElevationProfileData) => [
                feettometers(item.distance),
                ...item.coordinates.reverse(),
              ]),
              viewrefs
            );
            viewrefs.current.dashboardSettingsMode = null;
          };
          fetchElevationAsync();
        });
      }
    });
  }
};

export const getHighlightedSegment = (
  coordinate: number[],
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  let closestpoint: number = 0;
  let lastpoint: number = 0;
  let features: Features = {};

  if (viewrefs.current.profileLineLayer) {
    let closestdistance: number = 0;
    viewrefs.current.profileLineLayer.getSource().forEachFeature((feat) => {
      if (feat.getGeometry()) {
        if (feat.getGeometry()?.getType() === "Point") {
          const propertyclass = feat.get("Class");
          if (propertyclass === "Elevation Point") {
            const p: Point = feat.getGeometry() as Point;
            const c: number[] = p.getCoordinates();
            const id: number = feat.get("Order");
            features[id.toString()] = c;
            if (id > lastpoint) {
              lastpoint = id;
            }
            const d = Math.abs(findDistanceBetweenPoints([coordinate, c]));
            if (closestdistance === 0 || d < closestdistance) {
              closestdistance = d;
              closestpoint = id;
            }
          }
        }
      }
    });
  }
  if (closestpoint === 0) return [0, 1];
  else if (closestpoint === lastpoint) return [lastpoint - 1, lastpoint];
  else {
    //calculate for adjacent segments
    //const firstsegmentdiff = Math.abs(findDistanceBetweenPoints([coordinate, c]));
    const calculatelikelyhood = (c1: number[], c2: number[]) => {
      return Math.abs(
        Math.abs(findDistanceBetweenPoints([c1, c2])) -
          Math.abs(findDistanceBetweenPoints([c1, coordinate])) -
          Math.abs(findDistanceBetweenPoints([c2, coordinate]))
      );
    };
    if (viewrefs.current.profileLineLayer) {
      const firstsegment = calculatelikelyhood(
        features[closestpoint - 1],
        features[closestpoint]
      );
      const secondsegment = calculatelikelyhood(
        features[closestpoint],
        features[closestpoint + 1]
      );
      if (firstsegment < secondsegment) return [closestpoint - 1, closestpoint];
      else return [closestpoint, closestpoint + 1];
    }
  }
};

export const getHighlightedPoint = (
  coordinate: number[],
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  let closestpoint: number = 0;
  let lastpoint: number = 0;
  let features: Features = {};

  if (viewrefs.current.profileLineLayer) {
    let closestdistance: number = 0;
    viewrefs.current.profileLineLayer.getSource().forEachFeature((feat) => {
      if (feat.getGeometry()) {
        if (feat.getGeometry()?.getType() === "Point") {
          const propertyclass = feat.get("Class");
          if (propertyclass === "Elevation Point") {
            const p: Point = feat.getGeometry() as Point;
            const c: number[] = p.getCoordinates();
            const id: number = feat.get("Order");
            features[id.toString()] = c;
            if (id > lastpoint) {
              lastpoint = id;
            }
            const d = Math.abs(findDistanceBetweenPoints([coordinate, c]));
            if (closestdistance === 0 || d < closestdistance) {
              closestdistance = d;
              closestpoint = id;
            }
          }
        }
      }
    });
  }
  return closestpoint;
};

const getUpdatedElevationProfileData = (
  oldData: ElevationProfileData[],
  newLatLng: number[],
  distance: number,
  elevation: number,
  segment: number[]
): ElevationProfileData[] => {
  let data: ElevationProfileData[] = [];

  for (let i = 0; i < oldData.length; i++) {
    const item: ElevationProfileData = oldData[i];
    if (item.id === segment[0]) {
      data.push(item);
      //add new item
      data.push({
        id: segment[1],
        distance: oldData[segment[0]].distance + distance / 2,
        elevation: elevation,
        coordinates: newLatLng,
      });
    } else if (item.id >= segment[1]) {
      //increment order
      data.push({ ...item, id: item.id + 1 });
    } else {
      data.push(item);
    }
  }
  return data.sort((a, b) => (a.id > b.id ? 1 : -1));
};

const getDeletedElevationProfileData = (
  oldData: ElevationProfileData[],
  segment: number[],
  distance: number
): ElevationProfileData[] => {
  let data: ElevationProfileData[] = [];

  for (let i = 0; i < oldData.length; i++) {
    const item: ElevationProfileData = oldData[i];
    if (item.id === segment[0]) {
      //do nothing
    } else if (item.id > segment[0]) {
      //deccrement order
      data.push({
        ...item,
        id: item.id - 1,
        distance: item.distance + distance,
      });
    } else {
      data.push(item);
    }
  }
  return data.sort((a, b) => (a.id > b.id ? 1 : -1));
};

export const clearLines = (
  setElevationProfileData: (val: ElevationProfileData[]) => void,
  setElevationProfileDataLoaded: (val: boolean) => void,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  if (viewrefs.current.profileLineLayer) {
    viewrefs.current.profileLineLayer.getSource().forEachFeature((feat) => {
      if (viewrefs.current.profileLineLayer) {
        viewrefs.current.profileLineLayer.getSource().removeFeature(feat);
      }
    });
    viewrefs.current.profileLineLayer = null;
    setElevationProfileData([]);
    viewrefs.current.refElevationProfileData = [];
    setElevationProfileDataLoaded(false);
  }
};
export const checkname = (
  name: string | null,
  payload: ElevationProfilePayload,
  scope: string
): any => {
  if (name && name in payload) {
    const ret = window.confirm(
      name +
        " already exists in " +
        (scope === "private" ? "your private" : "the public") +
        " list, do you want to overwrite it?"
    );
    if (ret) {
      return name;
    } else {
      const newname: string | null = prompt("please enter a new name", name);
      if (newname) {
        return checkname(newname, payload, scope);
      } else return null;
    }
  } else if (name) {
    return name;
  } else {
    const newname: string | null = prompt("please enter a new name");
    if (newname) {
      return checkname(newname, payload, scope);
    } else return null;
  }
};

export const setSlopeLine = (
  points: number[],
  elevationProfileData: ElevationProfileData[],
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  const point1 = elevationProfileData[points[0]];
  const point2 = elevationProfileData[points[1]];
  drawSlopePoints(point1.coordinates[0], point1.coordinates[1], viewrefs, 1);
  drawSlopePoints(point2.coordinates[0], point2.coordinates[1], viewrefs, 2);
  drawSlopeLine(
    [
      [point1.coordinates[1], point1.coordinates[0]],
      [point2.coordinates[1], point2.coordinates[0]],
    ],
    viewrefs
  );
};

export const calculateSlope = (
  points: number[],
  elevationProfileData: ElevationProfileData[]
) => {
  const point1 = elevationProfileData[points[0]];
  const point2 = elevationProfileData[points[1]];
  const distance = meterstofeet(
    Math.abs(
      findDistanceBetweenPoints([
        fromLonLat([...point1.coordinates].reverse(), "EPSG:3857"),
        fromLonLat([...point2.coordinates].reverse(), "EPSG:3857"),
      ])
    )
  );
  const rise = point2.elevation - point1.elevation;
  return rise / distance;
  //
};
export const drawSlopePoints = (
  latitude: number,
  longitude: number,
  viewrefs: React.MutableRefObject<ViewRefs>,
  Order: number
) => {
  if (viewrefs.current.profileLineLayer) {
    //create feature
    const newpoint = new Feature({
      geometry: new Point(fromLonLat([longitude, latitude], "EPSG:3857")),
    });
    newpoint.setStyle(slopePointStyle);
    newpoint.setProperties({
      Class: "Slope Point",
      Order: Order,
    });
    viewrefs.current.profileLineLayer.getSource().addFeature(newpoint);
  }
};
export const drawSlopeLine = (
  coordinates: number[][],
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  const c = coordinates.map((item) =>
    fromLonLat([item[0], item[1]], "EPSG:3857")
  );
  if (viewrefs.current.profileLineLayer) {
    if (viewrefs.current.slopeLine) {
      viewrefs.current.profileLineLayer
        .getSource()
        .removeFeature(viewrefs.current.slopeLine);
    }
    viewrefs.current.slopeLine = new Feature({
      geometry: new LineString(c),
    });
    viewrefs.current.slopeLine.setStyle(slopeLineStyle);
    viewrefs.current.slopeLine.setProperties({
      Class: "Slope Line",
    });
    viewrefs.current.profileLineLayer
      .getSource()
      .addFeature(viewrefs.current.slopeLine);
  }
};

export const removeSlopeLine = (viewrefs: React.MutableRefObject<ViewRefs>) => {
  if (viewrefs.current.profileLineLayer) {
    viewrefs.current.profileLineLayer.getSource().forEachFeature((feat) => {
      if (feat.getGeometry()) {
        // if (feat.getGeometry()?.getType() === "Point") {
        const propertyClass: string = feat.get("Class");
        if (propertyClass === "Slope Point" || propertyClass === "Slope Line") {
          if (viewrefs.current.profileLineLayer) {
            viewrefs.current.profileLineLayer.getSource().removeFeature(feat);
          }
        }
        // }
      }
    });
  }
};

export const setSlopePointsTranslation = (
  selected: number,
  map: Map | null,
  setSlopePoints: (val: number[]) => void,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  if (viewrefs.current.profileLineLayer && map) {
    let selectedFeatures: Feature<Geometry>[] = [];
    let profileLineFeature: Feature<Geometry> | null = null;
    let startcoordinates: number[][] = [];
    viewrefs.current.profileLineLayer.getSource().forEachFeature((f) => {
      if (f.getGeometry()) {
        if (f.getGeometry()?.getType() === "Point") {
          const Order = _.get(f.getProperties(), "Order");
          const PropertyClass = _.get(f.getProperties(), "Class");
          if (selected === +Order && PropertyClass === "Slope Point") {
            selectedFeatures.push(f);
          }
        }
        if (f.getGeometry()?.getType() === "LineString") {
          const PropertyClass = _.get(f.getProperties(), "Class");
          if (PropertyClass === "Slope Line") {
            profileLineFeature = f;
          }
        }
      }
    });

    const translateElevation = new olInteraction.Translate({
      features: new Collection(selectedFeatures),
    });
    map.addInteraction(translateElevation);
    translateElevation.on("translatestart", function (evt) {
      viewrefs.current.dashboardSettingsMode = "moving";
      if (profileLineFeature) {
        let x: LineString | undefined =
          profileLineFeature.getGeometry() as LineString;
        startcoordinates = x.getCoordinates();
      }
    });
    translateElevation.on("translating", function (evt) {
      if (profileLineFeature) {
        let x: LineString | undefined =
          profileLineFeature.getGeometry() as LineString;
        let newCoordinates = startcoordinates.map((item, i) => {
          if (selected - 1 === i) {
            return evt.coordinate;
          }
          return item;
        });
        x.setCoordinates(newCoordinates);
      }
    });
    translateElevation.on("translateend", function (evt) {
      map.removeInteraction(translateElevation);
      if (profileLineFeature && viewrefs.current.profileLineLayer) {
        viewrefs.current.dashboardSettingsMode = "Slope";
        //get closest point

        const distance: { [key: string]: number } = {};
        viewrefs.current.profileLineLayer.getSource().forEachFeature((f) => {
          const ElevationPoint: Point | undefined = f.getGeometry() as
            | Point
            | undefined;
          if (ElevationPoint) {
            if (ElevationPoint?.getType() === "Point") {
              const Order = _.get(f.getProperties(), "Order");
              const PropertyClass = _.get(f.getProperties(), "Class");
              if (PropertyClass === "Elevation Point") {
                const c = ElevationPoint.getCoordinates();
                distance[Order] = findDistanceBetweenPoints([
                  c,
                  evt.coordinate,
                ]);
              }
            }
          }
        });
        let key = Object.keys(distance).reduce((key, v) =>
          distance[v] < distance[key] ? v : key
        );
        let newSlope = [...viewrefs.current.slopePoints];
        newSlope[selected - 1] = +key;
        setSlopePoints(newSlope);
      }
    });
  }
};
