import React, { useContext } from "react";
import { CanopyConfig } from "../../api/AlchemyApiTypes";
import { CanopyLegend } from "../dashboards/canopy/canopylegend/CanopyLegend";
import { StoreContext } from "../../store/store";
import { State } from "../../store/storetypes";

export const Canopy: React.FC = () => {
  const [store] = useContext(StoreContext) as [State];
  const { selectedCanopyPoint, showlegend } = store;
  if (showlegend.Canopy) {
    const config: CanopyConfig = {
      canopyHeight: selectedCanopyPoint,
      canopycolorscale: ["white", "green"],
      width: 60,
      height: 200,
      minElevation: 0,
      maxElevation: 30,
      fontSize: 14,
      textColor: "#000000",
      markerColor: "#000000",
      markerWidth: 1,
      markerTextMargin: 17,
      textBackground: {
        color: "#000000",
        stroke: "#ffffff",
        strokeWidth: 1,
        borderradius: 4,
        height: "1em",
      },
      suffix: "ft",
      decimalPlaces: 1,
      layout: {
        margin: { top: 20, right: 10, bottom: 20, left: 10 },
      },
    };

    return (
      <div
        style={{
          display: "inline-block",
          backgroundColor: "none",
          marginLeft: 5,
        }}
      >
        <CanopyLegend config={config} />
      </div>
    );
  } else {
    return null;
  }
};
