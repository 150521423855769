import * as React from "react";
import { SVGProps } from "react";

const VideoIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27.025}
    height={27.025}
    viewBox="0 0 27.025 27.025"
    {...props}
  >
    <defs>{/* <style>{".a_VideoIconLarge{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(1 1)">
      <path
        className="a_VideoIconLarge"
        d="M15.513,29.025A13.512,13.512,0,0,1,5.958,5.958,13.424,13.424,0,0,1,15.513,2h0a13.512,13.512,0,0,1,9.555,23.067A13.424,13.424,0,0,1,15.513,29.025ZM15.513,4a11.512,11.512,0,1,0,8.141,3.372A11.437,11.437,0,0,0,15.513,4Z"
        transform="translate(-3 -3)"
      />
      <path
        className="a_VideoIconLarge"
        d="M15,11a1,1,0,0,1,.555.168l7.508,5a1,1,0,0,1,0,1.664l-7.508,5A1,1,0,0,1,14,22.01V12a1,1,0,0,1,1-1Zm5.7,6L16,13.869v6.273Z"
        transform="translate(-4.99 -4.493)"
      />
    </g>
  </svg>
);

export default VideoIconLarge;
