import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import GeoJSON from "ol/format/GeoJSON";
import { VectorLayerConfig, Storage, VectorFilters } from "./AlchemyApiTypes";
import { fetchDataStorage } from "../api/AlchemyAnalytics";
import Auth from "../services/auth";
import { getFileName } from "../utils/download";

export function updateVectorSource(
  layer: VectorLayer<VectorSource<Geometry>>,
  config: VectorLayerConfig,
  setloadedlayers: (name: string) => void,
  storage: Storage,
  auth: Auth
) {
  const getData = async () => {
    try {
      const data = await fetchDataStorage(
        config.filepath,
        storage,
        auth,
        config.cacheKey,
        "vectorurl"
      );
      const vectorSource = new VectorSource({
        features: new GeoJSON({
          featureProjection: "EPSG:3857",
        }).readFeatures(data),
      });

      vectorSource.set("Name", getFileName(config.key));
      vectorSource.set("Key", config.key);
      vectorSource.set("cacheKey", config.cacheKey);

      layer.setSource(vectorSource);
    } catch (err) {
      //alert(config.displayName + " : " + err);
      console.log(config.displayName + " : " + err);
    } finally {
      setloadedlayers(config.key);
    }
  };
  getData();
}

export function filterVectorSource(
  vectorFilters: VectorFilters,
  storage: Storage
) {
  if (vectorFilters && vectorFilters.key && vectorFilters.layers) {
    const data =
      storage[
        vectorFilters.cacheKey ? vectorFilters.cacheKey : vectorFilters.key
      ];
    if (
      vectorFilters.Filtered &&
      vectorFilters.key in vectorFilters.Filtered &&
      Object.entries(vectorFilters.Filtered[vectorFilters.key]).filter(
        (item) => {
          return item[1].length > 0;
        }
      ).length > 0
    ) {
      const FilterProperties = vectorFilters.Filtered[vectorFilters.key];
      const activeFilters = Object.entries(FilterProperties).filter((item) => {
        return item[1].length > 0;
      });
      const activeFiltersName = activeFilters.map((item) => {
        return item[0];
      });

      let filtereddata = {};

      if (vectorFilters.isAnd) {
        filtereddata = {
          ...data,
          features: data.features.filter((feature: any) => {
            return (
              activeFilters.filter((item) => {
                return (
                  Object.entries(feature.properties)
                    .filter((prop: any) => {
                      return activeFiltersName.includes(prop[0]);
                    })
                    .filter((prop: any) => {
                      return (
                        item[0] === prop[0] &&
                        !item[1].includes(prop[1] ? prop[1].toString() : "")
                      );
                    }).length > 0
                );
              }).length === 0
            );
          }),
        };
      } else {
        filtereddata = {
          ...data,
          features: data.features.filter((feature: any) => {
            return (
              activeFilters.filter((item) => {
                return (
                  Object.entries(feature.properties)
                    .filter((prop: any) => {
                      return activeFiltersName.includes(prop[0]);
                    })
                    .filter((prop: any) => {
                      return (
                        item[0] === prop[0] &&
                        item[1].includes(prop[1] ? prop[1].toString() : "")
                      );
                    }).length > 0
                );
              }).length > 0
            );
          }),
        };
      }

      const vectorSource = new VectorSource({
        features: new GeoJSON({
          featureProjection: "EPSG:3857",
        }).readFeatures(filtereddata),
      });

      vectorSource.set("Name", getFileName(vectorFilters.key));
      vectorSource.set("Key", vectorFilters.key);
      vectorFilters.layers[0].setSource(vectorSource);
    } else {
      const vectorSource = new VectorSource({
        features: new GeoJSON({
          featureProjection: "EPSG:3857",
        }).readFeatures(data),
      });
      vectorSource.set("Name", getFileName(vectorFilters.key));
      vectorSource.set("Key", vectorFilters.key);
      vectorFilters.layers[0].setSource(vectorSource);
    }
  }
}
