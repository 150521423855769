import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import { VectorStyleStyle } from "./AlchemyApiTypes";

export function createEmptyVectorLayer(
  displayonload: boolean,
  style: VectorStyleStyle
): VectorLayer<VectorSource<Geometry>> {
  const vectorSource = new VectorSource({});
  return new VectorLayer({
    source: vectorSource,
    style: new Style({
      fill: new Fill({
        color: style.fill,
      }),
      stroke: new Stroke({
        color: style.linecolour,
        width: style.linewidth,
      }),
      image: new Circle({
        radius: style.radius,
        fill: new Fill({ color: style.fill }),
        stroke: new Stroke({
          color: style.linecolour,
          width: style.linewidth,
        }),
      }),
    }),
    visible: displayonload,
  });
}
