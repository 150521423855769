import React, { useEffect } from "react";
import "./potree.css";
import "./jquery-ui.css";
import "./spectrum.css";
import * as THREE from "three";

export const LidarViewer = ({ height, width, url, potreeSettings, name }) => {
  useEffect(() => {
    const Potree = window.Potree;
    const $ = window.$;

    window.viewer = new Potree.Viewer(
      document.getElementById("potree_render_area")
    );

    window.viewer.setEDLEnabled(true);
    window.viewer.setFOV(60);
    window.viewer.setPointBudget(1_000_000);
    window.viewer.loadSettingsFromURL();
    window.viewer.setBackground("black");

    window.viewer.loadGUI(() => {
      window.viewer.setLanguage("en");
      $("#menu_tools").next().show();
      $("#menu_clipping").next().show();
    });

    // Load and add point cloud to scene
    Potree.loadPointCloud(url, name, (e) => {
      let pointcloud = e.pointcloud;
      let material = e.pointcloud.material;
      material.size =
        potreeSettings && potreeSettings.size ? +potreeSettings.size : 1;
      material.shape =
        Potree.PointShape[
          potreeSettings && potreeSettings.shape
            ? potreeSettings.shape
            : "SQUARE"
        ];

      material.pointSizeType =
        Potree.PointSizeType[
          potreeSettings && potreeSettings["size type"]
            ? potreeSettings["size type"]
            : "FIXED"
        ];
      if (potreeSettings && potreeSettings.classified) {
        material.activeAttributeName = "classification";
      }

      window.viewer.scene.addPointCloud(pointcloud);

      if (potreeSettings && potreeSettings.camera) {
        const position = potreeSettings.camera.position
          .split(",")
          .map((item) => +item);
        const target = potreeSettings.camera.target
          .split(",")
          .map((item) => +item);
        if (position.length === 3 && target.length === 3) {
          window.viewer.scene.view.position.set(...position);
          window.viewer.scene.view.lookAt(new THREE.Vector3(...target));
        } else {
          window.viewer.fitToScreen();
        }
      } else {
        window.viewer.fitToScreen();
      }
    });
    return () => {
      console.log("return");
      //cleanup if you can
    };
  }, [url, potreeSettings, name]);

  return (
    <div
      style={{
        position: "absolute",
        width: width,
        height: height,
        left: 0,
        top: 0,
        marginRight: 10,
      }}
    >
      <div id="potree_render_area"></div>
      <div id="potree_sidebar_container"> </div>
    </div>
  );
};
