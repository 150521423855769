import * as React from "react";
import { SVGProps } from "react";

const ZoomInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
  >
    <defs>
      <style>{".b_ZoomInIcon{fill:none;}"}</style>
    </defs>
    <g transform="translate(-1272.713 -1222.427)">
      <path
        className="b_ZoomInIcon"
        d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z"
        transform="translate(1269.713 1219.427)"
      />
      <path
        className="a_ZoomInIcon"
        d="M19.5,24h-3V12h3Z"
        transform="translate(1269.713 1219.427)"
      />
      <path
        className="a_ZoomInIcon"
        d="M24,19.5H12v-3H24Z"
        transform="translate(1269.713 1219.427)"
      />
    </g>
  </svg>
);

export default ZoomInIcon;
