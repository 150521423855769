import * as React from "react";
import { SVGProps } from "react";

const ReturnHomeIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.523}
    height={27.025}
    viewBox="0 0 24.523 27.025"
    {...props}
  >
    <defs>{/* <style>{".a_ReturnHomeIconLarge{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(1 1)">
      <path
        className="a_ReturnHomeIconLarge"
        d="M15.761,2a1,1,0,0,1,.614.211l11.261,8.759a1,1,0,0,1,.386.789V25.522a3.506,3.506,0,0,1-3.5,3.5H7a3.506,3.506,0,0,1-3.5-3.5V11.759a1,1,0,0,1,.386-.789L15.147,2.211A1,1,0,0,1,15.761,2ZM26.023,12.248,15.761,4.267,5.5,12.248V25.522a1.5,1.5,0,0,0,1.5,1.5H24.52a1.5,1.5,0,0,0,1.5-1.5Z"
        transform="translate(-4.5 -3)"
      />
      <path
        className="a_ReturnHomeIconLarge"
        d="M21.008,31.512a1,1,0,0,1-1-1V19H14.5V30.512a1,1,0,1,1-2,0V18a1,1,0,0,1,1-1h7.508a1,1,0,0,1,1,1V30.512A1,1,0,0,1,21.008,31.512Z"
        transform="translate(-5.992 -5.488)"
      />
    </g>
  </svg>
);

export default ReturnHomeIconLarge;
