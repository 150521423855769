import * as d3 from "d3";

var interpolators = [
  // These are from d3-scale.
  "Viridis",
  "Inferno",
  "Magma",
  "Plasma",
  "Warm",
  "Cool",
  "Rainbow",
  "CubehelixDefault",
  // These are from d3-scale-chromatic
  "Blues",
  "Greens",
  "Greys",
  "Oranges",
  "Purples",
  "Reds",
  "BuGn",
  "BuPu",
  "GnBu",
  "OrRd",
  "PuBuGn",
  "PuBu",
  "PuRd",
  "RdPu",
  "YlGnBu",
  "YlGn",
  "YlOrBr",
  "YlOrRd",
];

export const getColorScale = (categories, colormap) => {
  const colorScale = d3.scaleOrdinal().domain(categories).range(colormap);
  return colorScale;
};

export const getLinearColorScale = (categories, colormap) => {
  const colorScale = d3.scaleLinear().domain(categories).range(colormap);
  return colorScale;
};

export const getSequentialColorScale = (categories, interpolator) => {
  if (!isNaN(interpolator)) {
    interpolator = interpolators[interpolator];
  }
  const colorScale = d3
    .scaleSequential()
    .domain(categories)
    .interpolator(d3["interpolate" + interpolator]);

  return colorScale;
};
