import React, { useContext, useCallback } from "react";
import { DashboardSettings, Dashboard } from "../../api/AlchemyApiTypes";
import loadingGIF from "../../images/ZZ5H.gif";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";

interface Props {
  dashboardCleanupComponents?: (key: string) => void;
}

export const DashboardList: React.FC<Props> = ({
  dashboardCleanupComponents,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const {
    dashboardSettings,
    loadedVectorlayers,
    showlegend,
    sqlActive,
    sqlDependantDashboards,
  } = store;

  const setDashboardSettings = useCallback(
    (val: DashboardSettings) => {
      dispatch({
        type: actionTypes.SET_DASHBOARD_SETTINGS,
        payload: val,
      });
    },
    [dispatch]
  );

  if (dashboardSettings && Object.keys(dashboardSettings).length > 0) {
    const toggleShowDashboard = (key: string) => {
      let newdashboardSettings = { ...dashboardSettings };
      newdashboardSettings[key].selected = !newdashboardSettings[key].selected;
      Object.entries(newdashboardSettings)
        .filter((fitem) => {
          return fitem[0] !== key;
        })
        .forEach((item: any) => {
          if (item[1].selected) {
            if (dashboardCleanupComponents) {
              dashboardCleanupComponents(item[0]);
              newdashboardSettings[item[0]].selected = false;
            }
          }
        });
      if (!newdashboardSettings[key].selected) {
        if (dashboardCleanupComponents) {
          dashboardCleanupComponents(key);
        }
      } else {
        if (newdashboardSettings[key].legend) {
          dispatch({
            type: actionTypes.SET_SHOW_LEGEND,
            payload: {
              ...showlegend,
              [newdashboardSettings[key].legend]: true,
            },
          });
        }
      }
      setDashboardSettings(newdashboardSettings);
    };

    const gifOrInput = (item: string, i: number) => {
      const dashboarditem: Dashboard = dashboardSettings[item];
      if (
        dashboarditem.dependancy === "none" ||
        (loadedVectorlayers && loadedVectorlayers[dashboarditem.dependancy])
      ) {
        return (
          <div style={{ marginTop: "0", width: "30px" }}>
            <input
              style={{ accentColor: "#ffffff", cursor: "pointer" }}
              type="checkbox"
              onChange={() => toggleShowDashboard(item)}
              checked={dashboardSettings[item].selected}
            />
          </div>
        );
      } else {
        return (
          <div style={{ marginTop: "0", width: "30px" }}>
            <img
              alt="loader"
              src={loadingGIF}
              style={{
                width: 20,
                height: 20,
                marginLeft: -4,
              }}
            />
          </div>
        );
      }
    };

    return (
      <div
        style={{
          width: 245,
          backgroundColor: "transparent",
          padding: 20,
        }}
      >
        <div
          style={{
            borderTop: "2px solid #757575",
            paddingTop: 20,
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {Object.entries(dashboardSettings)
            .filter((item: any) => {
              return (
                item[1].active &&
                (sqlActive || sqlDependantDashboards.indexOf(item[0]) === -1)
              );
            })
            .sort((a: any, b: any) => {
              return b[1].displayName - a[1].displayName;
            })
            .map((item: any, i: number) => {
              return (
                <div key={i} style={{ display: "flex", padding: 5 }}>
                  {gifOrInput(item[0], i)}
                  <div>{item[1].displayName}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
