import { getFileName } from "../../utils/download";
import { ViewInfo, Marker } from "../../api/AlchemyApiTypes";

export const getFileMarkers = (mapInfo: ViewInfo | null) => {
  if (mapInfo) {
    const { mapConfig, mapStyle } = mapInfo;
    if (mapConfig?.file_urls) {
      const files: string[] = [];
      mapConfig?.file_urls.forEach((item) => {
        files.push(item);
      });
      const file_style = mapStyle?.FileStyle;
      if (file_style) {
        const bucket_files: Marker[] = files
          .filter((item) => {
            const key = getFileName(item);
            return (
              key in file_style &&
              file_style[key].coordinates &&
              file_style[key].coordinates?.length === 2
            );
          })
          .map((item) => {
            const key = getFileName(item);
            return {
              id: "file_" + item,
              displayName:
                file_style[key].displayName ||
                key.split(".")[0].split("_").join(" "),
              mapCoordinates: file_style[key].coordinates || [],
              marker: file_style[key].marker || "Summary",
            };
          });
        return bucket_files;
      }
    }
  }
};
