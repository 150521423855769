import React, { useEffect, useContext } from "react";
import { PropertyDisplay } from "../components/PropertyDisplay";
import Auth from "../services/auth";
import { ExplodedFeatures, ElevationProfileData } from "../api/AlchemyApiTypes";
import {
  setStyleElevationPoints,
  setElevationPointsTranslation,
  setSlopePointsTranslation,
} from "../map/functions";
import _ from "lodash";
import { StoreContext } from "../store/store";
import { State, Action, actionTypes } from "../store/storetypes";
import { ViewRefs } from "../store/refs";

interface Props {
  getExplodedFeatures: () => ExplodedFeatures | null;
  auth: Auth;
  setSlopePoints: (val: number[]) => void;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const PropertyDisplayController: React.FC<Props> = ({
  getExplodedFeatures,
  auth,
  setSlopePoints,
  viewrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const {
    elevationProfileData,
    elevationProfileDataLoaded,
    featureProperties,
    vectorShowProperties,
  } = store;
  const map = viewrefs.current.map;
  useEffect(() => {
    if (viewrefs.current.dashboardSettingsMode === "Slope") {
      if (featureProperties.display) {
        const filtered = featureProperties.features.filter((item) => {
          const Class = _.get(item.properties, "Class", null);
          return Class === "Slope Point";
        });
        const filteredOrder = filtered.map((item) => {
          return +_.get(item.properties, "Order", null);
        })[0];
        setSlopePointsTranslation(filteredOrder, map, setSlopePoints, viewrefs);
      }
    } else {
      const fsetElevationProfileData = (newData: ElevationProfileData[]) => {
        dispatch({
          type: actionTypes.SET_ELEVATION_PROFILE_DATA,
          payload: newData,
        });
        viewrefs.current.refElevationProfileData = newData;
      };
      if (featureProperties.display) {
        const filtered = featureProperties.features.filter((item) => {
          const Class = _.get(item.properties, "Class", null);
          return Class === "Elevation Point";
        });
        const filteredhexagon = featureProperties.features
          .filter((item) => {
            const Class = _.get(item.properties, "Class", null);
            return Class === "canopy";
          })
          .map((item) => {
            return +_.get(item.properties, "height", null);
          });
        const filteredH3heatmap = featureProperties.features
          .filter((item) => {
            const Class = _.get(item.properties, "Class", null);
            return Class === "point count";
          })
          .map((item) => {
            return +_.get(item.properties, "count", null);
          });
        const filteredOrder = filtered.map((item) => {
          return +_.get(item.properties, "Order", null);
        })[0];
        dispatch({
          type: actionTypes.SET_SELECTED_ELEVATION_POINTS,
          payload: filteredOrder ? [filteredOrder] : [],
        });
        dispatch({
          type: actionTypes.SET_SELECTED_CANOPY_POINT,
          payload: filteredhexagon ? filteredhexagon[0] : 0,
        });
        dispatch({
          type: actionTypes.SET_SELECTED_POINT_COUNT,
          payload: filteredH3heatmap ? filteredH3heatmap[0] : 0,
        });
        //change style of dots
        setStyleElevationPoints(filteredOrder, viewrefs);
        if (
          elevationProfileDataLoaded &&
          viewrefs.current.dashboardSettingsMode === null
        ) {
          setElevationPointsTranslation(
            filteredOrder,
            map,
            auth,
            elevationProfileData,
            fsetElevationProfileData,
            viewrefs
          );
        }
      }
    }
  }, [
    viewrefs,
    dispatch,
    featureProperties,
    map,
    auth,
    elevationProfileDataLoaded,
    elevationProfileData,
    setSlopePoints,
  ]);
  if (featureProperties.display) {
    const ExplodedFeatures = getExplodedFeatures();
    return (
      <>
        {featureProperties.features
          .filter((item) => {
            const key = item.layer.getSource().get("Key");

            return (
              vectorShowProperties[key] ||
              ("Class" in item.properties && ExplodedFeatures === null) ||
              (ExplodedFeatures &&
                ExplodedFeatures?.features
                  .map((item) => item.feature)
                  .indexOf(item.feature) !== -1)
            );
          })
          .map((item, i) => {
            return <PropertyDisplay key={i} featureProperties={item} />;
          })}
      </>
    );
  } else {
    return null;
  }
};
