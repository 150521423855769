import {
  BuildingAnalysisScore,
  CollectionMap,
  DashboardSettings,
  DivDimensions,
  DivPosition,
  ElevationProfileData,
  Export,
  FeatureSelection,
  FieldPhotoObject,
  Loaded,
  SelectedPhoto,
  MapLayer,
  MapLegend,
  UserVectorStyles,
  VectorFilters,
  VectorShowProperties,
  ViewConfigResponse,
  ViewInfo,
  VideoObject,
} from "../api/AlchemyApiTypes";
import { OlMapSettings } from "../map/types";
// import Map from "ol/Map";

export type State = {
  activatedashboardSettingsMode: number;
  activateElevationProfileData: number;
  buildingAnalysisRefreshScorecard: number;
  buildingAnalysisScore: BuildingAnalysisScore | null;
  canopyCoordinateChanged: number;
  clearDashboard: string;
  clickPanel: { active: boolean; box: DOMRect | null; header: string };
  coordinates: number[];
  dashboardSettings: DashboardSettings;
  dashboardSettingModeState: string | null;
  displayViews: CollectionMap | null;
  defaultLidarDimensions: DivDimensions;
  defaultLidarPosition: DivPosition;
  elevation: number;
  elevationProfileData: ElevationProfileData[];
  elevationProfileDataLoaded: boolean;
  exporting: Export;
  featureProperties: FeatureSelection;
  fieldphotoitems: Array<FieldPhotoObject> | undefined;
  filter: {
    active: boolean;
    selected: string[];
  };
  filtered: string[];
  forceUpdate: number;
  h3heatmaplegendinfo: {
    active: Boolean;
    max: number;
  };
  heatmapZoomRatio: number;
  hoveredFieldPhoto: string[] | null;
  hoverPanel: { active: boolean; box: DOMRect | null; header: string };
  leftPanelTransition: boolean;
  loadingElevation: boolean;
  loadedVectorlayers: Loaded | null;
  mapConfig: ViewConfigResponse | null;
  mapInfo: ViewInfo | null;
  mapSettings: OlMapSettings | null;
  // map: Map | null;
  panelExpanded: boolean;
  polySelectReady: boolean;
  profileLineReady: boolean;
  selectedBuilding: string;
  selectedCanopyPoint: number;
  selectedElevationPoints: number[];
  selectedFieldPhoto: SelectedPhoto | null;
  selectedPointCount: number;
  showlegend: MapLegend;
  slope: number;
  slopeChanged: number;
  sqlActive: boolean;
  sqlDependantDashboards: string[];
  supportEmail: { show: boolean; message: string };
  userVectorStyles: UserVectorStyles;
  vectorFilters: VectorFilters;
  vectorlayers: MapLayer[] | null;
  vectorShowProperties: VectorShowProperties;
  videoitems: Array<VideoObject> | undefined;
};

export type Action = {
  type: string;
  payload: any;
};

export const actionTypes = {
  SET_ACTIVATE_DASHBOARD_SETTINGS_MODE: "SET_ACTIVATE_DASHBOARD_SETTINGS_MODE",
  SET_ACTIVATE_ELEVATION_PROFILE_DATA: "SET_ACTIVATE_ELEVATION_PROFILE_DATA",
  SET_BUILDINGANALYSIS_REFRESH_SCORECARD:
    "SET_BUILDING_ANALYSIS_REFRESH_SCORECARD",
  SET_BUILDINGANALYSIS_SCORE: "SET_BUILDINGANALYSIS_SCORE",
  SET_CANOPY_COORDINATES_CHANGED: "SET_CANOPY_COORDINATES_CHANGED",
  SET_CLEAR_DASHBOARD: "SET_CLEAR_DASHBOARD",
  SET_CLICKPANEL: "SET_CLICKPANEL",
  SET_COORDINATES: "SET_COORDINATES",
  SET_DASHBOARD_SETTINGS: "SET_DASHBOARD_SETTINGS",
  SET_DASHBOARD_SETTINGS_MODE_STATE: "SET_DASHBOARD_SETTINGS_MODE_STATE",
  SET_DEFAULT_LIDAR_DIMENSIONS: "SET_DEFAULT_LIDAR_DIMENSIONS",
  SET_DEFAULT_LIDAR_POSITION: "SET_DEFAULT_LIDAR_POSITION",
  SET_DISPLAYVIEWS: "SET_DISPLAYVIEWS",
  SET_ELEVATION: "SET_ELEVATION",
  SET_ELEVATION_PROFILE_DATA: "SET_ELEVATION_PROFILE_DATA",
  SET_ELEVATION_PROFILE_DATA_LOADED: "SET_ELEVATION_PROFILE_DATA_LOADED",
  SET_EXPORT: "SET_EXPORT",
  SET_FEATURE_PROPERTIES: "SET_FEATURE_PROPERTIES",
  SET_FIELD_PHOTO_ITEMS: "SET_FIELD_PHOTO_ITEMS",
  SET_FILTER: "SET_FILTER",
  SET_FILTERED: "SET_FILTERED",
  SET_FORCE_UPDATE: "SET_FORCE_UPDATE",
  SET_H3HEATMAP_LEGEND_INFO: "SET_H3HEATMAP_LEGEND_INFO",
  SET_HEATMAP_ZOOM_RATIO: "SET_HEATMAP_ZOOM_RATIO",
  SET_HOVERED_FIELD_PHOTO: "SET_HOVERED_FIELD_PHOTO",
  SET_HOVERPANEL: "SET_HOVERPANEL",
  SET_LEFT_PANEL_TRANSITION: "SET_LEFT_PANEL_TRANSITION",
  SET_LOADED_VECTOR_LAYERS: "SET_LOADED_VECTOR_LAYERS",
  SET_LOADING_ELEVATION: "SET_LOADING_ELEVATION",
  SET_MAP: "SET_MAP",
  SET_MAP_CONFIG: "SET_MAP_CONFIG",
  SET_MAP_INFO: "SET_MAP_INFO",
  SET_MAP_SETTINGS: "SET_MAP_SETTINGS",
  SET_PANEL_EXPANDED: "SET_PANEL_EXPANDED",
  SET_POLY_SELECT_READY: "SET_POLY_SELECT_READY",
  SET_PROFILE_LINE_READY: "SET_PROFILE_LINE_READY",
  SET_SELECTED_BUILDING: "SET_SELECTED_BUILDING",
  SET_SELECTED_CANOPY_POINT: "SET_SELECTED_CANOPY_POINT",
  SET_SELECTED_ELEVATION_POINTS: "SET_SELECTED_ELEVATION_POINTS",
  SET_SELECTED_FIELD_PHOTO: "SET_SELECTED_FIELD_PHOTO",
  SET_SELECTED_POINT_COUNT: "SET_SELECTED_POINT_COUNT",
  SET_SHOW_LEGEND: "SET_SHOW_LEGEND",
  SET_SLOPE: "SET_SLOPE",
  SET_SLOPE_CHANGED: "SET_SLOPE_CHANGED",
  SET_SQL_ACTIVE: "SET_SQL_ACTIVE",
  SET_SUPPORT_EMAIL: "SET_SUPPORT_EMAIL",
  SET_USER_VECTOR_STYLES: "SET_USER_VECTOR_STYLES",
  SET_VECTOR_FILTERS: "SET_VECTOR_FILTERS",
  SET_VECTOR_LAYERS: "SET_VECTOR_LAYERS",
  SET_VECTOR_SHOW_PROPERTIES: "SET_VECTOR_SHOW_PROPERTIES",
  SET_VIDEO_ITEMS: "SET_VIDEO_ITEMS",
};
