import React, { useContext, useState, useRef } from "react";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { ButtonComponent } from "../ButtonComponent";
import { sendSupportEmail } from "../../api/AlchemyAnalytics";
import { DivPosition } from "../../api/AlchemyApiTypes";
import { Rnd } from "react-rnd";
import Auth from "../../services/auth";
import loadingGIF from "../../images/mail-download.gif";
import { getDefaultPosition } from "../dashboards/functions";

interface Props {
  auth: Auth;
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
}

export const SupportEmailForm: React.FC<Props> = ({ auth, mapviewRoot }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  interface DivDimensions {
    width: number;
    height: number;
  }

  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });
  const defaultDimentions: DivDimensions = { height: 407, width: 652 };

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [disabledrag, setdisabledrag] = useState<boolean>(false);
  const divDimensions = useRef<DivDimensions>(defaultDimentions);
  const { supportEmail } = store;

  const onSubmit = () => {
    const asyncfunction = async () => {
      await sendSupportEmail(auth, supportEmail.message);
      setInProgress(false);
      dispatch({
        type: actionTypes.SET_SUPPORT_EMAIL,
        payload: { show: false, message: "" },
      });
    };

    if (supportEmail.message) {
      setInProgress(true);
      setDisabled(true);
      asyncfunction();
    } else {
      alert("Invalid Message");
    }
  };

  const onCancel = () => {
    dispatch({
      type: actionTypes.SET_SUPPORT_EMAIL,
      payload: { show: false, message: "" },
    });
  };

  const onChange = (message: string) => {
    dispatch({
      type: actionTypes.SET_SUPPORT_EMAIL,
      payload: { ...supportEmail, message: message },
    });
  };

  return (
    <Rnd
      position={getDefaultPosition(
        defaultDimentions.height,
        defaultDimentions.width,
        position,
        mapviewRoot
      )}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      disableDragging={disabledrag}
      style={{ zIndex: 9999 }}
    >
      <div
        style={{
          position: "absolute",
          display: "block",
          backgroundColor: "#000000",
          height: divDimensions.current.height + "px",
          width: divDimensions.current.width + "px",
          zIndex: 999,
          border: "1px solid #363636",
          textAlign: "center",
          paddingLeft: 30,
          paddingRight: 30,
          color: "#ffffff",
        }}
      >
        <div style={{ marginTop: 15, fontSize: 18 }}>
          Enter your message to send to flyalchemy portal support
        </div>
        <div>
          <textarea
            disabled={disabled}
            style={{
              width: "100%",
              height: divDimensions.current.height - 140,
              marginTop: 20,
              border: "1px solid #363636",
              resize: "none",
              color: "#ffffff",
              backgroundColor: "#000000",
            }}
            value={supportEmail.message}
            onChange={(e) => onChange(e.target.value)}
            onMouseEnter={() => setdisabledrag(true)}
            onMouseLeave={() => setdisabledrag(false)}
          ></textarea>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 15 }}
        >
          {!inProgress && (
            <>
              <ButtonComponent
                name="Send"
                style={{
                  width: "100px",
                  height: "40px",
                  marginLeft: "0px",
                  marginRight: "10px",
                  border: "2px solid #ffffff",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  fontSize: "16px",
                  borderRadius: "2px",
                }}
                onClick={() => onSubmit()}
                hoverStyle={{
                  backgroundColor: "#757575",
                  border: "2px solid #757575",
                }}
              />
              <ButtonComponent
                name="Cancel"
                style={{
                  width: "100px",
                  height: "40px",
                  marginLeft: "10px",
                  marginRight: "0px",
                  border: "2px solid #ffffff",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  fontSize: "16px",
                  borderRadius: "2px",
                }}
                onClick={() => onCancel()}
                hoverStyle={{
                  backgroundColor: "#757575",
                  border: "2px solid #757575",
                }}
              />
            </>
          )}
          {inProgress && (
            <div
              style={{
                display: "flex",
                width: "80px",
                height: "80px",
                marginLeft: "0px",
                marginRight: "5px",
                justifyContent: "center",
              }}
            >
              <img
                alt="loading"
                style={{
                  width: "80px",
                  height: "80px",
                  marginTop: -25,
                }}
                src={loadingGIF}
              />
            </div>
          )}
        </div>
      </div>
    </Rnd>
  );
};
