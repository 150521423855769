import Auth from "./auth";
import { ViewConfigResponse } from "../api/AlchemyApiTypes";
import { getMapInfoByUser } from "../api/AlchemyAnalytics";

export default class ViewsService {
  private allViews: ViewConfigResponse[] | null = null;

  hasMultipleViews(): boolean {
    return this.allViews != null && this.allViews.length > 1;
  }

  constructor(private auth: Auth) {}

  async refreshViews(): Promise<ViewConfigResponse[]> {
    this.allViews = await getMapInfoByUser(this.auth);
    return this.allViews;
  }

  async getAllViews(): Promise<ViewConfigResponse[]> {
    if (this.allViews === null) {
      return this.refreshViews();
    }
    return Promise.resolve(this.allViews);
  }

  async getViewById(viewId: string): Promise<ViewConfigResponse | null> {
    return this.getAllViews().then(
      (allViews: ViewConfigResponse[]): ViewConfigResponse | null => {
        return (
          allViews.find((view) => view.view_config.ViewId === viewId) ?? null
        );
      }
    );
  }

  async getDefaultView(): Promise<ViewConfigResponse | null> {
    return this.getAllViews().then(
      (allViews: ViewConfigResponse[]): ViewConfigResponse => {
        return allViews[0] ?? null;
      }
    );
  }
}
