import React, { CSSProperties, useContext, useState } from "react";
import { Rnd } from "react-rnd";
import { getDefaultPositionBottomRight } from "../../functions";
import { DivPosition } from "../../../../api/AlchemyApiTypes";
import { hexdensity } from "../../../../map/math";
import {
  style_columnHeaders,
  style_columnValues,
  style_rowHeaders,
  style_table,
  style_alertValues,
} from "./FuelScoreStyles";
import { ViewRefs } from "../../../../store/refs";
import { limits } from "./FuelLimits";
import {
  BuildingAnalysisScore,
  BuildingAnalysisArea,
} from "../../../../api/AlchemyApiTypes";
import { StoreContext } from "../../../../store/store";
import { State, Action, actionTypes } from "../../../../store/storetypes";

interface Props {
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  expandedbuildingAnalysisScore: BuildingAnalysisScore;
  expandedbuildingAnalysisArea: BuildingAnalysisArea;
  keyId: string;
  highlighted: boolean;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

const DashboardDimensions = { height: 160, width: 390 };

const getValueStyle = (val: number, zone: string, fuel: string) => {
  if (val > limits[fuel][zone]) {
    return style_alertValues;
  } else {
    return style_columnValues;
  }
};
const Fuels = [
  { value: "light", descr: "Light" },
  { value: "medium", descr: "Medium" },
  { value: "heavy", descr: "Heavy" },
];

const Parcel = {
  value: "parcel",
  descr: "Parcel",
  style: {
    border: "1px solid green",
    borderRadius: 4,
    paddingLeft: 4,
    paddingRight: 4,
  },
};
const Zones = [
  {
    value: "building",
    descr: "Building",
    style: {
      border: "1px solid blue",
      borderRadius: 4,
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  {
    value: "zone0",
    descr: "Zone 0 (0 - 5ft)",
    style: {
      border: "2px solid #A7181C",
      borderRadius: 4,
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  {
    value: "zone1",
    descr: "Zone 1 (5 - 30ft)",
    style: {
      border: "2px solid #E95E18",
      borderRadius: 4,
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  {
    value: "zone2",
    descr: "Zone 2 (30 - 100ft)",
    style: {
      border: "2px solid #EB9C07",
      borderRadius: 4,
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
];

export const FuelScoreDisplay: React.FC<Props> = ({
  mapviewRoot,
  expandedbuildingAnalysisScore,
  expandedbuildingAnalysisArea,
  keyId,
  highlighted,
  viewrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });
  const { showlegend } = store;
  if (showlegend.FuelScore && expandedbuildingAnalysisScore) {
    const getDensityValue = (fuel: string, zone: string, id: string) => {
      const area = expandedbuildingAnalysisArea[zone][id];
      const numberhexes = expandedbuildingAnalysisScore[fuel][zone][id];
      return hexdensity(numberhexes, area) * 100;
    };
    return (
      <Rnd
        position={getDefaultPositionBottomRight(
          DashboardDimensions.height,
          DashboardDimensions.width, // * (+keyId + 1),
          position,
          mapviewRoot
        )}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
          viewrefs.current.buildingAnalysisFloatingCards.push(keyId);
          const index =
            viewrefs.current.buildingAnalysisCardOrder.indexOf(keyId);
          if (index > -1) {
            viewrefs.current.buildingAnalysisCardOrder.splice(index, 1);
          }
          dispatch({
            type: actionTypes.SET_BUILDINGANALYSIS_REFRESH_SCORECARD,
            payload: Math.random(),
          });
        }}
        style={{ zIndex: 9999 }}
      >
        <div
          style={{
            display:
              keyId === viewrefs.current.buildingAnalysisCardOrder[0]
                ? "inline-block"
                : position.x === 0 && position.y === 0
                ? "none"
                : "inline-block",
            backgroundColor: highlighted ? "#000000" : "#363636",
            height: DashboardDimensions.height,
            width: DashboardDimensions.width,
            // border: highlighted ? "4px solid #757575" : "1px solid #757575",
            borderRadius: 4,
            color: "#ffffff",
          }}
        >
          <table style={style_table}>
            <thead>
              <tr>
                <th style={style_columnHeaders}>
                  <div style={{ ...style_rowHeaders, ...Parcel.style }}>
                    {Parcel.descr}
                  </div>
                </th>
                {Fuels.map(
                  (fuel: { value: string; descr: string }, i: number) => {
                    return (
                      <th key={i} style={style_columnHeaders}>
                        {fuel.descr}
                      </th>
                    );
                  }
                )}
              </tr>
            </thead>
            <tbody>
              {Zones.map(
                (
                  zone: { value: string; descr: string; style: CSSProperties },
                  i: number
                ) => {
                  return (
                    <tr key={i}>
                      <td style={style_rowHeaders}>
                        <div style={zone.style}>{zone.descr}</div>
                      </td>
                      {Fuels.map(
                        (fuel: { value: string; descr: string }, i: number) => {
                          return (
                            <td
                              key={i}
                              style={getValueStyle(
                                getDensityValue(fuel.value, zone.value, keyId),
                                zone.value,
                                fuel.value
                              )}
                            >
                              {getDensityValue(
                                fuel.value,
                                zone.value,
                                keyId
                              ).toFixed(2) + "%"}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </Rnd>
    );
  } else {
    return null;
  }
};
