interface ChartData {
  ARN: string;
  NumberOfFeatures: number;
}

interface Counter {
  [key: string]: number;
}

export const getHighestFeatureCountArray = (chartData: ChartData[]) => {
  return Math.max(...chartData.map((item) => item.NumberOfFeatures));
};

export const getHighestFeatureCountObj = (Counter: Counter) => {
  return Math.max(...Object.entries(Counter).map((item) => item[1]));
};
