import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";

import { XyzLayerConfig } from "./AlchemyApiTypes";
import { MapBaseLayer } from "../map/types";
import stageconfig from "../stageconfig.json";

export function createXyzLayer(layer: XyzLayerConfig): MapBaseLayer {
  const urls = Array.isArray(layer.url) ? layer.url : [layer.url];
  return {
    name: layer.displayName,
    legend: layer.legend,
    info: layer.info,
    layers: urls.map((urlx) => {
      const url = urlx.replace("{stage}", stageconfig.stage);
      return new TileLayer({
        source: new XYZ({
          url,
          maxZoom: Number(layer.maxZoom),
          opaque: false,
          crossOrigin: "Anonymous", // TODO: actual CORS config
        }),
        visible: !!layer.displayOnLoad,
      });
    }),
  };
}
