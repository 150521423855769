import React, { useContext } from "react";

import { ButtonComponent } from "../ButtonComponent";

import Auth from "../../services/auth";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { ViewRefs } from "../../store/refs";

interface Props {
  auth: Auth;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const Settings: React.FC<Props> = ({ auth }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { supportEmail } = store;

  const onSupportClick = () => {
    dispatch({
      type: actionTypes.SET_SUPPORT_EMAIL,
      payload: { ...supportEmail, show: true },
    });
  };

  return (
    <div style={{ width: 245, backgroundColor: "transparent", padding: 20 }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          // flexDirection: "column",
          alignItems: "centre",
          borderTop: "2px solid #000000",
          padding: 5,
        }}
      >
        <ButtonComponent
          name="Log out"
          style={{
            width: "80px",
            height: "30px",
            border: "2px solid #ffffff",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "10px",
            backgroundColor: "#000000",
            color: "#ffffff",
            fontSize: "14px",
            borderRadius: "2px",
          }}
          onClick={() => auth.logout()}
          hoverStyle={{
            backgroundColor: "#757575",
            border: "2px solid #757575",
          }}
        />
        <ButtonComponent
          name="Support"
          style={{
            width: "80px",
            height: "30px",
            border: "2px solid #ffffff",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "10px",
            backgroundColor: "#000000",
            color: "#ffffff",
            fontSize: "14px",
            borderRadius: "2px",
          }}
          onClick={() => onSupportClick()}
          hoverStyle={{
            backgroundColor: "#757575",
            border: "2px solid #757575",
          }}
        />
      </div>
    </div>
  );
};
