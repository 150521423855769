import * as React from "react";
import { SVGProps } from "react";

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    viewBox="0 0 15 15"
    {...props}
  >
    <defs>
      <style>{".a_InfoIcon{fill:#255e93;}"}</style>
    </defs>
    <g transform="translate(-4717 -2157)">
      <circle
        className="a_InfoIcon"
        cx={7.5}
        cy={7.5}
        r={7.5}
        transform="translate(4717 2157)"
      />
      <path
        className="b"
        d="M1.12-8.885v-1.4H2.712v1.4Zm0,7.2V-8.007H2.712v6.322Z"
        transform="translate(4722.584 2170.19)"
      />
    </g>
  </svg>
);

export default InfoIcon;
