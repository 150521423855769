import React, { useContext, useRef, useEffect, useState } from "react";
import Auth from "../../services/auth";
import ExportIcon from "../../images/svg/other/ExportIcon";
import SidebarCollapsedLogo from "../../images/svg/logos/SidebarCollapsedLogo";
import SidebarExpandedLogo from "../../images/svg/logos/SidebarExpandedLogo";
import { onLoadffects } from "./functions";
import { PanelItem } from "./PanelItem";
import { ViewSelection } from "./ViewSelection";
import { exitTasks, enterTasks } from "./functions";
import { Export, markerVectorLayersDefaults } from "../../api/AlchemyApiTypes";

import { exportViewAsPdf } from "../../map/functions";

import { ViewRefs } from "../../store/refs";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";

interface Props {
  auth: Auth;
  storage: storage;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

interface storage {
  [key: string]: object;
}

export const LeftPanel: React.FC<Props> = ({ auth, storage, viewrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { clickPanel, mapInfo, mapSettings, panelExpanded } = store;
  const [hoverExport, setHoverExport] = useState<boolean>(false);

  const map = viewrefs.current.map;

  interface ActiveFeatures {
    [key: string]: boolean;
  }
  const activeFeatures = useRef<ActiveFeatures>({
    "My Files": false,
    "Map Layers": false,
    "Map Lines": false,
    Dashboards: false,
    Markers: false,
    Photos: false,
    Videos: false,
    Settings: true,
    "Return Home": true,
    Collapse: true,
    Expand: true,
  });

  const [tr, triggerRefresh] = useState<number>(0);

  if (tr) {
    //
  }

  const setExporting = (payload: Export) => {
    dispatch({
      type: actionTypes.SET_EXPORT,
      payload: payload,
    });
  };
  useEffect(() => {
    let mounted = true;
    if (
      map &&
      mapInfo &&
      mapSettings &&
      viewrefs.current.mapValid &&
      viewrefs.current.runpaneleffects
    ) {
      viewrefs.current.runpaneleffects = false;
      //Init
      activeFeatures.current = {
        "My Files": false,
        "Map Layers": false,
        "Map Lines": false,
        Dashboards: false,
        Markers: false,
        Photos: false,
        Videos: false,
        Settings: true,
        "Return Home": true,
        Collapse: true,
        Expand: true,
      };
      viewrefs.current.markerVectorLayers = JSON.parse(
        JSON.stringify(markerVectorLayersDefaults)
      );

      const effectsShell = (header: string) => {
        onLoadffects(
          header,
          viewrefs,
          mapInfo,
          mounted,
          activeFeatures,
          triggershell,
          dispatch,
          auth,
          storage
        );
      };
      const triggershell = () => {
        if (mounted) {
          triggerRefresh(Math.random());
        }
      };
      effectsShell("My Files");
      if (mapSettings && mapSettings.layers.length > 0) {
        activeFeatures.current["Map Layers"] = true;
        triggerRefresh(Math.random());
      }
      effectsShell("Map Lines");
      effectsShell("Dashboards");
      effectsShell("Photos");
      effectsShell("Videos");
      effectsShell("Markers");
    }
    return () => {
      mounted = false;
    };
  }, [auth, map, mapInfo, mapSettings, viewrefs, dispatch, storage]);
  const setPanelState = (header: string, active: boolean, type: string) => {
    const payload = {
      active: active,
      box: panelBox.current[header === "Expand" ? "Collapse" : header],
      header,
    };
    if (type === "click") {
      if (header === clickPanel.header) {
        exitTasks(header, viewrefs);
      } else {
        enterTasks(header, viewrefs);
      }
      dispatch({
        type: actionTypes.SET_CLICKPANEL,
        payload: {
          ...payload,
          header: header === clickPanel.header ? "" : header,
          active: header === clickPanel.header ? false : true,
        },
      });
      dispatch({
        type: actionTypes.SET_HOVERPANEL,
        payload: { ...payload, active: false },
      });
    } else {
      dispatch({
        type: actionTypes.SET_HOVERPANEL,
        payload: payload,
      });
    }
  };
  interface Panelrefs {
    [key: string]: DOMRect;
  }
  const panelBox = useRef<Panelrefs>({});

  const PanelItemShell = (header: string) => {
    return (
      <PanelItem
        header={header}
        clickPanel={clickPanel}
        setPanelState={setPanelState}
        panelExpanded={panelExpanded}
        activeFeatures={activeFeatures.current}
        panelBox={panelBox}
      />
    );
  };

  return (
    <div
      className="transition-width"
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        width: panelExpanded ? 225 : 75,
        backgroundColor: "black",
      }}
    >
      <ViewSelection viewrefs={viewrefs} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          fontSize: 14,
          fontWeight: "normal",
          color: "#ffffff",
          marginTop: 20,
        }}
      >
        {PanelItemShell("My Files")}
        {PanelItemShell("Map Layers")}
        {PanelItemShell("Map Lines")}
        {PanelItemShell("Dashboards")}
        {PanelItemShell("Markers")}
        {PanelItemShell("Photos")}
        {PanelItemShell("Videos")}

        <div
          className="transition-width"
          style={{
            // height: panelExpanded ? 120 : 60,
            height: 120,
            width: panelExpanded ? 170 : 30,
            borderTop: "1px solid #363636",
            borderBottom: "1px solid #363636",
            marginTop: 27,
            marginBottom: 27,
            display: "flex",
            alignItems: "center",
          }}
          title={
            mapInfo?.mapConfig?.view_config.help
              ? mapInfo?.mapConfig?.view_config.help["Export"]
              : "Please enter a description in the Export section of the viewid"
          }
        >
          <div
            className="transition-height"
            style={{
              height: panelExpanded ? 65 : 30,
              // height: 65,
              width: 170,
              border: "2px solid #363636",
              // border: panelExpanded ? "2px solid #363636" : "none",
              borderRadius: 5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: hoverExport ? "#363636" : "transparent",
              cursor: "pointer",
            }}
            onMouseEnter={() => setHoverExport(true)}
            onMouseLeave={() => setHoverExport(false)}
            onClick={() => exportViewAsPdf(map, setExporting)}
          >
            <ExportIcon fill="#fff" />
            <div
              className="transition-width"
              style={{
                width: panelExpanded ? 170 : 0,
                whiteSpace: "nowrap",
                // textOverflow: "clip",
                overflow: "hidden",
                // display: panelExpanded ? "flex" : "none",

                display: "flex",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <div
                className="transition-height"
                style={{ height: panelExpanded ? 21 : 0 }}
              >
                Export Current View
              </div>
            </div>
          </div>
        </div>

        {PanelItemShell("Settings")}
        {PanelItemShell("Return Home")}
        {PanelItemShell(panelExpanded ? "Collapse" : "Expand")}

        <div
          className="transition-width"
          style={{
            position: "absolute",
            bottom: 60,
            width: panelExpanded ? 110 : 42,
            overflow: "hidden",
          }}
        >
          {panelExpanded ? (
            <SidebarExpandedLogo fill="#363636" />
          ) : (
            <SidebarCollapsedLogo fill="#363636" />
          )}
        </div>
      </div>
    </div>
  );
};
