import React, { useContext, useEffect } from "react";

import { FuelScoreDisplay } from "./FuelScoreDisplay";
import { ViewRefs } from "../../../../store/refs";
import { StoreContext } from "../../../../store/store";
import { State } from "../../../../store/storetypes";

interface Props {
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  viewrefs: React.MutableRefObject<ViewRefs>;
  setDashboardSettingsMode: (mode: string | null) => void;
}

export const FuelScore: React.FC<Props> = ({
  mapviewRoot,
  viewrefs,
  setDashboardSettingsMode,
}) => {
  const [store] = useContext(StoreContext) as [State];
  const {
    showlegend,
    buildingAnalysisScore,
    buildingAnalysisRefreshScorecard,
    selectedBuilding,
  } = store;

  if (buildingAnalysisRefreshScorecard) {
    //This is just a refresh trigger
  }

  useEffect(() => {
    if (showlegend.FuelScore) {
      setDashboardSettingsMode("Buildings");
    }
  }, [setDashboardSettingsMode, showlegend.FuelScore]);

  if (showlegend.FuelScore && buildingAnalysisScore) {
    return (
      <>
        {Object.keys(buildingAnalysisScore["heavy"]["building"]).map(
          (keyx: string, i: number) => {
            return (
              <FuelScoreDisplay
                mapviewRoot={mapviewRoot}
                expandedbuildingAnalysisScore={
                  viewrefs.current.expandedbuildingAnalysisScore
                }
                expandedbuildingAnalysisArea={
                  viewrefs.current.expandedbuildingAnalysisArea
                }
                key={i}
                keyId={keyx}
                highlighted={selectedBuilding === keyx}
                viewrefs={viewrefs}
              />
            );
          }
        )}
      </>
    );
  } else {
    return null;
  }
};
