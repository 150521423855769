import React, { useContext } from "react";

import { getFileName } from "../../utils/download";
import Auth from "../../services/auth";
import { FileStyle } from "../../api/AlchemyApiTypes";
import { iconstyles, iconstylesHighlight } from "../../map/icons";
import DocumentIcon from "../../images/svg/other/DocumentIcon";
import { downloadFile } from "../../map/functions";
import { Loader } from "../Loader";
import { StoreContext } from "../../store/store";
import { State } from "../../store/storetypes";
import { ViewRefs } from "../../store/refs";

interface Props {
  auth: Auth;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const Files: React.FC<Props> = ({ auth, viewrefs }) => {
  const [store] = useContext(StoreContext) as [State];
  const { mapInfo } = store;

  if (mapInfo) {
    const { mapConfig, mapStyle } = mapInfo;
    const file_style: FileStyle = mapStyle!.FileStyle;
    const files = mapConfig?.file_urls?.sort() || []; // Hide "special" files on this screen.
    const getStyleName = (filePath: string) => {
      const key = getFileName(filePath); //.split(".")[0];
      return key in file_style ? file_style[key].displayName : key;
    };
    const highlightItem = (item: string, action: string) => {
      const key = "file_" + item;
      const styleKey = getFileName(item);
      let marker = "Summary";

      if (
        mapInfo!.mapConfig &&
        file_style[styleKey] &&
        file_style[styleKey].coordinates?.length === 2
      ) {
        if (file_style[styleKey].marker) {
          const m = file_style[styleKey].marker;
          if (m && m in iconstyles) {
            marker = m;
          }
        }
        viewrefs.current.markerVectorLayers.Summary?.getSource()
          .getFeatures()
          .forEach((feature) => {
            if (feature.getId() === key) {
              feature.setStyle(
                action === "enter"
                  ? iconstylesHighlight[marker]
                  : iconstyles[marker]
              );
              feature.set("System_RenderOrder", action === "enter" ? 2 : 0);
            }
          });
      }
    };

    if (files.length > 0) {
      return (
        <div
          style={{ width: 245, backgroundColor: "transparent", padding: 20 }}
        >
          {files.map((filePath: string, i: number) => (
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                borderTop: "2px solid #000000",
                padding: 5,
                cursor: "pointer",
              }}
              key={i}
              onClick={() => downloadFile(auth, filePath)}
              onMouseEnter={() => highlightItem(filePath, "enter")}
              onMouseLeave={() => highlightItem(filePath, "leave")}
            >
              <div style={{ pointerEvents: "none" }}>
                <DocumentIcon fill="#ffffff" />
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  pointerEvents: "none",
                  marginLeft: 20,
                }}
              >
                {getStyleName(filePath)}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  } else {
    return <Loader name={"Files"} />;
  }
};
