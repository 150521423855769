import VectorLayer from "ol/layer/Vector";
import Layer from "ol/layer/Layer";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import { FeatureLike } from "ol/Feature";
import Source from "ol/source/Source";

export type LayerConfig = XyzLayerConfig;

export interface BuildingAnalysisScore {
  [key: string]: { [key: string]: { [key: string]: number } };
}
export interface BuildingAnalysisArea {
  [key: string]: { [key: string]: number };
}
export const BuildingAnalysisAreaDefaults: BuildingAnalysisArea = {
  parcel: {},
  building: {},
  zone0: {},
  zone1: {},
  zone2: {},
};
export interface VectorLayerConfig {
  key: string;
  displayName?: string;
  filepath: string;
  displayOnLoad?: boolean;
  propertiesOnLoad?: boolean;
  parcelAnalysis?: boolean;
  parcelAnalysisDefault?: boolean;
  cacheKey?: string;
  style: VectorStyleStyle;
  // featureIdentifier?: string;
  properties?: VectorProperties;
  geometry: string;
  heatmapOnly?: boolean;
  heatmapstyle?: VectorHeatmapStyle;
}

export interface XyzLayerConfig {
  displayName: string;
  maxZoom: string;
  type: string;
  url: string | string[];
  displayOnLoad?: boolean;
  legend?: string;
  info?: string;
}

export interface ViewConfig {
  ViewId: string;
  viewName?: string;
  collectionName?: string;
  subheader?: string;
  viewport: {
    center: string[];
    maxZoom: string;
    minZoom: string;
  };
  headers: { [key: string]: string };
  markers?: { [key: string]: string };
  help: { [key: string]: string };
  layers: LayerConfig[];
}

export interface VectorStyle {
  [key: string]: VectorLayerConfig;
}

export interface VectorProperties {
  hideEmpty?: boolean;
  changeName?: { [key: string]: string };
  hideList?: string[];
  includeFilter?: string[];
}

export interface VectorStyleStyle {
  fill: string;
  linecolour: string;
  linewidth: number;
  radius: number;
}

export interface VectorHeatmapStyle {
  blur: number;
  radius: number;
}

export const defaultStyle = {
  fill: "transparent",
  linecolour: "black",
  linewidth: 1,
  radius: 3,
};
export const defaultHeatmapStyle = {
  blur: 15,
  radius: 2,
};

export interface FileStyle {
  [key: string]: {
    classification?: string;
    displayName?: string;
    coordinates?: number[];
    marker?: string;
    key: string;
  };
}
export interface VideoStyle {
  [key: string]: {
    displayName?: string;
    coordinates?: number[];
    marker?: string;
    key: string;
  };
}

export interface FieldPhotoStyle {
  [key: string]: {
    displayName?: string;
    coordinates?: number[];
    marker?: string;
    datetime?: string;
  };
}

export interface MapStyle {
  FileStyle: FileStyle;
  VectorStyle: VectorStyle;
  VideoStyle: VideoStyle;
  Dashboards: DashboardItems;
  FieldPhotoStyle: FieldPhotoStyle;
  HostedClips: HostedClips;
}

export interface ConsolidatedURLs {
  vector_urls: string[];
  video_urls: string[];
  fieldphoto_urls: string[];
}

export interface MapLayer {
  layers: VectorLayer<VectorSource<Geometry>>[];
  config: VectorLayerConfig;
}

export interface VectorShowProperties {
  [key: string]: boolean;
}

export interface UserVectorStyles {
  [key: string]: VectorStyleStyle;
}

export interface UserSettings {
  [key: string]: {
    style: VectorStyleStyle;
    showproperties: boolean;
    visible: boolean;
  };
}

interface Potree {
  classified: boolean;
  shape: string;
  size: string;
  "size type": string;
}
export interface HostedClips {
  [key: string]: {
    displayName?: string;
    coordinates?: number[];
    marker?: string;
    URL: string;
    key: string;
    potree: Potree;
  };
}
export interface BucketItemStyles {
  documents?: FileStyle;
  vectorLayers?: VectorStyle;
  videoClips?: VideoStyle;
  dashboards?: DashboardItems;
  fieldPhotos?: FieldPhotoStyle;
  hostedClips?: HostedClips;
  exif?: EXIF;
}

interface EXIF {
  [key: string]: {
    GPSLatitude?: string;
    GPSLatitudeRef?: string;
    GPSLongitude?: string;
    GPSLongitudeRef?: string;
    DateTime?: string;
    id: string;
  };
}

export interface ViewConfigResponse {
  view_config: ViewConfig;
  file_urls: string[];
  vector_urls: string[];
  video_urls: string[];
  fieldphoto_urls: string[];
  bucket_item_styles?: BucketItemStyles;
  shared: {
    vector_urls: string[];
    video_urls: string[];
    fieldphoto_urls: string[];
    bucket_item_styles?: BucketItemStyles;
  };
  isDefault: boolean;
  client_name: string;
  SQL_Active?: boolean;
}

export interface DashboardItem2 {
  key: string;
  displayName: string;
  dependancy: string;
}

interface SpecificSettings {
  elevationProfile?: { polySelectable: string[] };
  heatmaps?: { itemList: string[] };
}

export interface DashboardItems {
  [key: string]: {
    displayName: string;
    dependancy: string;
    itemList: string[];
    specificSettings?: SpecificSettings;
    chart: { x_label: string; y_label: string };
    legend: string;
    active?: boolean;
  };
}

export interface ViewInfo {
  mapConfig: ViewConfigResponse | null;
  mapStyle: MapStyle | null;
  mapURL: ConsolidatedURLs | null;
}

export interface VideoControl {
  url: string;
  play: boolean;
  type: string;
  name: string;
  key: string;
}

export interface VideoControls {
  [key: string]: VideoControl;
}
export interface FieldPhotoControl {
  url: string;
  filepath: string;
  fieldPhotoObject: FieldPhotoObject;
}

export interface FieldPhotoControls {
  [key: string]: FieldPhotoControl;
}

export interface VideoObject {
  video: Video;
  checked: boolean;
}
export interface FieldPhotoObject {
  fieldphoto: FieldPhoto;
  checked: boolean;
}

export interface Video {
  id: string;
  fileLocation: string;
  displayName?: string;
  mapCoordinates?: number[];
  marker?: string;
  type: string;
  key: string;
}

export interface FieldPhoto {
  id: string;
  fileLocation: string;
  displayName?: string;
  mapCoordinates?: number[];
  marker?: string;
}
export interface Marker {
  mapCoordinates: number[];
  marker: string;
  displayName: string;
  id: string;
}
export interface MarkerLayers {
  [key: string]: VectorLayer<VectorSource<Geometry>> | null;
}
export interface FeatureSelection {
  display: boolean;
  features: Array<{
    properties: Object;
    layer: Layer<Source, any>;
    feature: FeatureLike;
  }>;
}

export interface Storage {
  [key: string]: any;
}

export interface BarchartConfig {
  data: any;
  width: number;
  height: number;
  bars: {
    padding: { inner: number; outer: number };
  };
  svgBackground: string;
  axes: {
    text: { colour: string };
    ticks: { colour: string };
    gridlines: { colour: string };
    fontSize: { x: number; y: number; labelx: number; labely: number };
  };
  layout: {
    margin: { top: number; right: number; bottom: number; left: number };
  };
  labels: { x: string; y: string };
  getColour: (d: number) => number;
  onMouseOver: (d: ChartData, action: string) => void;
}

export interface ElevationConfig {
  elevation: number;
  width: number;
  height: number;
  minElevation: number;
  maxElevation: number;
  fontSize: number;
  textColor: string;
  markerColor: string;
  markerWidth: number;
  textBackground: {
    color: string;
    stroke: string;
    strokeWidth: number;
    borderradius: number;
    height: string;
  };
  layout: {
    margin: { top: number; right: number; bottom: number; left: number };
  };
}
export interface CanopyConfig {
  canopyHeight: number;
  canopycolorscale: string[];
  width: number;
  height: number;
  minElevation: number;
  maxElevation: number;
  fontSize: number;
  textColor: string;
  markerColor: string;
  markerWidth: number;
  markerTextMargin: number;
  textBackground: {
    color: string;
    stroke: string;
    strokeWidth: number;
    borderradius: number;
    height: string;
  };
  suffix: string;
  decimalPlaces: number;
  layout: {
    margin: { top: number; right: number; bottom: number; left: number };
  };
}
interface AreaChartData {
  id: number;
  elevation: number;
}
export interface AreaChartConfig {
  data: AreaChartData[];
  highlightedpoint: number;
  width: number;
  height: number;
  minElevation: number;
  maxElevation: number;
  fontSize: { x: number; y: number };
  colours: {
    chart: string;
    textColor: { x: string; y: string };
    brushbar: { x: string; y: string };
    brushbackground: { x: string; y: string };
  };
  layout: {
    margin: { top: number; right: number; bottom: number; left: number };
  };
  setSelectedSegment: React.Dispatch<
    React.SetStateAction<{
      w: number | undefined;
      e: number | undefined;
    }>
  >;
}

export interface ChartData {
  ARN: string;
  NumberOfFeatures: number;
}

export interface Dashboard {
  displayName: string;
  selected: boolean;
  dependancy: string;
  itemlist: string[];
  specificSettings?: SpecificSettings;
  chart: { x_label: string; y_label: string };
  legend: string;
  active?: boolean;
}
export interface DashboardSettings {
  [key: string]: Dashboard;
}
export interface Loaded {
  [key: string]: boolean;
}
export interface ParcelAnalysisItem {
  displayName: string;
  selected: boolean;
}
export interface ParcelAnalysisSettings {
  [key: string]: ParcelAnalysisItem;
}
export interface SelectedPhoto {
  name?: string;
  src: string;
}
export interface ColorPicker {
  show: Boolean;
  geometry: String;
  style: VectorStyleStyle;
  key: string;
  callback: (
    key: string,
    style: VectorStyleStyle,
    setSavePickerInProgress: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
}

export interface VectorPropertyFilters {
  [key: string]: string[];
}

export interface VectorFiltered {
  [key: string]: VectorPropertyFilters;
}
export interface VectorFilters {
  show: boolean;
  key?: string;
  properties?: VectorProperties;
  cacheKey?: string;
  Filtered?: VectorFiltered;
  layers?: VectorLayer<VectorSource<Geometry>>[];
  isAnd: boolean;
}

export interface Payload {
  [key: string]: {
    visible: boolean;
    style: VectorStyleStyle;
    showproperties: boolean;
  };
}

export interface ElevationProfilePayload {
  [key: string]: {
    lineData: ElevationProfileDataSaved[];
  };
}

interface ExplodedFeature {
  feature: FeatureLike;
  coordinates: number[];
}
export interface ExplodedFeatures {
  features: ExplodedFeature[];
  centerPixel: number[];
  legLength: number;
  spider: VectorLayer<VectorSource<Geometry>>;
}

export interface SubheaderMap {
  [subheader: string]: ViewConfigResponse[];
}
export interface CollectionMap {
  [collection: string]: SubheaderMap;
}

export interface Export {
  exporting: boolean;
  exportCancelFn: (() => void) | null;
}
export interface MapLegend {
  [key: string]: boolean;
}

export interface ElevationProfileData {
  id: number;
  distance: number;
  elevation: number;
  coordinates: number[];
}
export interface ElevationProfileDataSaved {
  id: number;
  distance: string;
  elevation: string;
  coordinates: string[];
}
export interface GetElevationSQL {
  latitude: number;
  longitude: number;
  elevation: number;
}

export interface CanopyData {
  latitude: number;
  longitude: number;
  elevation: number;
  h3index_15: string;
  h3index_14: string;
  h3index_13: string;
  h3index_12: string;
  h3index_11: string;
  h3index_10: string;
}

export interface DivDimensions {
  width: number;
  height: number;
}
export interface DivPosition {
  x: number;
  y: number;
}

export const markerVectorLayersDefaults = {
  PSOM: null,
  Ground: null,
  Summary: null,
  "Field Photos": null,
  LiDAR: null,
  Video3D: null,
};
