import React, { useContext } from "react";
import loadingGIF from "../images/ZZ5H.gif";
import { meterstofeet } from "../map/math";
import { StoreContext } from "../store/store";
import { State } from "../store/storetypes";

export const CoordinateElevDisplay: React.FC = () => {
  const [store] = useContext(StoreContext) as [State];

  const { coordinates, elevation, loadingElevation } = store;

  if (coordinates && coordinates.length > 0) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          backgroundColor: "#000000",
          borderRadius: "4px",
          color: "#ffffff",
          fontSize: 14,
          fontWeight: "normal",
        }}
      >
        {coordinates[1].toFixed(4) +
          ", " +
          coordinates[0].toFixed(4) +
          (elevation > 0
            ? ", " + meterstofeet(elevation).toFixed(1) + "ft"
            : "")}
        {loadingElevation && (
          <img
            alt="loader"
            src={loadingGIF}
            style={{
              marginLeft: 20,
              marginTop: -5,
              width: 20,
              height: 20,
            }}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
};
