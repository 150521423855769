import React from "react";
import { FilterProps } from "./Types";
import FilterIcon from "../../images/svg/other/FilterIcon";

export const FilterForm: React.FC<FilterProps> = ({
  displayViews,
  filter,
  setFilter,
  setFiltered,
}) => {
  if (filter.active) {
    const selectCollection = (item: string) => {
      const newselected = [...filter.selected];
      const index: number = filter.selected.indexOf(item);
      if (index >= 0) {
        newselected.splice(index, 1);
      } else {
        newselected.push(item);
      }
      setFilter({ ...filter, selected: newselected });
    };
    const filterCollection = () => {
      setFiltered([...filter.selected]);
      setFilter({ ...filter, active: false });
    };
    return (
      <div
        style={{
          position: "absolute",
          top: 80,
          right: 298,
          width: 245,
          minHeight: 158,
          backgroundColor: "#363636",
          borderRadius: "5px",
          color: "#ffffff",
          paddingBottom: 15,
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: 600,
            borderBottom: "2px solid #000000",
            paddingTop: 10,
            paddingBottom: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ paddingLeft: 15 }}>Locations</div>
          <div
            style={{ marginRight: 15 }}
            title="Apply the filter, only underlined items are included"
          >
            <FilterIcon
              fill="#ffffff"
              onClick={() => filterCollection()}
              cursor="pointer"
            />
          </div>
        </div>
        <div style={{ fontWeight: "normal" }}>
          {displayViews &&
            Object.keys(displayViews)
              .sort()
              // .sort(defaultLastSort)
              .map((item, i) => {
                return (
                  <div
                    style={{
                      paddingLeft: 15,
                      paddingTop: 10,
                      textDecoration: filter.selected.includes(item)
                        ? "none"
                        : "underline",
                      textUnderlineOffset: 3,
                      textDecorationThickness: 2,
                      cursor: "pointer",
                    }}
                    key={i}
                    onClick={() => selectCollection(item)}
                  >
                    {item}
                  </div>
                );
              })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
