import * as React from "react";
import { SVGProps } from "react";

const DashboardIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={28.826}
    height={20.953}
    viewBox="0 0 28.826 20.953"
    {...props}
  >
    <defs>
      <style>
        {/* {
          ".a_DashboardIconLarge{fill:none;stroke:#fff;stroke-width:2px;}.b_DashboardIconLarge{clip-path:url(#a);}.c_DashboardIconLarge{fill:#fff;}"
        } */}
      </style>
      <clipPath id="a">
        <rect className="a_DashboardIconLarge" width={28.826} height={20.953} />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <g className="b_DashboardIconLarge">
        <path
          className="c_DashboardIconLarge"
          d="M2.09,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H2.09a1,1,0,0,1,1,1A1,1,0,0,1,2.09,1Z"
          transform="translate(1.782 14.413)"
        />
      </g>
    </g>
    <path
      className="c_DashboardIconLarge"
      d="M1.478,2.478a1,1,0,0,1-.707-.293L-.707.707a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0L2.185.771a1,1,0,0,1-.707,1.707Z"
      transform="translate(5.481 5.482)"
    />
    <g transform="translate(0 0)">
      <g className="b_DashboardIconLarge">
        <path
          className="c_DashboardIconLarge"
          d="M0,3.09a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V2.09A1,1,0,0,1,0,3.09Z"
          transform="translate(14.413 1.782)"
        />
      </g>
    </g>
    <path
      className="c_DashboardIconLarge"
      d="M0,2.478a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414L.771-.707a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414L.707,2.185A1,1,0,0,1,0,2.478Z"
      transform="translate(21.867 5.482)"
    />
    <g transform="translate(0 0)">
      <g className="b_DashboardIconLarge">
        <path
          className="c_DashboardIconLarge"
          d="M2.09,1H0A1,1,0,0,1-1,0,1,1,0,0,1,0-1H2.09a1,1,0,0,1,1,1A1,1,0,0,1,2.09,1Z"
          transform="translate(24.955 14.413)"
        />
      </g>
    </g>
    <path
      className="c_DashboardIconLarge"
      d="M0,4.539a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414L2.831-.707a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414L.707,4.246A1,1,0,0,1,0,4.539Z"
      transform="translate(14.413 10.874)"
    />
    <g transform="translate(0 0)">
      <g className="b_DashboardIconLarge">
        <path
          className="c_DashboardIconLarge"
          d="M26.71,20.951H2.614a1,1,0,0,1-.915-.6A14.157,14.157,0,0,1,14.662.5,14.152,14.152,0,0,1,27.626,20.353,1,1,0,0,1,26.71,20.951Zm-23.425-2H26.039A12.158,12.158,0,0,0,14.662,2.5,12.153,12.153,0,0,0,3.285,18.951Z"
          transform="translate(-0.249 -0.249)"
        />
      </g>
    </g>
  </svg>
);

export default DashboardIconLarge;
