export function downloadFromUrl(filepath: string, url: string): void {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = getFileName(filepath);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
}

export function getFileName(fullpath: string): string {
  const path = fullpath.split("/");
  const filename = path[path.length - 1];
  return filename;
}
