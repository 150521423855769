import Attribution from "ol/control/Attribution";
import BaseLayer from "ol/layer/Base";
import ViewsService from "../services/views";
import Auth from "../services/auth";
import { Map, View } from "ol";
import { constructFromConfig, fetchElevation } from "../api/AlchemyAnalytics";
import { OlMapSettings } from "../map/types";
import { defaults as defaultInteractions, DragZoom } from "ol/interaction";
import { fromLonLat } from "ol/proj";
import Layer from "ol/layer/Layer";
import { FeatureLike } from "ol/Feature";
import Point from "ol/geom/Point";
import {
  ViewConfigResponse,
  FeatureSelection,
  ViewInfo,
  ExplodedFeatures,
  MapLegend,
} from "../api/AlchemyApiTypes";
import {
  downloadFile,
  consolidateURLS,
  configureMapStyles,
  savePolySelectCoordinates,
  drawInitialProfileLine,
  getClosestElevation,
  getHighlightedSegment,
  getHighlightedPoint,
  drawPotentialElevationPoint,
  removePotentialElevationPoint,
  drawNewElevationPoint,
  deleteElevationPoint,
} from "../map/functions";

import { displayOverlapping, resetOverlapping } from "../helpers/spiderifyjs";
import { transform as oltransform } from "ol/proj";
import Source from "ol/source/Source";
import { ViewRefs } from "../store/refs";

interface SelectedProperties {
  properties: any;
  layer: Layer<Source, any>;
  feature: FeatureLike;
}

const getElevation = (
  auth: Auth,
  latitude: string,
  longitude: string,
  setElevation: (val: number) => void,
  setLoadingElevation: (val: boolean) => void
) => {
  setLoadingElevation(true);
  const getAsyncElevation = async () => {
    const ret = await fetchElevation(auth, latitude, longitude);
    const elevation = getClosestElevation(ret, [+latitude, +longitude]);
    setElevation(elevation);
    setLoadingElevation(false);
  };
  return getAsyncElevation();
};

const saveLineCoordinates = (
  profileLineCoordinates: number[][],
  setProfileLineReady: (val: boolean) => void,
  coordinate: any
) => {
  if (
    profileLineCoordinates[0][0] === 0 &&
    profileLineCoordinates[0][1] === 0
  ) {
    profileLineCoordinates[0] = coordinate;
  } else if (
    profileLineCoordinates[1][0] === 0 &&
    profileLineCoordinates[1][1] === 0
  ) {
    profileLineCoordinates[1] = coordinate;
    //draw line
    setProfileLineReady(true);
  }
};

const onMapClick = (
  evt: any,
  map: Map,
  getExplodedFeatures: () => ExplodedFeatures | null,
  setExplodedFeatures: (p: ExplodedFeatures | null) => void,
  fireVideoFromMarker: (id: string) => void,
  openFieldPhotoFromMarker: (id: string) => void,
  auth: Auth,
  setElevation: (val: number) => void,
  setLoadingElevation: (val: boolean) => void,
  setProfileLineReady: (val: boolean) => void,
  setPolySelectReady: (val: boolean) => void,
  setDashboardSettingsMode: (mode: string | null) => void,
  // getElevationSettingsValue: () => number[] | undefined,
  setActivateElevationProfileData: (val: number) => void,
  setCanopyCoordinate: (val: number[]) => void,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  const pixel = map.getEventPixel(evt.originalEvent);
  const hit = map.hasFeatureAtPixel(pixel);

  let coordinate = oltransform(evt.coordinate, "EPSG:3857", "EPSG:4326");
  if (viewrefs.current.dashboardSettingsMode) {
    if (viewrefs.current.dashboardSettingsMode === "Insert") {
      removePotentialElevationPoint(viewrefs);
      drawNewElevationPoint(setActivateElevationProfileData, auth, viewrefs);
      setDashboardSettingsMode(null);
    }
    if (viewrefs.current.dashboardSettingsMode === "Delete") {
      removePotentialElevationPoint(viewrefs);
      deleteElevationPoint(setActivateElevationProfileData, viewrefs);
      setDashboardSettingsMode(null);
    }
    if (viewrefs.current.dashboardSettingsMode === "Slope") {
      // setSlope(Math.random());
      // setDashboardSettingsMode(null);
    }
    if (viewrefs.current.dashboardSettingsMode === "Canopy") {
      setCanopyCoordinate(evt.coordinate);
    }
  } else {
    if (viewrefs.current.drawProfileLine) {
      saveLineCoordinates(
        viewrefs.current.profileLineCoordinates,
        setProfileLineReady,
        coordinate
      );
      return;
    }
    if (viewrefs.current.drawPolySelect) {
      //test for first point
      if (hit) {
        let selectedFeatures: FeatureLike[] = [];
        map.forEachFeatureAtPixel(evt.pixel, function (feature) {
          if (feature.getGeometry() instanceof Point) {
            selectedFeatures.push(feature);
          }
        });
        if (selectedFeatures.length > 0) {
          const feature = selectedFeatures[0];
          const clickedClass = feature.get("Class");
          if (clickedClass === "Poly Select Point") {
            const firstPoint = feature.get("System_FirstPoint");
            if (firstPoint === "true") {
              viewrefs.current.polyFirstPoint = true;
            }
          }
        }
      }
      savePolySelectCoordinates(map, viewrefs, setPolySelectReady, coordinate);
      return;
    }
    getElevation(
      auth,
      coordinate[1].toString(),
      coordinate[0].toString(),
      setElevation,
      setLoadingElevation
    );

    if (hit) {
      let selectedFeatures: FeatureLike[] = [];
      const ExplodedFeatures = getExplodedFeatures();
      map.forEachFeatureAtPixel(evt.pixel, function (feature) {
        if (feature.getGeometry() instanceof Point) {
          selectedFeatures.push(feature);
        }
      });
      if (selectedFeatures.length === 1) {
        const feature = selectedFeatures[0];

        const clickedId = feature?.getId();
        const clickedStringId = clickedId?.toString();
        const TypeId = clickedStringId ? clickedStringId.split("_")[0] : "";

        if (TypeId === "video" && clickedStringId) {
          fireVideoFromMarker(clickedStringId);
        }
        if (TypeId === "fieldphoto" && clickedStringId) {
          openFieldPhotoFromMarker(clickedStringId);
        }
        if (TypeId === "file" && clickedStringId) {
          const filepath = clickedStringId.split("file_")[1];
          downloadFile(auth, filepath);
        }
      } else {
        if (ExplodedFeatures == null) {
          displayOverlapping(pixel, map, setExplodedFeatures, selectedFeatures);
        }
      }
    }
  }
};

const onPointerMove = (
  evt: any,
  map: Map,
  setFeatureProperties: (val: FeatureSelection) => void,
  setHoveredFieldPhoto: (val: string[] | null) => void,
  getExplodedFeatures: () => ExplodedFeatures | null,
  setExplodedFeatures: (p: ExplodedFeatures | null) => void,
  setCoordinates: (val: number[]) => void,
  setSelectedBuilding: (val: string) => void,
  setElevation: (val: number) => void,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  let coordinate = oltransform(evt.coordinate, "EPSG:3857", "EPSG:4326");
  setCoordinates(coordinate);
  setElevation(0);

  if (
    viewrefs.current.drawProfileLine &&
    viewrefs.current.profileLineCoordinates[0][0] !== 0 &&
    viewrefs.current.profileLineCoordinates[1][0] === 0
  ) {
    drawInitialProfileLine(
      viewrefs.current.profileLineCoordinates,
      coordinate,
      map,
      viewrefs
    );
    return;
  }

  const pixel = map.getEventPixel(evt.originalEvent);
  const hit = map.hasFeatureAtPixel(pixel);
  document.body.style.cursor = hit ? "pointer" : "auto";
  const ExplodedFeatures = getExplodedFeatures();

  if (ExplodedFeatures) {
    resetOverlapping(pixel, map, ExplodedFeatures, setExplodedFeatures);
  }

  let selectedFeatures: SelectedProperties[] = [];
  map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
    let properties = { ...feature.getProperties() };
    delete properties.geometry;
    selectedFeatures.push({ properties, layer, feature });
  });

  if (selectedFeatures.length > 0) {
    const elevationprofilelineHover = selectedFeatures.filter((item) => {
      return (
        "Name" in item.properties &&
        item.properties.Name === "Elevation Profile Line"
      );
    });
    if (viewrefs.current.dashboardSettingsMode === "Insert") {
      if (elevationprofilelineHover.length === 1) {
        //find nearest point
        const HighlightedSegment: number[] | undefined = getHighlightedSegment(
          evt.coordinate,
          viewrefs
        );
        viewrefs.current.elevationSettingsValue = HighlightedSegment;
        removePotentialElevationPoint(viewrefs);
        drawPotentialElevationPoint(HighlightedSegment, viewrefs);
      }
    }
    if (viewrefs.current.dashboardSettingsMode === "Delete") {
      if (elevationprofilelineHover.length === 1) {
        //find nearest point
        const HighlightedPoint: number | undefined = getHighlightedPoint(
          evt.coordinate,
          viewrefs
        );
        viewrefs.current.elevationSettingsValue = [HighlightedPoint];
        removePotentialElevationPoint(viewrefs);
        drawPotentialElevationPoint([HighlightedPoint], viewrefs);
      }
    }
    if (viewrefs.current.dashboardSettingsMode === "Buildings") {
      const buildings: string[] = selectedFeatures
        .filter((item) => {
          return (
            "Class" in item.properties && item.properties.Class === "building"
          );
        })
        .map((item) => {
          return "System_Id" in item.properties && item.properties["System_Id"]
            ? item.properties["System_Id"]
            : undefined;
        });
      if (buildings.length > 0) {
        setSelectedBuilding(buildings[0]);
      }
    }

    setFeatureProperties({ display: true, features: selectedFeatures });
    setHoveredFieldPhoto(
      selectedFeatures
        .filter((item) => {
          return (
            "Class" in item.properties &&
            item.properties.Class === "Field Photos"
          );
        })
        .map((item) => {
          return "Name" in item.properties && item.properties.Name
            ? item.properties.Name
            : "";
        })
    );
  } else {
    setFeatureProperties({ display: false, features: [] });
    setHoveredFieldPhoto([]);
  }
};

const createMap = (mapConfig: OlMapSettings): Map => {
  const { viewSettings, layers } = mapConfig;
  const transformedCenter = fromLonLat(viewSettings.center.reverse());
  // const transformedCenter = fromLonLat([28.000256, -25.8834432]);
  const map = new Map({
    view: new View({
      center: transformedCenter,
      zoom: viewSettings.minZoom,
      minZoom: viewSettings.minZoom,
      maxZoom: viewSettings.maxZoom,
      constrainRotation: true,
    }),
    interactions: defaultInteractions().extend([new DragZoom()]),
    layers: layers.reduce((allLayers, layerConfig) => {
      allLayers.unshift(...layerConfig.layers);
      return allLayers;
    }, [] as BaseLayer[]),
    controls: [new Attribution({ collapsible: false })],
    // controls: [new Zoom(), new Attribution({ collapsible: false })],
  });
  return map;
};

export const viewChange = (
  mapId: string,
  viewId: string,
  viewsService: ViewsService,
  fireVideoFromMarker: (id: string) => void,
  openFieldPhotoFromMarker: (id: string) => void,
  setFeatureProperties: (val: FeatureSelection) => void,
  setMapSettings: (mapSettings: OlMapSettings | null) => void,
  setMap: (map: Map | null) => void,
  setMapConfig: (mapconfig: ViewConfigResponse | null) => void,
  setMapInfo: (mapinfo: ViewInfo | null) => void,
  auth: Auth,
  setHoveredFieldPhoto: (val: string[] | null) => void,
  setExplodedFeatures: (p: ExplodedFeatures | null) => void,
  getExplodedFeatures: () => ExplodedFeatures | null,
  setCoordinates: (val: number[]) => void,
  setElevation: (val: number) => void,
  setLoadingElevation: (val: boolean) => void,
  setShowLegend: (val: MapLegend) => void,
  setProfileLineReady: (val: boolean) => void,
  setPolySelectReady: (val: boolean) => void,
  setDashboardSettingsMode: (mode: string | null) => void,
  setActivateElevationProfileData: (val: number) => void,
  setZoomFactor: (zoom: number) => void,
  setCanopyCoordinate: (val: number[]) => void,
  setSelectedBuilding: (val: string) => void,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  async function getMapconfig(
    viewId: string,
    viewsService: ViewsService,
    setMapConfig: (mapconfig: ViewConfigResponse | null) => void,
    setMapInfo: (mapinfo: ViewInfo | null) => void
  ) {
    let response = viewId
      ? await viewsService.getViewById(viewId)
      : await viewsService.getDefaultView();

    setMapConfig(response);
    const mapInfo = {
      mapConfig: response,
      mapStyle: configureMapStyles(response),
      mapURL: consolidateURLS(response),
    };
    setMapInfo(mapInfo);

    if (response == null) {
      // TODO: Handle lack of views.
      console.log("no views");
      return;
    } else {
      const mapSettingsx = constructFromConfig(response.view_config);

      const initiallegends = response.view_config.layers
        .filter((item: any) => item.displayOnLoad && item.legend)
        .map((item: any) => {
          return item.legend;
        })
        .reduce((obj: any, item: any) => {
          if (item) {
            return {
              ...obj,
              [item]: true,
            };
          } else {
            return null;
          }
        }, {});

      const showlegend: MapLegend = {
        Fuel_Light: false,
        Fuel_Medium: false,
        Fuel_Heavy: false,
        Elevation: false,
        FuelHealth: false,
        Canopy: false,
        H3heatmap: false,
        FuelScore: false,
      };

      const newshowlegend: MapLegend = { ...showlegend, ...initiallegends };
      setShowLegend(newshowlegend);
      const mapx = createMap(mapSettingsx);
      mapx.on("click", function (evt) {
        onMapClick(
          evt,
          mapx,
          getExplodedFeatures,
          setExplodedFeatures,
          fireVideoFromMarker,
          openFieldPhotoFromMarker,
          auth,
          setElevation,
          setLoadingElevation,
          setProfileLineReady,
          setPolySelectReady,
          setDashboardSettingsMode,
          setActivateElevationProfileData,
          setCanopyCoordinate,
          viewrefs
        );
      });
      mapx.on("pointermove", (evt) => {
        onPointerMove(
          evt,
          mapx,
          setFeatureProperties,
          setHoveredFieldPhoto,
          getExplodedFeatures,
          setExplodedFeatures,
          setCoordinates,
          setSelectedBuilding,
          setElevation,
          viewrefs
        );
      });
      mapx.on("moveend", function (e) {
        var newZoom = mapx.getView().getZoom();
        if (newZoom) {
          setZoomFactor(newZoom);
        }
      });
      setMapSettings(mapSettingsx);
      setMap(mapx);
      viewrefs.current.mapValid = true;
      viewrefs.current.runpaneleffects = true;
    }
  }
  getMapconfig(viewId, viewsService, setMapConfig, setMapInfo);
};
