import * as React from "react";
import { SVGProps } from "react";

const DashboardLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={133.378}
    height={30.315}
    viewBox="0 0 133.378 30.315"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <path
      className="a"
      d="M10.311.59l7.428,14.584H14.965L13.116,11.52H4.646L2.8,15.174H0L7.447.59ZM5.782,9.244h6.194l-3.087-6.1Z"
      transform="translate(0 -0.367)"
    />
    <path
      className="a"
      d="M55.9.59h2.6V12.9H69.148v2.265H55.9Z"
      transform="translate(-34.802 -0.367)"
    />
    <path
      className="a"
      d="M104.474,0c3.718,0,6.5,1.706,7.616,4.529l-2.155,1.178a5.475,5.475,0,0,0-5.442-3.4c-3.431,0-5.952,2.114-5.952,5.22s2.521,5.22,5.952,5.22a5.448,5.448,0,0,0,5.442-3.4l2.155,1.178c-1.132,2.8-3.9,4.506-7.616,4.506-4.907,0-8.594-3.185-8.594-7.515S99.579,0,104.474,0Z"
      transform="translate(-59.693)"
    />
    <path
      className="a"
      d="M163.632.59V15.174h-2.6V8.8h-9.915v6.378h-2.6V.59h2.6V6.523h9.915V.59Z"
      transform="translate(-92.465 -0.367)"
    />
    <path
      className="a"
      d="M215.517.59V2.855H203.979V6.512h10.379V8.776H203.979V12.9h11.538v2.265h-14.1V.59Z"
      transform="translate(-125.399 -0.367)"
    />
    <path
      className="a"
      d="M257.964,15.174,252.336,2.945V15.174H249.8V.59h4.02l5.242,11.417L264.3.59h3.94V15.174H265.7V2.945l-5.639,12.229Z"
      transform="translate(-155.519 -0.367)"
    />
    <path
      className="a"
      d="M321.548.59h3.167L317.32,8.837v6.337h-2.6V8.837L307.33.59h3.208l5.5,6.194Z"
      transform="translate(-191.336 -0.367)"
    />
    <path
      className="a"
      d="M5.846,59.71l3.812,7.658H7.726l-.811-1.646H2.71l-.823,1.646H0L3.831,59.71ZM3.435,64.258H6.2l-1.378-2.8Z"
      transform="translate(0 -37.174)"
    />
    <path
      className="a"
      d="M55.157,67.368H53.591l-4.782-5.382v5.382H47.08V59.71h1.8l4.529,5.133V59.71h1.729Z"
      transform="translate(-29.311 -37.174)"
    />
    <path
      className="a"
      d="M95.827,59.7l3.808,7.658H97.7L96.9,65.71h-4.2l-.8,1.646H90l3.831-7.665Zm-2.412,4.548h2.763l-1.378-2.8Z"
      transform="translate(-56.032 -37.162)"
    />
    <path
      className="a"
      d="M137,59.71h1.781v6.148h5.178v1.51H137Z"
      transform="translate(-85.293 -37.174)"
    />
    <path
      className="a"
      d="M177.852,59.71h2.091l-3.812,4.484v3.174H174.35V64.194l-3.82-4.484h2.144l2.593,3.019Z"
      transform="translate(-106.168 -37.174)"
    />
    <path
      className="a"
      d="M220.854,61.27V67.4h-1.785V61.27H215.74V59.76h8.432v1.51Z"
      transform="translate(-134.314 -37.205)"
    />
    <path
      className="a"
      d="M262.061,67.368H260.28V59.71h1.781Z"
      transform="translate(-162.044 -37.174)"
    />
    <path
      className="a"
      d="M291.779,59.4a4.089,4.089,0,0,1,4.118,2.446l-1.578.755a2.525,2.525,0,0,0-2.54-1.657,2.422,2.422,0,1,0,0,4.8,2.525,2.525,0,0,0,2.529-1.653l1.578.755a4.1,4.1,0,0,1-4.118,2.446c-2.642,0-4.548-1.664-4.548-3.952S289.152,59.4,291.779,59.4Z"
      transform="translate(-178.816 -36.981)"
    />
    <path
      className="a"
      d="M331.472,64.4a4.408,4.408,0,0,0,3.37,1.408c1.185,0,2.019-.321,2.019-.876,0-.642-.63-.694-2.68-.917-1.823-.2-3.3-.577-3.3-2.114s1.464-2.5,3.684-2.5a5.436,5.436,0,0,1,3.989,1.419L337.424,62a3.956,3.956,0,0,0-2.985-1.132c-1.208,0-1.815.377-1.815.823s.532.608,2.423.823c2.008.223,3.65.532,3.65,2.208s-1.687,2.563-3.937,2.563c-1.763,0-3.65-.63-4.42-1.612Z"
      transform="translate(-205.662 -36.981)"
    />
    <path
      className="a"
      d="M344.841,36.394v.94h-.272v-.94h-.51V36.16h1.287v.234Z"
      transform="translate(-214.203 -22.512)"
    />
    <path
      className="a"
      d="M348.935,37.334l-.377-.9v.9h-.268V36.16h.411l.377.838.377-.838h.4v1.174H349.6v-.9l-.4.9Z"
      transform="translate(-216.837 -22.512)"
    />
  </svg>
);

export default DashboardLogo;
