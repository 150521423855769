import React, { useState, useEffect, useContext } from "react";
import close from "../../images/close.png";
import loadingGIF from "../../images/ZZ5H.gif";
import {
  DivPosition,
  FieldPhotoControl,
  Storage,
  SelectedPhoto,
} from "../../api/AlchemyApiTypes";
import { Rnd } from "react-rnd";
import { fetchDataStorage } from "../../api/AlchemyAnalytics";
import Auth from "../../services/auth";
import { highlightItem } from "./functions";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { ViewRefs } from "../../store/refs";

interface Props {
  fieldphoto: FieldPhotoControl;
  id: string;
  setPlay: (id: string) => void;
  index: number;
  name?: string;
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  storage: Storage;
  auth: Auth;
  isHovered: boolean;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const FieldPhotoPlayer: React.FC<Props> = ({
  fieldphoto,
  id,
  setPlay,
  index,
  name,
  mapviewRoot,
  storage,
  auth,
  isHovered,
  viewrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  if (store) {
    //use store somehow
  }

  const exitPlayer = () => {
    setPlay(id);
  };

  const [source, setSource] = useState<SelectedPhoto | null>(null);
  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });
  const [disabledrag, setdisabledrag] = useState<boolean>(true);

  useEffect(() => {
    let mounted = true;
    const getSource = async () => {
      const src = await fetchDataStorage(
        fieldphoto.filepath,
        storage,
        auth,
        "fieldphoto_" + fieldphoto.filepath,
        "fieldphotourl"
      );
      if (mounted) {
        setSource({ name: name, src: src.toString() });
      }
    };
    getSource();
    return () => {
      mounted = false;
    };
  }, [fieldphoto, setSource, storage, auth, name]);

  const getDefaultPosition = (DivDimensions: {
    height: number;
    width: number;
    marginLeft: number;
    marginRight: number;
    marginTop: number;
  }) => {
    if (position.x === 0 && position.y === 0) {
      if (mapviewRoot && mapviewRoot.current) {
        const box = mapviewRoot.current.getBoundingClientRect();
        const maxInRow = Math.floor(
          (box.width - DivDimensions.marginLeft - DivDimensions.marginRight) /
            DivDimensions.width
        );
        return {
          x:
            (index % maxInRow) * DivDimensions.width + DivDimensions.marginLeft,
          y:
            (Math.ceil((index + 1) / maxInRow) - 1) * DivDimensions.height +
            DivDimensions.marginTop,
        };
      }
    } else return position;
  };

  if (fieldphoto) {
    const DivDimensions = {
      height: 100,
      width: 100,
      marginLeft: 225,
      marginRight: 90,
      marginTop: 0,
    };

    return (
      <Rnd
        position={getDefaultPosition(DivDimensions)}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        style={{ zIndex: 9999 }}
        disableDragging={disabledrag}
      >
        <div
          style={{
            position: "absolute",
            display: "block",
            backgroundColor: "white",
            height: DivDimensions.height + "px",
            width: DivDimensions.width + "px",
            zIndex: 999,
            border: "1px solid black",
          }}
        >
          <img
            alt="close"
            src={close}
            style={{
              width: 20,
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
            onClick={() => exitPlayer()}
          ></img>
          <div
            style={{
              paddingLeft: "5px",
              fontSize: "10px",
              height: "20px",
              backgroundColor: isHovered ? "Cyan" : undefined,
            }}
            onMouseEnter={() => setdisabledrag(false)}
            onMouseLeave={() => setdisabledrag(true)}
          >
            {name}
          </div>

          <img
            alt="jpg"
            src={source ? source.src : loadingGIF}
            style={{
              width: source
                ? DivDimensions.width - 1
                : DivDimensions.width - 1 - 60,
              height: source
                ? DivDimensions.height - 20 - 1
                : DivDimensions.height - 20 - 1 - 40,
              marginTop: source ? 0 : 20,
              marginLeft: source ? 0 : 30,
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch({
                type: actionTypes.SET_SELECTED_FIELD_PHOTO,
                payload: source,
              });
            }}
            onMouseEnter={() =>
              highlightItem(
                fieldphoto.fieldPhotoObject,
                "enter",
                viewrefs.current.markerVectorLayers
              )
            }
            onMouseLeave={() =>
              highlightItem(
                fieldphoto.fieldPhotoObject,
                "leave",
                viewrefs.current.markerVectorLayers
              )
            }
          />
        </div>
      </Rnd>
    );
  } else {
    return null;
  }
};
