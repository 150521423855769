import { MapLayer, Loaded } from "../../../api/AlchemyApiTypes";
import loadingGIF from "../../../images/ZZ5H.gif";

export const resolutionFromZoom = (zoom: number) => {
  if (zoom === 1) {
    return "h3index_08";
  }
  if (zoom > 1 && zoom <= 1.5) {
    return "h3index_09";
  }
  if (zoom > 1.5 && zoom <= 2.25) {
    return "h3index_10";
  }
  if (zoom > 2.25 && zoom <= 3.375) {
    return "h3index_11";
  }
  if (zoom > 3.375 && zoom <= 5.0625) {
    return "h3index_12";
  }
  if (zoom > 5.0625 && zoom <= 7.59375) {
    return "h3index_13";
  }
  if (zoom > 7.59375) {
    return "h3index_14";
  }
  return "h3index_08";
};

export const gifOrInput = (
  item: MapLayer,
  i: number,
  loadedVectorlayers: Loaded | null,
  onSelect: (item: MapLayer) => void,
  isChecked: (item: MapLayer) => boolean
) => {
  if (loadedVectorlayers && loadedVectorlayers[item.config.key]) {
    return (
      <div
        style={{
          marginTop: "0",
          width: "40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          style={{
            marginLeft: "3px",
            cursor: "pointer",
            accentColor: "#ffffff",
          }}
          type="radio"
          onChange={() => onSelect(item)}
          checked={isChecked(item)}
        />
      </div>
    );
  } else {
    return (
      <div style={{ marginTop: "0", width: "40px" }}>
        <img
          alt="loader"
          src={loadingGIF}
          style={{
            width: 20,
            height: 20,
            marginLeft: 0,
          }}
        />
      </div>
    );
  }
};
