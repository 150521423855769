import * as React from "react";
import { SVGProps } from "react";

const ReturnHomeIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.016}
    height={16.574}
    viewBox="0 0 15.016 16.574"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a"
        d="M11.508,2.5a.5.5,0,0,1,.307.105l7.008,5.451a.5.5,0,0,1,.193.395v8.566a2.06,2.06,0,0,1-2.057,2.057H6.057A2.06,2.06,0,0,1,4,17.016V8.451a.5.5,0,0,1,.193-.395L11.2,2.605A.5.5,0,0,1,11.508,2.5Zm6.508,6.2L11.508,3.633,5,8.7v8.321a1.059,1.059,0,0,0,1.057,1.057h10.9a1.059,1.059,0,0,0,1.057-1.057Z"
        transform="translate(-4.5 -3)"
      />
      <path
        className="a"
        d="M18.172,26.287a.5.5,0,0,1-.5-.5V18.5H14v7.287a.5.5,0,1,1-1,0V18a.5.5,0,0,1,.5-.5h4.672a.5.5,0,0,1,.5.5v7.787A.5.5,0,0,1,18.172,26.287Z"
        transform="translate(-8.828 -10.213)"
      />
    </g>
  </svg>
);

export default ReturnHomeIconSmall;
