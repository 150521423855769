import * as React from "react";
import { SVGProps } from "react";

const VideosIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.574}
    height={16.574}
    viewBox="0 0 16.574 16.574"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a"
        d="M10.787,2.5A8.287,8.287,0,1,1,2.5,10.787,8.3,8.3,0,0,1,10.787,2.5Zm0,15.574A7.287,7.287,0,1,0,3.5,10.787,7.3,7.3,0,0,0,10.787,18.074Z"
        transform="translate(-3 -3)"
      />
      <path
        className="a"
        d="M15,11.5a.5.5,0,0,1,.277.084L19.949,14.7a.5.5,0,0,1,0,.832l-4.672,3.115a.5.5,0,0,1-.777-.416V12a.5.5,0,0,1,.5-.5Zm3.771,3.615L15.5,12.934V17.3Z"
        transform="translate(-8.771 -7.328)"
      />
    </g>
  </svg>
);

export default VideosIconSmall;
