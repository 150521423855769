import React, { useContext } from "react";
import { SVGImages } from "./SVGImages";
import { StoreContext } from "../../store/store";
import { State } from "../../store/storetypes";

interface Props {
  hoverstate: { active: boolean; box: DOMRect | null; header: string };
}

export const Hover: React.FC<Props> = ({ hoverstate }) => {
  const [store] = useContext(StoreContext) as [State];

  const { panelExpanded, mapInfo } = store;

  if (hoverstate.active) {
    const box = hoverstate.box;
    const SvgImage = SVGImages(hoverstate.header, true);
    if (box && SvgImage) {
      return (
        <>
          <div
            style={{
              position: "absolute",
              left: panelExpanded ? 211 : 61,
              top: box.top,
              zIndex: 99999,
              width: 0,
              height: 0,
              borderTop: "14px solid transparent",
              borderBottom: "14px solid transparent",
              borderRight: "14px solid #363636",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              left: panelExpanded ? 225 : 75,
              top: box.top,
              zIndex: 99999,
              minHeight: 158,
              width: 245,
              backgroundColor: "#363636",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "left",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 800,
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6,
            }}
          >
            <div style={{ marginTop: 15, marginBottom: 10 }}>
              <SvgImage fill="#ffffff" />
            </div>
            <div>{hoverstate.header}</div>
            <div
              style={{
                fontSize: 12,
                fontWeight: "normal",
                marginTop: 10,
                marginBottom: 25,
                paddingLeft: 17,
                paddingRight: 17,
                textAlign: "center",
              }}
            >
              {mapInfo?.mapConfig?.view_config.help
                ? mapInfo?.mapConfig?.view_config.help[hoverstate.header]
                : "No help has been defined for this view"}
            </div>
          </div>
        </>
      );
    } else return null;
  } else return null;
};
