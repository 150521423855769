import React from "react";
import { ButtonComponentMemo } from "../../ButtonComponentMemo";

const buttons: string[] = ["New Line", "Private", "Public"];
const buttonheight: string = "20px";
const buttonwidth: string = 100 / buttons.length + "%";

interface Props {
  tabindex: number;
  setTabindex: React.Dispatch<React.SetStateAction<number>>;
}

export const TabBar: React.FC<Props> = ({ tabindex, setTabindex }) => {
  return (
    <div style={{ width: "100%", display: "flex" }}>
      {buttons.map((item, i) => {
        return (
          <ButtonComponentMemo
            key={i}
            onClick={() => setTabindex(i)}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            style={{
              width: buttonwidth,
              height: buttonheight,
              borderRadius: "0px",
              marginLeft: "0px",
              backgroundColor: "#000000",
              border:
                i === tabindex ? "2px solid #757575" : "2px solid #ffffff",
              color: "#ffffff",
            }}
            name={item}
          />
        );
      })}
    </div>
  );
};
