import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../store/store";
import { useHistory } from "react-router-dom";
import { State } from "../../store/storetypes";
import { ViewRefs } from "../../store/refs";
// import ArrowDownIcon from "../../images/svg/other/ArrowDownIcon";
// import ArrowUpIcon from "../../images/svg/other/ArrowUpIcon";
import LocationDownIcon from "../../images/svg/other/LocationDownIcon";
import LocationUpIcon from "../../images/svg/other/LocationUpIcon";

interface Props {
  viewrefs: React.MutableRefObject<ViewRefs>;
}

interface ViewItems {
  [collection: string]: { ViewId: string; viewName: string }[];
}

export const ViewSelection: React.FC<Props> = ({ viewrefs }) => {
  const [store] = useContext(StoreContext) as [State];
  const history = useHistory();
  const redirect = (ViewId: string) => {
    history.push(`/views/${ViewId}`);
  };

  const { displayViews, mapInfo, mapSettings } = store;
  const [viewItems, setViewItems] = useState<ViewItems>({});
  const [showlist, setShowlist] = useState<boolean>(false);
  const [mouseoverid, setMouseoverid] = useState<string>();

  useEffect(() => {
    const newViewItems: ViewItems = {};
    if (displayViews) {
      Object.entries(displayViews).forEach((item: any) => {
        newViewItems[item[0]] = [];
        Object.entries(item[1]).forEach((subitem: any) => {
          subitem[1].forEach((row: any) => {
            newViewItems[item[0]].push({
              ViewId: row["view_config"]["ViewId"],
              viewName: row["view_config"]["viewName"],
            });
          });
        });
      });
      setViewItems(newViewItems);
    }
  }, [displayViews, viewrefs, mapInfo, mapSettings]);

  const toggleLocationIcon = () => {
    return showlist ? (
      <LocationUpIcon
        fill="#ffffff"
        onClick={() => setShowlist(false)}
        cursor="pointer"
      />
    ) : (
      <LocationDownIcon
        fill="#ffffff"
        onClick={() => setShowlist(true)}
        cursor="pointer"
      />
    );
  };

  return (
    <>
      <div
        style={{
          display: "block",
          height: 111,
          width: 225,
          backgroundColor: "#000000",
          borderBottom: "1px solid #363636",
        }}
      >
        <div
          style={{
            fontSize: 16,
            fontWeight: 800,
            color: "#ffffff",
            paddingTop: 19,
            marginLeft: 28,
            marginBottom: 10,
            marginRight: 28,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {mapInfo?.mapConfig?.view_config.collectionName}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "start",
          }}
        >
          <div
            style={{
              fontSize: 12,
              fontWeight: "normal",
              color: "#ffffff",
              // marginTop: 19,
              marginLeft: 28,
              width: 150,
            }}
          >
            {mapInfo?.mapConfig?.view_config.viewName}
          </div>
          {toggleLocationIcon()}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          display: showlist ? "block" : "none",
          width: 225,
          backgroundColor: "#363636",
          borderBottom: "1px solid #363636",
        }}
      >
        {Object.entries(viewItems)
          .sort()
          .map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  borderBottom: "2px solid #000000",
                  marginLeft: 28,
                  marginRight: 28,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 800,
                    color: "#ffffff",
                  }}
                >
                  {item[0]}
                </div>
                <div>
                  {item[1].map(
                    (row: { ViewId: string; viewName: string }, i) => {
                      return (
                        <div
                          key={"r_" + i}
                          style={{
                            fontSize: 12,
                            fontWeight: "normal",
                            color: "#ffffff",
                            paddingTop: 5,
                            width: 150,
                            textDecoration:
                              mouseoverid === `${item[0]}_ ${i}`
                                ? "underline"
                                : "none",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowlist(false);
                            redirect(row.ViewId);
                          }}
                          onMouseOver={() => setMouseoverid(`${item[0]}_ ${i}`)}
                        >
                          {row.viewName}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            );
          })}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 10,
            height: 20,
          }}
        >
          {/* <div style={{ marginRight: 4 }}>
            <ArrowUpIcon fill="#757575" />
          </div>
          <div style={{ marginLeft: 4 }}>
            <ArrowDownIcon fill="#ffffff" />
          </div> */}
        </div>
      </div>
    </>
  );
};
