import React, { useState } from "react";
import { ButtonComponent } from "../../ButtonComponent";
import info from "../../../images/profileInfoInverse.png";
import polyinfo from "../../../images/profilepolyInfoInverse.png";
import { ViewRefs } from "../../../store/refs";

interface Props {
  intervalft: number;
  setIntervalft: React.Dispatch<React.SetStateAction<number>>;
  setDisableDrag: React.Dispatch<React.SetStateAction<boolean>>;
  topMargin: number;
  onDrawClick: () => void;
  onPolySelectClick: () => void;
  polySelectable: string[] | undefined;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const DrawNewProfile: React.FC<Props> = ({
  intervalft,
  setIntervalft,
  setDisableDrag,
  topMargin,
  onDrawClick,
  onPolySelectClick,
  polySelectable,
  viewrefs,
}) => {
  const [focused, setFocused] = useState<String>("Draw");

  return (
    <>
      <div style={{ display: "flex", marginTop: topMargin }}>
        <ButtonComponent
          name="Draw Line"
          style={{
            width: "80px",
            height: "30px",
            backgroundColor: "#000000",
            color: "#ffffff",
            fontSize: "14px",
            borderRadius: "2px",
            border: viewrefs.current.drawProfileLine
              ? "Solid 2px red"
              : "Solid 2px #ffffff",
          }}
          onClick={() => onDrawClick()}
          hoverStyle={{
            backgroundColor: "#757575",
            border: "2px solid #757575",
          }}
          onMouseOver={() => setFocused("Draw")}
        />
        <span style={{ marginLeft: 10 }}>Interval</span>
        <input
          className="signin-input"
          style={{
            width: 60,
            height: 30,
            marginLeft: 10,
            marginRight: 5,
            cursor: "pointer",
          }}
          value={intervalft}
          type="number"
          onChange={(e) => setIntervalft(+e.target.value)}
          onMouseEnter={() => setDisableDrag(true)}
          onMouseLeave={() => setDisableDrag(false)}
        ></input>
        <span>ft</span>
        {polySelectable && polySelectable.length > 0 && (
          <ButtonComponent
            name="Create Line from Selected Points"
            style={{
              width: "240px",
              height: "30px",
              marginLeft: "30px",
              backgroundColor: "#000000",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
              border: viewrefs.current.drawPolySelect
                ? "Solid 2px red"
                : "Solid 2px #ffffff",
            }}
            onClick={() => onPolySelectClick()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            onMouseOver={() => setFocused("Poly")}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <img
          src={focused === "Draw" ? info : polyinfo}
          alt="Info"
          style={{ width: 587, height: 331, marginTop: 20 }}
        />
      </div>
    </>
  );
};
