import React, { useState, useContext } from "react";
import loadingGIF from "../../images/ZZ5H.gif";
import saveIcon from "../../images/saveInverted.png";
import saveHover from "../../images/saveHover.png";
import { ButtonComponent } from "../ButtonComponent";
import { ImageComponent } from "../ImageComponent";
import { StoreContext } from "../../store/store";
import { State } from "../../store/storetypes";

interface Props {
  restoreDefaultSettings: (
    setrestoreDefaultsInProgress: React.Dispatch<React.SetStateAction<boolean>>,
    setdefaultSelected: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  restoreUserSettings: (
    setrestoreSelectionsInProgress: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    setdefaultSelected: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  saveSettings: () => void;
  saveInProgress: boolean;
}

export const VectorLayerHeader: React.FC<Props> = ({
  restoreDefaultSettings,
  restoreUserSettings,
  saveSettings,
  saveInProgress,
}) => {
  const [store] = useContext(StoreContext) as [State];
  const { mapConfig } = store;

  const [defaultSelected, setdefaultSelected] = useState<boolean>(false);
  const [restoreDefaultsInProgress, setrestoreDefaultsInProgress] =
    useState<boolean>(false);
  const [restoreSelectionsInProgress, setrestoreSelectionsInProgress] =
    useState<boolean>(false);

  const restoreDefaults = () => {
    setrestoreDefaultsInProgress(true);
    setTimeout(() => {
      restoreDefaultSettings(setrestoreDefaultsInProgress, setdefaultSelected);
    }, 10);
  };
  const restoreSelections = () => {
    setrestoreSelectionsInProgress(true);
    setTimeout(() => {
      restoreUserSettings(setrestoreSelectionsInProgress, setdefaultSelected);
    }, 10);
  };

  const showLoader = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60px",
          height: "20px",
        }}
      >
        <img
          alt="Loading"
          src={loadingGIF}
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      </div>
    );
  };

  const defaultOrLoading = (restoreDefaultsInProgress: boolean) => {
    if (restoreDefaultsInProgress) {
      return <>{showLoader()}</>;
    } else {
      return (
        <ButtonComponent
          name="Defaults"
          hoverStyle={{
            backgroundColor: "#757575",
            border: "2px solid #757575",
          }}
          title="Restore to the system default settings"
          style={{
            width: "60px",
            marginLeft: "0px",
            height: "20px",
            border: "2px solid #FFFFFF",
            borderRadius: "2px",
            backgroundColor: "transparent",
            color: "#ffffff",
          }}
          onClick={restoreDefaults}
        />
      );
    }
  };

  const selectionsOrLoading = (restoreSelectionsInProgress: boolean) => {
    if (restoreSelectionsInProgress) {
      return <>{showLoader()}</>;
    } else {
      return (
        <ButtonComponent
          name="Selections"
          hoverStyle={{
            backgroundColor: "#757575",
            border: "2px solid #757575",
          }}
          title="Restore to your last user defined saved settings"
          style={{
            width: "60px",
            marginLeft: "0px",
            height: "20px",
            border: "2px solid #FFFFFF",
            borderRadius: "2px",
            backgroundColor: "transparent",
            color: "#ffffff",
          }}
          onClick={restoreSelections}
        />
      );
    }
  };
  const defaultSetup = (
    defaultSelected: boolean,
    restoreDefaultsInProgress: boolean,
    restoreSelectionsInProgress: boolean
  ) => {
    if (defaultSelected) {
      return (
        <div
          style={{
            display: "flex",
            marginLeft: "0x",
            justifyContent: "space-evenly",
            width: "200px",
            alignItems: "center",
          }}
        >
          {defaultOrLoading(restoreDefaultsInProgress)}
          {selectionsOrLoading(restoreSelectionsInProgress)}
          <ButtonComponent
            name="Cancel"
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            style={{
              width: "40px",
              marginLeft: "0px",
              height: "20px",
              border: "2px solid #FFFFFF",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
            onClick={() => setdefaultSelected(false)}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            width: "200px",
            alignItems: "center",
          }}
        >
          <div>{mapConfig?.view_config.headers["vectorLayers"]}</div>
          <ButtonComponent
            name="Restore"
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            title="Restore to the system default or user saved settings"
            style={{
              width: "80px",
              height: "20px",
              border: "2px solid #FFFFFF",
              borderRadius: "2px",
              backgroundColor: "#000000",
              color: "#ffffff",
            }}
            onClick={() => setdefaultSelected(true)}
          />
        </div>
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "225px",
        borderBottom: "2px solid #757575",
        paddingBottom: 10,
        marginBottom: 10,
      }}
    >
      {defaultSetup(
        defaultSelected,
        restoreDefaultsInProgress,
        restoreSelectionsInProgress
      )}

      <ImageComponent
        src={saveInProgress ? loadingGIF : saveIcon}
        srcHover={saveInProgress ? loadingGIF : saveHover}
        title="Save the currently displayed settings as your user defined settings"
        style={{
          width: "25px",
          height: "25px",
        }}
        onClick={saveSettings}
      />
    </div>
  );
};
