import * as React from "react";
import { SVGProps } from "react";

const LocationDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.574}
    height={16.574}
    viewBox="0 0 16.574 16.574"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(-5430.479 -3517.549)">
      <g transform="translate(5427.859 3509.544)">
        <path
          className="a"
          d="M10.9,21.9a.5.5,0,0,1-.354-.146l-3.4-3.4a.5.5,0,1,1,.707-.707L10.9,20.7l3.051-3.051a.5.5,0,1,1,.707.707l-3.4,3.4A.5.5,0,0,1,10.9,21.9Z"
          transform="translate(0 -2.949)"
        />
      </g>
      <g transform="translate(5430.979 3518.049)">
        <path
          className="a"
          d="M10.787,2.5A8.287,8.287,0,1,1,2.5,10.787,8.3,8.3,0,0,1,10.787,2.5Zm0,15.574A7.287,7.287,0,1,0,3.5,10.787,7.3,7.3,0,0,0,10.787,18.074Z"
          transform="translate(-3 -3)"
        />
      </g>
    </g>
  </svg>
);

export default LocationDownIcon;
