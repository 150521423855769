import * as React from "react";
import { SVGProps } from "react";

const ExportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.144}
    height={15.026}
    viewBox="0 0 18.144 15.026"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a"
        d="M15.115,29.115a.5.5,0,0,1-.354-.146l-3.115-3.115a.5.5,0,1,1,.707-.707l2.761,2.761,2.761-2.761a.5.5,0,1,1,.707.707l-3.115,3.115A.5.5,0,0,1,15.115,29.115Z"
        transform="translate(-6.542 -14.589)"
      />
      <path
        className="a"
        d="M18,25.508a.5.5,0,0,1-.5-.5V18a.5.5,0,0,1,1,0v7.008A.5.5,0,0,1,18,25.508Z"
        transform="translate(-9.427 -10.983)"
      />
      <path
        className="a"
        d="M16.974,16.742a.5.5,0,0,1-.288-.909,3.393,3.393,0,0,0-1.955-6.169H13.75a.5.5,0,0,1-.484-.376,5.729,5.729,0,1,0-9.84,5.221.5.5,0,1,1-.749.663,6.729,6.729,0,1,1,11.45-6.508h.6a4.393,4.393,0,0,1,2.53,7.987A.5.5,0,0,1,16.974,16.742Z"
        transform="translate(-1.486 -4.482)"
      />
    </g>
  </svg>
);

export default ExportIcon;
