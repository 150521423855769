import FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const Heading = [
  {
    id: "Id",
    distance: "Distance (ft)",
    elevation: "Elevation (ft)",
    latitude: "Latitude",
    longitude: "Longitude",
  },
];

export const exportToCSV = (csvData: any, fileName: string, wscols: any) => {
  const ws = XLSX.utils.json_to_sheet(Heading, {
    header: ["id", "distance", "elevation", "latitude", "longitude"],
    skipHeader: true,
    //   origin: 0 //ok
  });
  ws["!cols"] = wscols;
  XLSX.utils.sheet_add_json(ws, csvData, {
    header: ["id", "distance", "elevation", "latitude", "longitude"],
    skipHeader: true,
    origin: -1, //ok
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
