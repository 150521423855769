import { State, Action, actionTypes } from "./storetypes";

export const init_global: State = {
  activatedashboardSettingsMode: 0,
  activateElevationProfileData: 0,
  buildingAnalysisRefreshScorecard: 0,
  buildingAnalysisScore: null,
  canopyCoordinateChanged: 0,
  clearDashboard: "",
  clickPanel: { active: false, box: null, header: "" },
  coordinates: [],
  dashboardSettings: {},
  dashboardSettingModeState: null,
  defaultLidarDimensions: { width: 0, height: 0 },
  defaultLidarPosition: { x: 0, y: 0 },
  displayViews: null,
  elevation: 0,
  elevationProfileData: [],
  elevationProfileDataLoaded: false,
  exporting: {
    exporting: false,
    exportCancelFn: null,
  },
  featureProperties: {
    display: false,
    features: [],
  },
  fieldphotoitems: undefined,
  filter: {
    active: false,
    selected: [],
  },
  filtered: [],
  forceUpdate: 0,
  h3heatmaplegendinfo: { active: false, max: 0 },
  heatmapZoomRatio: 1,
  hoveredFieldPhoto: null,
  hoverPanel: { active: false, box: null, header: "" },
  leftPanelTransition: false,
  loadedVectorlayers: null,
  loadingElevation: false,
  // map: null,
  mapConfig: null,
  mapInfo: null,
  mapSettings: null,
  panelExpanded: true,
  polySelectReady: false,
  profileLineReady: false,
  selectedBuilding: "0",
  selectedCanopyPoint: 0,
  selectedElevationPoints: [],
  selectedFieldPhoto: null,
  selectedPointCount: 0,
  showlegend: {
    Fuel_Light: false,
    Fuel_Medium: false,
    Fuel_Heavy: false,
    Elevation: false,
    FuelHealth: false,
    Canopy: false,
    H3heatmap: false,
    FuelScore: false,
  },
  slope: 0,
  slopeChanged: 0,
  sqlActive: false,
  sqlDependantDashboards: [
    "ElevationProfile",
    "CanopyAnalysis",
    "BuildingAnalysis",
  ],
  supportEmail: { show: false, message: "" },
  userVectorStyles: {},
  vectorFilters: {
    show: false,
    isAnd: true,
  },
  vectorlayers: null,
  vectorShowProperties: {},
  videoitems: undefined,
};

export function global_reducer(state: State, action: Action) {
  switch (action.type) {
    case actionTypes.SET_ACTIVATE_DASHBOARD_SETTINGS_MODE: {
      return {
        ...state,
        activatedashboardSettingsMode: action.payload,
      };
    }
    case actionTypes.SET_ACTIVATE_ELEVATION_PROFILE_DATA: {
      return {
        ...state,
        activateElevationProfileData: action.payload,
      };
    }
    case actionTypes.SET_BUILDINGANALYSIS_SCORE: {
      return {
        ...state,
        buildingAnalysisScore: action.payload,
      };
    }
    case actionTypes.SET_BUILDINGANALYSIS_REFRESH_SCORECARD: {
      return {
        ...state,
        buildingAnalysisRefreshScorecard: action.payload,
      };
    }
    case actionTypes.SET_CANOPY_COORDINATES_CHANGED: {
      return {
        ...state,
        canopyCoordinateChanged: action.payload,
      };
    }
    case actionTypes.SET_CLEAR_DASHBOARD: {
      return {
        ...state,
        clearDashboard: action.payload,
      };
    }
    case actionTypes.SET_CLICKPANEL: {
      return {
        ...state,
        clickPanel: action.payload,
      };
    }
    case actionTypes.SET_COORDINATES: {
      return {
        ...state,
        coordinates: action.payload,
      };
    }
    case actionTypes.SET_DASHBOARD_SETTINGS: {
      return {
        ...state,
        dashboardSettings: action.payload,
      };
    }
    case actionTypes.SET_DASHBOARD_SETTINGS_MODE_STATE: {
      return {
        ...state,
        dashboardSettingModeState: action.payload,
      };
    }
    case actionTypes.SET_DEFAULT_LIDAR_DIMENSIONS: {
      return {
        ...state,
        defaultLidarDimensions: action.payload,
      };
    }
    case actionTypes.SET_DEFAULT_LIDAR_POSITION: {
      return {
        ...state,
        defaultLidarPosition: action.payload,
      };
    }
    case actionTypes.SET_DISPLAYVIEWS: {
      return {
        ...state,
        displayViews: action.payload,
      };
    }
    case actionTypes.SET_ELEVATION: {
      return {
        ...state,
        elevation: action.payload,
      };
    }
    case actionTypes.SET_ELEVATION_PROFILE_DATA: {
      return {
        ...state,
        elevationProfileData: action.payload,
      };
    }
    case actionTypes.SET_ELEVATION_PROFILE_DATA_LOADED: {
      return {
        ...state,
        elevationProfileDataLoaded: action.payload,
      };
    }
    case actionTypes.SET_EXPORT: {
      return {
        ...state,
        exporting: action.payload,
      };
    }
    case actionTypes.SET_FEATURE_PROPERTIES: {
      return {
        ...state,
        featureProperties: action.payload,
      };
    }
    case actionTypes.SET_FIELD_PHOTO_ITEMS: {
      return {
        ...state,
        fieldphotoitems: action.payload,
      };
    }
    case actionTypes.SET_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case actionTypes.SET_FILTERED: {
      return {
        ...state,
        filtered: action.payload,
      };
    }
    case actionTypes.SET_FORCE_UPDATE: {
      return {
        ...state,
        forceUpdate: action.payload,
      };
    }
    case actionTypes.SET_H3HEATMAP_LEGEND_INFO: {
      return {
        ...state,
        h3heatmaplegendinfo: action.payload,
      };
    }
    case actionTypes.SET_HEATMAP_ZOOM_RATIO: {
      return {
        ...state,
        heatmapZoomRatio: action.payload,
      };
    }
    case actionTypes.SET_HOVERED_FIELD_PHOTO: {
      return {
        ...state,
        hoveredFieldPhoto: action.payload,
      };
    }
    case actionTypes.SET_HOVERPANEL: {
      return {
        ...state,
        hoverPanel: action.payload,
      };
    }
    case actionTypes.SET_LEFT_PANEL_TRANSITION: {
      return {
        ...state,
        leftPanelTransition: action.payload,
      };
    }
    case actionTypes.SET_LOADED_VECTOR_LAYERS: {
      return {
        ...state,
        loadedVectorlayers: action.payload,
      };
    }
    case actionTypes.SET_LOADING_ELEVATION: {
      return {
        ...state,
        loadingElevation: action.payload,
      };
    }
    case actionTypes.SET_MAP: {
      return {
        ...state,
        map: action.payload,
      };
    }
    case actionTypes.SET_MAP_CONFIG: {
      return {
        ...state,
        mapConfig: action.payload,
      };
    }
    case actionTypes.SET_MAP_INFO: {
      return {
        ...state,
        mapInfo: action.payload,
      };
    }
    case actionTypes.SET_MAP_SETTINGS: {
      return {
        ...state,
        mapSettings: action.payload,
      };
    }
    case actionTypes.SET_PANEL_EXPANDED: {
      return {
        ...state,
        panelExpanded: action.payload,
      };
    }
    case actionTypes.SET_POLY_SELECT_READY: {
      return {
        ...state,
        polySelectReady: action.payload,
      };
    }
    case actionTypes.SET_PROFILE_LINE_READY: {
      return {
        ...state,
        profileLineReady: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_BUILDING: {
      return {
        ...state,
        selectedBuilding: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_CANOPY_POINT: {
      return {
        ...state,
        selectedCanopyPoint: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_ELEVATION_POINTS: {
      return {
        ...state,
        selectedElevationPoints: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_FIELD_PHOTO: {
      return {
        ...state,
        selectedFieldPhoto: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_POINT_COUNT: {
      return {
        ...state,
        selectedPointCount: action.payload,
      };
    }
    case actionTypes.SET_SHOW_LEGEND: {
      return {
        ...state,
        showlegend: action.payload,
      };
    }
    case actionTypes.SET_SLOPE: {
      return {
        ...state,
        slope: action.payload,
      };
    }
    case actionTypes.SET_SLOPE_CHANGED: {
      return {
        ...state,
        slopeChanged: action.payload,
      };
    }
    case actionTypes.SET_SQL_ACTIVE: {
      return {
        ...state,
        sqlActive: action.payload,
      };
    }
    case actionTypes.SET_SUPPORT_EMAIL: {
      return {
        ...state,
        supportEmail: action.payload,
      };
    }
    case actionTypes.SET_USER_VECTOR_STYLES: {
      return {
        ...state,
        userVectorStyles: action.payload,
      };
    }
    case actionTypes.SET_VECTOR_FILTERS: {
      return {
        ...state,
        vectorFilters: action.payload,
      };
    }
    case actionTypes.SET_VECTOR_LAYERS: {
      return {
        ...state,
        vectorlayers: action.payload,
      };
    }
    case actionTypes.SET_VECTOR_SHOW_PROPERTIES: {
      return {
        ...state,
        vectorShowProperties: action.payload,
      };
    }
    case actionTypes.SET_VIDEO_ITEMS: {
      return {
        ...state,
        videoitems: action.payload,
      };
    }

    default:
      return state;
  }
}
