import React, { useState, useContext } from "react";
import ReactPlayer from "react-player";
import close from "../../images/close.png";
import {
  VideoControl,
  DivDimensions,
  DivPosition,
} from "../../api/AlchemyApiTypes";
import Auth from "../../services/auth";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { LidarViewer } from "../lidar/LidarViewer";
import { Rnd } from "react-rnd";
import { getDefaultPosition } from "./functions";

interface Props {
  video: VideoControl;
  id: string;
  setPlay: (id: string) => void;
  index: number;
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  count: number;
  auth: Auth;
}

export const VideoPlayer: React.FC<Props> = ({
  video,
  id,
  setPlay,
  index,
  mapviewRoot,
  count,
  auth,
}) => {
  const exitPlayer = () => {
    setPlay(id);
  };

  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { mapInfo, defaultLidarDimensions, defaultLidarPosition } = store;

  const potreeSettings = mapInfo?.mapStyle?.HostedClips[video.key].potree;
  const headerHeight: number = 25;
  const paddingLeft: number = 5;

  const defaultDimensions: DivDimensions =
    video && video.type !== "LiDAR"
      ? { height: 407, width: 652 }
      : { height: 647, width: 1012 };

  const [disabledrag, setdisabledrag] = useState<boolean>(true);

  const [size, setSize] = useState<DivDimensions>(
    video && video.type === "LiDAR" && defaultLidarDimensions.height !== 0
      ? defaultLidarDimensions
      : defaultDimensions
  );

  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });

  if (video && video.url) {
    const selectType = (type: string) => {
      if (type !== "LiDAR") {
        return (
          <ReactPlayer
            url={video.url}
            controls={true}
            playing={true}
            width={size.width - 12}
            height={size.height - headerHeight - 17}
            volume={0}
            muted={true}
          />
        );
      } else {
        return (
          <LidarViewer
            width={size.width - paddingLeft * 2}
            height={size.height - headerHeight - 14}
            url={video.url}
            potreeSettings={potreeSettings}
            name={video.name}
          />
        );
      }
    };

    return (
      <Rnd
        style={{ zIndex: 9999 }}
        size={size}
        position={
          video && video.type === "LiDAR"
            ? getDefaultPosition(
                defaultLidarDimensions.height > 0
                  ? defaultLidarDimensions.height
                  : defaultDimensions.height,
                defaultLidarDimensions.width > 0
                  ? defaultLidarDimensions.width
                  : defaultDimensions.width,
                mapviewRoot,
                defaultLidarPosition,
                count,
                index
              )
            : getDefaultPosition(
                size.height,
                size.width,
                mapviewRoot,
                position,
                count,
                index
              )
        }
        minWidth={video && video.type === "LiDAR" ? 360 : 50}
        minHeight={100}
        onDragStop={(e, d) => {
          if (video && video.type === "LiDAR") {
            dispatch({
              type: actionTypes.SET_DEFAULT_LIDAR_POSITION,
              payload: { x: d.x, y: d.y },
            });
          }
          setPosition({ x: d.x, y: d.y });
        }}
        onResize={(e, direction, ref, delta, position) => {
          if (video && video.type === "LiDAR") {
            dispatch({
              type: actionTypes.SET_DEFAULT_LIDAR_DIMENSIONS,
              payload: {
                width: ref.offsetWidth,
                height: ref.offsetHeight,
              },
            });
          }
          setSize({
            width: ref.offsetWidth,
            height: ref.offsetHeight,
          });
          if (video && video.type === "LiDAR") {
            dispatch({
              type: actionTypes.SET_DEFAULT_LIDAR_POSITION,
              payload: position,
            });
          }
          setPosition(position);
        }}
        disableDragging={disabledrag}
      >
        <div
          style={{
            position: "absolute",
            display: "block",
            backgroundColor: "white",
            height: size.height + "px",
            width: size.width + "px",
            zIndex: 999,
            border: "1px solid black",
            paddingLeft: paddingLeft,
          }}
        >
          <div
            style={{ height: headerHeight }}
            onMouseEnter={() => setdisabledrag(false)}
            onMouseLeave={() => setdisabledrag(true)}
          >
            {video.name}
            <img
              alt="close"
              src={close}
              style={{
                width: 20,
                position: "absolute",
                top: 0,
                right: 0,
                cursor: "pointer",
              }}
              onClick={() => exitPlayer()}
            ></img>
          </div>
          <div
            style={{
              position: "relative",
            }}
          >
            {selectType(video.type)}
          </div>
        </div>
      </Rnd>
    );
  } else {
    return null;
  }
};
