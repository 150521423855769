import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import Geometry from "ol/geom/Geometry";
import { Map } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { geoToH3 } from "h3-js";
import geojson2h3 from "geojson2h3";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ViewRefs } from "../../../store/refs";
import {
  polygon as tpolygon,
  booleanContains,
  union,
  buffer,
} from "@turf/turf";
import {
  getPolygonStyle,
  getFuelStyle,
  getBuildingHighlightStyle,
} from "./styles";
import { transform as oltransform } from "ol/proj";
import {
  FilteredCoordinates,
  Fuel,
  H3Indexes,
  H3IndexesDefaults,
  ZoneObjects,
  ZoneObjectDefaults,
} from "./types";
import {
  BuildingAnalysisScore,
  BuildingAnalysisArea,
} from "../../../api/AlchemyApiTypes";
import { sqmeterstosqfeet } from "../../../map/math";

const getExtendedCoordinates = (type: string, coordinates: number[][][]) => {
  if (type === "zone0") {
    const distance = 0.001524; //5 feet in kilometers
    const originalpoly = tpolygon(coordinates);
    const extendedpoly = buffer(originalpoly, distance);
    return extendedpoly.geometry.coordinates;
  } else if (type === "zone1") {
    const distance = 0.009144; //30 feet in kilometers
    const originalpoly = tpolygon(coordinates);
    const extendedpoly = buffer(originalpoly, distance);
    return extendedpoly.geometry.coordinates;
  } else if (type === "zone2") {
    const distance = 0.03048; //100 feet in kilometers
    const originalpoly = tpolygon(coordinates);
    const extendedpoly = buffer(originalpoly, distance);
    return extendedpoly.geometry.coordinates;
  } else return coordinates;
};
export const drawPolygonOnMap = (
  coordinates: number[][][],
  map: Map | null,
  viewrefs: React.MutableRefObject<ViewRefs>,
  type: string,
  i: number
) => {
  const polystyle = getPolygonStyle(type);

  coordinates = getExtendedCoordinates(type, coordinates);

  let polygonFeature = new Feature(
    new Polygon(coordinates).transform("EPSG:4326", "EPSG:3857")
  );
  polygonFeature.set("Class", type);
  polygonFeature.set("System_Id", i.toString());
  const area_m = (polygonFeature.getGeometry() as Polygon).getArea();
  viewrefs.current.buildingAnalysisArea[type][i] = sqmeterstosqfeet(area_m);
  polygonFeature.set("Area", sqmeterstosqfeet(area_m).toFixed(0) + " ft\u00B2");

  if (viewrefs.current.buildingAnalysisLayer[type]) {
    let source = viewrefs.current.buildingAnalysisLayer[type].getSource();
    source.addFeature(polygonFeature);
  } else {
    let source = new VectorSource({
      features: [polygonFeature],
    });

    viewrefs.current.buildingAnalysisLayer[type] = new VectorLayer({
      source: source,
      style: polystyle,
    });
    setZindexForlayer(viewrefs, type);

    if (map) {
      map.addLayer(viewrefs.current.buildingAnalysisLayer[type]);
    }
  }
  return area_m;
};
export const drawCombinedPolygons = (
  buildings: number[][][][],
  map: Map | null,
  viewrefs: React.MutableRefObject<ViewRefs>,
  type: string
) => {
  let polyfeatures: Feature<Geometry>[] = [];
  buildings.forEach((item: any) => {
    const buildingobj = JSON.parse(item.coordinates);
    const coordinates = getExtendedCoordinates(type, buildingobj);
    let polygonFeature = new Feature(
      new Polygon(coordinates).transform("EPSG:4326", "EPSG:3857")
    );
    polyfeatures.push(polygonFeature);
  });

  let source = new VectorSource({
    features: polyfeatures,
  });

  var result: any;
  const formatx = new GeoJSON();
  source.forEachFeature(function (feature) {
    var turfPolygon: any = formatx.writeFeatureObject(feature);
    if (!result) {
      result = turfPolygon;
    } else {
      result = union(result, turfPolygon);
    }
  });

  var results = [];
  var olResult = formatx.readFeature(result);
  if (olResult.getGeometry().getType() === "MultiPolygon") {
    olResult
      .getGeometry()
      .getPolygons()
      .forEach(function (polygon: any) {
        const newFeature = new Feature(polygon);
        newFeature.set("Class", type);
        // newFeature.set("Area", (newFeature.getGeometry() as Polygon).getArea());
        results.push(newFeature);
      });
  } else {
    olResult.set("Class", type);
    // olResult.set("Area", (olResult.getGeometry() as Polygon).getArea());
    results.push(olResult);
  }
  let mergedsource = new VectorSource({
    features: results,
  });

  viewrefs.current.buildingAnalysisLayer[type] = new VectorLayer({
    source: mergedsource,
    style: getPolygonStyle(type),
  });
  setZindexForlayer(viewrefs, type);

  if (map) {
    map.addLayer(viewrefs.current.buildingAnalysisLayer[type]);
  }
};
export const getBox = (polygon: number[][][]) => {
  const allLat = polygon.map((item: number[][]) => {
    return item.map((coord: number[]) => {
      return coord[1];
    });
  });
  const allLon = polygon.map((item: number[][]) => {
    return item.map((coord: number[]) => {
      return coord[0];
    });
  });
  const minLon = Math.min(...allLon[0]);
  const maxLon = Math.max(...allLon[0]);
  const minLat = Math.min(...allLat[0]);
  const maxLat = Math.max(...allLat[0]);
  return { minLat, minLon, maxLat, maxLon };
};
export const getBoxFromZone = (
  viewrefs: React.MutableRefObject<ViewRefs>,
  zone: string
) => {
  //getcoords
  const polygon: number[][][] = [];
  const Source = viewrefs.current.buildingAnalysisLayer[zone].getSource();
  Source.forEachFeature(function (feature) {
    const OuterPolygon: Polygon = feature.getGeometry() as Polygon;
    const OuterCoordinates = OuterPolygon.getCoordinates()[0];
    polygon.push(OuterCoordinates);
  });
  //transform
  const transformedpoly = polygon.map((item: number[][]) => {
    return item.map((coord: number[]) => {
      return oltransform(coord, "EPSG:3857", "EPSG:4326");
    });
  });

  return getBox(transformedpoly);
};
export const zoomToFeatures = (
  buildings: number[][][][],
  map: Map | null,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  const type = buildings.length > 0 ? "zone2" : "parcel";
  var layerExtent = viewrefs.current.buildingAnalysisLayer[type]
    .getSource()
    .getExtent();

  if (layerExtent && map) {
    setTimeout(() => {
      map.getView().fit(layerExtent, { duration: 1000 });
    }, 40);
  }
};
export const addInnerPolygons = (
  viewrefs: React.MutableRefObject<ViewRefs>,
  outer: string,
  inner: string
) => {
  // console.log("inner", inner, "outer", outer);
  if (viewrefs.current.buildingAnalysisLayer["building"]) {
    const formatx = new GeoJSON();
    const Outersource =
      viewrefs.current.buildingAnalysisLayer[outer].getSource();
    const Innersource =
      viewrefs.current.buildingAnalysisLayer[inner].getSource();
    let polygonFeatures: Feature<Geometry>[] = [];
    let newOuterCoordinates: number[][][] = [];

    Outersource.forEachFeature(function (ofeature) {
      let i: string[] = [];
      const turfOuterPolygon: any = formatx.writeFeatureObject(ofeature);
      const OuterPolygon: Polygon = ofeature.getGeometry() as Polygon;
      const OuterCoordinates = OuterPolygon.getCoordinates()[0];
      newOuterCoordinates.push(OuterCoordinates);
      Innersource.forEachFeature(function (ifeature) {
        const turfInnerPolygon: any = formatx.writeFeatureObject(ifeature);
        if (booleanContains(turfOuterPolygon, turfInnerPolygon)) {
          //Add inner coordinates to outer
          const InnerPolygon: Polygon = ifeature.getGeometry() as Polygon;
          i.push(ifeature.get("System_Id"));
          const InnerCoordinates = InnerPolygon.getCoordinates();
          newOuterCoordinates.push(InnerCoordinates[0]);
        }
      });
      const key = i.length === 0 ? "0" : i.join("|");
      let polygonFeature = new Feature(new Polygon(newOuterCoordinates));
      polygonFeature.set("Class", outer);
      polygonFeature.set("System_Id", key.toString());
      const area_m = (polygonFeature.getGeometry() as Polygon).getArea();
      viewrefs.current.buildingAnalysisArea[outer][key] =
        sqmeterstosqfeet(area_m);
      polygonFeature.set(
        "Area",
        sqmeterstosqfeet(area_m).toFixed(0) + " ft\u00B2"
      );
      polygonFeatures.push(polygonFeature);
      newOuterCoordinates = [];
    });
    Outersource.clear();
    Outersource.addFeatures(polygonFeatures);
  }
};

const filterbyZone = (
  filteredCoordinates: React.MutableRefObject<FilteredCoordinates>,
  type: string,
  viewrefs: React.MutableRefObject<ViewRefs>,
  zone: string,
  coordinates: number[][]
) => {
  const source = viewrefs.current.buildingAnalysisLayer[zone].getSource();
  source.forEachFeature(function (feature) {
    let ret: number[][] = [];
    const Polygon: Polygon = feature.getGeometry() as Polygon;
    const id: string = feature.get("System_Id");
    coordinates.forEach((coord: number[]) => {
      if (Polygon.intersectsCoordinate(coord)) {
        ret.push(coord);
      }
    });
    filteredCoordinates.current[type][zone][id] = ret;
  });
};

export const isBuildingInParcel = (
  viewrefs: React.MutableRefObject<ViewRefs>,
  centroid: any
) => {
  let ret = false;
  const coord = oltransform(centroid.reverse(), "EPSG:4326", "EPSG:3857");
  const source = viewrefs.current.buildingAnalysisLayer["parcel"].getSource();
  source.forEachFeature(function (feature) {
    const Polygonx: Polygon = feature.getGeometry() as Polygon;
    const coordinates = Polygonx.getCoordinates();
    //use only outer ring
    const outerPolygonOnly = new Polygon([coordinates[0]]);
    if (outerPolygonOnly.intersectsCoordinate(coord)) {
      ret = true;
    }
  });
  return ret;
};
export const addHexFueltoMap = (
  viewrefs: React.MutableRefObject<ViewRefs>,
  fuel: Fuel[],
  type: string,
  filteredCoordinates: React.MutableRefObject<FilteredCoordinates>,
  h3indexes: React.MutableRefObject<H3Indexes>
) => {
  const fuelstyle = getFuelStyle(type);

  const convertedCoords: number[][] = fuel.map((item) => {
    const coord = [+item.longitude, +item.latitude];
    return oltransform(coord, "EPSG:4326", "EPSG:3857");
  });

  let h3allindexes: string[] = [];
  const zones = ["building", "zone0", "zone1", "zone2"];
  zones.forEach((zone: string) => {
    filterbyZone(filteredCoordinates, type, viewrefs, zone, convertedCoords);
    Object.entries(filteredCoordinates.current[type][zone]).forEach(
      (item: any) => {
        h3indexes.current[type][zone][item[0]] = [];
        item[1].forEach((citem: any) => {
          const coord = oltransform(citem, "EPSG:3857", "EPSG:4326");
          const h3Index = geoToH3(coord[1], coord[0], 15);
          h3indexes.current[type][zone][item[0]].push(h3Index);
          h3allindexes.push(h3Index);
        });
      }
    );
  });
  h3allindexes = Array.from(new Set(h3allindexes));

  const h3shapes = geojson2h3.h3SetToFeatureCollection(h3allindexes);

  let source = new VectorSource({
    features: new GeoJSON({
      featureProjection: "EPSG:3857",
    }).readFeatures(h3shapes),
  });

  viewrefs.current.buildingAnalysisLayer[type] = new VectorLayer({
    source: source,
    style: fuelstyle,
  });
  setZindexForlayer(viewrefs, type);
};

export const filterFuelbyCheckedZones = (
  viewrefs: React.MutableRefObject<ViewRefs>,
  zoneInfo: React.MutableRefObject<ZoneObjects>,
  h3indexes: React.MutableRefObject<H3Indexes>
) => {
  Object.keys(H3IndexesDefaults).forEach((type: string) => {
    let source = viewrefs.current.buildingAnalysisLayer[type].getSource();
    source.clear();
    let h3filtered: string[] = [];
    Object.keys(ZoneObjectDefaults).forEach((zone: string) => {
      if (zoneInfo.current[zone].checked) {
        Object.keys(h3indexes.current[type][zone]).forEach((item: string) => {
          h3filtered = h3filtered.concat(h3indexes.current[type][zone][item]);
        });
      }
    });
    h3filtered = Array.from(new Set(h3filtered));
    const h3shapes = geojson2h3.h3SetToFeatureCollection(h3filtered);
    source.addFeatures(
      new GeoJSON({
        featureProjection: "EPSG:3857",
      }).readFeatures(h3shapes)
    );
  });
};

export const getExpandedScores = (
  buildingAnalysisScore: BuildingAnalysisScore,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  let expandedbuildingAnalysisScore: BuildingAnalysisScore = {};
  Object.entries(buildingAnalysisScore).forEach((fuel: any) => {
    expandedbuildingAnalysisScore[fuel[0]] = {};
    Object.entries(fuel[1]).forEach((zone: any) => {
      expandedbuildingAnalysisScore[fuel[0]][zone[0]] = {};
      Object.entries(zone[1]).forEach((id: any) => {
        const keys = id[0].split("|");
        keys.forEach((key: string) => {
          expandedbuildingAnalysisScore[fuel[0]][zone[0]][key] = id[1];
        });
      });
    });
  });

  let expandedbuildingAnalysisArea: BuildingAnalysisArea = {};
  Object.entries(viewrefs.current.buildingAnalysisArea).forEach((zone: any) => {
    expandedbuildingAnalysisArea[zone[0]] = {};
    Object.entries(zone[1]).forEach((id: any) => {
      const keys = id[0].split("|");
      keys.forEach((key: string) => {
        expandedbuildingAnalysisArea[zone[0]][key] = id[1];
      });
    });
  });
  return {
    area: expandedbuildingAnalysisArea,
    score: expandedbuildingAnalysisScore,
  };
};
const setZindexForlayer = (
  viewrefs: React.MutableRefObject<ViewRefs>,
  type: string
) => {
  viewrefs.current.buildingAnalysisLayer[type].setZIndex(
    type === "building"
      ? 2
      : type === "heavy"
      ? 0
      : type === "medium"
      ? 0
      : type === "light"
      ? 0
      : 1
  );
};

export const setHighlightedBuilding = (
  building: string,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  if (viewrefs.current.buildingAnalysisLayer["building"]) {
    const source =
      viewrefs.current.buildingAnalysisLayer["building"].getSource();

    source.forEachFeature((feature: Feature<Geometry>) => {
      const id = feature.get("System_Id");
      feature.setStyle(getBuildingHighlightStyle(id === building));
    });
  }
};

export const exportToCSV = (csvData: any, fileName: string, wscols: any) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      parcel_id: "Parcel Id",
      building_id: "Building Id",
      zone: "Zone",
      fuel: "Fuel Type",
      hexagons: "Number Hex",
      density: "Density %",
      area_ft: "Area sq ft",
      parcel_area: "Parcel Area",
    },
  ];

  const ws = XLSX.utils.json_to_sheet(Heading, {
    header: [
      "parcel_id",
      "building_id",
      "zone",
      "fuel",
      "hexagons",
      "density",
      "area_ft",
      "parcel_area",
    ],
    skipHeader: true,
    //   origin: 0 //ok
  });
  ws["!cols"] = wscols;
  XLSX.utils.sheet_add_json(ws, csvData, {
    header: [
      "parcel_id",
      "building_id",
      "zone",
      "fuel",
      "hexagons",
      "density",
      "area_ft",
      "parcel_area",
    ],
    skipHeader: true,
    origin: -1, //ok
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
