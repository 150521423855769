import React, { useState, useEffect } from "react";

const defaultStyle = {
  width: "25px",
  marginLeft: "0px",
  marginRight: "0px",
  height: "25px",
  backgroundColor: "transparent",
};

interface StyleButton {
  width?: string;
  marginLeft?: string;
  marginRight?: string;
  height?: string | undefined;
  backgroundColor?: string;
}

interface Props {
  src: string;
  srcHover?: string;
  title?: string;
  style: StyleButton;
  onClick: () => void;
}

export const ImageComponent: React.FC<Props> = ({
  src,
  srcHover,
  title,
  style,
  onClick,
}) => {
  const [imagesrc, setimagesrc] = useState<string>(src);
  const stylewithdefaults = { ...defaultStyle, ...style };

  useEffect(() => {
    setimagesrc(src);
  }, [src]);

  const buttonStyle = {
    width: "100%",
    height: "100%",
    cursor: "pointer",
  };

  const onMouseOver = () => {
    setimagesrc(srcHover ? srcHover : src);
  };
  const onMouseLeave = () => {
    setimagesrc(src);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: stylewithdefaults.backgroundColor,
        width: stylewithdefaults.width,
        height: stylewithdefaults.height,
        marginLeft: stylewithdefaults.marginLeft,
        marginRight: stylewithdefaults.marginRight,
      }}
    >
      <img
        src={imagesrc}
        alt="alt"
        title={title}
        style={buttonStyle}
        onClick={() => onClick()}
        onMouseOver={() => onMouseOver()}
        onMouseLeave={() => onMouseLeave()}
      ></img>
    </div>
  );
};
