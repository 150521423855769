import React, { useState, useEffect, useContext } from "react";
import {
  DivPosition,
  Storage,
  VectorPropertyFilters,
} from "../../api/AlchemyApiTypes";
import { ButtonComponent } from "../ButtonComponent";
import Select from "react-select";
import { Rnd } from "react-rnd";
import { filterVectorSource } from "../../api/updateVectorSource";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { getDefaultPosition } from "../dashboards/functions";
import { colourStyles } from "./SelectStyles";

interface Props {
  storage: Storage;
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
}

interface Featureproperties {
  [key: string]: Set<string>;
}

export const FilterSelector: React.FC<Props> = ({ storage, mapviewRoot }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { vectorFilters } = store;
  const [featureProperties, setFeatureproperties] = useState<Featureproperties>(
    {}
  );

  const [localFilters, setLocalFilters] = useState<VectorPropertyFilters>({});
  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });

  const getHeader = (value: string) => {
    if (
      vectorFilters &&
      vectorFilters.properties &&
      vectorFilters.properties.changeName &&
      value in vectorFilters.properties.changeName
    ) {
      return vectorFilters.properties.changeName[value];
    } else return value;
  };

  useEffect(() => {
    const formatProperty = (value: any) => {
      return value ? value.toString() : "";
    };
    const key = vectorFilters.cacheKey
      ? vectorFilters.cacheKey
      : vectorFilters.key;
    if (key) {
      let properties: Featureproperties = {};
      const features = storage[key].features;
      features.forEach((feature: any) => {
        for (let keyx in feature.properties) {
          if (
            vectorFilters.properties &&
            vectorFilters.properties.includeFilter &&
            vectorFilters.properties.includeFilter.includes(keyx)
          ) {
            if (keyx in properties) {
              properties[keyx].add(formatProperty(feature.properties[keyx]));
            } else {
              properties[keyx] = new Set();
              properties[keyx].add(formatProperty(feature.properties[keyx]));
            }
          }
        }
      });
      const local =
        vectorFilters.key &&
        vectorFilters.Filtered &&
        vectorFilters.Filtered[vectorFilters.key]
          ? vectorFilters.Filtered[vectorFilters.key]
          : {};

      setLocalFilters(local);
      setFeatureproperties(properties);
    }
  }, [storage, vectorFilters]);

  const getDefaults = (item: string) => {
    return localFilters[item]
      ? localFilters[item].map((val) => ({ value: val, label: val }))
      : null;
  };

  const onChange = (val: any, id: string) => {
    let Filteredx: string[] = [];
    val.forEach((item: { value: string; label: string }) => {
      Filteredx.push(item.value);
    });
    const newFilter = { ...localFilters, [id]: Filteredx };
    setLocalFilters(newFilter);
  };

  const handleSave = () => {
    if (vectorFilters.key) {
      const Filtered = {
        ...vectorFilters.Filtered,
        [vectorFilters.key]: localFilters,
      };
      setLocalFilters({});
      const vf = { ...vectorFilters, show: false, Filtered };
      dispatch({
        type: actionTypes.SET_VECTOR_FILTERS,
        payload: vf,
      });

      filterVectorSource(vf, storage);
    }
  };
  const handleClear = () => {
    if (vectorFilters.key) {
      const Filtered = { ...vectorFilters.Filtered, [vectorFilters.key]: {} };
      const vf = { ...vectorFilters, show: false, Filtered };
      dispatch({
        type: actionTypes.SET_VECTOR_FILTERS,
        payload: vf,
      });
      filterVectorSource(vf, storage);
    }
  };
  const handleCancel = () => {
    dispatch({
      type: actionTypes.SET_VECTOR_FILTERS,
      payload: { ...vectorFilters, show: false },
    });
  };

  const toggleMode = (mode: string) => {
    dispatch({
      type: actionTypes.SET_VECTOR_FILTERS,
      payload: {
        ...vectorFilters,
        isAnd: mode === "AND" ? true : false,
      },
    });
  };

  const DivDimensions = { height: 600, width: 600 };

  if (vectorFilters && vectorFilters.show) {
    return (
      <Rnd
        position={getDefaultPosition(
          DivDimensions.height,
          DivDimensions.width,
          position,
          mapviewRoot
        )}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        style={{ zIndex: 9999 }}
      >
        <div
          style={{
            position: "relative",
            display: "block",
            backgroundColor: "#000000",
            height: DivDimensions.height + "px",
            width: DivDimensions.width + "px",
            padding: 20,
            color: "#ffffff",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "200px",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <ButtonComponent
                name="Save"
                hoverStyle={{
                  backgroundColor: "#757575",
                  border: "2px solid #757575",
                }}
                style={{
                  width: "60px",
                  marginRight: "10px",
                  marginLeft: "0px",
                  border: "2px solid #ffffff",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  fontSize: "14px",
                  borderRadius: "2px",
                }}
                onClick={() => handleSave()}
              />
              <ButtonComponent
                name="Clear"
                hoverStyle={{
                  backgroundColor: "#757575",
                  border: "2px solid #757575",
                }}
                style={{
                  width: "60px",
                  marginRight: "10px",
                  marginLeft: "0px",
                  border: "2px solid #ffffff",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  fontSize: "14px",
                  borderRadius: "2px",
                }}
                onClick={() => handleClear()}
              />
              <ButtonComponent
                name="Cancel"
                hoverStyle={{
                  backgroundColor: "#757575",
                  border: "2px solid #757575",
                }}
                style={{
                  width: "60px",
                  marginRight: "10px",
                  marginLeft: "0px",
                  border: "2px solid #ffffff",
                  backgroundColor: "#000000",
                  color: "#ffffff",
                  fontSize: "14px",
                  borderRadius: "2px",
                }}
                onClick={() => handleCancel()}
              />
            </div>

            <div
              style={{
                display: "flex",
                width: "160px",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: "-20px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div>AND</div>
                <input
                  style={{
                    marginLeft: "10px",
                    // marginTop: "6px",
                    accentColor: "#000000",
                  }}
                  type="radio"
                  name="Mode"
                  onChange={() => toggleMode("AND")}
                  checked={vectorFilters.isAnd}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div>OR</div>
                <input
                  style={{
                    marginLeft: "10px",
                    // marginTop: "6px",
                    accentColor: "#000000",
                  }}
                  type="radio"
                  name="Mode"
                  onChange={() => toggleMode("OR")}
                  checked={!vectorFilters.isAnd}
                />
              </div>
            </div>
          </div>

          <table>
            <tbody>
              {Object.entries(featureProperties).map((item: any, i: number) => {
                return (
                  <tr key={i}>
                    <td style={{ paddingRight: 10 }}>{getHeader(item[0])}</td>
                    <td>
                      <div style={{ width: 460 }}>
                        <Select
                          options={Array.from(item[1])
                            .map((x: any) => {
                              return { value: x, label: x };
                            })
                            .sort((a, b) => {
                              return a.value > b.value ? 1 : -1;
                            })}
                          isMulti={true}
                          onChange={(x) => {
                            onChange(x, item[0]);
                          }}
                          value={getDefaults(item[0])}
                          styles={colourStyles}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Rnd>
    );
  } else {
    return null;
  }
};
