import * as React from "react";
import { SVGProps } from "react";

const CollapseIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.114}
    height={15.016}
    viewBox="0 0 15.114 15.016"
    {...props}
  >
    <defs>{/* <style>{".a_CollapseIconSmall{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(14.614 14.516) rotate(180)">
      <path
        className="a_CollapseIconSmall"
        d="M14.114,14.516H1.557A2.06,2.06,0,0,1-.5,12.459V1.557A2.06,2.06,0,0,1,1.557-.5H14.114a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5H1.557A1.059,1.059,0,0,0,.5,1.557v10.9a1.059,1.059,0,0,0,1.057,1.057H14.114a.5.5,0,0,1,0,1Z"
        transform="translate(0 0)"
      />
      <path
        className="a_CollapseIconSmall"
        d="M0,8.287A.5.5,0,0,1-.354,8.14a.5.5,0,0,1,0-.707l3.54-3.54L-.354.354a.5.5,0,0,1,0-.707.5.5,0,0,1,.707,0L4.247,3.54a.5.5,0,0,1,0,.707L.354,8.14A.5.5,0,0,1,0,8.287Z"
        transform="translate(10.123 3.115)"
      />
      <path
        className="a_CollapseIconSmall"
        d="M9.344.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H9.344a.5.5,0,0,1,.5.5A.5.5,0,0,1,9.344.5Z"
        transform="translate(4.672 7.008)"
      />
    </g>
  </svg>
);

export default CollapseIconSmall;
