import CollapseIconSmall from "../../images/svg/panelSmall/CollapseIconSmall";
import DashboardIconSmall from "../../images/svg/panelSmall/DashboardIconSmall";
import ExpandIconSmall from "../../images/svg/panelSmall/ExpandIconSmall";
import ImagesIconSmall from "../../images/svg/panelSmall/ImagesIconSmall";
import LayersIconSmall from "../../images/svg/panelSmall/LayersIconSmall";
import MapIconSmall from "../../images/svg/panelSmall/MapIconSmall";
import MarkersIconSmall from "../../images/svg/panelSmall/MarkersIconSmall";
import MyFilesIconSmall from "../../images/svg/panelSmall/MyFilesIconSmall";
import ReturnHomeIconSmall from "../../images/svg/panelSmall/ReturnHomeIconSmall";
import SettingsIconSmall from "../../images/svg/panelSmall/SettingsIconSmall";
import VideosIconSmall from "../../images/svg/panelSmall/VideosIconSmall";
import CollapseIconLarge from "../../images/svg/panelLarge/CollapseIconLarge";
import DashboardIconLarge from "../../images/svg/panelLarge/DashboardIconLarge";
import ExpandIconLarge from "../../images/svg/panelLarge/ExpandIconLarge";
import ImagesIconLarge from "../../images/svg/panelLarge/ImagesIconLarge";
import LayersIconLarge from "../../images/svg/panelLarge/LayersIconLarge";
import MapIconLarge from "../../images/svg/panelLarge/MapIconLarge";
import MarkersIconLarge from "../../images/svg/panelLarge/MarkersIconLarge";
import MyFilesIconLarge from "../../images/svg/panelLarge/MyFilesIconLarge";
import ReturnHomeIconLarge from "../../images/svg/panelLarge/ReturnHomeIconLarge";
import SettingsIconLarge from "../../images/svg/panelLarge/SettingsIconLarge";
import VideoIconLarge from "../../images/svg/panelLarge/VideoIconLarge";

export const SVGImages = (header: string, large?: boolean) => {
  switch (header) {
    case "My Files":
      return large ? MyFilesIconLarge : MyFilesIconSmall;
    case "Map Layers":
      return large ? LayersIconLarge : LayersIconSmall;
    case "Map Lines":
      return large ? MapIconLarge : MapIconSmall;
    case "Dashboards":
      return large ? DashboardIconLarge : DashboardIconSmall;
    case "Markers":
      return large ? MarkersIconLarge : MarkersIconSmall;
    case "Photos":
      return large ? ImagesIconLarge : ImagesIconSmall;
    case "Videos":
      return large ? VideoIconLarge : VideosIconSmall;
    case "Settings":
      return large ? SettingsIconLarge : SettingsIconSmall;
    case "Return Home":
      return large ? ReturnHomeIconLarge : ReturnHomeIconSmall;
    case "Collapse":
      return large ? CollapseIconLarge : CollapseIconSmall;
    case "Expand":
      return large ? ExpandIconLarge : ExpandIconSmall;
  }
};
