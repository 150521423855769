import VectorSource from "ol/source/Vector";
import MultiLineString from "ol/geom/MultiLineString";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import VectorLayer from "ol/layer/Vector";

function generatePointsCircle(count, centerPixel) {
  var separation = 25,
    twoPi = Math.PI * 2,
    start_angle = twoPi / 12,
    circumference = separation * (2 + count),
    legLength = circumference / twoPi, //radius from circumference
    angleStep = twoPi / count,
    res = [],
    i,
    angle;
  res.length = count;

  for (i = count - 1; i >= 0; i--) {
    angle = start_angle + i * angleStep;
    res[i] = [
      centerPixel[0] + legLength * Math.cos(angle),
      centerPixel[1] + legLength * Math.sin(angle),
    ];
  }
  return { points: res, legLength };
}

export const displayOverlapping = (
  pixel,
  map,
  setExplodedFeatures,
  selectedFeatures
) => {
  var multiLineString = new MultiLineString([]);
  var layerRoute = new VectorLayer({
    source: new VectorSource({
      features: [new Feature({ geometry: multiLineString })],
    }),
    style: [
      new Style({
        stroke: new Stroke({
          width: 3,
          color: [51, 51, 51, 1],
        }),
        zIndex: 2,
      }),
    ],
    updateWhileAnimating: true,
  });

  const { points, legLength } = generatePointsCircle(
    selectedFeatures.length,
    pixel
  );

  const explodedFeatures = [];

  selectedFeatures.forEach((feature, index) => {
    const cd_end = map.getCoordinateFromPixel(points[index]);
    const coordinates = feature.getGeometry().getCoordinates();
    const explodedFeature = { feature, coordinates };
    explodedFeatures.push(explodedFeature);

    multiLineString.appendLineString(new LineString([coordinates, cd_end]));
    feature.set("System_RenderOrder", 1);
    feature.setGeometry(new Point(cd_end));
  });
  map.addLayer(layerRoute);

  const newExplodedFeatures = {
    features: explodedFeatures,
    centerPixel: pixel,
    legLength,
    spider: layerRoute,
  };
  setExplodedFeatures(newExplodedFeatures);
};

export const resetOverlapping = (
  pixel,
  map,
  ExplodedFeatures,
  setExplodedFeatures
) => {
  const distance = distanceBetweenPixels(pixel, ExplodedFeatures.centerPixel);
  if (distance > ExplodedFeatures.legLength + 10) {
    map.removeLayer(ExplodedFeatures.spider);

    ExplodedFeatures.features.forEach((item, index) => {
      item.feature.set("System_RenderOrder", 0);
      item.feature.setGeometry(new Point(item.coordinates));
    });
    setExplodedFeatures(null);
  }
};

const distanceBetweenPixels = (p1, p2) => {
  const a = p1[0] - p2[0];
  const b = p1[1] - p2[1];
  return Math.sqrt(a * a + b * b);
};
