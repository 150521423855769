import React, { useContext } from "react";
import { ParcelAnalysis } from "./ParcelAnalysis";
import { OtherDashboard } from "./OtherDashboard";
import { ElevationProfile } from "./elevationprofile/ElevationProfile";
import { CanopyAnalysis } from "./canopy/CanopyAnalysys";
import { BuildingAnalysis } from "./building/BuildingAnalysis";
import { Heatmaps } from "./heatmaps/Heatmaps";
import { Storage } from "../../api/AlchemyApiTypes";
import Auth from "../../services/auth";
import { StoreContext } from "../../store/store";
import { State } from "../../store/storetypes";
import { ViewRefs } from "../../store/refs";

interface Props {
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  // dashboardCleanupComponents: (key: string) => void;
  auth: Auth;
  setDashboardSettingsMode: (mode: string | null) => void;
  viewId: string;
  storage: Storage;
  setSlopePoints: (val: number[]) => void;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const DashboardController: React.FC<Props> = ({
  mapviewRoot,
  // dashboardCleanupComponents,
  auth,
  setDashboardSettingsMode,
  viewId,
  storage,
  setSlopePoints,
  viewrefs,
}) => {
  const [store] = useContext(StoreContext) as [State];

  const { dashboardSettings } = store;
  const dashboardSelection = (item: string, i: number) => {
    switch (item) {
      case "ParcelAnalysis":
        return <ParcelAnalysis key={i} mapviewRoot={mapviewRoot} />;

      case "ElevationProfile":
        return (
          <ElevationProfile
            key={i}
            mapviewRoot={mapviewRoot}
            auth={auth}
            setDashboardSettingsMode={setDashboardSettingsMode}
            viewId={viewId}
            setSlopePoints={setSlopePoints}
            viewrefs={viewrefs}
            storage={storage}
          />
        );
      case "Heatmaps":
        return (
          <Heatmaps
            key={i}
            mapviewRoot={mapviewRoot}
            storage={storage}
            auth={auth}
            settings={dashboardSettings.Heatmaps}
            viewrefs={viewrefs}
          />
        );
      case "Other":
        return <OtherDashboard key={i} mapviewRoot={mapviewRoot} />;
      case "CanopyAnalysis":
        return (
          <CanopyAnalysis
            key={i}
            mapviewRoot={mapviewRoot}
            setDashboardSettingsMode={setDashboardSettingsMode}
            auth={auth}
            storage={storage}
            viewrefs={viewrefs}
          />
        );

      case "BuildingAnalysis":
        return (
          <BuildingAnalysis
            key={i}
            mapviewRoot={mapviewRoot}
            auth={auth}
            viewrefs={viewrefs}
          />
        );
    }
  };

  if (dashboardSettings && Object.keys(dashboardSettings).length > 0) {
    return (
      <>
        {Object.keys(dashboardSettings)
          .filter((item) => {
            return dashboardSettings[item].selected;
          })
          .map((item, i) => {
            return dashboardSelection(item, i);
          })}
      </>
    );
  } else {
    return null;
  }
};
