import * as React from "react";
import { SVGProps } from "react";

const MyFilesIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.02}
    height={27.025}
    viewBox="0 0 22.02 27.025"
    {...props}
  >
    <defs>{/* <style>{".a_MyFilesIconLarge{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(1 1)">
      <path
        className="a_MyFilesIconLarge"
        d="M8.5,2h8.759a1,1,0,0,1,.707.293l8.759,8.759a1,1,0,0,1,.293.707V25.522a3.506,3.506,0,0,1-3.5,3.5H8.5a3.506,3.506,0,0,1-3.5-3.5V5.5A3.506,3.506,0,0,1,8.5,2Zm8.345,2H8.5A1.5,1.5,0,0,0,7,5.5v20.02a1.5,1.5,0,0,0,1.5,1.5H23.518a1.5,1.5,0,0,0,1.5-1.5V12.173Z"
        transform="translate(-6 -3)"
      />
      <path
        className="a_MyFilesIconLarge"
        d="M28.259,12.759H19.5a1,1,0,0,1-1-1V3a1,1,0,1,1,2,0v7.759h7.759a1,1,0,0,1,0,2Z"
        transform="translate(-8.239 -3)"
      />
    </g>
  </svg>
);

export default MyFilesIconLarge;
