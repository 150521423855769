import React, { useState, useContext } from "react";
import Layer from "ol/layer/Layer";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import showPropertiesSelected from "../../images/eyeselectedInverse.png";
import showPropertiesUnselected from "../../images/eyeunselectedInverse.png";
import selectFilter from "../../images/filterInverse.png";
import selectedFilter from "../../images/filterselectedInverse.png";
import loadingGIF from "../../images/ZZ5H.gif";
import Auth from "../../services/auth";
import {
  fetchVectorSettings,
  writeVectorSettings,
} from "../../api/AlchemyAnalytics";
import {
  MapLayer,
  VectorShowProperties,
  UserVectorStyles,
  ColorPicker,
  UserSettings,
  VectorStyleStyle,
  Payload,
  VectorProperties,
} from "../../api/AlchemyApiTypes";
import { setLayerStyle } from "../../map/functions";
import { Loader } from "../Loader";
import { ColourPicker } from "./ColourPicker";
import { setStorage, getStorage } from "../../helpers/localStorage";
import { VectorLayerHeader } from "./VectorLayerHeader";
import Source from "ol/source/Source";
import { ViewRefs } from "../../store/refs";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";

interface Props {
  auth: Auth;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const VectorLayers: React.FC<Props> = ({ auth, viewrefs }): any => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const {
    mapInfo,
    userVectorStyles,
    vectorFilters,
    vectorlayers,
    vectorShowProperties,
  } = store;
  const [colourPicker, setColourPicker] = useState<ColorPicker | null>(null);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const saveSettings = () => {
    setSaveInProgress(true);
    let localStorage: any = getStorage(null);
    const payload: Payload = {};

    if (vectorlayers) {
      vectorlayers.forEach((item: MapLayer) => {
        const key = item.config.key;
        payload[key] = {
          visible: item.layers[0].getVisible(),
          style: localStorage[key],
          showproperties: vectorShowProperties[key],
        };
      });
    }
    setSaveInProgress(true);
    const saveSettings = async () => {
      await writeVectorSettings(auth, viewrefs.current.viewId, payload);
      setSaveInProgress(false);
    };
    saveSettings();
  };
  const restoreDefaultSettings = (
    setrestoreDefaultsInProgress: React.Dispatch<React.SetStateAction<boolean>>,
    setdefaultSelected: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    let showProperties: VectorShowProperties = {};
    if (vectorlayers) {
      const newUserVectorStyles: UserVectorStyles = { ...userVectorStyles };
      vectorlayers.forEach((item: MapLayer) => {
        let propsOnLoad: boolean = false;
        let displayOnLoad: boolean = false;
        if (item.config.propertiesOnLoad) {
          propsOnLoad = true;
        }
        if (item.config.displayOnLoad) {
          displayOnLoad = true;
        }
        const key = item.config.key;
        const newStyle = item.config.style;
        setStorage(key, newStyle);
        newUserVectorStyles[key] = newStyle;
        showProperties[key] = propsOnLoad;
        setLayerStyle(item, newStyle);
        for (const layer of item.layers) {
          layer.setVisible(displayOnLoad);
        }
      });
      dispatch({
        type: actionTypes.SET_USER_VECTOR_STYLES,
        payload: newUserVectorStyles,
      });
      dispatch({
        type: actionTypes.SET_VECTOR_SHOW_PROPERTIES,
        payload: showProperties,
      });
    }
    setrestoreDefaultsInProgress(false);
    setdefaultSelected(false);
  };
  const restoreUserSettings = (
    setrestoreSelectionsInProgress: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    setdefaultSelected: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const getSettingsAndRestore = async () => {
      let showProperties: VectorShowProperties = {};

      const savedUserSettings: UserSettings = await fetchVectorSettings(
        auth,
        viewrefs.current.viewId
      );
      if (vectorlayers) {
        const newUserVectorStyles: UserVectorStyles = { ...userVectorStyles };
        vectorlayers.forEach((item: MapLayer) => {
          const key = item.config.key;
          let newStyle: any = {};
          let propsOnLoad: boolean = false;
          let displayOnLoad: boolean = false;
          if (savedUserSettings[key]) {
            if (savedUserSettings[key].showproperties) {
              propsOnLoad = true;
            }
            if (savedUserSettings[key].visible) {
              displayOnLoad = true;
            }
            newStyle = savedUserSettings[key].style;
          } else {
            if (item.config.propertiesOnLoad) {
              propsOnLoad = true;
            }
            if (item.config.displayOnLoad) {
              displayOnLoad = true;
            }
            newStyle = item.config.style;
          }

          setStorage(key, newStyle);
          newUserVectorStyles[key] = newStyle;
          showProperties[key] = propsOnLoad;
          setLayerStyle(item, newStyle);
          for (const layer of item.layers) {
            layer.setVisible(displayOnLoad);
          }
        });
        dispatch({
          type: actionTypes.SET_USER_VECTOR_STYLES,
          payload: newUserVectorStyles,
        });
        dispatch({
          type: actionTypes.SET_VECTOR_SHOW_PROPERTIES,
          payload: showProperties,
        });
      }
      setrestoreSelectionsInProgress(false);
      setdefaultSelected(false);
    };
    getSettingsAndRestore();
  };

  if (mapInfo) {
    const { mapURL, mapStyle } = mapInfo;
    if (mapURL && mapStyle) {
      const toggleLayers = (layers: Layer<Source, any>[]) => {
        for (const layer of layers) {
          layer.setVisible(!layer.getVisible());
        }
        dispatch({
          type: actionTypes.SET_FORCE_UPDATE,
          payload: Math.random(),
        });
      };

      const toggleShowProperty = (key: string) => {
        let newvectorShowProperties = { ...vectorShowProperties };
        newvectorShowProperties[key] = !newvectorShowProperties[key];
        dispatch({
          type: actionTypes.SET_VECTOR_SHOW_PROPERTIES,
          payload: newvectorShowProperties,
        });
      };

      const renderStyle = (
        key: string,
        style: VectorStyleStyle,
        setSavePickerInProgress: React.Dispatch<React.SetStateAction<boolean>>
      ) => {
        let SelectedLayer: MapLayer | null = null;
        if (vectorlayers) {
          for (let i = 0; vectorlayers.length; i++) {
            if (vectorlayers[i].config.key === key) {
              SelectedLayer = vectorlayers[i];
              break;
            }
          }
        }
        if (SelectedLayer) {
          setLayerStyle(SelectedLayer, style);
          const newUserVectorStyles = { ...userVectorStyles };
          newUserVectorStyles[key] = style;
          dispatch({
            type: actionTypes.SET_USER_VECTOR_STYLES,
            payload: newUserVectorStyles,
          });
        }
        setStorage(key, style);
        setSavePickerInProgress(false);
      };

      const setStyle = (
        key: string,
        geometry: string,
        style: VectorStyleStyle
      ) => {
        setColourPicker({
          show: true,
          style,
          key,
          geometry,
          callback: renderStyle,
        });
      };
      const setFilter = (
        key: string,
        cacheKey: string | undefined,
        properties: VectorProperties | undefined,
        layers: VectorLayer<VectorSource<Geometry>>[]
      ) => {
        dispatch({
          type: actionTypes.SET_VECTOR_FILTERS,
          payload: {
            ...vectorFilters,
            show: true,
            key,
            cacheKey,
            properties,
            layers,
          },
        });
      };

      const gifOrInput = (item: MapLayer, i: number) => {
        if (
          viewrefs.current.loadedlayers &&
          viewrefs.current.loadedlayers[item.config.key]
        ) {
          return (
            <div
              style={{
                marginTop: "0",
                width: "80px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                style={{
                  marginLeft: "3px",
                  cursor: "pointer",
                  accentColor: "#ffffff",
                }}
                id={`check_${i}`}
                type="checkbox"
                onChange={() => toggleLayers(item.layers)}
                checked={item.layers[0]?.getVisible()}
              />
              <img
                id={`check_${i}`}
                alt="loader"
                src={
                  vectorShowProperties[item.config.key]
                    ? showPropertiesSelected
                    : showPropertiesUnselected
                }
                style={{
                  width: 15,
                  height: 15,
                  marginLeft: 5,
                  marginTop: 0,
                  cursor: "pointer",
                }}
                onClick={() => toggleShowProperty(item.config.key)}
              />
              <div
                style={{
                  width: 10,
                  height: 10,
                  marginLeft: 5,
                  marginTop: 0,
                  border:
                    colourPicker && colourPicker?.key === item.config.key
                      ? `3px solid ${
                          userVectorStyles[item.config.key].linecolour
                        }`
                      : `2px solid ${
                          userVectorStyles[item.config.key].linecolour
                        }`,
                  borderRadius:
                    item.config.geometry && item.config.geometry === "Point"
                      ? "6px"
                      : "2px",
                  backgroundColor: userVectorStyles[item.config.key].fill,
                  cursor: "pointer",
                }}
                onClick={() =>
                  setStyle(
                    item.config.key,
                    item.config.geometry,
                    getStorage(item.config.key)
                  )
                }
              ></div>

              {item.config.properties?.includeFilter &&
                item.config.properties?.includeFilter.length > 0 && (
                  <img
                    id={`checkf_${i}`}
                    alt="loader"
                    src={
                      vectorFilters &&
                      vectorFilters.Filtered &&
                      item.config.key in vectorFilters.Filtered &&
                      Object.entries(
                        vectorFilters.Filtered[item.config.key]
                      ).filter((item) => {
                        return item[1].length > 0;
                      }).length > 0
                        ? selectedFilter
                        : selectFilter
                    }
                    style={{
                      width: 10,
                      height: 10,
                      marginLeft: 5,
                      marginTop: 0,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setFilter(
                        item.config.key,
                        item.config.cacheKey,
                        item.config.properties,
                        item.layers
                      )
                    }
                  />
                )}
            </div>
          );
        } else {
          return (
            <div style={{ marginTop: "0", width: "80px" }}>
              <img
                id={`check_${i}`}
                alt="loader"
                src={loadingGIF}
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: 18,
                }}
              />
            </div>
          );
        }
      };

      if (mapURL && mapURL.vector_urls && mapURL.vector_urls.length > 0) {
        if (vectorlayers && vectorlayers.length > 0) {
          return (
            <div
              style={{
                // width: 300,
                minHeight: 340,
                backgroundColor: "transparent",
                padding: 20,
              }}
            >
              <VectorLayerHeader
                restoreDefaultSettings={restoreDefaultSettings}
                restoreUserSettings={restoreUserSettings}
                saveSettings={saveSettings}
                saveInProgress={saveInProgress}
              />
              <div
                style={{
                  display: "flex",
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                <table>
                  <tbody>
                    {vectorlayers
                      .filter((item: MapLayer) => {
                        return !item.config.heatmapOnly;
                      })
                      .sort((a, b) =>
                        (a.config.displayName ? a.config.displayName : "") >
                        (b.config.displayName ? b.config.displayName : "")
                          ? 1
                          : -1
                      )
                      .map((item: MapLayer, i: number) => {
                        return (
                          <tr key={i} style={{ height: 35 }}>
                            <td>{gifOrInput(item, i)}</td>
                            <td>
                              {item.config.displayName
                                ? item.config?.displayName
                                : item.config.key.split("_").join(" ")}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <ColourPicker
                  colourPicker={colourPicker}
                  setColourPicker={setColourPicker}
                />
              </div>
            </div>
          );
        } else {
          return <Loader name={"Layers"} />;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};
