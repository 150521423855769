import { Files } from "../files/Files";
import { MapLayers } from "../maplayers/MapLayers";
import { VectorLayers } from "../vectorlayers/VectorLayers";
import { DashboardList } from "../dashboards/DashboardList";
import { Markers } from "../Markers";
import { FieldPhotos } from "../fieldphotos/FieldPhotos";
import { VideoClips } from "../video/VideoClips";
import { Settings } from "../settings/Settings";

export const getListComponent = (header: string) => {
  switch (header) {
    case "My Files":
      return Files;
    case "Map Layers":
      return MapLayers;
    case "Map Lines":
      return VectorLayers;
    case "Dashboards":
      return DashboardList;
    case "Markers":
      return Markers;
    case "Photos":
      return FieldPhotos;
    case "Videos":
      return VideoClips;
    case "Settings":
      return Settings;
    // case "Return Home":
    //   return large ? ReturnHomeIconLarge : ReturnHomeIconSmall;
    // case "Collapse":
    //   return large ? CollapseIconLarge : CollapseIconSmall;
    // case "Expand":
    //   return large ? ExpandIconLarge : ExpandIconSmall;
    default:
      return Files;
  }
};
