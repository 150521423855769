import React, { useState, useContext } from "react";
import close from "../../images/closeInverse.png";
import { Rnd } from "react-rnd";
import { DivPosition } from "../../api/AlchemyApiTypes";
import { getDefaultPosition } from "./functions";
import { State, Action, actionTypes } from "../../store/storetypes";
import { StoreContext } from "../../store/store";

interface Props {
  // setSelected: (key: string) => void;
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
}

export const OtherDashboard: React.FC<Props> = ({
  // setSelected,
  mapviewRoot,
}) => {
  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });

  const DashboardDimensions = { height: 200, width: 300 };

  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { dashboardSettings } = store;

  const onClose = () => {
    const newSettings = { ...dashboardSettings };
    newSettings["Other"].selected = false;
    dispatch({
      type: actionTypes.SET_DASHBOARD_SETTINGS,
      payload: newSettings,
    });
  };

  return (
    <Rnd
      position={getDefaultPosition(
        DashboardDimensions.height,
        DashboardDimensions.width,
        position,
        mapviewRoot
      )}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      style={{ zIndex: 9999 }}
    >
      <div
        style={{
          position: "absolute",
          display: "block",
          backgroundColor: "#000000",
          height: DashboardDimensions.height + "px",
          width: DashboardDimensions.width + "px",
          zIndex: 999,
          // border: "1px solid black",
          padding: "1rem",
          borderRadius: 4,
          color: "#ffffff",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "absolute",
            top: 10,
            left: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 18,
            }}
          >
            {"Working Dashboard"}
          </div>
        </div>
        <img
          alt="close"
          src={close}
          style={{
            width: 20,
            position: "absolute",
            top: 0,
            right: 0,
            cursor: "pointer",
          }}
          onClick={() => onClose()}
        ></img>
      </div>
    </Rnd>
  );
};
