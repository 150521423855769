export const findPointAlongLine = (coordinates: number[][], d: number) => {
  const x0 = coordinates[0][0];
  const y0 = coordinates[0][1];
  const x1 = coordinates[1][0];
  const y1 = coordinates[1][1];

  const m = (y1 - y0) / (x1 - x0);

  let x = x0 + d / Math.sqrt(1 + m * m);
  let y = m * (x - x0) + y0;

  return [x, y];
};

export const findDistanceBetweenPoints = (coordinates: number[][]) => {
  const x0 = coordinates[0][0];
  const y0 = coordinates[0][1];
  const x1 = coordinates[1][0];
  const y1 = coordinates[1][1];

  const dx = Math.abs(x1 - x0);
  const dy = Math.abs(y1 - y0);

  return Math.sqrt(dx * dx + dy * dy);
};
export const meterstofeet = (value: number) => {
  return value * 3.28084;
};
export const feettometers = (value: number) => {
  return value / 3.28084;
};
export const sqmeterstosqfeet = (value: number) => {
  return value * 10.76391041671;
};
export const sqfeettosqmeters = (value: number) => {
  return value / 10.76391041671;
};
export const hexdensity = (hexes: number, area: number) => {
  return (hexes * 9.68751937504) / area;
};
