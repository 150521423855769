export const style_columnHeaders = {
  width: 70,
  fontWeight: "normal",
  fontSize: 14,
  textAlign: "right" as const,
};
export const style_rowHeaders = {
  width: 140,
  fontWeight: "normal",
  fontSize: 14,
  textAlign: "left" as const,
};
export const style_columnValues = {
  textAlign: "right" as const,
  fontWeight: "normal",
  fontSize: 12,
  width: 70,
};
export const style_alertValues = {
  textAlign: "right" as const,
  width: 70,
  fontWeight: "normal",
  fontSize: 13,
  color: "red",
};
export const style_table = {
  marginTop: 20,
  marginLeft: 20,
  marginRight: 20,
};
