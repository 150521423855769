import React from "react";

import { ButtonComponent } from "../../components/ButtonComponent";
import DocumentIcon from "../../images/svg/other/DocumentIcon";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getFileDownloadUrl } from "../../api/AlchemyAnalytics";
import { FileStyle, ViewConfigResponse } from "../../api/AlchemyApiTypes";
import { CardProps } from "./Types";

export const ViewCard: React.FC<CardProps> = ({ view, redirected, auth }) => {
  const history = useHistory();

  const redirect = (ViewId: string) => {
    history.push(`/views/${ViewId}`);
  };
  async function download(filepath: string) {
    const url = await getFileDownloadUrl(auth, filepath, "fileurl");
    window.open(url, "_blank");
  }
  const getDocumentUrl = (classification: string, view: ViewConfigResponse) => {
    if (view.bucket_item_styles && view.bucket_item_styles.documents) {
      const style: FileStyle = view.bucket_item_styles.documents;
      const returnArray = Object.entries(style)
        .filter((item) => {
          return item[1].classification === classification;
        })
        .map((item) => {
          return item[1].key;
        });
      if (returnArray && returnArray.length > 0) {
        return returnArray[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getDocumentJSX = (classification: string, view: ViewConfigResponse) => {
    const url = getDocumentUrl(classification, view);
    return (
      <ButtonComponent
        name={classification}
        hoverStyle={{
          backgroundColor: url ? "#757575" : "#000000",
          border: "1px solid #757575",
        }}
        title={`Download ${classification} document`}
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          marginRight: "10px",
          marginLeft: "0px",
          height: "30px",
          border: `1px solid ${url ? "#ffffff" : "#757575"}`,
          borderRadius: "5px",
          backgroundColor: "#000000",
          color: url ? "#ffffff" : "#757575",
          fontSize: "12px",
          fontWeight: "600",
          cursor: url ? "pointer" : "default",
        }}
        component={<DocumentIcon fill={url ? "#ffffff" : "#757575"} />}
        onClick={() => (url ? download(url) : console.log("no url found"))}
      />
    );
  };

  return (
    <div
      style={{
        position: "relative",
        display: "block",
        height: 176,
        width: 278,
        border: `2px solid #363636`,
        borderRadius: 10,
        backgroundColor: "#000000",
        marginRight: 15,
        marginBottom: 15,
        marginTop: 0,
        marginLeft: 0,
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: 16,
          marginLeft: 18,
          marginTop: 16,
          marginRight: 16,
          backgroundColor: "#000000",
        }}
      >
        {view.view_config.viewName ?? view.view_config.ViewId}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          position: "absolute",
          height: 80,
          top: 85,
          left: 18,
        }}
      >
        {getDocumentJSX("Summary", view)}
        {view.isDefault && !redirected && (
          <Redirect to={`/views/${view.view_config.ViewId}`} />
        )}
        <ButtonComponent
          name="Map"
          hoverStyle={{
            backgroundColor: "#757575",
            border: "1px solid #757575",
          }}
          title="Show map with features"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            marginRight: "10px",
            marginLeft: "0px",
            height: "30px",
            border: "1px solid #ffffff",
            borderRadius: "5px",
            backgroundColor: "#000000",
            color: "#ffffff",
            fontSize: "12px",
            fontWeight: "600",
          }}
          onClick={() => redirect(view.view_config.ViewId)}
        />
        {getDocumentJSX("FBAN", view)}
        {getDocumentJSX("PSOM", view)}
        {getDocumentJSX("Inspection", view)}
      </div>
    </div>
  );
};
