import * as React from "react";
import { SVGProps } from "react";

const ImagesIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.523}
    height={24.522}
    viewBox="0 0 24.523 24.522"
    {...props}
  >
    <defs>{/* <style>{".a_ImagesIconLarge{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(1 1)">
      <path
        className="a_ImagesIconLarge"
        d="M7,3.5H24.52a3.506,3.506,0,0,1,3.5,3.5V24.52a3.506,3.506,0,0,1-3.5,3.5H7a3.506,3.506,0,0,1-3.5-3.5V7A3.506,3.506,0,0,1,7,3.5ZM24.52,26.022a1.5,1.5,0,0,0,1.5-1.5V7a1.5,1.5,0,0,0-1.5-1.5H7A1.5,1.5,0,0,0,5.5,7V24.52a1.5,1.5,0,0,0,1.5,1.5Z"
        transform="translate(-4.5 -4.5)"
      />
      <path
        className="a_ImagesIconLarge"
        d="M12.377,9.5A2.877,2.877,0,1,1,9.5,12.377,2.88,2.88,0,0,1,12.377,9.5Zm0,3.754a.877.877,0,1,0-.877-.877A.878.878,0,0,0,12.377,13.254Z"
        transform="translate(-5.495 -5.495)"
      />
      <path
        className="a_ImagesIconLarge"
        d="M7.5,29.764a1,1,0,0,1-.707-1.707L20.557,14.293a1,1,0,0,1,1.414,0l6.256,6.256a1,1,0,0,1-1.414,1.414l-5.549-5.549L8.207,29.471A1,1,0,0,1,7.5,29.764Z"
        transform="translate(-4.997 -6.241)"
      />
    </g>
  </svg>
);

export default ImagesIconLarge;
