import * as React from "react";
import { SVGProps } from "react";

const MyFilesIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.459}
    height={16.574}
    viewBox="0 0 13.459 16.574"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a"
        d="M7.557,2.5h5.451a.5.5,0,0,1,.354.146L18.813,8.1a.5.5,0,0,1,.146.354v8.566A2.06,2.06,0,0,1,16.9,19.074H7.557A2.06,2.06,0,0,1,5.5,17.016V4.557A2.06,2.06,0,0,1,7.557,2.5Zm5.244,1H7.557A1.059,1.059,0,0,0,6.5,4.557V17.016a1.059,1.059,0,0,0,1.057,1.057H16.9a1.059,1.059,0,0,0,1.057-1.057V8.658Z"
        transform="translate(-6 -3)"
      />
      <path
        className="a"
        d="M24.951,8.951H19.5a.5.5,0,0,1-.5-.5V3a.5.5,0,0,1,1,0V7.951h4.951a.5.5,0,0,1,0,1Z"
        transform="translate(-12.492 -3)"
      />
    </g>
  </svg>
);

export default MyFilesIconSmall;
