import React from "react";

export const Loading = () => (
  <div
    className="spinner-border"
    role="status"
    style={{
      height: "10rem",
      width: "10rem",
      marginLeft: "auto",
      marginRight: "auto",
    }}
  >
    <span className="sr-only">Loading...</span>
  </div>
);
