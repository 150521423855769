import * as React from "react";
import { SVGProps } from "react";

const MapIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.131}
    height={16.574}
    viewBox="0 0 18.131 16.574"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a"
        d="M18.631,2.5a.5.5,0,0,1,.5.5V15.459a.5.5,0,0,1-.252.434l-5.451,3.115a.5.5,0,0,1-.472.013l-5.99-3L1.748,19.008A.5.5,0,0,1,1,18.574V6.115a.5.5,0,0,1,.252-.434L6.7,2.566a.5.5,0,0,1,.472-.013l5.99,3,5.218-2.982A.5.5,0,0,1,18.631,2.5ZM13.18,6.615a.5.5,0,0,1-.224-.053l-5.99-3L2,6.4V17.712l4.7-2.687a.5.5,0,0,1,.472-.013l5.99,3,4.967-2.838V3.862l-4.7,2.687A.5.5,0,0,1,13.18,6.615Z"
        transform="translate(-1.5 -3)"
      />
      <path
        className="a"
        d="M12,15.959a.5.5,0,0,1-.5-.5V3a.5.5,0,1,1,1,0V15.459A.5.5,0,0,1,12,15.959Z"
        transform="translate(-6.549 -3)"
      />
      <path
        className="a"
        d="M24,21.959a.5.5,0,0,1-.5-.5V9a.5.5,0,0,1,1,0V21.459A.5.5,0,0,1,24,21.959Z"
        transform="translate(-12.32 -5.885)"
      />
    </g>
  </svg>
);

export default MapIconSmall;
