import React, { useContext } from "react";
import { FieldPhotoObject } from "../../api/AlchemyApiTypes";
import { Loader } from "../Loader";
import Auth from "../../services/auth";
import { highlightItem } from "./functions";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { ViewRefs } from "../../store/refs";

interface Props {
  auth: Auth;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const FieldPhotos: React.FC<Props> = ({ viewrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { fieldphotoitems, mapInfo } = store;

  const toggleItems = (item: FieldPhotoObject) => {
    const newItems = fieldphotoitems?.map((i) => {
      if (i.fieldphoto.id === item.fieldphoto.id) {
        return { fieldphoto: i.fieldphoto, checked: !i.checked };
      } else {
        return i;
      }
    });
    viewrefs.current.fieldphotoitemsRef = newItems;
    dispatch({
      type: actionTypes.SET_FIELD_PHOTO_ITEMS,
      payload: newItems,
    });
  };
  const isChecked = (item: FieldPhotoObject) => {
    return item.checked;
  };

  if (mapInfo && mapInfo.mapURL && mapInfo.mapURL.fieldphoto_urls.length > 0) {
    if (fieldphotoitems && fieldphotoitems.length > 0) {
      return (
        <div
          style={{
            width: 245,
            backgroundColor: "transparent",
            padding: 20,
          }}
        >
          <div
            style={{
              borderTop: "2px solid #757575",
              marginTop: 10,
              paddingTop: 10,
            }}
          ></div>
          <div style={{ maxHeight: 500, overflowY: "auto" }}>
            {fieldphotoitems.map((item: FieldPhotoObject, i: number) => {
              return (
                <div
                  key={i}
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{ accentColor: "#ffffff", cursor: "pointer" }}
                    type="checkbox"
                    onChange={() => toggleItems(item)}
                    checked={isChecked(item)}
                  />
                  <div
                    style={{ marginLeft: 10 }}
                    onMouseEnter={() =>
                      highlightItem(
                        item,
                        "enter",
                        viewrefs.current.markerVectorLayers
                      )
                    }
                    onMouseLeave={() =>
                      highlightItem(
                        item,
                        "leave",
                        viewrefs.current.markerVectorLayers
                      )
                    }
                  >
                    {item.fieldphoto.displayName}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return <Loader name={"Photos"} />;
    }
  } else {
    return null;
  }
};
