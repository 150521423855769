import * as React from "react";
import { SVGProps } from "react";

const MarkersIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.016}
    height={18.131}
    viewBox="0 0 15.016 18.131"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a"
        d="M11.508,1a7.517,7.517,0,0,1,7.508,7.508,8.625,8.625,0,0,1-1.156,4.121,16.2,16.2,0,0,1-2.485,3.329,23.316,23.316,0,0,1-3.59,3.09.5.5,0,0,1-.555,0,23.316,23.316,0,0,1-3.59-3.09,16.2,16.2,0,0,1-2.485-3.329A8.625,8.625,0,0,1,4,8.508,7.517,7.517,0,0,1,11.508,1Zm0,17.019c1.276-.925,6.508-5,6.508-9.511A6.508,6.508,0,0,0,5,8.508C5,13.024,10.233,17.094,11.508,18.019Z"
        transform="translate(-4.5 -1.5)"
      />
      <path
        className="a"
        d="M15.836,10A2.836,2.836,0,1,1,13,12.836,2.839,2.839,0,0,1,15.836,10Zm0,4.672A1.836,1.836,0,1,0,14,12.836,1.838,1.838,0,0,0,15.836,14.672Z"
        transform="translate(-8.828 -5.828)"
      />
    </g>
  </svg>
);

export default MarkersIconSmall;
