import TileLayer from "ol/layer/Tile";

import Auth from "../services/auth";
import {
  ViewConfig,
  ViewConfigResponse,
  Storage,
  Payload,
  ElevationProfilePayload,
} from "./AlchemyApiTypes";
import { createXyzLayer } from "./createXyzLayer";
import { OlMapSettings, ViewSettings } from "../map/types";
import XYZ from "ol/source/XYZ";
// import offlineConfig from "../offlineConfig.json";
import stageconfig from "../stageconfig.json";

const apiUrl = `https://ddqx4nvzh3.execute-api.us-east-1.amazonaws.com/${stageconfig.stage}`;

//const offline: boolean = false;

const mapboxApiKey =
  "pk.eyJ1IjoiY29ucmFkLWFkbWluIiwiYSI6ImNra3g3bnNqaTBqZjYydW9lMTRsejkzMXkifQ.4t7gfb9dT9pw0LUfIwHkJg";

async function fetchMapInfo(token: string): Promise<ViewConfigResponse[]> {
  const response = await fetch(`${apiUrl}/config`, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json();
}

export async function sendSupportEmail(
  auth: Auth,
  message: string
): Promise<{ body: string }> {
  const token = await auth.getToken();
  const response = await fetch(`${apiUrl}/sendemail`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      message: message,
      username: window.sessionStorage.getItem("Alchemy-currentUser"),
      date: new Date().toLocaleString(),
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function writeVectorSettings(
  auth: Auth,
  viewId: string,
  payload: Payload
): Promise<{ body: string }> {
  const token = await auth.getToken();
  const response = await fetch(`${apiUrl}/vectorsettings`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      viewId: viewId,
      username: window.sessionStorage.getItem("Alchemy-currentUser"),
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function writeElevationProfiles(
  auth: Auth,
  viewId: string,
  payload: ElevationProfilePayload,
  scope: string
): Promise<{ body: string }> {
  const token = await auth.getToken();
  const response = await fetch(`${apiUrl}/elevationprofile`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      viewId: viewId,
      username:
        scope === "private"
          ? window.sessionStorage.getItem("Alchemy-currentUser")
          : "public",
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function fetchVectorSettings(
  auth: Auth,
  viewId: string
): Promise<any> {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiUrl}/vectorsettings?username=${window.sessionStorage.getItem(
      "Alchemy-currentUser"
    )}&viewId=${viewId}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response.body;
  });
}

export async function fetchElevationProfiles(
  auth: Auth,
  viewId: string,
  scope: string
): Promise<any> {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiUrl}/elevationprofile?username=${
      scope === "private"
        ? window.sessionStorage.getItem("Alchemy-currentUser")
        : "public"
    }&viewId=${viewId}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response.body;
  });
}

export async function fetchElevation(
  auth: Auth,
  latitude: string,
  longitude: string
): Promise<any> {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiUrl}/elevationsql?latitude=${latitude}&longitude=${longitude}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function fetchCanopy(
  auth: Auth,
  latitude: string,
  longitude: string,
  level: number
): Promise<any> {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiUrl}/h3sql?latitude=${latitude}&longitude=${longitude}&level=${level}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function fetchParcel(
  auth: Auth,
  apn: string,
  fid: string
): Promise<any> {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiUrl}/douglasparcelsql?APN=${apn}&FID=${fid}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}
export async function fetchParcelReport(auth: Auth): Promise<any> {
  const token = await auth.getToken();
  const response = await fetch(`${apiUrl}/parcelreport`, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function fetchBuildings(
  auth: Auth,
  box: { minLon: number; maxLon: number; minLat: number; maxLat: number }
): Promise<any> {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiUrl}/buildings?minlat=${box.minLat.toString()}&minlon=${box.minLon.toString()}&maxlat=${box.maxLat.toString()}&maxlon=${box.maxLon.toString()}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}
export async function fetchFuel(
  auth: Auth,
  box: { minLon: number; maxLon: number; minLat: number; maxLat: number },
  vegtype: string
): Promise<any> {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiUrl}/fuel?minlat=${box.minLat.toString()}&minlon=${box.minLon.toString()}&maxlat=${box.maxLat.toString()}&maxlon=${box.maxLon.toString()}&vegtype=${vegtype}&hashlength=9`,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function fetchElevationAlongLine(
  auth: Auth,
  linePoints: string //LinePoints[]
): Promise<any> {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiUrl}/elevationalongline?linePoints=${linePoints}`,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response.body;
  });
}
////
async function fetchDownloadUrl(
  token: string,
  filename: string,
  resource: string
): Promise<{ body: string }> {
  const response = await fetch(`${apiUrl}/${resource}`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      file: filename,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export function constructFromConfig(config: ViewConfig): OlMapSettings {
  const viewSettings: ViewSettings = {
    center: config.viewport.center.map((v) => Number(v)),
    maxZoom: Number(config.viewport.maxZoom),
    minZoom: Number(config.viewport.minZoom),
  };
  //geojson layers handled elsewhere now so just filter out in case old version still requires this
  const layers = config.layers
    .filter((layer) => {
      return layer.type === "xyz";
    })
    .map((layer) => {
      switch (layer.type) {
        case "xyz":
          return createXyzLayer(layer);
        default:
          throw Error("Unknown layer type " + layer);
      }
    });

  const basemap = {
    name: "Street Map",
    layers: [createMapboxBaselayer()],
  };

  return {
    viewSettings,
    layers: [...layers, basemap],
  };
}

export async function getMapInfoByUser(
  auth: Auth
): Promise<ViewConfigResponse[]> {
  try {
    const token = await auth.getToken();
    const mapConfig = await fetchMapInfo(token);
    console.log("mapConfig", mapConfig);
    return mapConfig;
  } catch (error) {
    console.error("could not get config", error);
    throw error;
  }
}

export async function getFileDownloadUrl(
  auth: Auth,
  filepath: string,
  resource: string
): Promise<string> {
  try {
    const token = await auth.getToken();
    const downloadUrl = await fetchDownloadUrl(token, filepath, resource);
    return downloadUrl.body;
  } catch (error) {
    console.error("Could not get file URL", error);
    throw error;
  }
}

function createMapboxBaselayer() {
  return new TileLayer({
    source: new XYZ({
      attributions:
        '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
      url:
        "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/" +
        "{z}/{x}/{y}@2x?access_token=" +
        mapboxApiKey,
      crossOrigin: "Anonymous",
    }),
  });
}

export async function fetchData(url: string): Promise<{ body: object }> {
  const response = await fetch(url, {
    method: "GET",
  });

  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function fetchDataStorage(
  filepath: string,
  storage: Storage,
  auth: Auth,
  cacheKey: string | undefined,
  resource: string
): Promise<{}> {
  const key = cacheKey ? cacheKey : filepath;
  const cachedValue = storage[key];

  if (cachedValue) {
    if (resource === "fieldphotourl") {
      const imageObj: { imageURL: string } = cachedValue;
      return imageObj.imageURL;
    } else {
      return cachedValue;
    }
  } else {
    const url = await getFileDownloadUrl(auth, filepath, resource);
    const response = await fetch(url, {
      method: "GET",
    });

    if (resource === "fieldphotourl") {
      return response.blob().then((response) => {
        const imageURL = URL.createObjectURL(response);
        storage[key] = { imageURL: imageURL };
        return imageURL;
      });
    } else {
      return response.json().then((response) => {
        if (response.body?.errorMessage) {
          throw Error(response.body.errorMessage);
        }
        storage[key] = response;
        return response;
      });
    }
  }
}
