import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";

const polygonParcelLineStyle = new Style({
  stroke: new Stroke({
    color: "green",
    width: 1,
  }),
  fill: new Fill({
    color: "transparent",
  }),
});
const polygonHighlightBuildingLineStyle = new Style({
  stroke: new Stroke({
    color: "blue",
    width: 4,
  }),
  fill: new Fill({
    color: "transparent",
  }),
});
const polygonBuildingLineStyle = new Style({
  stroke: new Stroke({
    color: "blue",
    width: 2,
  }),
  fill: new Fill({
    color: "transparent",
  }),
});
const polygonZone0LineStyle = new Style({
  stroke: new Stroke({
    color: "#A7181C",
    width: 2,
  }),
  fill: new Fill({
    color: "transparent",
  }),
});
const polygonZone1LineStyle = new Style({
  stroke: new Stroke({
    color: "#E95E18",
    width: 2,
  }),
  fill: new Fill({
    color: "transparent",
  }),
});
const polygonZone2LineStyle = new Style({
  stroke: new Stroke({
    color: "#EB9C07",
    width: 2,
  }),
  fill: new Fill({
    color: "transparent",
  }),
});

export const getPolygonStyle = (type: string) => {
  const polystyle =
    type === "parcel"
      ? polygonParcelLineStyle
      : type === "building"
      ? polygonBuildingLineStyle
      : type === "zone0"
      ? polygonZone0LineStyle
      : type === "zone1"
      ? polygonZone1LineStyle
      : type === "zone2"
      ? polygonZone2LineStyle
      : polygonParcelLineStyle;

  return polystyle;
};

const fuelHeavyStyle = new Style({
  stroke: new Stroke({
    color: "#F83722",
    width: 0.1,
  }),
  fill: new Fill({
    color: "#F83722",
  }),
});

const fuelMediumStyle = new Style({
  stroke: new Stroke({
    color: "#FEB14E",
    width: 0.1,
  }),
  fill: new Fill({
    color: "#FEB14E",
  }),
});

const fuelLightStyle = new Style({
  stroke: new Stroke({
    color: "#FCEDA1",
    width: 0.1,
  }),
  fill: new Fill({
    color: "#FCEDA1",
  }),
});

export const getFuelStyle = (type: string) => {
  const fuelstyle =
    type === "heavy"
      ? fuelHeavyStyle
      : type === "medium"
      ? fuelMediumStyle
      : type === "light"
      ? fuelLightStyle
      : fuelHeavyStyle;
  return fuelstyle;
};

export const getBuildingHighlightStyle = (highlight: boolean) => {
  return highlight
    ? polygonHighlightBuildingLineStyle
    : polygonBuildingLineStyle;
};
