import React, { useContext } from "react";
import { iconstyles, iconstylesHighlight, icons } from "../../map/icons";
import { Loader } from "../Loader";
import { VideoObject } from "../../api/AlchemyApiTypes";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { ViewRefs } from "../../store/refs";
import Auth from "../../services/auth";

interface Props {
  auth: Auth;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const VideoClips: React.FC<Props> = ({ auth, viewrefs }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { mapInfo, videoitems } = store;
  const markerHeaders = mapInfo?.mapConfig?.view_config.markers;

  const toggleItems = (item: VideoObject) => {
    const newItems = videoitems?.map((i) => {
      if (i.video.id === item.video.id) {
        return { video: i.video, checked: !i.checked };
      } else {
        return i;
      }
    });
    viewrefs.current.videoitemsRef = newItems;
    dispatch({
      type: actionTypes.SET_VIDEO_ITEMS,
      payload: newItems,
    });
  };
  const isChecked = (item: VideoObject) => {
    return item.checked;
  };
  const isDisabled = (item: VideoObject) => {
    let count = 0;
    let lidarchecked = 0;
    //count checked items
    videoitems?.forEach((item) => {
      if (item.checked) {
        count++;
        if (item.video.type === "LiDAR") {
          lidarchecked++;
        }
      }
    });
    return item.checked || (count < 2 && lidarchecked < 1) ? false : true;
  };

  const highlightItem = (item: VideoObject, marker: string, action: string) => {
    viewrefs.current.markerVectorLayers[marker]?.setZIndex(
      action === "enter" ? 99 : 10
    );
    viewrefs.current.markerVectorLayers[marker]
      ?.getSource()
      .getFeatures()
      .forEach((feature) => {
        if (feature.getId() === item.video.id) {
          if (item.video.marker) {
            feature.setStyle(
              action === "enter"
                ? iconstylesHighlight[item.video.marker]
                : iconstyles[item.video.marker]
            );
            feature.set("System_RenderOrder", action === "enter" ? 2 : 0);
          }
        }
      });
  };

  const getHeader = (name: string) => {
    if (markerHeaders && name in markerHeaders) {
      return markerHeaders[name];
    } else return name;
  };

  const getVideoInputs = (videoitems: VideoObject[], marker: string) => {
    return videoitems
      .filter((item) => {
        return item.video.marker
          ? item.video.marker === marker
          : marker === "Ground";
      })
      .sort((a, b) => {
        if (a.video.displayName && b.video.displayName) {
          return a.video.displayName > b.video.displayName ? 1 : -1;
        } else return 0;
      })
      .map((item: VideoObject, i: number) => {
        return (
          <div
            key={i}
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <input
              style={{ accentColor: "#ffffff", cursor: "pointer" }}
              type="checkbox"
              onChange={() => toggleItems(item)}
              checked={isChecked(item)}
              disabled={isDisabled(item)}
            />
            <div
              style={{ marginLeft: 10 }}
              onMouseEnter={() => highlightItem(item, marker, "enter")}
              onMouseLeave={() => highlightItem(item, marker, "leave")}
            >
              {item.video.displayName}
            </div>
          </div>
        );
      });
  };
  if (
    mapInfo &&
    mapInfo.mapURL &&
    (mapInfo.mapURL.video_urls.length > 0 ||
      (mapInfo.mapStyle?.HostedClips &&
        Object.keys(mapInfo.mapStyle?.HostedClips).length > 0))
  ) {
    if (videoitems && videoitems.length > 0) {
      return (
        <div
          style={{
            fontSize: 14,
            fontWeight: 600,
            width: 245,
            backgroundColor: "transparent",
            padding: 20,
          }}
        >
          <div style={{ borderTop: "2px solid #757575", paddingTop: 10 }}></div>
          <div
            style={{
              maxHeight: 500,
              overflowY: "auto",
            }}
          >
            <div style={{ display: "flex" }}>
              <h6>{getHeader("Ground")}</h6>
              <img
                src={icons["Ground"]}
                alt="alt"
                style={{ width: 15, height: 15, marginTop: 3, marginLeft: 10 }}
              ></img>
            </div>
            {getVideoInputs(videoitems, "Ground")}

            <br></br>
            <div style={{ display: "flex" }}>
              <h6>{getHeader("Video3D")}</h6>
              <img
                src={icons["Video3D"]}
                alt="alt"
                style={{ width: 15, height: 15, marginTop: 3, marginLeft: 10 }}
              ></img>
            </div>
            {getVideoInputs(videoitems, "Video3D")}

            <br></br>
            <div style={{ display: "flex" }}>
              <h6>{getHeader("LiDAR")}</h6>
              <img
                src={icons["LiDAR"]}
                alt="alt"
                style={{ width: 15, height: 15, marginTop: 3, marginLeft: 10 }}
              ></img>
            </div>
            {getVideoInputs(videoitems, "LiDAR")}
            <br></br>
            <div style={{ display: "flex" }}>
              <h6>{getHeader("PSOM")}</h6>
              <img
                src={icons["PSOM"]}
                alt="alt"
                style={{ width: 15, height: 15, marginTop: 3, marginLeft: 10 }}
              ></img>
            </div>
            {getVideoInputs(videoitems, "PSOM")}
          </div>
        </div>
      );
    } else {
      return <Loader name={"Video Clips"} />;
    }
  } else {
    return null;
  }
};
