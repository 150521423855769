import React, { useContext } from "react";
import { StoreContext } from "../../store/store";
import { State } from "../../store/storetypes";
import * as fuelstyles from "./fuelstyles";

export const Fuel: React.FC = () => {
  const [store] = useContext(StoreContext) as [State];
  const { showlegend } = store;
  if (
    showlegend.Fuel_Light ||
    showlegend.Fuel_Medium ||
    showlegend.Fuel_Heavy
  ) {
    return (
      <div
        style={{
          // display: "inline-block",
          backgroundColor: "#000000",
          borderRadius: 5,
          paddingBottom: 10,
          maxHeight: 114,
          marginLeft: 5,
        }}
      >
        <div
          style={{
            fontSize: 14,
            fontWeight: 800,
            color: "#ffffff",
            borderBottom: "1px solid #969696",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
          }}
        >
          Fuel Color Key
        </div>
        <div>
          <div style={fuelstyles.fuel_colour_line}>
            <div style={fuelstyles.color_red_border}></div>
            <div style={fuelstyles.color_text}>Heavy Crown</div>
          </div>
          <div style={fuelstyles.fuel_colour_line}>
            <div style={fuelstyles.color_orange_border}></div>
            <div style={fuelstyles.color_text}>Medium Shrub</div>
          </div>
          <div style={fuelstyles.fuel_colour_line}>
            <div style={fuelstyles.color_cream_border}></div>
            <div style={fuelstyles.color_text}>Light Surface</div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const FuelHealth: React.FC = () => {
  const [store] = useContext(StoreContext) as [State];
  const { showlegend } = store;
  if (showlegend.FuelHealth) {
    return (
      <div
        style={{
          // display: "inline-block",
          backgroundColor: "#000000",
          borderRadius: 5,
          paddingBottom: 10,
          maxHeight: 114,
          marginLeft: 5,
        }}
      >
        <div
          style={{
            fontSize: 14,
            fontWeight: 800,
            color: "#ffffff",
            borderBottom: "1px solid #969696",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
          }}
        >
          Fuel Health Color Key
        </div>
        <div>
          <div style={fuelstyles.fuel_colour_line}>
            <div style={fuelstyles.color_green_border}></div>
            <div style={fuelstyles.color_text}>Good Health</div>
          </div>
          <div style={fuelstyles.fuel_colour_line}>
            <div style={fuelstyles.color_yellow_border}></div>
            <div style={fuelstyles.color_text}>Moderate Health</div>
          </div>
          <div style={fuelstyles.fuel_colour_line}>
            <div style={fuelstyles.color_red_border}></div>
            <div style={fuelstyles.color_text}>Poor Health</div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
