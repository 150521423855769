import * as React from "react";
import { SVGProps } from "react";

const MapIconLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29.528}
    height={27.025}
    viewBox="0 0 29.528 27.025"
    {...props}
  >
    <defs>{/* <style>{".a_MapIconLarge{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(1 1)">
      <path
        className="a_MapIconLarge"
        d="M10.259,2a1,1,0,0,1,.447.106l9.531,4.766,8.294-4.739a1,1,0,0,1,1.5.868V23.02a1,1,0,0,1-.5.868l-8.759,5a1,1,0,0,1-.943.026L10.29,24.154,2,28.893a1,1,0,0,1-1.5-.868V8A1,1,0,0,1,1,7.137l8.759-5A1,1,0,0,1,10.259,2Zm10.01,7a1,1,0,0,1-.447-.106L10.29,4.134,2.5,8.585V26.3l7.263-4.15a1,1,0,0,1,.943-.026l9.531,4.766,7.79-4.452V4.723l-7.263,4.15A1,1,0,0,1,20.269,9Z"
        transform="translate(-1.5 -3)"
      />
      <path
        className="a_MapIconLarge"
        d="M12,24.02a1,1,0,0,1-1-1V3a1,1,0,1,1,2,0V23.02A1,1,0,0,1,12,24.02Z"
        transform="translate(-3.241 -3)"
      />
      <path
        className="a_MapIconLarge"
        d="M24,30.02a1,1,0,0,1-1-1V9a1,1,0,0,1,2,0V29.02A1,1,0,0,1,24,30.02Z"
        transform="translate(-5.231 -3.995)"
      />
    </g>
  </svg>
);

export default MapIconLarge;
