import * as React from "react";
import { SVGProps } from "react";

const SidebarCollapsedLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41.816}
    height={18.12}
    viewBox="0 0 41.816 18.12"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#757575;}"}</style> */}</defs>
    <path
      className="a"
      d="M6.7,17.811A9.06,9.06,0,1,1,16.353,3.684l.1.169.184.243c.221.324.552.862,1.1,1.679.631,1,1.194,2.328,3.195,2.328s2.555-1.326,3.2-2.328c.464-.744.8-1.252,1.024-1.591a9.051,9.051,0,1,1-.088,9.634c-.2-.354-.53-.825-.935-1.473-.641-1-1.186-2.32-3.2-2.313s-2.563,1.311-3.2,2.313c-.464.722-.8,1.223-1.017,1.562A9.06,9.06,0,0,1,6.7,17.812ZM37.9,14.165a7.214,7.214,0,1,0-5.09,2.1h-.022A7.2,7.2,0,0,0,37.9,14.165ZM1.869,9.056A7.211,7.211,0,1,0,9.081,1.843h0A7.212,7.212,0,0,0,1.869,9.056Zm28.122,5.677V11.706l.951.552,1.849,1.1,2.6,1.473a6.312,6.312,0,0,1-5.4-.1Zm-23.729,0V11.706l.958.552,1.849,1.1,2.6,1.473a6.329,6.329,0,0,1-5.406-.1ZM33.685,12.81l.958-.552,1.841-1.1,2.6-1.473A6.329,6.329,0,0,1,36.3,14.32Zm-23.725,0,.951-.552,1.848-1.1,2.6-1.473a6.329,6.329,0,0,1-2.784,4.633ZM26.489,9.466,29.1,7.956v6.231a6.284,6.284,0,0,1-2.619-4.721Zm-23.732,0,2.652-1.51v6.231A6.3,6.3,0,0,1,2.753,9.466ZM36.48,3.927A6.3,6.3,0,0,1,39.1,8.649l-2.616,1.51Zm-23.71,0a6.321,6.321,0,0,1,2.615,4.758l-2.615,1.473ZM5.56,3.868,8.174,5.342l-.955.552L5.4,6.925,2.775,8.494A6.313,6.313,0,0,1,5.56,3.868Zm23.747-.029,2.615,1.473-.98.582-1.819,1.1-2.6,1.473a6.314,6.314,0,0,1,2.784-4.626ZM10.914,5.894,9.066,4.767l-2.6-1.473a6.313,6.313,0,0,1,5.4.1V6.439Zm23.725,0L32.805,4.767l-2.6-1.473a6.313,6.313,0,0,1,5.4.1V6.41Z"
      transform="translate(-0.005 -0.006)"
    />
  </svg>
);

export default SidebarCollapsedLogo;
