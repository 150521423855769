import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import { ColorPicker } from "../../api/AlchemyApiTypes";
import { ButtonComponent } from "../ButtonComponent";
import { VectorStyleStyle } from "../../api/AlchemyApiTypes";
import loadingGIF from "../../images/ZZ5H.gif";
import "./sketchPicker.css";

interface Props {
  //mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  colourPicker: ColorPicker | null;
  setColourPicker: React.Dispatch<React.SetStateAction<ColorPicker | null>>;
}

export const ColourPicker: React.FC<Props> = ({
  colourPicker,
  setColourPicker,
}) => {
  const [style, setStyle] = useState<VectorStyleStyle | null>(null);
  const [savePickerInProgress, setSavePickerInProgress] =
    useState<boolean>(false);
  const [property, setProperty] = useState<string>("linecolour");
  const [borderStyle, setBorderStyle] = useState<{
    linecolour: string;
    fill: string;
  }>({ linecolour: "2px solid black", fill: "2px solid #ffffff" });

  useEffect(() => {
    if (colourPicker) {
      setStyle(colourPicker.style);
      if (colourPicker.geometry !== "Point") {
        setProperty("linecolour");
      }
    }
  }, [colourPicker, setStyle, setProperty]);

  const DivDimensions = { height: 200, width: 200 };

  const handleChangeComplete = (color: any) => {
    if (style) {
      const newStyle: VectorStyleStyle = { ...style };
      if (property === "linecolour") {
        newStyle.linecolour = color.hex;
      } else {
        newStyle.fill = color.hex;
      }
      setStyle(newStyle);
    }
  };
  const handleSave = () => {
    setSavePickerInProgress(true);
    setTimeout(() => {
      if (colourPicker && style) {
        colourPicker.callback(colourPicker.key, style, setSavePickerInProgress);
        setColourPicker({
          ...colourPicker,
          show: false,
          style: style,
          key: "",
        });
      }
    }, 10);
  };
  const handleCancel = () => {
    if (colourPicker) {
      setColourPicker({
        ...colourPicker,
        show: false,
        key: "",
      });
    }
  };
  const handleTransparent = () => {
    if (style) {
      const newStyle: VectorStyleStyle = { ...style };
      if (property === "linecolour") {
        newStyle.linecolour = "transparent";
      } else {
        newStyle.fill = "transparent";
      }
      setStyle(newStyle);
    }
  };
  const handleFill = () => {
    setBorderStyle({
      linecolour: "2px solid #ffffff",
      fill: "2px solid black",
    });
    setProperty("fill");
  };
  const handleLine = () => {
    setBorderStyle({
      linecolour: "2px solid black",
      fill: "2px solid #ffffff",
    });
    setProperty("linecolour");
  };
  const handleRadius = (value: string) => {
    if (style) {
      setStyle({ ...style, radius: +value });
    }
  };
  const handleWidth = (value: string) => {
    if (style) {
      setStyle({ ...style, linewidth: +value });
    }
  };
  const getColour = () => {
    if (style) {
      if (property === "linecolour") {
        return style.linecolour;
      } else {
        return style.fill;
      }
    }
  };

  const ButtonOrLoading: React.FC<{ saveInProgress: boolean }> = ({
    saveInProgress,
  }) => {
    if (saveInProgress) {
      return (
        <div
          style={{
            display: "flex",
            width: "60px",
            marginRight: "10px",
            marginLeft: "0px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt="Loading" src={loadingGIF} style={{ width: "20px" }} />
        </div>
      );
    } else {
      return (
        <ButtonComponent
          name="Save"
          hoverStyle={{
            backgroundColor: "#757575",
            border: "2px solid #757575",
          }}
          style={{
            width: "60px",
            marginRight: "10px",
            marginLeft: "0px",
            border: "2px solid #FFFFFF",
            borderRadius: "2px",
            backgroundColor: "transparent",
            color: "#ffffff",
          }}
          onClick={() => handleSave()}
        />
      );
    }
  };

  if (colourPicker && colourPicker.show && style) {
    return (
      <div
        style={{
          position: "relative",
          display: "block",
          backgroundColor: "#363636",
          color: "#ffffff",
          height: DivDimensions.height + "px",
          width: DivDimensions.width + "px",
          marginLeft: "15px",
          marginTop: "-102px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            marginBottom: "5px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ButtonComponent
            name="Line"
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            style={{
              width: "40px",
              marginRight: "0px",
              marginLeft: "0px",
              border: borderStyle.linecolour,
              fontSize: "10px",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
            onClick={handleLine}
          />
          <ButtonComponent
            name="Fill"
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            style={{
              width: "40px",
              marginRight: "0px",
              marginLeft: "0px",
              border: borderStyle.fill,
              fontSize: "10px",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
            onClick={handleFill}
          />
          <div
            style={{
              display: "flex",
              fontSize: 9,
              fontWeight: "normal",
              marginRight: "0px",
              alignItems: "center",
            }}
          >
            <span>{colourPicker.geometry === "Point" ? "W" : "Width"}:</span>
            <input
              style={{
                width: 20,
                marginLeft: 2,
                color: "#ffffff",
                backgroundColor: "#363636",
              }}
              value={style.linewidth}
              type="text"
              onChange={(e) => handleWidth(e.target.value)}
            ></input>
          </div>
          {colourPicker.geometry === "Point" && (
            <div
              style={{
                display: "flex",
                fontSize: 9,
                marginRight: "0px",
                alignItems: "center",
              }}
            >
              <span>R:</span>
              <input
                style={{ width: 20, marginLeft: 2 }}
                value={style.radius}
                type="text"
                onChange={(e) => handleRadius(e.target.value)}
              ></input>
            </div>
          )}
          <div
            style={{
              width: "15px",
              height: "15px",
              border: `${style.linewidth}px solid ${style.linecolour}`,
              backgroundColor: style.fill,
              borderRadius: colourPicker.geometry === "Point" ? "30px" : "2px",
            }}
          />
        </div>
        <SketchPicker
          width={DivDimensions.width + "px"}
          color={getColour()}
          onChange={(color) => handleChangeComplete(color)}
        />
        <div style={{ display: "flex", marginTop: "5px" }}>
          <ButtonOrLoading saveInProgress={savePickerInProgress} />
          <ButtonComponent
            name="Cancel"
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            style={{
              width: "70px",
              marginRight: "10px",
              marginLeft: "0px",
              border: "2px solid #FFFFFF",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
            onClick={handleCancel}
          />
          <ButtonComponent
            name="Transparent"
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            style={{
              width: "110px",
              marginRight: "10px",
              marginLeft: "0px",
              border: "2px solid #FFFFFF",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
            onClick={handleTransparent}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};
