interface DivPosition {
  x: number;
  y: number;
}

export const getDefaultPosition = (
  height: number,
  width: number,
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined,
  position: DivPosition,
  count: number,
  index: number
) => {
  if (position.x === 0 && position.y === 0) {
    if (mapviewRoot && mapviewRoot.current) {
      const box = mapviewRoot.current.getBoundingClientRect();
      if (count === 1) {
        return {
          x: box.width / 2 - width / 2,
          y: box.height - height - 10,
        };
      } else {
        return index === 0
          ? {
              x: box.width / 2 - width,
              y: box.height - height,
            }
          : {
              x: box.width / 2,
              y: box.height - height - 10,
            };
      }
    } else {
      return { x: 0, y: 0 };
    }
  } else return position;
};
