import * as d3 from "d3";
// import { meterstofeet } from "../../../../map/math";

export function draw(container, config) {
  const {
    canopyHeight,
    canopycolorscale,
    width: cwidth,
    height: cheight,
    minElevation,
    maxElevation,
    layout,
    fontSize,
    textColor,
    markerColor,
    markerWidth,
    markerTextMargin,
    textBackground,
    suffix,
    decimalPlaces,
  } = config;

  /** d3 margin convention */

  const width = cwidth - layout.margin.left - layout.margin.right;
  const height = cheight - layout.margin.top - layout.margin.bottom;

  const svg = d3.select(container);
  svg.selectAll("g").remove();

  const background = svg.append("g");
  const legend = svg
    .append("g")
    .attr(
      "transform",
      "translate(" + layout.margin.left + "," + layout.margin.top + ")"
    );

  background
    .append("rect")
    .attr("width", cwidth)
    .attr("height", cheight)
    .attr("x", 0)
    .attr("y", 0)
    .style("fill", "none");

  background
    .append("rect")
    .style("fill", textBackground.color)
    .attr("y", 0)
    .attr("x", 0)
    .attr("width", cwidth)
    .attr("height", textBackground.height)
    .attr("rx", textBackground.borderradius)
    .attr("ry", textBackground.borderradius)
    .style("stroke", textBackground.stroke)
    .style("stroke-width", textBackground.strokeWidth);

  background
    .append("text")
    .style("fill", textBackground.stroke)
    .attr("y", 0)
    .attr("x", cwidth / 2)
    .attr("dy", "1em")
    .style("text-anchor", "middle")
    .style("font-size", fontSize)
    .text(maxElevation.toFixed(decimalPlaces) + suffix);

  background
    .append("rect")
    .style("fill", textBackground.color)
    .attr("y", cheight - layout.margin.top + textBackground.strokeWidth + 2)
    .attr("x", 0)
    .attr("width", cwidth)
    .attr("height", textBackground.height)
    .attr("rx", textBackground.borderradius)
    .attr("ry", textBackground.borderradius)
    .style("stroke", textBackground.stroke)
    .style("stroke-width", textBackground.strokeWidth);

  background
    .append("text")
    .style("fill", textBackground.stroke)
    .attr("y", cheight - layout.margin.top + textBackground.strokeWidth + 2)
    .attr("x", cwidth / 2)
    .attr("dy", "1em")
    .style("text-anchor", "middle")
    .style("font-size", fontSize)
    .text(minElevation.toFixed(decimalPlaces) + suffix);

  //Append a defs (for definition) element to your SVG
  var defs = legend.append("defs");

  //Append a linearGradient element to the defs and give it a unique id
  var linearGradient = defs
    .append("linearGradient")
    .attr("id", "linear-gradient");

  const data = [
    { color: canopycolorscale[0], value: 0 },
    { color: canopycolorscale[1], value: 100 },
  ];

  linearGradient
    .attr("x1", "0%")
    .attr("y1", "100%")
    .attr("x2", "0%")
    .attr("y2", "0%");

  linearGradient
    .selectAll("stop")
    .data(data)
    .enter()
    .append("stop")
    .attr("offset", (d) => d.value + "%")
    .attr("stop-color", (d) => d.color);

  let y = d3
    .scaleLinear()
    .range([height, 0])
    .domain([minElevation, maxElevation]);

  /** add to SVG */
  addRectangle();
  if (canopyHeight) {
    addmarker();
    addLabel();
  }

  function addRectangle() {
    legend
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("x", 0)
      .attr("y", 0)
      .style("fill", "url(#linear-gradient)");
  }
  function addmarker() {
    legend
      .append("rect")
      .attr("width", width)
      .attr("height", markerWidth)
      .attr("y", y(canopyHeight))
      .attr("x", 0)
      .attr("fill", markerColor);
  }
  function addLabel() {
    legend
      .append("text")
      .style("fill", textColor)
      .attr("y", y(canopyHeight) - markerTextMargin)
      .attr("x", width / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-size", fontSize)
      .text(canopyHeight.toFixed(decimalPlaces) + suffix);
  }
}
