import React, { useState, useEffect, useRef, useContext } from "react";
import { Rnd } from "react-rnd";
import { fromLonLat } from "ol/proj";
import { transform as oltransform } from "ol/proj";
import close from "../../../images/closeInverse.png";
import settings from "../../../images/settingsInverse.png";
import selecticon from "../../../images/selectOutlineInverse.png";
import charticon from "../../../images/chartInverse.png";
import {
  findPointAlongLine,
  findDistanceBetweenPoints,
  meterstofeet,
  feettometers,
} from "../../../map/math";
import {
  fetchElevation,
  fetchElevationProfiles,
  writeElevationProfiles,
} from "../../../api/AlchemyAnalytics";
import Auth from "../../../services/auth";
import {
  AreaChartConfig,
  DivPosition,
  ElevationProfileData,
  ElevationProfilePayload,
  Storage,
} from "../../../api/AlchemyApiTypes";
import { AreaChart } from "../../areaChart/AreaChartReact";
import {
  drawElevationPoints,
  drawActualProfileLine,
  clearLines,
  getClosestElevation,
  calculateSlope,
  setSlopeLine,
  removeSlopeLine,
  polySelectPoints,
} from "../../../map/functions";
import { getDefaultPosition } from "../functions";
import { ElevationProfileControls } from "./ElevationProfileControls";
import { TabBar } from "./TabBar";
import { DrawNewProfile } from "./DrawNewProfile";
import { DisplaySavedProfiles } from "./DisplaySavedProfiles";
import { StoreContext } from "../../../store/store";
import { State, Action, actionTypes } from "../../../store/storetypes";
import { ViewRefs } from "../../../store/refs";

interface Props {
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  auth: Auth;
  setDashboardSettingsMode: (mode: string | null) => void;
  viewId: string;
  setSlopePoints: (val: number[]) => void;
  viewrefs: React.MutableRefObject<ViewRefs>;
  storage: Storage;
}

export const ElevationProfile: React.FC<Props> = ({
  mapviewRoot,
  auth,
  setDashboardSettingsMode,
  viewId,
  setSlopePoints,
  viewrefs,
  storage,
}) => {
  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const [tabindex, setTabindex] = useState<number>(0);
  const {
    dashboardSettings,
    dashboardSettingModeState,
    elevationProfileData,
    elevationProfileDataLoaded,
    profileLineReady,
    polySelectReady,
    selectedElevationPoints,
    slope,
    slopeChanged,
    mapConfig,
  } = store;

  const map = viewrefs.current.map;

  const polySelectable =
    mapConfig?.bucket_item_styles?.dashboards &&
    mapConfig?.bucket_item_styles?.dashboards["ElevationProfile"] &&
    mapConfig?.bucket_item_styles?.dashboards["ElevationProfile"]
      .specificSettings &&
    mapConfig?.bucket_item_styles?.dashboards["ElevationProfile"]
      .specificSettings.elevationProfile
      ? mapConfig?.bucket_item_styles?.dashboards["ElevationProfile"]
          .specificSettings.elevationProfile.polySelectable
      : undefined;

  const setElevationProfileData = (val: ElevationProfileData[]) => {
    dispatch({
      type: actionTypes.SET_ELEVATION_PROFILE_DATA,
      payload: val,
    });
  };
  const setElevationProfileDataLoaded = (val: boolean) => {
    dispatch({
      type: actionTypes.SET_ELEVATION_PROFILE_DATA_LOADED,
      payload: val,
    });
  };

  const [intervalft, setIntervalft] = useState<number>(30);
  const [disableDrag, setDisableDrag] = useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [showSelectionOptions, setShowSelectionOptions] =
    useState<boolean>(true);
  const [selectedSegment, setSelectedSegment] = useState<{
    w: number | undefined;
    e: number | undefined;
  }>({ e: undefined, w: undefined });
  const [privateElevationProfiles, setPrivateElevationProfiles] =
    useState<ElevationProfilePayload>({});
  const [publicElevationProfiles, setPublicElevationProfiles] =
    useState<ElevationProfilePayload>({});

  const selectedProfileName = useRef<string | null>(null);
  const setSelectedProfileName = (name: string | null) => {
    selectedProfileName.current = name;
  };

  const onClose = () => {
    // dashboardCleanupComponents("ElevationProfile");
    // setSelected("ElevationProfile");
    const newSettings = { ...dashboardSettings };
    newSettings["ElevationProfile"].selected = false;
    dispatch({
      type: actionTypes.SET_DASHBOARD_SETTINGS,
      payload: newSettings,
    });
  };
  const onSettings = () => {
    setShowControls(!showControls);
  };
  const onSelect = () => {
    setShowSelectionOptions(true);
  };
  const onChart = () => {
    setShowSelectionOptions(false);
  };

  useEffect(() => {
    let mounted = true;
    if (
      viewrefs.current.slopePoints[0] === 0 &&
      viewrefs.current.slopePoints[1] === 0
    ) {
      //do nothing
    } else {
      if (mounted) {
        dispatch({
          type: actionTypes.SET_SLOPE,
          payload: calculateSlope(
            viewrefs.current.slopePoints,
            elevationProfileData
          ),
        });
        removeSlopeLine(viewrefs);
        setSlopeLine(
          viewrefs.current.slopePoints,
          elevationProfileData,
          viewrefs
        );
      }
    }
    return () => {
      mounted = false;
    };
  }, [viewrefs, dispatch, slopeChanged, elevationProfileData]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const asyncOnLoad = async () => {
        const privateelevationprofiles: ElevationProfilePayload =
          await fetchElevationProfiles(auth, viewId, "private");
        setPrivateElevationProfiles(privateelevationprofiles);
        const publicelevationprofiles: ElevationProfilePayload =
          await fetchElevationProfiles(auth, viewId, "public");
        setPublicElevationProfiles(publicelevationprofiles);
      };
      asyncOnLoad();
    }
    return () => {
      mounted = false;
    };
  }, [auth, viewId]);

  useEffect(() => {
    let mounted = true;
    if (profileLineReady && mounted) {
      const asyncFunction = async () => {
        viewrefs.current.drawProfileLine = false;
        let c = [
          fromLonLat(viewrefs.current.profileLineCoordinates[0], "EPSG:3857"),
          fromLonLat(viewrefs.current.profileLineCoordinates[1], "EPSG:3857"),
        ];
        if (c[0][0] > c[1][0]) {
          //reverse points
          c = [c[1], c[0]];
        }
        let tempdata: ElevationProfileData[] = [];
        const interval: number = feettometers(intervalft);
        const d = findDistanceBetweenPoints(c);
        viewrefs.current.distanceBetweenPoints = meterstofeet(d);

        let linecoordinate: number[][] = [];
        let i: number = 0;
        for (; i < d; i += interval) {
          const newpoint = findPointAlongLine(c, i);
          const coordinate = oltransform(newpoint, "EPSG:3857", "EPSG:4326");
          linecoordinate.push([i, coordinate[0], coordinate[1]]);
        }
        //Add Last Point
        const remaining = d - (i - interval);

        if (remaining > 0) {
          i = i - interval + remaining;
          const newpoint = findPointAlongLine(c, i);
          const coordinate = oltransform(newpoint, "EPSG:3857", "EPSG:4326");
          linecoordinate.push([i, coordinate[0], coordinate[1]]);
        }

        drawActualProfileLine(linecoordinate, viewrefs, map);

        for (let i = 0; i < linecoordinate.length; i++) {
          if (mounted) {
            let coord = linecoordinate[i];
            const ret = await fetchElevation(
              auth,
              coord[2].toString(),
              coord[1].toString()
            );
            let elevation: number = 0;
            let distance: number = 0;
            if (ret) {
              elevation = meterstofeet(
                getClosestElevation(ret, [coord[2], coord[1]])
              );
              if (elevation > 0) {
                distance = meterstofeet(coord[0]);
                tempdata.push({
                  id: i,
                  distance: distance,
                  elevation: elevation,
                  coordinates: [coord[2], coord[1]],
                });
              }
            } else {
              console.log("elevation", coord[0], 0);
            }
            drawElevationPoints(
              coord[2],
              coord[1],
              elevation,
              distance,
              i,
              viewrefs
            );
            if (mounted) {
              dispatch({
                type: actionTypes.SET_ELEVATION_PROFILE_DATA,
                payload: [...tempdata],
              });
              viewrefs.current.refElevationProfileData = [...tempdata];
            }
          } else {
            break;
          }
        }
        //complete
        if (mounted) {
          dispatch({
            type: actionTypes.SET_ELEVATION_PROFILE_DATA_LOADED,
            payload: true,
          });
          setShowSelectionOptions(false);
        }
      };
      asyncFunction();
    }
    return () => {
      mounted = false;
    };
  }, [viewrefs, dispatch, profileLineReady, auth, intervalft, map]);

  useEffect(() => {
    let mounted = true;
    if (polySelectReady && mounted) {
      const asyncFunction = async () => {
        viewrefs.current.drawPolySelect = false;
        let tempdata: ElevationProfileData[] = [];
        let linecoordinate: number[][] = [];

        linecoordinate = await polySelectPoints(
          viewrefs,
          map,
          polySelectable,
          storage,
          auth
        );

        if (linecoordinate.length === 0) return;

        const d = linecoordinate[linecoordinate.length - 1][0];

        viewrefs.current.distanceBetweenPoints = meterstofeet(d);
        drawActualProfileLine(linecoordinate, viewrefs, map);

        for (let i = 0; i < linecoordinate.length; i++) {
          if (mounted) {
            let coord = linecoordinate[i];
            const ret = await fetchElevation(
              auth,
              coord[2].toString(),
              coord[1].toString()
            );
            let elevation: number = 0;
            let distance: number = 0;
            if (ret) {
              elevation = meterstofeet(
                getClosestElevation(ret, [coord[2], coord[1]])
              );
              distance = meterstofeet(coord[0]);
              tempdata.push({
                id: i,
                distance: distance,
                elevation: elevation,
                coordinates: [coord[2], coord[1]],
              });
            } else {
              console.log("elevation", coord[0], 0);
            }
            drawElevationPoints(
              coord[2],
              coord[1],
              elevation,
              distance,
              i,
              viewrefs
            );
            if (mounted) {
              dispatch({
                type: actionTypes.SET_ELEVATION_PROFILE_DATA,
                payload: [...tempdata],
              });
              viewrefs.current.refElevationProfileData = [...tempdata];
            }
          } else {
            break;
          }
        }
        //complete
        if (mounted) {
          dispatch({
            type: actionTypes.SET_ELEVATION_PROFILE_DATA_LOADED,
            payload: true,
          });
          setShowSelectionOptions(false);
        }
      };
      asyncFunction();
    }
    return () => {
      mounted = false;
    };
  }, [viewrefs, dispatch, polySelectReady, polySelectable, auth, map, storage]);

  useEffect(() => {}, [selectedSegment]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch({
        type: actionTypes.SET_ELEVATION_PROFILE_DATA,
        payload: [],
      });
      dispatch({
        type: actionTypes.SET_ELEVATION_PROFILE_DATA_LOADED,
        payload: false,
      });
    }
    return () => {
      mounted = false;
      dispatch({
        type: actionTypes.SET_CLEAR_DASHBOARD,
        payload: "ElevationProfile",
      });
    };
  }, [dispatch]);

  const saveElevationProfile = (
    setSaveInProgress: React.Dispatch<React.SetStateAction<boolean>>,
    name: string
  ) => {
    const asyncSaveElevationProfile = async () => {
      const payload: ElevationProfilePayload = JSON.parse(
        JSON.stringify(privateElevationProfiles)
      );
      payload[name] = {
        lineData: elevationProfileData.map((item) => {
          return {
            ...item,
            elevation: item.elevation.toString(),
            distance: item.distance.toString(),
            coordinates: item.coordinates.map((coord: any) => {
              return coord.toString();
            }),
          };
        }),
      };
      try {
        const ret = await writeElevationProfiles(
          auth,
          viewId,
          payload,
          "private"
        );
        if (ret.body === "saved") {
          setPrivateElevationProfiles(payload);
        } else {
          throw JSON.stringify(ret);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setSaveInProgress(false);
      }
    };
    asyncSaveElevationProfile();
  };

  const DashboardDimensions = { height: 460, width: 800 };
  const onDrawClick = () => {
    clearLines(
      setElevationProfileData,
      setElevationProfileDataLoaded,
      viewrefs
    );
    viewrefs.current.profileLineCoordinates = [
      [0, 0],
      [0, 0],
    ];
    dispatch({
      type: actionTypes.SET_PROFILE_LINE_READY,
      payload: false,
    });
    setShowSelectionOptions(false);
    viewrefs.current.drawProfileLine = true;
    setSelectedProfileName(null);
  };

  const onPolySelectClick = () => {
    viewrefs.current.polySelectCoordinates = [];
    dispatch({
      type: actionTypes.SET_POLY_SELECT_READY,
      payload: false,
    });
    setShowSelectionOptions(false);
    viewrefs.current.drawPolySelect = true;
    setSelectedProfileName(null);
  };

  const config: AreaChartConfig = {
    data: elevationProfileData,
    highlightedpoint: selectedElevationPoints[0],
    width: 750,
    height: 410,
    minElevation: 1890,
    maxElevation: 2540,
    fontSize: { x: 14, y: 13 },
    colours: {
      chart: "#000000",
      textColor: { x: "#ffffff", y: "#ffffff" },
      brushbar: { x: "#6B7280", y: "#6B7280" },
      brushbackground: { x: "#7F7F7F", y: "#7F7F7F" },
    },
    layout: {
      margin: { top: 30, right: 30, bottom: 60, left: 80 },
    },
    setSelectedSegment: setSelectedSegment,
  };

  return (
    <>
      <Rnd
        position={getDefaultPosition(
          DashboardDimensions.height,
          DashboardDimensions.width,
          position,
          mapviewRoot
        )}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        disableDragging={disableDrag}
        style={{ zIndex: 9999 }}
      >
        <div
          style={{
            position: "absolute",
            display: "block",
            backgroundColor: "#000000",
            height: DashboardDimensions.height + "px",
            width: DashboardDimensions.width + "px",
            zIndex: 999,
            // border: "1px solid black",
            padding: "1rem",
            borderRadius: 4,
            color: "#ffffff",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              position: "absolute",
              top: 10,
              left: 0,
              pointerEvents: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 18,
              }}
            >
              {"Elevation & Planning"}
            </div>
          </div>
          <div style={{ position: "absolute", top: 5, right: 40 }}>
            {elevationProfileData && elevationProfileData.length > 1 && (
              <div>
                {`Length of line: ${viewrefs.current.distanceBetweenPoints.toFixed(
                  1
                )} ft`}{" "}
              </div>
            )}
          </div>
          {elevationProfileData && elevationProfileData.length > 1 && (
            <div
              style={{
                position: "absolute",
                top: 5,
                left: 160,
                display: "flex",
              }}
            >
              <div>{`Slope: ${slope.toFixed(2)} `} </div>
            </div>
          )}
          {elevationProfileData &&
            elevationProfileData.length > 1 &&
            elevationProfileDataLoaded && (
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  top: 5,
                  left: 5,
                }}
              >
                <img
                  alt="settings"
                  src={settings}
                  style={{ width: 20, marginRight: 10, cursor: "pointer" }}
                  onClick={() => onSettings()}
                ></img>
                {!showSelectionOptions && (
                  <img
                    alt="select"
                    src={selecticon}
                    style={{ width: 20, cursor: "pointer" }}
                    onClick={() => onSelect()}
                  ></img>
                )}
                {showSelectionOptions && (
                  <img
                    alt="chart"
                    src={charticon}
                    style={{ width: 20, cursor: "pointer" }}
                    onClick={() => onChart()}
                  ></img>
                )}
              </div>
            )}
          <img
            alt="close"
            src={close}
            style={{
              width: 20,
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
            onClick={() => onClose()}
          ></img>

          {showSelectionOptions && (
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <TabBar tabindex={tabindex} setTabindex={setTabindex} />
            </div>
          )}

          {tabindex === 0 &&
            (showSelectionOptions ||
              (elevationProfileData && elevationProfileData.length < 2)) && (
              <DrawNewProfile
                intervalft={intervalft}
                setIntervalft={setIntervalft}
                setDisableDrag={setDisableDrag}
                topMargin={showSelectionOptions ? 0 : 50}
                onDrawClick={onDrawClick}
                onPolySelectClick={onPolySelectClick}
                polySelectable={polySelectable}
                viewrefs={viewrefs}
              />
            )}
          {tabindex === 1 && showSelectionOptions && (
            <DisplaySavedProfiles
              savedElevationProfiles={privateElevationProfiles}
              destinationElevationProfiles={publicElevationProfiles}
              map={map}
              setSelectedProfileName={setSelectedProfileName}
              setShowSelectionOptions={setShowSelectionOptions}
              scope="private"
              auth={auth}
              viewId={viewId}
              setSavedElevationProfiles={setPrivateElevationProfiles}
              setDestinationElevationProfiles={setPublicElevationProfiles}
              viewrefs={viewrefs}
            />
          )}
          {tabindex === 2 && showSelectionOptions && (
            <DisplaySavedProfiles
              savedElevationProfiles={publicElevationProfiles}
              destinationElevationProfiles={privateElevationProfiles}
              map={map}
              setSelectedProfileName={setSelectedProfileName}
              setShowSelectionOptions={setShowSelectionOptions}
              scope="public"
              auth={auth}
              viewId={viewId}
              setSavedElevationProfiles={setPublicElevationProfiles}
              setDestinationElevationProfiles={setPrivateElevationProfiles}
              viewrefs={viewrefs}
            />
          )}

          <div
            style={{
              display: "flex",
              marginTop: "5px",
              justifyContent: "center",
            }}
          >
            {elevationProfileData &&
              elevationProfileData.length > 1 &&
              !showSelectionOptions && <AreaChart config={config} />}
          </div>
        </div>
      </Rnd>
      {showControls && (
        <ElevationProfileControls
          mapviewRoot={mapviewRoot}
          setDashboardSettingsMode={setDashboardSettingsMode}
          dashboardSettingModeState={dashboardSettingModeState}
          saveElevationProfile={saveElevationProfile}
          selectedProfileName={selectedProfileName}
          setSelectedProfileName={setSelectedProfileName}
          privateElevationProfiles={privateElevationProfiles}
          setSlopePoints={setSlopePoints}
          viewrefs={viewrefs}
        />
      )}
    </>
  );
};
