import * as React from "react";
import { SVGProps } from "react";

const LayersIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.574}
    height={16.574}
    viewBox="0 0 16.574 16.574"
    {...props}
  >
    <defs>{/* <style>{".a_LayersIconSmall{fill:#fff;}"}</style> */}</defs>
    <g transform="translate(0.5 0.5)">
      <path
        className="a_LayersIconSmall"
        d="M10.787,2.5a.5.5,0,0,1,.224.053L18.8,6.446a.5.5,0,0,1,0,.894L11.01,11.234a.5.5,0,0,1-.447,0L2.776,7.341a.5.5,0,0,1,0-.894l7.787-3.893A.5.5,0,0,1,10.787,2.5Zm6.669,4.393L10.787,3.559,4.118,6.893l6.669,3.334Z"
        transform="translate(-3 -3)"
      />
      <path
        className="a_LayersIconSmall"
        d="M10.787,29.893a.5.5,0,0,1-.224-.053L2.776,25.947a.5.5,0,0,1,.447-.894l7.563,3.782,7.563-3.782a.5.5,0,0,1,.447.894L11.01,29.841A.5.5,0,0,1,10.787,29.893Z"
        transform="translate(-3 -13.82)"
      />
      <path
        className="a_LayersIconSmall"
        d="M10.787,22.393a.5.5,0,0,1-.224-.053L2.776,18.447a.5.5,0,0,1,.447-.894l7.563,3.782,7.563-3.782a.5.5,0,0,1,.447.894L11.01,22.341A.5.5,0,0,1,10.787,22.393Z"
        transform="translate(-3 -10.213)"
      />
    </g>
  </svg>
);

export default LayersIconSmall;
