import React, { useState, useContext } from "react";
import close from "../../images/close.png";
import { Rnd } from "react-rnd";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { getDefaultPosition } from "../dashboards/functions";
import { DivPosition } from "../../api/AlchemyApiTypes";

interface Props {
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
}

export const FieldPhotoSelected: React.FC<Props> = ({ mapviewRoot }) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];

  const { selectedFieldPhoto } = store;

  const exitSelected = () => {
    dispatch({
      type: actionTypes.SET_SELECTED_FIELD_PHOTO,
      payload: null,
    });
  };

  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });
  const [disabledrag, setdisabledrag] = useState<boolean>(true);
  const DivDimensions = { height: 407, width: 550 };

  if (selectedFieldPhoto) {
    return (
      <Rnd
        position={getDefaultPosition(
          DivDimensions.height,
          DivDimensions.width,
          position,
          mapviewRoot
        )}
        onDragStop={(e, d) => {
          setPosition({ x: d.x, y: d.y });
        }}
        style={{ zIndex: 9999 }}
        disableDragging={disabledrag}
      >
        <div
          style={{
            position: "absolute",
            display: "block",
            backgroundColor: "white",
            width: DivDimensions.width + "px",
            zIndex: 999,
            border: "1px solid black",
          }}
        >
          <img
            alt="close"
            src={close}
            style={{
              width: 20,
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
            onClick={() => exitSelected()}
          ></img>
          <div
            style={{ paddingLeft: "5px", fontSize: "10px", height: "20px" }}
            onMouseEnter={() => setdisabledrag(false)}
            onMouseLeave={() => setdisabledrag(true)}
          >
            {selectedFieldPhoto.name}
          </div>

          <img
            alt="jpg"
            src={selectedFieldPhoto.src}
            style={{
              width: DivDimensions.width - 1,
            }}
          />
        </div>
      </Rnd>
    );
  } else {
    return null;
  }
};
