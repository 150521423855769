import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import { iconstyles } from "../../map/icons";

import { setMarkers } from "../../map/functions";
import { getFileMarkers } from "../files/functions";
import { ViewRefs } from "../../store/refs";
import Auth from "../../services/auth";
import { setStorage, getStorage } from "../../helpers/localStorage";
import { createEmptyVectorLayer } from "../../api/createEmptyVectorLayer";
import { updateVectorSource } from "../../api/updateVectorSource";
import { getFileName } from "../../utils/download";
import { Action, actionTypes } from "../../store/storetypes";
import { fetchVectorSettings } from "../../api/AlchemyAnalytics";
import {
  DashboardSettings,
  VectorLayerConfig,
  defaultStyle,
  MapLayer,
  Storage,
  VectorStyle,
  VectorShowProperties,
  UserVectorStyles,
  UserSettings,
  VectorStyleStyle,
  defaultHeatmapStyle,
  ViewInfo,
} from "../../api/AlchemyApiTypes";

interface ActiveFeatures {
  [key: string]: boolean;
}

export const exitTasks = (
  header: string,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  switch (header) {
    case "My Files":
      viewrefs.current.markerVectorLayers["Summary"]?.setZIndex(10);
      break;
    case "Photos":
      viewrefs.current.markerVectorLayers["Field Photos"]?.setZIndex(10);
      break;
  }
};
export const enterTasks = (
  header: string,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  switch (header) {
    case "My Files":
      viewrefs.current.markerVectorLayers["Summary"]?.setZIndex(99);
      break;
    case "Photos":
      viewrefs.current.markerVectorLayers["Field Photos"]?.setZIndex(99);
      break;
  }
};
export const onLoadffects = (
  header: string,
  viewrefs: React.MutableRefObject<ViewRefs>,
  mapInfo: ViewInfo | null,
  mounted: boolean,
  activeFeatures: React.MutableRefObject<ActiveFeatures>,
  triggerRefresh: () => void,
  dispatch: React.Dispatch<Action>,
  auth: Auth,
  storage: Storage
) => {
  const setloadedlayers = (key: string) => {
    if (mounted) {
      viewrefs.current.loadedlayers = {
        ...viewrefs.current.loadedlayers,
        [key]: true,
      };
      dispatch({
        type: actionTypes.SET_LOADED_VECTOR_LAYERS,
        payload: { ...viewrefs.current.loadedlayers },
      });
      dispatch({
        type: actionTypes.SET_FORCE_UPDATE,
        payload: Math.random(),
      });
    }
  };
  const getVectorLayerConfig = (filepath: string, info: VectorLayerConfig) => {
    const layerConfig: VectorLayerConfig = {
      ...info,
      filepath: filepath,
      displayOnLoad: true,
    };
    return layerConfig;
  };
  async function loadVectorLayers() {
    if (mapInfo && viewrefs.current.map) {
      const savedUserSettings: UserSettings = await fetchVectorSettings(
        auth,
        viewrefs.current.viewId
      );
      let localStorage: any = getStorage(null);
      const { mapStyle, mapURL } = mapInfo;
      if (mapStyle && mapURL) {
        const { vector_urls } = mapURL;
        const vector_style: VectorStyle = mapStyle?.VectorStyle;
        //If no vector layors defined return null
        if (vector_urls && vector_urls.length > 0) {
          let vector_info: VectorLayerConfig[] = [];
          vector_info = vector_urls.map((item: string, i: number) => {
            const key = getFileName(item);
            if (key in vector_style) {
              vector_style[key].displayName = vector_style[key].displayName
                ? vector_style[key].displayName
                : key.split("_").join(" ");

              return vector_style[key];
            } else {
              //return default
              return {
                key: key,
                displayName: key.split("_").join(" "),
                type: "geojson",
                url: "",
                filepath: "",
                displayOnLoad: false,
                propertiesOnLoad: false,
                parcelAnalysis: false,
                parcelAnalysisDefault: false,
                style: defaultStyle,
                geometry: "Line",
                heatmapOnly: false,
                heatmapStyle: defaultHeatmapStyle,
              };
            }
          });

          let newLoaded: {
            [key: string]: boolean;
          } = {};
          let newStyles: UserVectorStyles = {};
          let showProperties: VectorShowProperties = {};
          for (let i = 0; i < vector_urls.length; i++) {
            newLoaded[vector_info[i].key] = false;
            let propsOnLoad: boolean = false;
            if (
              vector_info[i].propertiesOnLoad ||
              (savedUserSettings &&
                savedUserSettings[vector_info[i].key] &&
                savedUserSettings[vector_info[i].key].showproperties)
            ) {
              propsOnLoad = true;
            }
            showProperties[vector_info[i].key] = propsOnLoad;
          }
          viewrefs.current.loadedlayers = newLoaded;
          dispatch({
            type: actionTypes.SET_LOADED_VECTOR_LAYERS,
            payload: { ...viewrefs.current.loadedlayers },
          });
          const newVectorLayers: MapLayer[] = [];
          for (let i = 0; i < vector_urls.length; i++) {
            const url = vector_urls[i];
            const info = vector_info[i];
            const displayonload: boolean =
              (savedUserSettings &&
                savedUserSettings[info.key] &&
                savedUserSettings[info.key].visible) ||
              info.displayOnLoad
                ? true
                : false;

            if (info.key in localStorage) {
              newStyles[info.key] = localStorage[info.key];
            } else if (savedUserSettings && savedUserSettings[info.key]) {
              newStyles[info.key] = savedUserSettings[info.key].style;
              localStorage[info.key] = savedUserSettings[info.key].style;
            } else {
              let newColour: VectorStyleStyle = info.style;
              newStyles[info.key] = newColour;
              localStorage[info.key] = newColour;
            }
            const newLayer: VectorLayer<VectorSource<Geometry>> =
              createEmptyVectorLayer(
                // info,
                displayonload,
                newStyles[info.key]
              );
            const newConfig = getVectorLayerConfig(url, info);

            const newMapLayer: MapLayer = {
              layers: [newLayer],
              config: info,
            };

            viewrefs.current.map.addLayer(newLayer);
            newVectorLayers.push(newMapLayer);
            updateVectorSource(
              newLayer,
              newConfig,
              setloadedlayers,
              storage,
              auth
            );
          }
          dispatch({
            type: actionTypes.SET_VECTOR_LAYERS,
            payload: newVectorLayers,
          });
          dispatch({
            type: actionTypes.SET_VECTOR_SHOW_PROPERTIES,
            payload: showProperties,
          });
          dispatch({
            type: actionTypes.SET_USER_VECTOR_STYLES,
            payload: newStyles,
          });
          setStorage(null, localStorage);
          activeFeatures.current["Map Lines"] = true;
          triggerRefresh();
        }
      }
      // activeFeatures.current["Map Lines"] = true;
      // triggerRefresh();
    }
  }
  const getFieldPhotoInfo = (mapInfo: ViewInfo | null) => {
    if (mapInfo) {
      const { mapStyle, mapURL } = mapInfo;
      const fieldphotos = mapURL?.fieldphoto_urls?.sort() || [];
      const fieldphoto_style = mapStyle?.FieldPhotoStyle;

      return fieldphotos.map((item) => {
        const key = getFileName(item);
        if (fieldphoto_style && key in fieldphoto_style) {
          return {
            id: "fieldphoto_" + item,
            fileLocation: item,
            displayName: fieldphoto_style[key].displayName
              ? fieldphoto_style[key].displayName
              : key.split(".")[0].split("_").join(" "),
            mapCoordinates: fieldphoto_style[key].coordinates,
            datetime: fieldphoto_style[key].datetime,
            marker: fieldphoto_style[key].marker,
          };
        } else {
          return {
            id: "fieldphoto_" + item,
            fileLocation: item,
            displayName: key.split(".")[0].split("_").join(" "),
            mapCoordinates: [],
            datetime: "",
            marker: "",
          };
        }
      });
    }
  };
  async function loadPhotos() {
    if (mapInfo) {
      //get available fieldphotos
      const fieldphotos = getFieldPhotoInfo(mapInfo);
      if (fieldphotos && fieldphotos.length > 0) {
        const newState = fieldphotos.map((item) => ({
          fieldphoto: item,
          checked: false,
        }));

        viewrefs.current.fieldphotoitemsRef = newState;
        dispatch({
          type: actionTypes.SET_FIELD_PHOTO_ITEMS,
          payload: newState,
        });
        //add maplayer
        const markers = fieldphotos
          .filter((item) => {
            return (
              item.mapCoordinates &&
              item.mapCoordinates?.length > 0 &&
              item.marker
            );
          })
          .map((item, i) => {
            const newFeature = new Feature({
              geometry: new Point(
                fromLonLat(
                  item.mapCoordinates ? item.mapCoordinates : [1, 1],
                  "EPSG:3857"
                )
              ),
            });
            newFeature.setId(item.id);
            if (item.marker) {
              newFeature.setStyle(iconstyles[item.marker]);
            }
            newFeature.setProperties({
              Class: "Field Photos",
              Name: item.displayName,
              Longitude: item.mapCoordinates
                ? item.mapCoordinates[0]
                : undefined,
              Latitude: item.mapCoordinates
                ? item.mapCoordinates[1]
                : undefined,
              Date: item.datetime ? item.datetime : undefined,
              // Coordinates: item.mapCoordinates,
              System_RenderOrder: 0,
            });
            return newFeature;
          });
        if (markers.length > 0) {
          const vectorSource = new VectorSource({
            features: markers,
          });
          viewrefs.current.markerVectorLayers["Field Photos"] = new VectorLayer(
            {
              source: vectorSource,
              zIndex: 10,
              renderOrder: function (a, b) {
                return (
                  a.get("System_RenderOrder") - b.get("System_RenderOrder")
                );
              },
            }
          );
          if (viewrefs.current.map) {
            viewrefs.current.map.addLayer(
              viewrefs.current.markerVectorLayers["Field Photos"]
            );
          }
        }
        activeFeatures.current["Photos"] = true;
        triggerRefresh();
      }
    }
  }
  const getVideoInfo = (mapInfo: ViewInfo | null) => {
    if (mapInfo) {
      const { mapStyle, mapURL } = mapInfo;
      const videos = mapURL?.video_urls?.sort() || [];
      const video_style = mapStyle?.VideoStyle;

      const bucket_videos = videos.map((item) => {
        const key = getFileName(item);
        if (video_style && key in video_style) {
          return {
            id: "video_" + item,
            fileLocation: item,
            displayName:
              video_style[key].displayName ||
              key.split(".")[0].split("_").join(" "),
            mapCoordinates: video_style[key].coordinates || [],
            marker: video_style[key].marker || "",
            type: "S3",
            key: video_style[key].key,
          };
        } else {
          return {
            id: "video_" + item,
            fileLocation: item,
            displayName: key.split(".")[0].split("_").join(" "),
            mapCoordinates: [],
            marker: "",
            type: "S3",
            key: "",
          };
        }
      });

      if (
        mapStyle &&
        mapStyle?.HostedClips &&
        Object.keys(mapStyle?.HostedClips).length > 0
      ) {
        const hosted_videos = Object.keys(mapStyle?.HostedClips || []).map(
          (key) => {
            const item = mapStyle?.HostedClips[key];
            return {
              id: "video_" + item.URL,
              fileLocation: item.URL,
              displayName:
                item.displayName || key.split(".")[0].split("_").join(" "),
              mapCoordinates: item.coordinates || [],
              marker: item.marker || "",
              type:
                item.marker && item.marker === "LiDAR" ? "LiDAR" : "YouTube",
              key: item.key,
            };
          }
        );
        return bucket_videos.concat(hosted_videos);
      } else {
        return bucket_videos;
      }
    }
  };
  async function loadVideos() {
    if (mapInfo) {
      //get available videos
      const videos = getVideoInfo(mapInfo);
      if (videos && videos.length > 0) {
        const newState = videos.map((item) => ({
          video: item,
          checked: false,
        }));

        viewrefs.current.videoitemsRef = newState;
        dispatch({
          type: actionTypes.SET_VIDEO_ITEMS,
          payload: newState,
        });
        //add maplayers
        setMarkers(
          "PSOM",
          videos,
          viewrefs.current.markerVectorLayers,
          viewrefs.current.map
        );
        setMarkers(
          "Ground",
          videos,
          viewrefs.current.markerVectorLayers,
          viewrefs.current.map
        );
        setMarkers(
          "LiDAR",
          videos,
          viewrefs.current.markerVectorLayers,
          viewrefs.current.map
        );
        setMarkers(
          "Video3D",
          videos,
          viewrefs.current.markerVectorLayers,
          viewrefs.current.map
        );
        activeFeatures.current["Videos"] = true;
        triggerRefresh();
      }
    }
  }
  switch (header) {
    case "My Files":
      if (mapInfo && viewrefs.current.map) {
        const asyncFiles = async () => {
          const files = getFileMarkers(mapInfo);

          if (files && files.length > 0) {
            setMarkers(
              "Summary",
              files,
              viewrefs.current.markerVectorLayers,
              viewrefs.current.map,
              true
            );
            activeFeatures.current["My Files"] = true;
            triggerRefresh();
          }
        };
        asyncFiles();
      }
      break;
    case "Map Lines":
      loadVectorLayers();
      break;
    case "Dashboards":
      if (mapInfo && mapInfo.mapStyle?.Dashboards) {
        let tempDashboards: DashboardSettings = {};
        const Dashboards = mapInfo.mapStyle?.Dashboards;
        if (Object.keys(Dashboards).length > 0) {
          for (let key in Dashboards) {
            tempDashboards[key] = {
              displayName: Dashboards[key].displayName,
              selected: false,
              dependancy: Dashboards[key].dependancy,
              itemlist: Dashboards[key].itemList,
              specificSettings: Dashboards[key].specificSettings,
              chart: Dashboards[key].chart,
              legend: Dashboards[key].legend,
              active: Dashboards[key].active,
            };
          }
          dispatch({
            type: actionTypes.SET_DASHBOARD_SETTINGS,
            payload: tempDashboards,
          });
          activeFeatures.current["Dashboards"] = true;
          triggerRefresh();
        }
      }
      break;
    case "Markers":
      const markerLayerTypes = Object.keys(
        viewrefs.current.markerVectorLayers
      ).filter((item) => {
        return viewrefs.current.markerVectorLayers[item];
      });
      if (markerLayerTypes && markerLayerTypes.length > 0) {
        activeFeatures.current["Markers"] = true;
        triggerRefresh();
      }
      break;
    case "Photos":
      loadPhotos();
      break;
    case "Videos":
      loadVideos();
  }
};
export const setPanelStateOut = (
  header: string,
  dispatch: (value: Action) => void,
  viewrefs: React.MutableRefObject<ViewRefs>
) => {
  exitTasks(header, viewrefs);
  dispatch({
    type: actionTypes.SET_CLICKPANEL,
    payload: {
      active: false,
      box: null,
      header: "",
    },
  });
};
