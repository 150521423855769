import React from "react";

type Props = {
  baseBorder: string;
};
export const TopRight: React.FC<Props> = ({ baseBorder, children }) => (
  <div
    style={{
      position: "absolute",
      display: "flex",
      top: baseBorder,
      right: baseBorder,
      // flexDirection: "column",
      justifyContent: "flex-start",
      backgroundColor: "none",
      //padding: "10rem",
    }}
  >
    {children}
  </div>
);

export const TopLeft: React.FC<Props> = ({ baseBorder, children }) => (
  <div
    style={{
      position: "absolute",
      display: "flex",
      top: baseBorder,
      left: baseBorder,
      flexDirection: "column",
      justifyContent: "flex-start",
      backgroundColor: "none",
      //padding: "10rem",
    }}
  >
    {children}
  </div>
);

export const BottomLeft: React.FC<Props> = ({ baseBorder, children }) => (
  <div
    style={{
      position: "absolute",
      display: "flex",
      bottom: baseBorder,
      left: baseBorder,
      flexDirection: "column",
      justifyContent: "flex-end",
      backgroundColor: "none",
      //padding: "10rem",
    }}
  >
    {children}
  </div>
);

export const BottomRight: React.FC = ({ children }) => (
  <div
    style={{
      display: "flex",
      position: "absolute",
      bottom: "6em",
      right: ".5em",
      backgroundColor: "none",
      alignItems: "end",
    }}
  >
    {children}
  </div>
);

export const TopMiddle: React.FC<Props> = ({ baseBorder, children }) => (
  <div
    style={{
      position: "absolute",
      display: "flex",
      top: baseBorder,
      width: "100%",
      justifyContent: "center",
      pointerEvents: "none",
    }}
  >
    {children}
  </div>
);

export const BottomMiddle: React.FC<Props> = ({ baseBorder, children }) => (
  <div
    style={{
      position: "absolute",
      display: "flex",
      bottom: "0",
      width: "100%",
      justifyContent: "center",
      pointerEvents: "none",
    }}
  >
    {children}
  </div>
);
