import React from "react";
import loadingGIF from "../images/ZZ5H.gif";

interface Props {
  name: string;
}

export const Loader: React.FC<Props> = ({ name }) => {
  return (
    <div
      style={{
        width: 60,
        height: 43,
        border: "3px solid black",
        borderRadius: 4,
        margin: 5,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: 9, fontWeight: 600, width: 30, marginLeft: 3 }}>
        {name}
      </div>
      <img
        alt="loader"
        src={loadingGIF}
        style={{
          width: 20,
          height: 20,
        }}
      />
    </div>
  );
};
