import React, { useEffect, useState, useRef, useContext } from "react";
import { transform as oltransform } from "ol/proj";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import GeoJSON from "ol/format/GeoJSON";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import geojson2h3 from "geojson2h3";
import ReactSlider from "react-slider";
import close from "../../../images/closeInverse.png";
import { fetchCanopy } from "../../../api/AlchemyAnalytics";
import { CanopyData, Storage, DivPosition } from "../../../api/AlchemyApiTypes";
import { getDefaultPosition } from "../functions";
import { Rnd } from "react-rnd";
import { ButtonComponent } from "../../ButtonComponent";
import Auth from "../../../services/auth";
import { getLinearColorScale } from "../../../helpers/colours";
import loadingGIF from "../../../images/ZZ5H.gif";
import { StoreContext } from "../../../store/store";
import { State, Action, actionTypes } from "../../../store/storetypes";
import { ViewRefs } from "../../../store/refs";
import "./slider.css";

const canopycolorscale = getLinearColorScale([0, 30], ["white", "green"]);

interface Props {
  // setSelected: (key: string) => void;
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  setDashboardSettingsMode: (mode: string | null) => void;
  auth: Auth;
  storage: Storage;
  // dashboardCleanupComponents: (key: string) => void;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const CanopyAnalysis: React.FC<Props> = ({
  // setSelected,
  mapviewRoot,
  setDashboardSettingsMode,
  auth,
  storage,
  // dashboardCleanupComponents,
  viewrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const {
    canopyCoordinateChanged,
    dashboardSettings,
    dashboardSettingModeState,
  } = store;

  const map = viewrefs.current.map;

  const [position, setPosition] = useState<DivPosition>({ x: 0, y: 0 });

  const onClose = () => {
    // dashboardCleanupComponents("CanopyAnalysis");
    // setSelected("CanopyAnalysis");
    const newSettings = { ...dashboardSettings };
    newSettings["CanopyAnalysis"].selected = false;
    dispatch({
      type: actionTypes.SET_DASHBOARD_SETTINGS,
      payload: newSettings,
    });
  };

  const resolutions = useRef<string[]>([
    "h3index_15",
    "h3index_14",
    "h3index_13",
  ]);

  const descriptions: { [key: string]: string } = {
    h3index_15: "High",
    h3index_14: "Medium",
    h3index_13: "Low",
  };
  const initializeObjects = (itemlist: string[], val: any) => {
    return itemlist.reduce((obj: any, item) => {
      return {
        ...obj,
        [item]: val,
      };
    }, {});
  };
  interface H3VectorSource {
    [key: string]: VectorSource<Geometry> | null;
  }
  interface H3Layer {
    [key: string]: VectorLayer<VectorSource<Geometry>> | null;
  }
  interface AvgElevation {
    [key: string]: {
      [key: string]: { count: number; elevation: number };
    };
  }
  interface GHObj {
    [key: string]: any;
  }
  const avgElevation = useRef<AvgElevation>({});
  const ghobj = useRef<GHObj>(initializeObjects(resolutions.current, {}));
  const [resolution, setResolution] = useState<string>(resolutions.current[0]);
  const defaultResolution = useRef<{ set: boolean; res: string }>({
    set: false,
    res: resolutions.current[0],
  });

  const [inProgress, setInProgress] = useState<number>(0);
  const [swatch, setSwatch] = useState<number>(10);
  const level = useRef<number>(10);
  const h3layer = useRef<H3Layer>(initializeObjects(resolutions.current, null));
  const h3vectorsource = useRef<H3VectorSource>(
    initializeObjects(resolutions.current, null)
  );
  const loadedFromCache = useRef<boolean>(false);

  useEffect(() => {
    let mounted = true;
    if ("canopy" in storage) {
      h3layer.current = storage["canopy"];
      h3vectorsource.current = storage["canopy_source"];
      const res = storage["canopy_resolution"];
      if (mounted) {
        setResolution(res);
      }
      if (map) {
        map.addLayer(
          h3layer.current[res] as VectorLayer<VectorSource<Geometry>>
        );
      }
      loadedFromCache.current = true;
      defaultResolution.current.set = true;
      if (mounted) {
        setSwatch(storage["canopy_brush"]);
      }
      level.current = storage["canopy_brush"];
    }
    return () => {
      mounted = false;
    };
  }, [storage, map, setResolution]);

  useEffect(() => {
    let mounted = true;
    const clickedPoint = viewrefs.current.canopyCoordinate;
    if (
      (clickedPoint[0] === 0 && clickedPoint[1] === 0) ||
      loadedFromCache.current
    ) {
      loadedFromCache.current = false;
    } else {
      const asyncfunc = async () => {
        const newLatLng: number[] = oltransform(
          clickedPoint,
          "EPSG:3857",
          "EPSG:4326"
        ).reverse();

        const setFeatureStyle = (feature: any) => {
          const fill = feature.get("System_fill");
          return new Style({
            fill: new Fill({
              color: fill,
            }),
            stroke: new Stroke({
              color: "transparent",
              width: 0.1,
            }),
          });
        };

        const hexstyle = (res: string, h3index: string) => {
          const lev: string = res.split("_")[1];
          const elevation =
            avgElevation.current[lev][h3index].elevation /
            avgElevation.current[lev][h3index].count;
          return {
            System_fill: canopycolorscale(elevation),
            height: elevation.toFixed(1),
            Class: "canopy",
          };
        };

        const hexdata: CanopyData[] = await fetchCanopy(
          auth,
          newLatLng[0].toString(),
          newLatLng[1].toString(),
          level.current
        );
        if (hexdata.length === 0) {
          if (mounted) {
            setInProgress(2);
          }
          return;
        }

        resolutions.current.forEach((res: string) => {
          const hexstylex = (h3index: string) => {
            return hexstyle(res, h3index);
          };
          const lev: string = res.split("_")[1];
          hexdata.forEach((item: any) => {
            if (lev in avgElevation.current) {
              //do nothing
            } else {
              //init
              avgElevation.current[lev] = {};
            }
            if (item[res] in avgElevation.current[lev]) {
              avgElevation.current[lev][item[res]].count++;
              avgElevation.current[lev][item[res]].elevation += item.elevation;
            } else {
              avgElevation.current[lev][item[res]] = {
                count: 1,
                elevation: item.elevation,
              };
            }
          });
          ghobj.current[res] = geojson2h3.h3SetToFeatureCollection(
            Object.keys(avgElevation.current[lev]),
            hexstylex
          );
        });
        resolutions.current.forEach((res: string) => {
          if (!h3layer.current[res]) {
            h3layer.current[res] = new VectorLayer();
          }

          if (!h3vectorsource.current[res]) {
            h3vectorsource.current[res] = new VectorSource({
              features: new GeoJSON({
                featureProjection: "EPSG:3857",
              }).readFeatures(ghobj.current[res]),
            });
          } else {
            const nv = new VectorSource({
              features: new GeoJSON({
                featureProjection: "EPSG:3857",
              }).readFeatures(ghobj.current[res]),
            });

            (h3vectorsource.current[res] as VectorSource<Geometry>).addFeatures(
              nv.getFeatures()
            );
          }
          (
            h3layer.current[res] as VectorLayer<VectorSource<Geometry>>
          ).setSource(h3vectorsource.current[res] as VectorSource<Geometry>);
          (
            h3layer.current[res] as VectorLayer<VectorSource<Geometry>>
          ).setStyle(setFeatureStyle);
        });
        if (!defaultResolution.current.set) {
          defaultResolution.current.set = true;
          storage["canopy_resolution"] = defaultResolution.current.res;
          if (map) {
            map.addLayer(
              h3layer.current[defaultResolution.current.res] as VectorLayer<
                VectorSource<Geometry>
              >
            );
          }
        }
        storage["canopy"] = h3layer.current;
        storage["canopy_source"] = h3vectorsource.current;
        storage["canopy_brush"] = level.current;
        if (mounted) {
          setInProgress(0);
        }
      };
      if (mounted) {
        setInProgress(1);
      }
      asyncfunc();
    }
    return () => {
      mounted = false;
    };
  }, [
    viewrefs,
    canopyCoordinateChanged,
    auth,
    map,
    resolutions,
    setInProgress,
    storage,
  ]);
  useEffect(() => {
    return () => {
      dispatch({
        type: actionTypes.SET_CLEAR_DASHBOARD,
        payload: "CanopyAnalysis",
      });
    };
  }, [dispatch]);

  const InsertBorder =
    dashboardSettingModeState && dashboardSettingModeState === "Canopy"
      ? "Solid 2px red"
      : "Solid 2px #ffffff";

  const onCanopy = () => {
    setDashboardSettingsMode("Canopy");
    setInProgress(0);
  };
  const onCancel = () => {
    setDashboardSettingsMode(null);
    setInProgress(0);
  };
  const onClear = () => {
    //remove layers
    if (map) {
      resolutions.current.forEach((res: string) => {
        map.removeLayer(
          h3layer.current[res] as VectorLayer<VectorSource<Geometry>>
        );
      });
    }
    h3layer.current = initializeObjects(resolutions.current, null);
    h3vectorsource.current = initializeObjects(resolutions.current, null);
    delete storage["canopy"];
    delete storage["canopy_source"];
    defaultResolution.current.set = false;
    defaultResolution.current.res = resolution;
    avgElevation.current = {};
    setInProgress(0);
    viewrefs.current.canopyCoordinate = [0, 0];
  };

  const swatchChange = (value: number) => {
    setSwatch(value);
    level.current = value;
    storage["canopy_brush"] = level.current;
  };

  const onResolutionChange = (i: number) => {
    //remove layers
    if (map) {
      resolutions.current.forEach((res: string) => {
        if (h3layer.current[res]) {
          map.removeLayer(
            h3layer.current[res] as VectorLayer<VectorSource<Geometry>>
          );
        }
      });
      //addlayer
      if (h3layer.current[resolutions.current[i]]) {
        map.addLayer(
          h3layer.current[resolutions.current[i]] as VectorLayer<
            VectorSource<Geometry>
          >
        );
      }
    }
    storage["canopy_resolution"] = resolutions.current[i];
    setResolution(resolutions.current[i]);
  };

  const isChecked = (i: number) => {
    return resolution === resolutions.current[i];
  };

  const DashboardDimensions = { height: 240, width: 300 };

  return (
    <Rnd
      position={getDefaultPosition(
        DashboardDimensions.height,
        DashboardDimensions.width,
        position,
        mapviewRoot
      )}
      onDragStop={(e, d) => {
        setPosition({ x: d.x, y: d.y });
      }}
      style={{ zIndex: 9999 }}
    >
      <div
        style={{
          position: "absolute",
          display: "block",
          backgroundColor: "#000000",
          height: DashboardDimensions.height + "px",
          width: DashboardDimensions.width + "px",
          zIndex: 999,
          border: "1px solid #757575",
          padding: "1rem",
          borderRadius: 4,
          color: "#ffffff",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "absolute",
            top: 10,
            left: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 18,
            }}
          >
            {"Canopy Analysis"}
          </div>
          {inProgress === 1 && (
            <img
              alt="ip"
              src={loadingGIF}
              style={{ height: 20, marginTop: -15 }}
            />
          )}
          {inProgress === 0 && <div style={{ height: 20 }} />}
          {inProgress === 2 && (
            <div style={{ height: 20, color: "red", marginTop: -10 }}>
              No data found
            </div>
          )}
        </div>
        <img
          alt="close"
          src={close}
          style={{
            width: 20,
            position: "absolute",
            top: 0,
            right: 0,
            cursor: "pointer",
          }}
          onClick={() => onClose()}
        ></img>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            marginTop: "40px",
          }}
        >
          <ButtonComponent
            name="Paint"
            style={{
              width: "80px",
              height: "30px",
              border: InsertBorder,
              marginLeft: "0px",
              marginRight: "0px",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
            }}
            onClick={() => onCanopy()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
          />
          <ButtonComponent
            name="Cancel"
            style={{
              width: "80px",
              height: "30px",
              marginLeft: "0px",
              marginRight: "0px",
              border: "Solid 2px #ffffff",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
            }}
            onClick={() => onCancel()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
          />
          <ButtonComponent
            name="Clear"
            style={{
              width: "80px",
              height: "30px",
              marginLeft: "0px",
              marginRight: "0px",
              border: "Solid 2px #ffffff",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "14px",
              borderRadius: "2px",
            }}
            onClick={() => onClear()}
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: 12, margin: 10 }}>Brush size</div>
          <div
            style={{
              height: 48,
            }}
          >
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="thumb"
              trackClassName="track"
              max={30}
              min={1}
              value={swatch}
              onChange={(value, index) => {
                swatchChange(value);
              }}
              renderThumb={(props, state) => (
                <div {...props}>{state.valueNow}</div>
              )}
            />
          </div>
          <div style={{ fontSize: 11, marginTop: 10 }}>resolution</div>
          <div
            style={{
              display: "flex",
              marginTop: 0,
              justifyContent: "center",
            }}
          >
            {resolutions.current.map((res: string, i: number) => {
              return (
                <div key={i} style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ fontSize: 12 }}>{descriptions[res]}</span>
                  <input
                    style={{
                      margin: 5,
                      accentColor: "#000000",
                      cursor: "pointer",
                    }}
                    type="radio"
                    name="resolutions"
                    checked={isChecked(i)}
                    onChange={(e) => onResolutionChange(i)}
                  ></input>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Rnd>
  );
};
