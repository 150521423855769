import { CSSProperties } from "react";

export const fuel_colour_line: CSSProperties = {
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  marginLeft: 20,
  marginTop: 3,
};
export const color_red_border: CSSProperties = {
  backgroundColor: "#f83722",
  border: "1px solid #ffffff",
  borderRadius: 2,
  height: 14,
  width: 14,
};
export const color_orange_border: CSSProperties = {
  backgroundColor: "#feb14e",
  border: "1px solid #ffffff",
  borderRadius: 2,
  height: 14,
  width: 14,
};

export const color_cream_border: CSSProperties = {
  backgroundColor: "#fceda1",
  border: "1px solid #ffffff",
  borderRadius: 2,
  height: 14,
  width: 14,
};

export const color_yellow_border: CSSProperties = {
  backgroundColor: "#F0DC2C",
  border: "1px solid #ffffff",
  borderRadius: 2,
  height: 14,
  width: 14,
};

export const color_green_border: CSSProperties = {
  backgroundColor: "#007800",
  border: "1px solid #ffffff",
  borderRadius: 2,
  height: 14,
  width: 14,
};

export const color_text: CSSProperties = {
  color: "#969696",
  fontSize: 12,
  fontWeight: "normal",
  paddingLeft: 10,
  paddingRight: 20,
  // paddingTop: 5,
  // paddingBottom: 5,
};
