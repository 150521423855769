import * as React from "react";
import { SVGProps } from "react";

const SidebarExpandedLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={109.378}
    height={24.86}
    viewBox="0 0 109.378 24.86"
    {...props}
  >
    <defs>{/* <style>{".a{fill:#757575;}"}</style> */}</defs>
    <path
      className="a"
      d="M8.456.59l6.091,11.96H12.272l-1.517-3H3.81l-1.517,3H0L6.107.59Zm-3.714,7.1H9.821l-2.532-5Z"
      transform="translate(0 -0.407)"
    />
    <path
      className="a"
      d="M55.9.59h2.133v10.1h8.731v1.857H55.9Z"
      transform="translate(-38.598 -0.407)"
    />
    <path
      className="a"
      d="M102.928,0c3.049,0,5.33,1.4,6.246,3.714l-1.767.966a4.49,4.49,0,0,0-4.463-2.786c-2.813,0-4.881,1.733-4.881,4.281s2.068,4.281,4.881,4.281a4.468,4.468,0,0,0,4.463-2.786l1.767.966c-.929,2.3-3.2,3.7-6.246,3.7-4.024,0-7.048-2.612-7.048-6.162S98.913,0,102.928,0Z"
      transform="translate(-66.204)"
    />
    <path
      className="a"
      d="M160.913.59V12.55H158.78V7.319h-8.131V12.55H148.52V.59h2.129V5.456h8.131V.59Z"
      transform="translate(-102.551 -0.407)"
    />
    <path
      className="a"
      d="M212.98.59V2.447h-9.462v3h8.512V7.3h-8.512v3.383h9.462v1.857H201.42V.59Z"
      transform="translate(-139.078 -0.407)"
    />
    <path
      className="a"
      d="M256.495,12.55,251.88,2.521V12.55H249.8V.59h3.3l4.3,9.363,4.3-9.363h3.231V12.55h-2.08V2.521L258.219,12.55Z"
      transform="translate(-172.484 -0.407)"
    />
    <path
      className="a"
      d="M318.989.59h2.6l-6.063,6.763v5.2h-2.129v-5.2L307.33.59h2.631l4.513,5.079Z"
      transform="translate(-212.208 -0.407)"
    />
    <path
      className="a"
      d="M4.794,59.71,7.92,65.99H6.336L5.67,64.641H2.222L1.548,65.99H0l3.142-6.28ZM2.817,63.44H5.085l-1.13-2.293Z"
      transform="translate(0 -41.229)"
    />
    <path
      className="a"
      d="M53.7,65.99H52.419L48.5,61.576V65.99H47.08V59.71h1.479l3.714,4.209V59.71h1.418Z"
      transform="translate(-32.508 -41.229)"
    />
    <path
      className="a"
      d="M94.779,59.7l3.123,6.28H96.317l-.662-1.349H92.207l-.659,1.349H90l3.142-6.286ZM92.8,63.426h2.266l-1.13-2.293Z"
      transform="translate(-62.144 -41.215)"
    />
    <path
      className="a"
      d="M137,59.71h1.461v5.042h4.247V65.99H137Z"
      transform="translate(-94.597 -41.229)"
    />
    <path
      className="a"
      d="M176.535,59.71h1.715l-3.126,3.677v2.6h-1.461v-2.6L170.53,59.71h1.758l2.126,2.476Z"
      transform="translate(-117.749 -41.229)"
    />
    <path
      className="a"
      d="M219.934,61v5.026H218.47V61h-2.73V59.76h6.914V61Z"
      transform="translate(-148.966 -41.264)"
    />
    <path
      className="a"
      d="M261.741,65.99H260.28V59.71h1.461Z"
      transform="translate(-179.72 -41.229)"
    />
    <path
      className="a"
      d="M290.959,59.4a3.354,3.354,0,0,1,3.377,2.006l-1.294.619a2.071,2.071,0,0,0-2.083-1.359,1.986,1.986,0,1,0,0,3.94,2.071,2.071,0,0,0,2.074-1.356l1.294.619a3.362,3.362,0,0,1-3.377,2.006c-2.167,0-3.73-1.365-3.73-3.241S288.8,59.4,290.959,59.4Z"
      transform="translate(-198.322 -41.015)"
    />
    <path
      className="a"
      d="M331.269,63.5a3.615,3.615,0,0,0,2.764,1.154c.972,0,1.656-.263,1.656-.718,0-.526-.517-.569-2.2-.752-1.495-.167-2.705-.474-2.705-1.733s1.2-2.049,3.021-2.049a4.457,4.457,0,0,1,3.272,1.164l-.929.972a3.244,3.244,0,0,0-2.448-.929c-.99,0-1.489.31-1.489.675s.436.5,1.987.675c1.647.183,2.993.436,2.993,1.811s-1.384,2.1-3.228,2.1a4.948,4.948,0,0,1-3.624-1.322Z"
      transform="translate(-228.096 -41.015)"
    />
    <path
      className="a"
      d="M344.7,36.352v.771h-.223v-.771h-.418V36.16h1.055v.192Z"
      transform="translate(-237.57 -24.968)"
    />
    <path
      className="a"
      d="M348.819,37.123l-.309-.74v.74h-.22V36.16h.337l.309.687.31-.687h.331v.963h-.217v-.74l-.325.74Z"
      transform="translate(-240.49 -24.968)"
    />
  </svg>
);

export default SidebarExpandedLogo;
