import React from "react";

interface Props {
  radius: number;
  setRadius: React.Dispatch<React.SetStateAction<number>>;
  blur: number;
  setBlur: React.Dispatch<React.SetStateAction<number>>;
  setDisableDrag: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Controls: React.FC<Props> = ({
  radius,
  setRadius,
  blur,
  setBlur,
  setDisableDrag,
}) => {
  const onRadiusChange = (value: string) => {
    setRadius(+value);
  };
  const onMouseenter = () => {
    setDisableDrag(true);
  };
  const onMouseleave = () => {
    setDisableDrag(false);
  };
  const onBlurChange = (value: string) => {
    setBlur(+value);
  };
  return (
    <div
      style={{ marginTop: "20px" }}
      onMouseEnter={() => onMouseenter()}
      onMouseLeave={() => onMouseleave()}
    >
      <table>
        <tbody>
          <tr>
            <td style={{ paddingRight: "10px" }}>Radius Size</td>
            <td>
              <input
                style={{ accentColor: "#363636", cursor: "pointer" }}
                type="range"
                min="1"
                max="10"
                step="0.5"
                value={radius}
                onChange={(e) => onRadiusChange(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Blur Size</td>
            <td>
              <input
                style={{ accentColor: "#363636", cursor: "pointer" }}
                type="range"
                min="1"
                max="30"
                step="0.5"
                value={blur}
                onChange={(e) => onBlurChange(e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
