import React, { useEffect, useRef } from "react";
import { ElevationConfig } from "../../../../api/AlchemyApiTypes";
import { draw } from "./draw";

interface Props {
  config: ElevationConfig;
}

export const ElevationLegend: React.FC<Props> = ({ config }) => {
  const ref = useRef<SVGSVGElement>(null);

  const drawGraph = () => {
    draw(ref.current, config);
  };
  useEffect(drawGraph, [config]);

  return <svg ref={ref} width={config.width} height={config.height}></svg>;
};
