import React, { useContext } from "react";
import { ElevationLegend } from "../dashboards/elevationprofile/elevationLegend/ElevationLegendReact";
import { ElevationConfig } from "../../api/AlchemyApiTypes";
import { StoreContext } from "../../store/store";
import { State } from "../../store/storetypes";

export const Elevation: React.FC = () => {
  const [store] = useContext(StoreContext) as [State];
  const { elevation, showlegend } = store;
  if (showlegend.Elevation) {
    const config: ElevationConfig = {
      elevation: elevation,
      width: 60,
      height: 400,
      minElevation: 1890,
      maxElevation: 2540,
      fontSize: 14,
      textColor: "#ffffff",
      markerColor: "black",
      markerWidth: 3,
      textBackground: {
        color: "#000000",
        stroke: "#000000",
        strokeWidth: 1,
        borderradius: 4,
        height: "1em",
      },
      layout: {
        margin: { top: 20, right: 10, bottom: 20, left: 10 },
      },
    };

    return (
      <div
        style={{
          display: "inline-block",
          backgroundColor: "none",
          marginLeft: 5,
        }}
      >
        <ElevationLegend config={config} />
      </div>
    );
  } else {
    return null;
  }
};
