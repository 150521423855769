import React, { useEffect, useState, useContext } from "react";
import Auth from "../../services/auth";
import {
  FieldPhotoControls,
  FieldPhotoObject,
  Storage,
} from "../../api/AlchemyApiTypes";
import { FieldPhotoPlayer } from "./FieldPhotoPlayer";
import { StoreContext } from "../../store/store";
import { State, Action, actionTypes } from "../../store/storetypes";
import { ViewRefs } from "../../store/refs";

interface Props {
  auth: Auth;
  mapviewRoot: React.RefObject<HTMLDivElement> | undefined;
  storage: Storage;
  viewrefs: React.MutableRefObject<ViewRefs>;
}

export const FieldPhotoDisplayController: React.FC<Props> = ({
  auth,
  mapviewRoot,
  storage,
  viewrefs,
}) => {
  const [store, dispatch] = useContext(StoreContext) as [
    State,
    React.Dispatch<Action>
  ];
  const { fieldphotoitems, hoveredFieldPhoto } = store;
  const [urls, seturls] = useState<FieldPhotoControls | null>(null);

  useEffect(() => {
    let mounted = true;
    async function getUrl() {
      const newurls: FieldPhotoControls = {};
      if (fieldphotoitems) {
        for (var i = 0; i < fieldphotoitems.length; i++) {
          if (fieldphotoitems[i].checked) {
            const filepath = fieldphotoitems[i].fieldphoto.fileLocation;
            newurls[i] = {
              fieldPhotoObject: fieldphotoitems[i],
              url: "",
              filepath: filepath,
            };
          }
        }
      }
      if (mounted) {
        seturls(newurls);
      }
    }
    getUrl();
    return () => {
      mounted = false;
    };
  }, [fieldphotoitems, auth]);

  const setPlay = (id: string) => {
    const newUrls: FieldPhotoControls = { ...urls };
    if (fieldphotoitems) {
      const newvideoitems: FieldPhotoObject[] | undefined = [
        ...fieldphotoitems,
      ];
      newvideoitems[+id].checked = false;
      viewrefs.current.fieldphotoitemsRef = newvideoitems;
      dispatch({
        type: actionTypes.SET_FIELD_PHOTO_ITEMS,
        payload: newvideoitems,
      });
      seturls(newUrls);
    }
  };

  const showPlayer = (id: string) => {
    if (urls) {
      const index = Object.keys(urls).indexOf(id);
      const name = urls[id].fieldPhotoObject.fieldphoto.displayName;

      return (
        <FieldPhotoPlayer
          key={id}
          fieldphoto={urls[id]}
          id={id}
          setPlay={setPlay}
          index={index}
          name={name}
          mapviewRoot={mapviewRoot}
          storage={storage}
          auth={auth}
          isHovered={name ? hoveredFieldPhoto?.indexOf(name) !== -1 : false}
          viewrefs={viewrefs}
        />
      );
    } else {
      return null;
    }
  };
  if (urls && Object.keys(urls).length > 0) {
    return (
      <>
        {Object.keys(urls).map((id: string) => {
          return showPlayer(id);
        })}
      </>
    );
  } else {
    return null;
  }
};
