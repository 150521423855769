import * as d3 from "d3";
import { meterstofeet } from "../../map/math";

export function draw(container, scalefactors, config) {
  const {
    data,
    highlightedpoint,
    width: cwidth,
    height: cheight,
    minElevation,
    maxElevation,
    layout,
    fontSize,
    colours,
    setSelectedSegment,
  } = config;

  const chartdata = data.sort((a, b) => (a.id > b.id ? 1 : -1));

  /** d3 margin convention */

  const width = cwidth - layout.margin.left - layout.margin.right;
  const height = cheight - layout.margin.top - layout.margin.bottom;

  const highlightedpointsdata = chartdata
    .filter((item) => {
      return item.id === highlightedpoint;
    })
    .map((d) => {
      return [
        { x: d.distance, y: meterstofeet(maxElevation) },
        { x: d.distance, y: meterstofeet(minElevation) },
      ];
    });

  //   let xAxisG = {}; /** x axis group */
  let yAxisG = {}; /** y axis group */
  let xAxisG = {}; /** y axis group */
  let firstZoom = true; /** don't set the brushactive flag on first zoom */

  const svg = d3.select(container);
  svg.selectAll("g").remove();

  //   const background = svg.append("g");

  const chart = svg
    .append("g")
    .attr(
      "transform",
      "translate(" + layout.margin.left + "," + layout.margin.top + ")"
    );

  var defs = chart.append("defs");

  defs
    .append("clipPath")
    .attr("id", "mask")
    .style("pointer-events", "none")
    .append("rect")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", width)
    .attr("height", height);

  const xbrushArea = svg
    .append("g")
    .attr(
      "transform",
      "translate(" +
        layout.margin.left +
        "," +
        (height + layout.margin.top + layout.margin.bottom - 20) +
        ")"
    );
  const ybrushArea = svg
    .append("g")
    .attr("transform", "translate(" + 0 + "," + layout.margin.top + ")");

  const axesGroup = svg
    .append("g")
    .attr(
      "transform",
      "translate(" + layout.margin.left + "," + layout.margin.top + ")"
    );

  /** Keeps track of manual brushing */
  let xbrush = { e: width, w: 0, offset: 0 };
  let ybrush = { n: 0, s: height, offset: 0 };

  const zoom = d3
    .zoom()
    .scaleExtent([1, Infinity])
    .translateExtent([
      [0, 0],
      [width, height],
    ])
    .extent([
      [0, 0],
      [width, height],
    ])
    .on("zoom", zoomed)
    .on("end", zoomend);

  svg.call(zoom);

  //Append a linearGradient element to the defs and give it a unique id
  var linearGradient = defs
    .append("linearGradient")
    .attr("id", "linear-gradient");

  let gradientdata = [
    { color: "#007800", value: 0 },
    { color: "#595600", value: 28 },
    { color: "#774B00", value: 47 },
    { color: "#926E31", value: 60 },
    { color: "#AD9265", value: 74 },
    { color: "#FFFFFF", value: 100 },
  ];

  linearGradient
    .attr("x1", "0%")
    .attr("y1", "100%")
    .attr("x2", "0%")
    .attr("y2", "0%");

  linearGradient
    .selectAll("stop")
    .data(gradientdata)
    .enter()
    .append("stop")
    .attr("offset", (d) => d.value + "%")
    .attr("stop-color", (d) => d.color);

  let y = d3
    .scaleLinear()
    .range([height, 0])
    .domain([meterstofeet(minElevation), meterstofeet(maxElevation)]);
  let scaleY = d3
    .scaleLinear()
    .range([height, 0])
    .domain([meterstofeet(minElevation), meterstofeet(maxElevation)]);

  let x = d3
    .scaleLinear()
    .range([0, width])
    .domain(
      d3.extent(chartdata, function (d) {
        return d.distance;
      })
    );
  let scaleX = d3
    .scaleLinear()
    .range([0, width])
    .domain(
      d3.extent(chartdata, function (d) {
        return d.distance;
      })
    );

  const xAxis = d3.axisBottom(x).tickSize(6);
  const yAxis = d3.axisLeft(y).tickSize(6);

  // define the area
  const area = d3
    .area()
    .x(function (d) {
      return scaleX(d.distance);
    })
    .y0(0)
    .y1(function (d) {
      return scaleY(d.elevation);
    });

  // define the line
  const line = d3
    .line()
    .x(function (d) {
      return scaleX(d.x);
    })
    .y(function (d) {
      return scaleY(d.y);
    });

  const gr = chart
    .append("rect")
    .attr("class", "gradientRect")
    .attr("x", 0)
    .attr("y", 0)
    .attr("height", height)
    .attr("width", width)
    .style("fill", "url(#linear-gradient)");

  gr.attr("clip-path", "url(#mask)");

  const groverlay = chart
    .append("rect")
    .attr("class", "gradientRect")
    .attr("x", 0)
    .attr("y", 0)
    .attr("height", height)
    .attr("width", width)
    .attr("opacity", 0.5)
    .style("fill", "#C3C3C3");

  groverlay.attr("clip-path", "url(#mask)");

  chart
    .append("path")
    .datum(chartdata)
    .attr("class", "area")
    .attr("fill", colours.chart)
    .attr("d", area);

  const hlines = chart
    .selectAll(".hpoint")
    .data(highlightedpointsdata)
    .enter()
    .append("path")
    .attr("class", "hpoint")
    .style("stroke", "red")
    .style("stroke-width", 1)
    .attr("d", line);

  hlines.attr("clip-path", "url(#mask)");

  xAxisG = axesGroup
    .append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis);

  yAxisG = axesGroup.append("g").call(yAxis);
  /** Style the xAxis */
  xAxisG.selectAll("line").attr("stroke", "black");
  xAxisG.selectAll(".domain").attr("stroke", "black");
  xAxisG
    .selectAll("text")
    .attr("fill", colours.textColor.x)
    .style("font-size", fontSize.x);

  yAxisG.selectAll("line").attr("stroke", "black");
  yAxisG.selectAll(".domain").attr("stroke", "black");
  yAxisG
    .selectAll("text")
    .attr("fill", colours.textColor.y)
    .style("font-size", fontSize.y);

  xbrushArea
    .append("rect")
    .attr("class", "overlay")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", width)
    .attr("height", 20)
    .attr("fill", colours.brushbackground.x)
    .style("cursor", "crosshair");
  xbrushArea
    .append("rect")
    .attr("class", "brush")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", width)
    .attr("height", 20)
    .attr("fill", colours.brushbar.x)
    .attr("stroke", "black")
    .attr("stroke-width", 1)
    .style("cursor", "move")
    .call(d3.drag().on("start", mx_started));
  xbrushArea
    .append("rect")
    .attr("class", "handle handle--w")
    .attr("x", -3)
    .attr("y", -3)
    .attr("width", 6)
    .attr("height", 20 + 6)
    .attr("fill", "grey")
    .style("cursor", "ew-resize")
    .style("opacity", 0)
    .call(d3.drag().on("start", w_started));
  xbrushArea
    .append("rect")
    .attr("class", "handle handle--e")
    .attr("x", width - 3)
    .attr("y", -3)
    .attr("width", 6)
    .attr("height", 20 + 6)
    .attr("fill", "grey")
    .style("cursor", "ew-resize")
    .style("opacity", 0)
    .call(d3.drag().on("start", e_started));

  ybrushArea
    .append("rect")
    .attr("class", "overlay")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", 20)
    .attr("height", height)
    .attr("fill", colours.brushbackground.y)
    .style("cursor", "crosshair");
  ybrushArea
    .append("rect")
    .attr("class", "brush")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", 20)
    .attr("height", height)
    .attr("fill", colours.brushbar.y)
    .attr("stroke", "black")
    .attr("stroke-width", 1)
    .style("cursor", "move")
    .call(d3.drag().on("start", my_started));
  ybrushArea
    .append("rect")
    .attr("class", "handle handle--n")
    .attr("x", -3)
    .attr("y", -3)
    .attr("width", 20 + 6)
    .attr("height", 6)
    .attr("fill", "grey")
    .style("cursor", "ns-resize")
    .style("opacity", 0)
    .call(d3.drag().on("start", n_started));
  ybrushArea
    .append("rect")
    .attr("class", "handle handle--s")
    .attr("x", -3)
    .attr("y", height - 3)
    .attr("width", 20 + 6)
    .attr("height", 6)
    .attr("fill", "grey")
    .style("cursor", "ns-resize")
    .style("opacity", 0)
    .call(d3.drag().on("start", s_started));

  function n_started(event) {
    event.on("drag", n_dragged).on("end", xy_saveScales);
  }
  function s_started(event) {
    event.on("drag", s_dragged).on("end", xy_saveScales);
  }
  function w_started(event) {
    event.on("drag", w_dragged).on("end", xy_saveScales);
  }
  function e_started(event) {
    event.on("drag", e_dragged).on("end", xy_saveScales);
  }
  function mx_started(event) {
    xbrush.offset = event.x - xbrush.w;
    event.on("drag", mx_dragged).on("end", xy_saveScales);
  }
  function my_started(event) {
    ybrush.offset = event.y - ybrush.n;
    event.on("drag", my_dragged).on("end", xy_saveScales);
  }
  function n_dragged(event) {
    if (event.y < 0 || event.y > ybrush.s) return;
    n_brushaction(event.y);
  }
  function s_dragged(event) {
    if (event.y > height || event.y < ybrush.n) return;
    s_brushaction(event.y);
  }
  function w_dragged(event) {
    if (event.x < 0 || event.x > xbrush.e) return;
    w_brushaction(event.x);
  }
  function e_dragged(event) {
    if (event.x > width || event.x < xbrush.w) return;
    e_brushaction(event.x);
  }

  function n_brushaction(event_y) {
    ybrush.n = event_y;
    const brushareaheight = ybrush.s - ybrush.n;
    const gradientheight = (height * height) / brushareaheight;
    ybrushArea.select(".handle--n").attr("y", () => event_y - 3);
    ybrushArea
      .select(".brush")
      .attr("y", () => event_y)
      .attr("height", brushareaheight);
    scaleY.domain([ybrush.s, ybrush.n].map(y.invert, y));
    yAxisG.call(yAxis.scale(scaleY));
    chart.select(".area").attr("d", area);
    chart.selectAll(".hpoint").attr("d", line);
    chart
      .selectAll(".gradientRect")
      .attr("height", gradientheight)
      .attr("y", y_gradientvaluecalculation(gradientheight, brushareaheight));
  }
  function s_brushaction(event_y) {
    ybrush.s = event_y;
    const brushareaheight = ybrush.s - ybrush.n;
    const gradientheight = (height * height) / brushareaheight;
    ybrushArea.select(".handle--s").attr("y", () => event_y - 3);
    ybrushArea.select(".brush").attr("height", brushareaheight);
    scaleY.domain([ybrush.s, ybrush.n].map(y.invert, y));
    yAxisG.call(yAxis.scale(scaleY));
    chart.select(".area").attr("d", area);
    chart.selectAll(".hpoint").attr("d", line);
    chart
      .selectAll(".gradientRect")
      .attr("height", gradientheight)
      .attr("y", y_gradientvaluecalculation(gradientheight, brushareaheight));
  }
  function y_gradientvaluecalculation(gradientheight, brushareaheight) {
    const brushdiff = height - brushareaheight;
    const gradientdiff = gradientheight - height;
    const gradientYDiffN =
      ybrush.n === 0 ? 0 : gradientdiff * (ybrush.n / brushdiff) * -1;
    return gradientYDiffN;
  }

  function w_brushaction(event_x) {
    xbrush.w = event_x;
    xbrushArea.select(".handle--w").attr("x", () => event_x - 3);
    xbrushArea
      .select(".brush")
      .attr("x", () => event_x)
      .attr("width", xbrush.e - xbrush.w);
    scaleX.domain([xbrush.w, xbrush.e].map(x.invert, x));
    xAxisG.call(xAxis.scale(scaleX));
    chart.select(".area").attr("d", area);
    chart.selectAll(".hpoint").attr("d", line);
  }
  function e_brushaction(event_x) {
    xbrush.e = event_x;
    xbrushArea.select(".handle--e").attr("x", () => event_x - 3);
    xbrushArea.select(".brush").attr("width", xbrush.e - xbrush.w);
    scaleX.domain([xbrush.w, xbrush.e].map(x.invert, x));
    xAxisG.call(xAxis.scale(scaleX));
    chart.select(".area").attr("d", area);
    chart.selectAll(".hpoint").attr("d", line);
  }

  function my_dragged(event) {
    if (
      event.y - ybrush.offset < 0 ||
      event.y - ybrush.offset + ybrush.s - ybrush.n > height
    ) {
      return;
    }
    let barHeight = ybrush.s - ybrush.n;
    ybrush.n = event.y - ybrush.offset;
    ybrush.s = ybrush.n + barHeight;

    const brushareaheight = ybrush.s - ybrush.n;
    const gradientheight = (height * height) / brushareaheight;
    ybrushArea.select(".handle--n").attr("y", () => ybrush.n - 3);
    ybrushArea.select(".handle--s").attr("y", () => ybrush.s - 3);
    ybrushArea
      .select(".brush")
      .attr("y", () => ybrush.n)
      .attr("height", barHeight);
    scaleY.domain([ybrush.s, ybrush.n].map(y.invert, y));
    yAxisG.call(yAxis.scale(scaleY));
    chart.select(".area").attr("d", area);
    chart.selectAll(".hpoint").attr("d", line);
    chart
      .selectAll(".gradientRect")
      .attr("height", gradientheight)
      .attr("y", y_gradientvaluecalculation(gradientheight, brushareaheight));
  }
  function mx_dragged(event) {
    if (
      event.x - xbrush.offset < 0 ||
      event.x - xbrush.offset + xbrush.e - xbrush.w > width
    )
      return;
    let barWidth = xbrush.e - xbrush.w;
    xbrush.w = event.x - xbrush.offset;
    xbrush.e = xbrush.w + barWidth;
    xbrushArea.select(".handle--w").attr("x", () => xbrush.w - 3);
    xbrushArea.select(".handle--e").attr("x", () => xbrush.e - 3);
    xbrushArea
      .select(".brush")
      .attr("x", () => xbrush.w)
      .attr("width", barWidth);
    scaleX.domain([xbrush.w, xbrush.e].map(x.invert, x));
    xAxisG.call(xAxis.scale(scaleX));
    chart.select(".area").attr("d", area);
    chart.selectAll(".hpoint").attr("d", line);
  }

  function xy_saveScales() {
    scalefactors.xbrush.e = xbrush.e;
    scalefactors.xbrush.w = xbrush.w;
    scalefactors.ybrush.n = ybrush.n;
    scalefactors.ybrush.s = ybrush.s;
    scalefactors.brushactive = true;

    const selectedsegment = {
      w: xbrush.w === 0 ? undefined : x.invert(xbrush.w),
      e: xbrush.e === width ? undefined : x.invert(xbrush.e),
    };

    setSelectedSegment(selectedsegment);
  }
  /** trigger a zoom event for initial render based on saved scalefactors */
  const transform = d3.zoomIdentity
    .scale(scalefactors.scale)
    .translate(...scalefactors.position);
  svg.call(zoom.transform, transform);

  if (scalefactors.brushactive) {
    w_brushaction(scalefactors.xbrush.w);
    e_brushaction(scalefactors.xbrush.e);
    n_brushaction(scalefactors.ybrush.n);
    s_brushaction(scalefactors.ybrush.s);
  }

  function zoomed(event) {
    if (event.sourceEvent && event.sourceEvent.type === "dblclick") return;
    /** get tranformed scales */
    scaleX = event.transform.rescaleX(x);
    scaleY = event.transform.rescaleY(y);
    /** redraw the axes */
    xAxisG.call(xAxis.scale(scaleX));
    yAxisG.call(yAxis.scale(scaleY));
    /** redraw the canvas */

    chart.select(".area").attr("d", area);
    chart.selectAll(".hpoint").attr("d", line);
    /** Adjust x brush */
    const xrange = scaleX.range().map(event.transform.invertX, event.transform);
    xbrushArea.select(".handle--w").attr("x", () => xrange[0] - 3);
    xbrushArea.select(".handle--e").attr("x", () => xrange[1] - 3);
    xbrushArea
      .select(".brush")
      .attr("x", () => xrange[0])
      .attr("width", xrange[1] - xrange[0]);
    xbrush.w = xrange[0];
    xbrush.e = xrange[1];
    /** adjust y brush */
    const yrange = scaleY.range().map(event.transform.invertY, event.transform);
    ybrushArea.select(".handle--n").attr("y", () => yrange[1] - 3);
    ybrushArea.select(".handle--s").attr("y", () => yrange[0] - 3);
    ybrushArea
      .select(".brush")
      .attr("y", () => yrange[1])
      .attr("height", yrange[0] - yrange[1]);
    ybrush.n = yrange[1];
    ybrush.s = yrange[0];

    const brushareaheight = ybrush.s - ybrush.n;
    const gradientheight = (height * height) / brushareaheight;
    chart
      .selectAll(".gradientRect")
      .attr("height", gradientheight)
      .attr("y", y_gradientvaluecalculation(gradientheight, brushareaheight));
    /** Save the current scalefactors */
    let tx = event.transform.x;
    let tk = event.transform.k;
    let ty = event.transform.y;
    tx = tx / tk;
    ty = ty / tk;
    scalefactors.scale = tk;
    scalefactors.position = [tx, ty];
  }
  function zoomend(event) {
    if (event.sourceEvent && event.sourceEvent.type === "dblclick") return;
    if (firstZoom) {
      firstZoom = false;
    } else {
      xy_saveScales();
      scalefactors.brushactive = false;
    }
  }
}
